import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

const FooterItem = ({ item, active, onClick }) => (
	<BottomNavigationAction
		key={item.label}
		label={item.label}
		icon={<item.icon />}
		classes={{
			label: 'label'
		}}
		className={`footer-item ${active ? 'footer-item--active' : ''}`}
		showLabel
		onClick={e => onClick(e, item)}
		component="a"
		href={item.path}
	/>
);

export default withStyles({})(FooterItem);
