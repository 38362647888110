import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';

export default withStyles({})(({ children, variant, className = '' }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `metric-card--${variant}`;

    return <div className={`metric-card ${cssVariant || ''} ${className}`}>{children}</div>;
});
