import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M400 64h-48V12c0-6.62-5.37-12-12-12h-8c-6.62 0-12 5.37-12 12v52H128V12c0-6.62-5.37-12-12-12h-8c-6.62 0-12 5.37-12 12v52H48C21.49 64 0 85.49 0 112v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zM48 96h352c8.82 0 16 7.17 16 16v48H32v-48c0-8.82 7.17-16 16-16zm352 384H48c-8.82 0-16-7.17-16-16V192h384v272c0 8.82-7.17 16-16 16z"
        />
    </SvgIcon>
);
