import gql from 'graphql-tag';
import cloneDeep from 'lodash.clonedeep';
import { getClient } from '../../utils/apollo';
import { isEmailValid, messages, validationHelper } from '../../utils/validation';
import { deleteTypeName } from '../../utils/objects';
import { getUser } from '../../utils/sessions';
import Quote from '../../fragments/Quote';

export const createNewEnquiry = () => {
    return {
        LegacyKey: null,
        EnquiryByBusiness: null,
        EnquiryType: null,
        ClientSameAsEnquirer: null,
        Notes: null,
        CremationDate: null,
        CremationTime: null,
        Deceased: {
            FirstName: null,
            MiddleName: null,
            Surname: null,
            Gender: null,
            ResidentialAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            },
            DateOfBirth: null,
            Situation: null,
            DeathStatus: null,
            DateOfDeathTiming: null,
            DateOfDeath1: null,
            DateOfDeath2: null,
            PlaceOfDeath: null,
            PlaceOfDeathUseResidentialAddress: false
        },
        Client: {
            RelationshipToDeceased: null,
            Title: null,
            FirstName: null,
            MiddleName: null,
            Surname: null,
            PrimaryNumber: null,
            SecondaryNumber: null,
            Email: null,
            PostalAddressSameAsResidentialAddress: false,
            PostalAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            },
            ResidentialAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            }
        },
        Enquirer: {
            RelationshipToDeceased: null,
            Title: null,
            FirstName: null,
            MiddleName: null,
            Surname: null,
            PrimaryNumber: null,
            SecondaryNumber: null,
            Email: null,
            PostalAddressSameAsResidentialAddress: false,
            PostalAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            },
            ResidentialAddress: {
                Name: null,
                AddressLine1: null,
                AddressLine2: null,
                Suburb: null,
                Postcode: null,
                State: null,
                Country: null
            }
        }
    };
};

export const SampleOptions = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3', value: '3' }
];

export const GenderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Indeterminate', value: 'Indeterminate' },
    { label: 'Intersex', value: 'Intersex' },
    { label: 'Unknown', value: 'Unknown' }
];

export const BrandEnquiry = [
    { label: 'Mountain View Crematorium', value: '1' },
    { label: 'H Parsons', value: '2' },
    { label: 'Stan Crapp', value: '3' },
    { label: 'Nowra', value: '4' }
];

export const EnquiryType = [
    { label: 'Cremation Enquiry', value: 'Cremation' },
    { label: 'Plaque Enquiry', value: 'Plaque' }
    // {label:'General', value:'General'},
    // {label:'Pre-Need', value:'PreNeed'},
];

export const AssociatedOrderTypes = [
    { label: 'Cremation Order', value: 'cremationOrder' },
    { label: 'Plaque Order', value: 'plaqueOrder' },
    { label: 'Product Order', value: 'productOrder' }
];

export const ProductStatus = [
    { label: 'Unknown', value: 'unknown' },
    { label: 'Pending', value: 'pending' }
];

export const CurrentSituation = [
    { label: 'Is alive', value: 'Alive' },
    { label: 'Is deceased', value: 'Deceased' }
];

export const RelationshipToDeceasedOptions = [
    { label: 'I am the client', value: 'self' },
    { label: 'Child', value: 'child' },
    { label: 'Parent/guardian', value: 'parent' },
    { label: 'Sibling', value: 'sibling' },
    { label: 'Relative', value: 'relative' },
    { label: 'Legal representative', value: 'representative' },
    { label: 'Carer', value: 'carer' },
    { label: 'Associate', value: 'associate' },
    { label: 'Friend', value: 'friend' },
    { label: 'Funeral director', value: 'director' },
    { label: 'Widow', value: 'widow' },
    { label: 'Widower', value: 'widower' },
    { label: 'No relation', value: 'none' }
];

export const TitleOptions = [
    { label: 'Ms', value: 'Ms' },
    { label: 'Mrs', value: 'Mrs' },
    { label: 'Miss', value: 'Miss' },
    { label: 'Dr', value: 'Dr' },
    { label: 'Fr', value: 'Fr' },
    { label: 'Mr', value: 'Mr' },
    { label: 'Rev', value: 'Rev' }
];

export const DeathTimingOption = {
    APPROX: { label: 'Approximately', value: 'Approx' },
    ON: { label: 'On', value: 'On' },
    ABOUT: { label: 'On or about', value: 'On or about' },
    AFTER: { label: 'On or after', value: 'Sometime On or After' },
    BETWEEN: { label: 'Between', value: 'Between' },
    UNKNOWN: { label: 'Unknown', value: 'Unknown' }
};
export const DeathTimingOptions = Object.values(DeathTimingOption);

export const validateEnquirerField = (newValue, persistedValue, hasValue, getField) => {
    const byBusiness = getField('EnquiryByBusiness') === true;
    if (byBusiness || hasValue) return validationHelper.ok();

    return validationHelper.required();
};

export const validation = {
    Enquiry: {
        required: ['EnquiryType', 'Deceased.Gender', 'Deceased.Situation'],

        onValidate: {
            'Enquirer.FirstName': validateEnquirerField,
            'Enquirer.Title': validateEnquirerField,
            'Enquirer.RelationshipToDeceased': validateEnquirerField,
            'Enquirer.PrimaryNumber': validateEnquirerField,
            'Enquirer.ResidentialAddress.AddressLine1': (newValue, persistedValue, hasValue, getField) => {
                if (getField('EnquiryByBusiness') === true) return validationHelper.ok();
                if (!!hasValue) return validationHelper.ok();
                return validationHelper.required();
            },
            'Enquirer.Email': (newValue, persistedValue, hasValue, getField) => {
                if (getField('Enquirer.NoEmailAvailable') === true) return validationHelper.ok();
                if (getField('EnquiryByBusiness') === true) return validationHelper.ok();
                if (!hasValue) return validationHelper.required();

                return isEmailValid(persistedValue)
                    ? validationHelper.ok()
                    : validationHelper.required(messages.invalidEmail);
            },
            'Deceased.DateOfBirth': (newValue, persistedValue, hasValue, getField) => {
                const dobUnknown = getField('Deceased.DateOfBirthUnknown') === true;
                if (dobUnknown || hasValue) return validationHelper.ok();

                return validationHelper.required();
            },
            'Deceased.DateOfDeathTiming': (newValue, persistedValue, hasValue, getField) => {
                const situation = getField('Deceased.Situation');
                if (situation !== 'Deceased' || hasValue) return validationHelper.ok();

                return validationHelper.required();
            },
            'Deceased.DateOfDeath1': (newValue, persistedValue, hasValue, getField) => {
                const dateOfDeathType = getField('Deceased.DateOfDeathTiming');
                if (
                    getField('Deceased.Situation') !== 'Deceased' ||
                    dateOfDeathType === DeathTimingOption.UNKNOWN.value ||
                    hasValue
                )
                    return validationHelper.ok();

                return validationHelper.required();
            },
            'Deceased.DateOfDeath2': (newValue, persistedValue, hasValue, getField) => {
                const dateOfDeathType = getField('Deceased.DateOfDeathTiming');
                if (
                    getField('Deceased.Situation') !== 'Deceased' ||
                    dateOfDeathType !== DeathTimingOption.BETWEEN.value ||
                    hasValue
                )
                    return validationHelper.ok();

                return validationHelper.required();
            }
        }
    }
};

export const createCremationOrderMutation = gql`
    mutation CreateCremationOrder($input: CreateCremationOrderInput!) {
        createCremationOrder(input: $input) {
            ID
            LegacyKey
            Enquiry {
                ID
            }
        }
    }
`;

export const createCremationAsync = async enquiryForm => {
    const user = getUser();
    const variables = {
        input: {
            CreatedByID: user.ID,
            EnquiryID: enquiryForm.getField('ID')
        }
    };

    return await getClient().mutate({
        mutation: createCremationOrderMutation,
        variables
    });
};

export const createPlaqueOrderMutation = gql`
    mutation CreatePlaqueOrder($input: CreatePlaqueOrderInput!) {
        createPlaqueOrder(input: $input) {
            ID
            LegacyKey
            Enquiry {
                ID
            }
        }
    }
`;

export const createPlaqueAsync = async enquiryForm => {
    const user = getUser();
    const variables = {
        input: {
            CreatedByID: user.ID,
            EnquiryID: enquiryForm.getField('ID')
        }
    };

    return await getClient().mutate({
        mutation: createPlaqueOrderMutation,
        variables
    });
};

export const copyEnquiry = ({
    EnquiryByBusiness,
    EnquiryType,
    ClientSameAsEnquirer,
    CremationDate,
    CremationTime,
    Notes,
    Deceased,
    Client,
    Enquirer,
    Business,
    PlaqueLocation
}) => {
    let copied = {
        EnquiryByBusiness,
        EnquiryType,
        ClientSameAsEnquirer,
        CremationDate,
        CremationTime,
        Notes,
        Deceased,
        Client,
        Enquirer,
        Business,
        PlaqueLocation
    };

    const refactorObject = type => {
        if (copied[type] && copied[type].ID) {
            deleteTypeName(copied[type]);
            delete copied[type].ID;
        } else {
            delete copied[type];
        }
    };

    refactorObject('Deceased');
    refactorObject('Client');
    refactorObject('Enquirer');
    refactorObject('Business');
    refactorObject('PlaqueLocation');

    return deleteTypeName(cloneDeep(copied));
};

export const copyFuneral = ({
    FirstName,
    MiddleName,
    Surname,
    Gender,
    DateOfBirth,
    UnknownDOB,
    ResidentialAddress1,
    ResidentialAddress2,
    ResidentialTown,
    ResidentialState,
    PostCode,
    Informant,
    DateOfDeath1,
    DateOfDeath2,
    DateOfDeathType,
    PlaceOfDeathType,
    PlaceOfDeath,
    PlaceOfDeathResidentialAddress,
    UseResidentialAddress
}) => {
    let placeOfDeath = {};
    if (PlaceOfDeathType === 'Residential') {
        placeOfDeath = {
            AddressLine1: PlaceOfDeathResidentialAddress.AddressLine1,
            AddressLine2: PlaceOfDeathResidentialAddress.AddressLine2,
            Suburb: PlaceOfDeathResidentialAddress.Suburb,
            State: PlaceOfDeathResidentialAddress.State,
            Postcode: PlaceOfDeathResidentialAddress.Postcode,
            Country: PlaceOfDeathResidentialAddress.Country
        };
    } else {
        placeOfDeath = {
            AddressLine1: PlaceOfDeath.AddressLine1,
            AddressLine2: PlaceOfDeath.AddressLine2,
            Suburb: PlaceOfDeath.Suburb,
            State: PlaceOfDeath.State,
            Postcode: PlaceOfDeath.Postcode,
            Country: PlaceOfDeath.Country
        };
    }

    let copied = {
        Deceased: {
            FirstName: FirstName,
            MiddleName: MiddleName,
            Surname: Surname,
            Gender: Gender,
            DateOfBirth: DateOfBirth,
            DateOfBirthUnknown: UnknownDOB,
            ResidentialAddress: {
                AddressLine1: ResidentialAddress1,
                AddressLine2: ResidentialAddress2,
                Suburb: ResidentialTown,
                State: ResidentialState,
                Postcode: PostCode
            },
            Situation: 'Deceased',
            DateOfDeathTiming: DateOfDeathType,
            DateOfDeath1: DateOfDeath1,
            DateOfDeath2: DateOfDeath2,
            PlaceOfDeath: placeOfDeath,
            PlaceOfDeathUseResidentialAddress: UseResidentialAddress
        },
        Client: {
            FirstName: Informant.FirstName,
            MiddleName: Informant.MiddleName,
            Surname: Informant.Surname,
            PrimaryNumber: Informant.Phone,
            SecondaryNumber: Informant.Mobile,
            ResidentialAddress: {
                AddressLine1: Informant.ResidentialAddress1,
                Suburb: Informant.SuburbCityAust,
                State: Informant.ResidentialState,
                Postcode: Informant.ResidentialPostcode,
                Country: Informant.Country
            }
        }
    };

    // const refactorObject = type => {
    //     if (copied[type] && copied[type].ID) {
    //         deleteTypeName(copied[type]);
    //         delete copied[type].ID;
    //     } else {
    //         delete copied[type];
    //     }
    // };
    //
    // refactorObject('Informant');

    return deleteTypeName(cloneDeep(copied));
};

export const readEnquiryQuotes = gql`
    ${Quote}
    query readEnquiryQuotes($id: ID!) {
        readCMQuotes(enquiryID: $id) {
            edges {
                node {
                    ...Quote
                }
            }
        }
    }
`;
