import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { compose } from 'react-apollo/index';
import { withStyles } from '@material-ui/core/styles';
import Modal from './Modal';
import DataFormView from '../DataFormView';
import { getClient } from '../../utils/apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import CloseIcon from '../icon/CloseIcon';
import Appointment from '../../fragments/Appointment';
import AppointmentDetail from '../../views/Calendar/AppointmentPopover';
import { validationHelper } from '../../utils/validation';
import { isNullOrUndefined } from '../../utils/objects';
import { isContactDefined } from '../../utils/bookable';
import { dateToString, dateToTimeString } from '../../utils/date';

class AppointmentModal extends Component {
    state = {
        itemID: null,
        type: null
    };

    static getDerivedStateFromProps({ appointmentId }, { itemID }) {
        if (appointmentId && (!itemID || appointmentId !== itemID)) {
            return { itemID: appointmentId };
        }

        return null;
    }

    render() {
        const { open, onMutate, eventBegin, eventUntil, appointmentDefaults = {}, memberList = [] } = this.props;
        const { itemID } = this.state;
        return (
            <Modal open={open} onClose={this.closeModal} title={itemID ? 'Edit Appointment' : 'Add New Appointment'}>
                <DataFormView
                    tabs={[
                        {
                            id: 'Appointment',
                            label: 'Appointment',
                            component: withStyles({})(AppointmentDetail),
                            fragment: Appointment,
                            onLoad: data => {
                                if (data.LinkTo === undefined)
                                    data.LinkTo =
                                        (!!data.Plaque && !!data.Plaque.ID && 'Plaque') ||
                                        (!!data.Cremation && !!data.Cremation.ID && 'Cremation') ||
                                        (!!data.EnquiryCM && !!data.EnquiryCM.ID && 'Enquiry') ||
                                        null;
                            },
                            formatSaveData: (saveData, state) => {
                                if (isContactDefined(saveData.LocationCM)) {
                                    saveData.LocationCMID = state.LocationCM.ID;
                                    delete saveData.LocationCM;
                                }

                                if (saveData.LocationResidentialAddress) {
                                    delete saveData.LocationResidentialAddress.Name;
                                }

                                const localAssignedMembers = [];
                                if (saveData && saveData.Members) {
                                    state.Members.forEach(function(member) {
                                        if (!!member.ID) {
                                            localAssignedMembers.push({
                                                ID: member.ID
                                            });
                                        }
                                    });

                                    saveData.Members = localAssignedMembers;
                                }

                                if (saveData && saveData.Cremation && saveData.Cremation.ID) {
                                    saveData.CremationID = saveData.Cremation.ID;
                                }
                                if (saveData && saveData.Plaque && saveData.Plaque.ID) {
                                    saveData.PlaqueID = saveData.Plaque.ID;
                                }
                                if (saveData && saveData.EnquiryCM && saveData.EnquiryCM.ID) {
                                    saveData.EnquiryCMID = saveData.EnquiryCM.ID;
                                }
                                delete saveData.Cremation;
                                delete saveData.Plaque;
                                delete saveData.EnquiryCM;

                                delete saveData.LinkTo;
                                delete saveData.UseAddressBook;
                                delete saveData.PhoneOnly;
                                if (saveData.ID === null) {
                                    delete saveData.ID;
                                }
                            },
                            validation: {
                                required: ['Type', 'Reason'],
                                onValidate: {
                                    LinkTo: (newValue, persistedValue, hasValue, getField) => {
                                        const val = newValue || persistedValue || null;
                                        if (getField('Type') === 'AshCollection' && !val) {
                                            return validationHelper.required();
                                        }
                                        return validationHelper.ok();
                                    },
                                    EnquiryCM: (newValue, persistedValue, hasValue, getField) => {
                                        const val = newValue || persistedValue || null;
                                        if (
                                            getField('LinkTo') === 'Enquiry' &&
                                            (!val || isNullOrUndefined(val.ID) || !Number(val.ID))
                                        ) {
                                            return validationHelper.required();
                                        }
                                        return validationHelper.ok();
                                    },
                                    Cremation: (newValue, persistedValue, hasValue, getField) => {
                                        const val = newValue || persistedValue || null;
                                        if (
                                            getField('LinkTo') === 'Cremation' &&
                                            (!val || isNullOrUndefined(val.ID) || !Number(val.ID))
                                        ) {
                                            return validationHelper.required();
                                        }
                                        return validationHelper.ok();
                                    },
                                    Plaque: (newValue, persistedValue, hasValue, getField) => {
                                        const val = newValue || persistedValue || null;
                                        if (
                                            getField('LinkTo') === 'Plaque' &&
                                            (!val || isNullOrUndefined(val.ID) || !Number(val.ID))
                                        ) {
                                            return validationHelper.required();
                                        }
                                        return validationHelper.ok();
                                    }
                                }
                            }
                        }
                    ]}
                    createNewFunc={() => ({
                        ID: null, // fake data
                        Reason: '',
                        LinkTo: undefined,
                        Type: null,
                        Date: dateToString(eventBegin || moment().add(1, 'day')),
                        DateTo: dateToString(eventUntil || moment().add(1, 'day')),
                        TimeFrom: dateToTimeString(eventBegin) || '09:00',
                        TimeTo: dateToTimeString(eventUntil) || '11:00',
                        AllDay: false,
                        Recuring: false,
                        Office: null,
                        Members: memberList,
                        LocationType: 'Residential',
                        ...appointmentDefaults
                    })}
                    objectType="Appointment"
                    name="Appointment"
                    context={this}
                    createNew={!itemID}
                    itemId={itemID}
                    client={getClient()}
                    additionalActions={[
                        {
                            label: (
                                <Fragment>
                                    <CloseIcon />
                                    {'Close'}
                                </Fragment>
                            ),
                            variant: 'secondary',
                            onClick: this.closeModal,
                            skipSave: true
                        }
                    ]}
                    onUpdated={onMutate}
                    onCreated={e => {
                        this.onCreateNew(e);
                        if (onMutate) onMutate(e);
                    }}
                />
            </Modal>
        );
    }

    closeModal = event => {
        const { onClose } = this.props;
        if (onClose) onClose(event);
        let cleanState = {};
        Object.keys(this.state).forEach(x => (cleanState[x] = null));
        this.setState(cleanState);
    };

    onCreateNew(obj) {
        this.setState({ itemID: obj.ID });
    }
}

export default compose(withSnackbarMessage, withStyles({}))(AppointmentModal);
