import gql from "graphql-tag";
import PlaqueProduct from './PlaqueProduct';

export default gql`
    fragment PlaqueCombinationLocation on CremationManagerAddressBook {
        ID
        Name
        Contact
        Email
        Phone
        Fax
        Mobile
        AddressLine1
        AddressLine2
        State
        Suburb
        Postcode
        AddressBookCategory
        Religion
        Notes
        NotCurrent
        RegistrationNoMisc

        PlaqueCombinations {
            ID
            LocationType
            PlaqueSize
            AttachmentMethod
            PlaquePosition
            PlaqueMaterial
            WallPosition
            PlaqueWordingAdditionalLines
            PlaqueWordingLines
            VaseType
            AshesSelection
            Motif
            FlowerBorder
            RaisedBorder
            BorderType
            PlacementByCouncil
            QuoteTemplatesAllowed
            ProductOrder {
                ID
                Niche
                NicheCoordinates
                Location {
                    ID
                    Name
                    Contact
                    Email
                    Phone
                    Fax
                    Mobile
                    AddressLine1
                    AddressLine2
                    State
                    Suburb
                    Postcode
                    AddressBookCategory
                    Religion
                    Notes
                    NotCurrent
                    RegistrationNoMisc
                }
                Product {
                    ...PlaqueProduct
                }
            }
            PlaqueSupplier {
                ID
                Name
                Contact
                Email
                Phone
                Fax
                Mobile
                AddressLine1
                AddressLine2
                State
                Suburb
                Postcode
                AddressBookCategory
                Religion
                Notes
                NotCurrent
                RegistrationNoMisc
            }
            WallPositions {
                ID
                Title
            }
            MaterialOptions {
                ID
                Title
            }
            Optionals {
                ID
                Title
            }
            AttachmentMethods {
                ID
                Title
            }
            BorderTypes {
                ID
                Title
            }
        }
    }
    ${PlaqueProduct}
`;
