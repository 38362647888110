import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import Typography from '../../../components/form/Typography';
import TextField from '../../../components/form/TextField';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import TimeSelect from '../../../components/form/TimeSelect';
import Checkbox from '../../../components/form/Checkbox';
import Button from '../../../components/form/Button';
import createForm from '../../../utils/createForm';
import { adjustTimeString, dateTimeToString } from '../../../utils/date';
import TickCircleIcon from '../../../components/icon/TickCircleIcon';

class ReflectionRoomBookingModal extends Component {
    state = {
        ReflectionRoomID: null
    };

    componentWillReceiveProps(nextProps) {
        const { data } = nextProps;
        if (data) {
            this.form.state = { ...data };
            this.setState({ ReflectionRoomID: data.ID });
        }
    }

    render() {
        const { open, onClose, data } = this.props;
        const form = this.form;
        const { ReflectionRoomID } = this.state;
        return (
            <Modal open={open} variant="simple" onClose={onClose} canClickOut={false}>
                <Grid container>
                    <Grid item>
                        <Typography variant="h2" weight="medium">
                            {ReflectionRoomID > 0 ? 'Add a' : 'Edit'} reflection room booking
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField label="Date" type="date" form={form} name="Date" />
                    </Grid>

                    <Grid item xs={12} sm={6} />

                    <Grid item xs={12} sm={6}>
                        <TextField label="Start Time" type="time" form={form} name="Time" />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TimeSelect label="Duration (Hours)" form={form} name="Duration" />
                    </Grid>

                    <Grid item>
                        <Typography variant="h4">Attendees</Typography>
                        <Inline>
                            <Checkbox label="Applicant" form={form} name="Applicant" />
                            <Checkbox label="Authorised Contact" form={form} name="AuthorisedContact" />
                        </Inline>
                    </Grid>

                    <Grid item>
                        <TextField label="Additional Attendees" multiline form={form} name="AttendeeNames" />
                    </Grid>

                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings}>
                            <div />
                            <Button variant="confirmation" onClick={() => this.closeMe({ ...this.form.state })}>
                                <TickCircleIcon />
                                {data && data.Date ? 'Update' : 'Add'} Booking
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    closeMe = data => {
        const { onClose } = this.props;
        if (onClose) {
            if (data) {
                data.Start = dateTimeToString(data.Date + ' ' + data.Time);
                data.End = dateTimeToString(data.Date + ' ' + adjustTimeString(data.Time, data.Duration * 60));
            }
            onClose(data);
        }
        this.setState({ ReflectionRoomID: null });
    };

    form = createForm(this, {});
}

export default withStyles({})(ReflectionRoomBookingModal);
