import React, { Component, Fragment } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Search from '@material-ui/icons/Search';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import Typography from '../../components/form/Typography';
import Button from '../../components/form/Button';
import AlertModal from '../../components/modal/AlertModal';
import MessageBox from '../../components/modal/MessageBox';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Modal from '../../components/modal/Modal';
import TextField from '../../components/form/TextField';
import Checkbox from '../../components/form/Checkbox';
import Filter from '../../components/modal/Filter';

class ModalStyling extends Component {
    state = {
        showMessageBox: false,
        showOkCancelMessageBox: false,

        showPrimaryAlertModal: false,
        showSaveAlertModal: false,
        showWarningAlertModal: false,
        showErrorAlertModal: false,

        showSimpleModal: false,
        showPrimaryModal: false,
        showFilterPop: false,
        showStepModal: false
    };

    render() {
        const {
            showMessageBox,
            showOkCancelMessageBox,
            showPrimaryAlertModal,
            showSaveAlertModal,
            showWarningAlertModal,
            showErrorAlertModal
        } = this.state;

        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={32}>
                                <Grid item xs={12}>
                                    <Typography variant="h4">Modal Styling</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h6">Filter Popover</Typography>
                                    <Separator />
                                    {`<Filter anchorEl={event.currentTarget} />`}
                                </Grid>
                                <Grid item xs={12}>
                                    <Inline>
                                        <Button
                                            onClick={event =>
                                                this.setState({
                                                    showFilterPop: event.currentTarget
                                                })
                                            }
                                        >
                                            Filter
                                        </Button>
                                    </Inline>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6">Message box styling</Typography>
                                    <Separator />
                                    {`<MessageBox />`}
                                </Grid>
                                <Grid item xs={12}>
                                    <Inline>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showMessageBox: true
                                                })
                                            }
                                        >
                                            Message Box
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showOkCancelMessageBox: true
                                                })
                                            }
                                        >
                                            Confirmation onAccept=
                                        </Button>
                                    </Inline>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6">Modals</Typography>
                                    <Separator /> {`<Modal />`}
                                </Grid>
                                <Grid item xs={12}>
                                    <Inline>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showSimpleModal: true
                                                })
                                            }
                                        >
                                            variant=simple
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showPrimaryModal: true
                                                })
                                            }
                                        >
                                            variant=primary
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showStepModal: true
                                                })
                                            }
                                        >
                                            variant=stepped
                                        </Button>
                                    </Inline>
                                </Grid>

                                <Grid item xs={12}>
                                    <Typography variant="h6">Alert modals</Typography>
                                    <Separator />
                                    {`<AlertModal />`}
                                </Grid>
                                <Grid item xs={12}>
                                    <Inline>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showPrimaryAlertModal: true
                                                })
                                            }
                                        >
                                            variant=primary
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showSaveAlertModal: true
                                                })
                                            }
                                        >
                                            variant=save
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showWarningAlertModal: true
                                                })
                                            }
                                        >
                                            variant=warning
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                this.setState({
                                                    showErrorAlertModal: true
                                                })
                                            }
                                        >
                                            variant=error
                                        </Button>
                                    </Inline>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <MessageBox
                    open={showMessageBox}
                    title="Message"
                    message="This is a message box"
                    onCancel={() => this.setState({ showMessageBox: false })}
                />

                <MessageBox
                    open={showOkCancelMessageBox}
                    title="Confirmation"
                    message="This is a confirmation message box. Are you sure you want to continue?"
                    onCancel={() => this.setState({ showOkCancelMessageBox: false })}
                    onAccept={() => this.setState({ showOkCancelMessageBox: false })}
                />

                <AlertModal
                    variant="primary"
                    open={showPrimaryAlertModal}
                    message="Please select an option for this alert modal"
                    onClose={() => this.setState({ showPrimaryAlertModal: false })}
                    primaryAction="Option A"
                    onClickPrimaryAction={() => this.setState({ showPrimaryAlertModal: false })}
                    secondaryAction="Option B"
                    onClickSecondaryAction={() => this.setState({ showPrimaryAlertModal: false })}
                />

                <AlertModal
                    variant="save"
                    open={showSaveAlertModal}
                    message="Please select an option for this alert modal"
                    onClose={() => this.setState({ showSaveAlertModal: false })}
                    primaryAction="Option A"
                    onClickPrimaryAction={() => this.setState({ showSaveAlertModal: false })}
                    secondaryAction="Option B"
                    onClickSecondaryAction={() => this.setState({ showSaveAlertModal: false })}
                />

                <AlertModal
                    variant="warning"
                    open={showWarningAlertModal}
                    message="Please select an option for this alert modal"
                    onClose={() => this.setState({ showWarningAlertModal: false })}
                    primaryAction="Option A"
                    onClickPrimaryAction={() => this.setState({ showWarningAlertModal: false })}
                    secondaryAction="Option B"
                    onClickSecondaryAction={() => this.setState({ showWarningAlertModal: false })}
                />

                <AlertModal
                    variant="error"
                    open={showErrorAlertModal}
                    message="Please select an option for this alert modal"
                    onClose={() => this.setState({ showErrorAlertModal: false })}
                    primaryAction="Option A"
                    onClickPrimaryAction={() => this.setState({ showErrorAlertModal: false })}
                    secondaryAction="Option B"
                    onClickSecondaryAction={() => this.setState({ showErrorAlertModal: false })}
                />

                {this.renderSimpleModal()}
                {this.renderPrimaryModal()}
                {this.renderFilterPop()}
                {this.renderStepModal()}
            </Fragment>
        );
    }

    renderSimpleModal() {
        const { showSimpleModal } = this.state;
        return (
            <Modal open={showSimpleModal} variant="simple" onClose={() => this.setState({ showSimpleModal: false })}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        This is a modal with content!
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderPrimaryModal() {
        const { showPrimaryModal } = this.state;
        return (
            <Modal
                open={showPrimaryModal}
                variant="primary"
                onClose={() => this.setState({ showPrimaryModal: false })}
                title="Modal Title"
                subtitle="Subtitle"
            >
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        Primary modal content
                    </Grid>
                    <Grid item xs={12}>
                        <Inline alignment={inlineAlignment.right}>
                            <Button
                                onClick={() => this.setState({ showPrimaryModal: false })}
                                variant="modal-action modal-action--cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => this.setState({ showPrimaryModal: false })}
                                variant="modal-action modal-action--ok"
                            >
                                Ok
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderStepModal() {
        const { showStepModal } = this.state;
        return (
            <Modal
                variant="stepped"
                canClickOut={false}
                open={showStepModal}
                onClose={() => this.setState({ showStepModal: false })}
                steps={[
                    {
                        abbreviation: 'Details',
                        title: 'Enter your details',
                        subtitle: 'This is a subtitle',
                        content: <div>Please enter your details</div>
                    },
                    {
                        abbreviation: 'Confirmation',
                        title: 'Confirm your details',
                        content: <div>Your details need to be confirmed</div>
                    },
                    {
                        abbreviation: 'Summary',
                        title: 'Summary Page',
                        content: <div>Here's a summary</div>
                    }
                ]}
            >
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Inline alignment={inlineAlignment.right}>
                            <Button
                                onClick={() => this.setState({ showStepModal: false })}
                                variant="modal-action modal-action--ok"
                            >
                                Ok
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderFilterPop() {
        const { showFilterPop } = this.state;

        return (
            <Filter
                //variant="simple"
                //className="popover--filter"
                open={!!showFilterPop}
                anchorEl={showFilterPop}
                onClose={() => this.setState({ showFilterPop: false })}
            >
                <Grid container>
                    <Grid item>
                        <Typography variant="h4" size="lg">
                            Filter
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h5" size="md">
                            Keyword
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            placeholder="filter by some thing"
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment>
                                        <div className="input-adornment--end">
                                            <Search />
                                        </div>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h5" size="md">
                            Staff
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Inline>
                            <Button variant="secondary" size="tiny">
                                Select All
                            </Button>
                            <Button variant="secondary" size="tiny">
                                Unselect All
                            </Button>
                        </Inline>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={6}>
                                <Checkbox label="Staff Name" />
                            </Grid>
                            <Grid item xs={6}>
                                <Checkbox label="Staff Name" />
                            </Grid>
                            <Grid item xs={6}>
                                <Checkbox label="Staff Name" />
                            </Grid>
                            <Grid item xs={6}>
                                <Checkbox label="Staff Name" />
                            </Grid>
                            <Grid item xs={6}>
                                <Checkbox label="Staff Name" />
                            </Grid>
                            <Grid item xs={6}>
                                <Checkbox label="Staff Name" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Inline alignment={inlineAlignment.right}>
                            <Button
                                onClick={() => this.setState({ showFilterPop: false })}
                                variant="modal-action modal-action--ok"
                            >
                                Ok
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Filter>
        );
    }
}

export default withStyles({})(ModalStyling);
