import gql from 'graphql-tag';

export default gql`
    fragment TaskFragment on Task {
        ID
        Title
        Description
        Category
        RequiredAction
        TabURLSegment
        Due
        InProgress
        Completed
        Archived
        Comment
        Created
        LinkURL
        RecurFreq
        RecurType
        RecurEnds
        Comment
        Master {
            ID
        }
        Funeral {
            ID
            LegacyKey
        }
        Cremation {
            ID
            LegacyKey
        }
        Plaque {
            ID
            LegacyKey
        }
        EnquiryCM {
            ID
            LegacyKey
        }
        EnquiryFM {
            ID
            LegacyKey
        }
        Recurrences {
            ID
        }

        Audits {
            edges {
                node {
                    ID
                    Created
                    Type
                    Comment
                    AddedBy {
                        ID
                        Email
                        Surname
                        FirstName
                    }
                }
            }
        }

        AssignedMembers {
            ID
            Email
            Surname
            FirstName
            _join {
                TaskMembers {
                    ID
                    Starred
                }
            }
        }
        AssignedTeams {
            ID
            Title
        }
        AssignedLabels {
            ID
            Title
            NotActive
            Member {
                ID
            }
        }
        ChecklistItems {
            ID
            Title
            Completed
        }
        Attachments {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;
