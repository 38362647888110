import gql from "graphql-tag";
import AshLocationChange from "./AshLocationChange";

export default gql`
	fragment AshLocation on AshLocation {
		ID
		Created
		Location
		Ashes
		LocationDetail
		Notes
		Changes {
			...AshLocationChange
		}
	}
	${AshLocationChange}
`;
