import gql from "graphql-tag";

export default gql`
	fragment CremationManagerAddressBook on CremationManagerAddressBook {
		ID
        Name
        Contact
        Email
        Phone
        Fax
        Mobile
        AddressLine1
        AddressLine2
        State
        Suburb
        Postcode
        AddressBookCategory
        Religion
        Notes
        NotCurrent
        RegistrationNoMisc
	}
`;