import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 24.477 21.757" {...props}>
        <path
            fill="currentColor"
            d="M23.457,2.72H5.494L5.14.832a1.02,1.02,0,0,0-1-.832H.51A.51.51,0,0,0,0,.51V.85a.51.51,0,0,0,.51.51H3.855L6.827,17.208a2.72,2.72,0,1,0,4.367.47h6.169a2.72,2.72,0,1,0,4.251-.589,1.02,1.02,0,0,0-.989-.771H8.043l-.51-2.72H21.616a1.02,1.02,0,0,0,1-.812l1.841-8.839A1.02,1.02,0,0,0,23.457,2.72ZM10.2,19.038a1.36,1.36,0,1,1-1.36-1.36A1.361,1.361,0,0,1,10.2,19.038Zm9.519,1.36a1.36,1.36,0,1,1,1.36-1.36A1.361,1.361,0,0,1,19.718,20.4Zm1.621-8.159H7.279L5.749,4.08h17.29Z"
        />
    </SvgIcon>
);