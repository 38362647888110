import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Button as TabButton, Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Fab from '@material-ui/core/Fab';
import Clear from '@material-ui/icons/Clear';
import { stringIsNullOrEmpty } from '../../utils/strings';
import Typography from '../form/Typography';
import Inline, { inlineAlignment } from '../layout/Inline';
import TaskStar from '../workQueue/TaskStar';
import Button from '../form/Button';
import BackArrow from '../icon/BackArrow';
import { isNullOrUndefined } from '../../utils/objects';

class ModalWrapper extends Component {
    state = {
        index: 0
    };

    componentWillReceiveProps(nextProperties) {
        if (!isNullOrUndefined(nextProperties.activeTab)) {
            this.setState({ index: nextProperties.activeTab });
        }
    }

    render() {
        const { variant = 'primary' } = this.props;

        if (variant === 'simple') return this.renderSimple();

        if (stringIsNullOrEmpty(variant) || variant === 'primary') return this.renderPrimary();

        if (variant === 'task') return this.renderTask();

        if (variant === 'stepped') return this.renderStepped();

        return <span>unknown modal variant {variant}</span>;
    }

    renderSimple() {
        const { open, onClose, className, canClickOut = true, BackdropProps, children, actions } = this.props;
        return (
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                open={open}
                disableBackdropClick={!canClickOut}
                disableEscapeKeyDown={!canClickOut}
                BackdropProps={BackdropProps}
                onClose={onClose}
            >
                <div className={`modal ${className || ''}`}>
                    <div className="paper">
                        <Fab color="primary" aria-label="Close" className="fab close" onClick={() => onClose()}>
                            <Clear />
                        </Fab>
                        {children}
                    </div>
                    {!!actions &&
                        <div className="modal-footer paper">
                            {actions}
                        </div>
                    }
                </div>
            </Modal>
        );
    }

    renderStepped() {
        const { open, onClose, steps = [], canClickOut = false, children, className } = this.props;
        const { index } = this.state;

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="stepped-modal-title"
                aria-describedby="stepped-modal-description"
                disableBackdropClick={!canClickOut}
                disableEscapeKeyDown={!canClickOut}
                className={className}
            >
                <div className="modal modal--stepped">
                    <div className="modal-header">
                        {steps.map((step, i) => (
                            <div key={i}>
                                <TabButton
                                    className={`step-title-button ${index === i ? 'active' : ''}`}
                                    onClick={() => this.handleTabClick(step, i)}
                                >
                                    {step.abbreviation}
                                </TabButton>
                            </div>
                        ))}
                    </div>
                    <div className="modal-content paper">
                        {this.renderStep(index, steps[index])}
                        {children}
                    </div>
                    {!!steps[index].actions &&
                        <div className="modal-footer paper">
                            {steps[index].actions}
                        </div>
                    }
                </div>
            </Modal>
        );
    }

    handleTabClick(step, index) {
        this.setState({ index: index });
        if (step.hasOwnProperty('onChangeTab')) {
            step.onChangeTab(index);
        }
    }

    renderPrimary() {
        const { open, title, subtitle, onClose, children, actions, canClickOut = false } = this.props;

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="primary-modal-title"
                aria-describedby="primary-modal-description"
                disableBackdropClick={!canClickOut}
                disableEscapeKeyDown={!canClickOut}
            >
                <div className="modal modal--primary">
                    <div className="modal-header">
                        <Typography className="header" size="xs">
                            {title}
                        </Typography>
                        {subtitle && (
                            <Typography className="subtitle" size="xxlg">
                                {subtitle}
                            </Typography>
                        )}
                    </div>
                    <div className="modal-content paper">{children}</div>
                    {actions && <div className="modal-footer paper">{actions}</div>}
                </div>
            </Modal>
        );
    }

    renderTask() {
        const { open, title, taskid, onClose, children, canClickOut = false } = this.props;

        return (
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="primary-modal-title"
                aria-describedby="primary-modal-description"
                disableBackdropClick={!canClickOut}
                disableEscapeKeyDown={!canClickOut}
            >
                <div className="modal modal--task">
                    <div className="modal-header">
                        <div className="title-container">
                            <Inline center>
                                <Hidden smUp>
                                    <Button variant="primary icon-button white shadow" size="tiny" onClick={onClose}>
                                        <BackArrow />
                                    </Button>
                                </Hidden>
                                <Typography className="header" size="xs">
                                    {taskid && ` #${taskid}`}
                                </Typography>
                            </Inline>
                            {title && (
                                <Typography className="subtitle" size="xxlg">
                                    {title}
                                </Typography>
                            )}
                        </div>
                        <div className="star-container">
                            <TaskStar />
                        </div>
                    </div>
                    <div className="modal-content paper">{children}</div>
                </div>
            </Modal>
        );
    }

    renderStep(index, step) {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <div className="step-title">
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <Typography variant="h1">
                                {step.title && <span className="step-title-number">{step.title}&nbsp;</span>}
                                <span className="step-title-detail">{step.subtitle}</span>
                            </Typography>
                            {!!step.button && <div className="step-button">{step.button}</div>}
                        </Inline>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="step-content">{step.content}</div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles({})(ModalWrapper);
