import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo/index';
import CloseIcon from '../../../components/icon/CloseIcon';
import Button from '../../../components/form/Button';
import DialogActions from '@material-ui/core/DialogActions';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

class DocumentModal extends Component {
    state = {
        document: null
    };

    render() {
        const { classes, open, form, document, onClose, ...other } = this.props;

        if (!document || !open) {
            return null;
        }

        const winner = window.open(
            document.AbsoluteLink,
            '_blank',
            'menubar=no,width=' + Math.min(window.screen.width, 1024)
        );
        winner.focus();
        onClose();
        if (1 < 2) return null;

        return (
            <Dialog {...other} className={classes.root} onClose={onClose}>
                <DialogTitle> {`${document.Name}`} </DialogTitle>

                <DialogContent className={classes.modalContent}>
                    <iframe
                        src={document.AbsoluteLink}
                        style={{ width: '100%', height: '100%' }}
                        title="document-pdf"
                    />
                </DialogContent>

                <DialogActions className={classes.modalFooter}>
                    <Button onClick={onClose}>
                        <CloseIcon />
                        <span className={classes.svgLabel}>Close</span>
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = () => ({
    root: {
        '& > div:nth-child(2) > div:first-child': {
            maxWidth: 'unset',
            height: '100%'
        }
    },

    modalFooter: {
        padding: '0 24px 24px',
        margin: 0
    },

    modalContent: {
        height: 'auto',
        maxWidth: '1200px',
        width: '98vw',
        overflowY: 'hidden',
        overflowX: 'auto'
    }
});

// prettier-ignore
export default compose(
    withStyles(styles)
)(DocumentModal);
