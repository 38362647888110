import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Draggable from 'react-draggable';
import Paper from '@material-ui/core/Paper';
import cx from 'classnames';
import ViewTaskModalDetail from './ViewTaskModalDetail';

class ViewTaskModal extends Component {
    PaperComponent = (props, task, diagId) => {
        return (
            <Draggable key={diagId} handle={`#${diagId}`} cancel={'[class*="MuiDialogContent-root"]'} bounds="body">
                <Paper {...props} />
            </Draggable>
        );
    };

    handleClose = () => {
        const { onClose } = this.props;
        onClose();
    };

    render() {
        const { classes, open, task } = this.props;
        if (!(task && !!task.node && !!open)) return null;
        const diagId = `ViewTaskModal-${task ? task.node.ID : 'X'}`;
        return (
            <Dialog
                disableBackdropClick
                className={cx(classes.root, 'workqueue-page', 'draggable-root')}
                open={open}
                PaperComponent={props => this.PaperComponent(props, task, diagId)}
                BackdropProps={{
                    invisible: true,
                    style: {
                        pointerEvents: 'none' // allow clicking on background!
                    }
                }}
                onClose={this.handleClose}
                aria-labelledby={diagId}
            >
                <ViewTaskModalDetail dragHandle={diagId} task={task} onClose={this.handleClose} />
            </Dialog>
        );
    }
}

const styles = ({ breakpoints, palette }) => ({
    root: {
        pointerEvents: 'none',
        '& > :nth-child(2)': {
            pointerEvents: 'none',
            '& > div': {
                borderRadius: 12,
                border: '3px solid ' + palette.primary.main,
                pointerEvents: 'auto',
                overflow: 'hidden',
                maxHeight: '94vh',
                [breakpoints.down('sm')]: {
                    margin: 6
                }
            }
        }
    }
});

export default withStyles(styles)(ViewTaskModal);
