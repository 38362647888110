import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M464 32H48C21.53 32 0 53.53 0 80v32c0 22.10 15.02 40.75 35.39 46.308A47.72 47.72 0 0 0 32 176v256c0 26.46 21.53 48 48 48h352c26.46 0 48-21.53 48-48V176a47.72 47.72 0 0 0-3.39-17.692C496.97 152.75 512 134.10 512 112V80c0-26.46-21.53-48-48-48zm-16 400c0 8.83-7.16 16-16 16H80c-8.83 0-16-7.16-16-16V176c0-8.83 7.16-16 16-16h352c8.83 0 16 7.16 16 16v256zm32-320c0 8.83-7.16 16-16 16H48c-8.83 0-16-7.16-16-16V80c0-8.83 7.16-16 16-16h416c8.83 0 16 7.16 16 16v32zM308 256H204c-6.62 0-12-5.37-12-12v-8c0-6.62 5.37-12 12-12h104c6.62 0 12 5.37 12 12v8c0 6.62-5.37 12-12 12z"
        />
    </SvgIcon>
);
