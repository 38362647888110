export const BILL_TO_OPTIONS = [
    { label: 'Applicant', value: 'Applicant' },
    { label: 'Authorised Contact', value: 'AuthorisedContact' }, // AuthorisedContact
    { label: 'Choose From Address Book', value: 'AddressBook' },
    { label: 'Other', value: 'Other' },
    { label: 'Unknown', value: '' }
];

export const CUSTOMER_SATISFACTION_OPTIONS = [
    { label: 'Satisfied', value: 'Satisfied' },
    { label: 'Unsatisfied', value: 'Unsatisfied' },
    { label: 'Not Specified', value: 'Not Specified' }
];
