import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import DataFormView from '../../components/DataFormView';
import RecordDetailsTab from './RecordDetailsTab';
import ContactDetailsTab from './ContactDetailsTab';
import CremationActionTab from './CremationActionTab';
import BillingTab from './BillingTab';
import CorrespondenceTab from './CorrespondenceTab';
import Button from '../../components/form/Button';
import ViewHeader from '../../components/header/ViewHeader';
import { createNewCremationOrder } from './CremationConstants';
import BackIcon from '../../components/icon/BackIcon';
import AlertBar from '../../components/form/AlertBar';
import AlertModal from '../../components/modal/AlertModal';
import CremationOrder from '../../fragments/CremationOrder';
import { joinDefined } from '../../utils/strings';
import GoFuneral from '../../components/GoFuneral';
import Spinner from '../../components/loading/Spinner';
import { getClient } from '../../utils/apollo';
import SummaryTab from './SummaryTab';
import DocumentsIcon from '../../components/icon/DocumentsIcon';
import TemplateSelectionModal from './Modals/TemplateSelectionModal';
import Inline from '../../components/layout/Inline';
import RecordDrawer from '../../components/workQueue/RecordDrawer';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

/**
 * Tab Definition
 */
const CremationTabs = [
    RecordDetailsTab,
    ContactDetailsTab,
    CremationActionTab,
    BillingTab,
    CorrespondenceTab,
    SummaryTab
];

class Cremation extends Component {
    constructor(props) {
        super(props);

        this.cremationRecord = null;
    }

    state = {
        showMarkForCremationAlertModal: false,
        showUnMarkForCremationAlertModal: false,
        showTemplateSelectionModal: false
    };

    render() {
        const { match } = this.props;
        const {
            showMarkForCremationAlertModal,
            showUnMarkForCremationAlertModal,
            showTemplateSelectionModal
        } = this.state;
        return (
            <Grid container>
                <Query query={query} variables={{ id: match.params.id }}>
                    {results => {
                        const { loading, data } = results;
                        if (loading)
                            return (
                                <div>
                                    <Spinner />
                                </div>
                            );

                        const cremation = data && data.readOneCremationOrder;

                        this.cremationRecord = cremation;

                        return this.renderContent();
                    }}
                </Query>

                <AlertModal
                    variant="save"
                    open={showMarkForCremationAlertModal}
                    title="Prompt"
                    onClose={() =>
                        this.setState({
                            showMarkForCremationAlertModal: false
                        })
                    }
                    primaryAction="Yep, mark it"
                    onClickPrimaryAction={() => this.handleMarkFormCremation()}
                    secondaryAction="Actually, not just yet"
                    onClickSecondaryAction={() =>
                        this.setState({
                            showMarkForCremationAlertModal: false
                        })
                    }
                >
                    <p>Are you sure you'd like to mark this order as ready for cremation?</p>
                </AlertModal>

                <AlertModal
                    variant="save"
                    open={showUnMarkForCremationAlertModal}
                    title="Prompt"
                    onClose={() =>
                        this.setState({
                            showUnMarkForCremationAlertModal: false
                        })
                    }
                    primaryAction="Yep, unmark it"
                    onClickPrimaryAction={() => this.handleUnMarkFormCremation()}
                    secondaryAction="Actually, not just yet"
                    onClickSecondaryAction={() =>
                        this.setState({
                            showUnMarkForCremationAlertModal: false
                        })
                    }
                >
                    <p>Are you sure you'd like to unmark this order as ready for cremation?</p>
                </AlertModal>

                <TemplateSelectionModal
                    open={showTemplateSelectionModal}
                    cremationID={match.params.id}
                    onClose={() => this.handleCloseTemplateSelectionModal()}
                />
            </Grid>
        );
    }

    renderContent() {
        const { match } = this.props;
        return (
            <Fragment>
                <Grid item>
                    {this.renderHeader()}
                    {this.renderAlerts()}
                </Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <DataFormView
                            tabs={CremationTabs}
                            createNewFunc={createNewCremationOrder}
                            objectType="CremationOrder"
                            itemId={match.params.id}
                            name="Cremation"
                            onCreated={e => this.onCreateNew(e)}
                            context={this}
                            additionalActions={this.getAdditionalActions()}
                        />
                    </Paper>
                </Grid>
            </Fragment>
        );
    }

    onCreateNew(cremationOrder) {
        const { history } = this.props;
        history.push(`cremation/${cremationOrder.ID}`);
    }

    renderHeader() {
        const cremation = this.cremationRecord;
        let subtitle = '';
        if (cremation && cremation.LegacyKey && cremation.Enquiry) {
            subtitle = joinDefined(
                [
                    cremation.LegacyKey,
                    cremation.Deceased.FirstName,
                    cremation.Deceased.MiddleName,
                    cremation.Deceased.Surname
                ],
                ' '
            );
        }
        const { history } = this.props;
        return (
            <ViewHeader variant="record" title="Cremation" subtitle={subtitle}>
                <Inline>
                    <Button variant="secondary" onClick={() => history.push('/cremations/')}>
                        <BackIcon />
                        Back
                    </Button>
                    {!!cremation && (
                        <Fragment>
                            <GoFuneral funeral={cremation.Funeral} />
                            <Button
                                variant="primary icon-button white shadow"
                                size="lg"
                                title="Generate Merge Documents"
                                onClick={() => this.handleShowTemplateSelectionModal()}
                            >
                                <DocumentsIcon />
                            </Button>
                            <RecordDrawer cremationId={cremation.ID} legacyKey={cremation.LegacyKey} />
                        </Fragment>
                    )}
                </Inline>
            </ViewHeader>
        );
    }

    renderAlerts() {
        const cremation = this.cremationRecord;
        if (!cremation) return null;
        const approvedAllRequiredDocs = !cremation.HasMissingCertificates;
        const cancelled = cremation.CremationCancelled;
        return (
            <Fragment>
                {(cancelled && <AlertBar variant="error">This record was cancelled.</AlertBar>) ||
                    (!approvedAllRequiredDocs && (
                        <AlertBar variant="warning">
                            Attention required: Unable to complete cremation because important documents are missing or
                            require review.
                        </AlertBar>
                    ))}
            </Fragment>
        );
    }

    handleShowTemplateSelectionModal() {
        this.setState({ showTemplateSelectionModal: true });
    }

    handleCloseTemplateSelectionModal() {
        this.setState({ showTemplateSelectionModal: false });
    }

    handleMarkFormCremation() {
        this.handleReadyForCremation(true);
    }

    handleUnMarkFormCremation() {
        this.handleReadyForCremation(false);
    }

    handleReadyForCremation(flag) {
        const { match } = this.props;

        if (flag) {
            this.cremationRecord.ReadyForCremation = true;
            this.setState({
                showMarkForCremationAlertModal: false
            });
        } else {
            this.cremationRecord.ReadyForCremation = false;
            this.setState({
                showUnMarkForCremationAlertModal: false
            });
        }

        const variables = {
            input: {
                ID: match.params.id,
                ReadyForCremation: flag
            }
        };
        const mutation = gql`
            mutation UpdateCremationOrder($input: UpdateCremationOrderInput!) {
                updateCremationOrder(input: $input) {
                    ID
                    ReadyForCremation
                }
            }
        `;
        getClient()
            .mutate({ mutation, variables })
            .then(() => window.location.reload());
    }

    getAdditionalActions() {
        const cremation = this.cremationRecord;
        if (!cremation) return null;
        const marked = cremation.ReadyForCremation;

        /*
         * hotfix HCM-760
        const approvedAllRequiredDocs = cremation.RequiredDocuments.length
            ? cremation.RequiredDocuments.filter(e => e.Status === 'Approved').length === 5
            : false;
        if (!approvedAllRequiredDocs) return null;
        */
        const action = marked
            ? {
                  label: 'Unmark for cremation...',
                  variant: 'secondary',
                  onClick: () => {
                      this.setState({
                          showUnMarkForCremationAlertModal: true
                      });
                  }
              }
            : {
                  label: 'Mark as Cremation Ready...',
                  variant: 'primary',
                  onClick: () => {
                      this.setState({
                          showMarkForCremationAlertModal: true
                      });
                  }
              };

        return [action];
    }
}

const query = gql`
    ${CremationOrder}
    query readOneCremationOrder($id: ID!) {
        readOneCremationOrder(ID: $id) {
            ID
            ...CremationOrder
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(Cremation), { fallback: ErrorFallback });
