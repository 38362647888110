import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '../../components/form/Checkbox';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import CremationOrderFragment from '../../fragments/CremationOrder';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import InvoiceModal, { calculateTotals } from './Modals/InvoiceModal';
import TextField from '../../components/form/TextField';
import { joinDefined, prettyPrice } from '../../utils/strings';
import AssociatedOrders from '../../components/AssociatedOrders';
import InputAdornment from '@material-ui/core/InputAdornment';
import moment from 'moment';
import Select from '../../components/form/Select';
import NotesReadOnly from '../../components/form/NotesReadOnly';

class BillingTab extends Component {
    state = {
        invoiceModal: {
            open: false,
            invoiceIndex: null
        }
    };

    render() {
        const { invoiceModal } = this.state;
        const { form } = this.props;
        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>
                <InvoiceModal
                    open={invoiceModal.open}
                    invoiceIndex={invoiceModal.invoiceIndex}
                    form={form}
                    onClose={() => this.setState({ invoiceModal: { open: false, invoiceIndex: null } })}
                />
            </Fragment>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderAssociatedInvoices()}
                {/*<UnderConstruction variant="section">{this.renderAssociatedQuotes()}</UnderConstruction>*/}
            </Fragment>
        );
    }

    renderAssociatedInvoices() {
        const { form } = this.props;
        const allInvoices = form.getState('Invoices') || [];
        const invoices = allInvoices.filter(inv => !!inv.Customer && !!Number(inv.Customer.ID)); // hack to ignore bad invoices
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Associated Invoices</h4>
                </Grid>

                <Grid item>
                    {(invoices.length > 0 && (
                        <Table>
                            <HeaderRow pad>
                                <Cell colSpan={1}>Invoice No.</Cell>
                                <Cell colSpan={1}>Billing Name</Cell>
                                <Cell colSpan={1}>Status</Cell>
                                <Cell colSpan={1}>Due Date</Cell>
                                <Cell colSpan={1}>Amount</Cell>
                                <Cell colSpan={1}>Sent</Cell>
                            </HeaderRow>

                            {invoices.map((invoice, index) => this.renderInvoice(invoice, index))}
                        </Table>
                    )) || <span>There are currently no invoices.</span>}
                </Grid>

                <Grid item>
                    <Inline>
                        <Button variant="primary" onClick={() => this.handleShowInvoiceModal()}>
                            Add new invoice...
                        </Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderInvoice(invoice, index) {
        const totals = calculateTotals(invoice);
        return (
            <Row pad key={index}>
                <Cell dataLabel="Invoice No">
                    <Button
                        onClick={() =>
                            this.setState({
                                invoiceModal: { open: true, invoiceIndex: index }
                            })
                        }
                        variant="link-orange"
                    >
                        {'#' + invoice.ID}
                    </Button>
                </Cell>
                <Cell dataLabel="Billing Name">
                    {!!invoice.Customer &&
                        (!!invoice.Customer.CustomerName
                            ? invoice.Customer.CustomerName
                            : joinDefined(
                                  [invoice.Customer.FirstName, invoice.Customer.MiddleName, invoice.Customer.Surname],
                                  ' '
                              ))}
                </Cell>
                <Cell dataLabel="Status">{invoice.XeroStatus}</Cell>
                <Cell dataLabel="Due Date">{invoice.InvoiceDueDate}</Cell>
                <Cell dataLabel="Amount">{prettyPrice(totals.totalWithGst)}</Cell>
                <Cell dataLabel="Sent">
                    {invoice.XeroStatus === 'AUTHORISED' || invoice.XeroStatus === 'PAID'
                        ? invoice.InvoiceSent
                            ? 'YES'
                            : 'NO'
                        : ' - '}
                </Cell>
            </Row>
        );
    }

    renderKeyOverride() {
        const { form } = this.props;
        const noOverride = !!form.getField('Funeral.ID');
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Job Status</h4>
                    <Checkbox label="This job is cancelled" form={form} name="CremationCancelled" />
                </Grid>

                {form.getField('CremationCancelled') && (
                    <Fragment>
                        <Grid pc={0.5}>
                            <TextField
                                type="date"
                                label="Date Cancelled"
                                required
                                form={form}
                                name="CancelledDate"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                variant="link add-new-button"
                                                onClick={() =>
                                                    form.setField({
                                                        CancelledDate: moment().format('YYYY-MM-DD')
                                                    })
                                                }
                                                title="Add Today's Date"
                                            >
                                                Add Today
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid pc={0.5}>
                            <Select
                                required
                                form={form}
                                name="CancelledReason"
                                label="Reason"
                                options={[
                                    { label: 'Accidental/unwanted record', value: 'invalid' },
                                    { label: 'Went with someone cheaper', value: 'competition' }
                                ]}
                            />
                        </Grid>
                    </Fragment>
                )}

                <Grid item>
                    <TextField label="Cremation Key Override" form={form} name="LegacyKey" disabled={noOverride} />
                </Grid>

                <Grid item>
                    <h4>Additional Notes</h4>
                </Grid>
                <Grid item>
                    <TextField placeholder="Notes are written here..." multiline name="AdditionalNotes" form={form} />
                </Grid>

                {form.getField('OldAdditionalNotes') && (
                    <Grid item>
                        <h4>Note History</h4>
                    </Grid>
                )}

                {form.getField('OldAdditionalNotes') && (
                    <Grid item>
                        <NotesReadOnly name="OldAdditionalNotes" form={form} />
                    </Grid>
                )}
            </Grid>
        );
    }

    renderAssociatedQuotes() {
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Associated Quotes</h4>
                </Grid>

                <Grid item>
                    <Table>
                        <HeaderRow pad>
                            <Cell colSpan={1}>Quote No.</Cell>
                            <Cell colSpan={1}>Type</Cell>
                            <Cell colSpan={1}>Date Responded</Cell>
                            <Cell colSpan={1}>Accepted</Cell>
                            <Cell colSpan={1}>Amount (Incl. GST)</Cell>
                        </HeaderRow>
                        <Row pad>
                            <Cell dataLabel="Quote No.">
                                <Button variant="link-orange">16449</Button>
                            </Cell>
                            <Cell dataLabel="Type">At-Need</Cell>
                            <Cell dataLabel="Date Responded">20/02/2019 11:58 AM</Cell>
                            <Cell dataLabel="Accepted">
                                <Checkbox />
                            </Cell>
                            <Cell dataLabel="Amount (Incl. GST)">$6408.00</Cell>
                        </Row>
                    </Table>
                </Grid>

                <Grid item>
                    <Inline>
                        <Button variant="primary">View initial enquiry</Button>
                        <Button variant="secondary">Print all quotes</Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderRightColumn() {
        return (
            <Fragment>
                {this.renderKeyOverride()}
                {this.renderAssociatedOrders()}
            </Fragment>
        );
    }

    renderAssociatedOrders() {
        const { form } = this.props;
        return <AssociatedOrders parentType="CremationOrder" parentID={form.getField('ID')} />;
    }

    handleShowInvoiceModal() {
        this.setState({
            invoiceModal: { open: true, invoiceIndex: null }
        });
    }

    handleCloseInvoiceModal() {
        this.setState({ invoiceModal: { open: false, invoiceIndex: null } });
    }
}

export default {
    id: 'Billing',
    label: 'Billing',
    component: withStyles({})(BillingTab),
    fragment: CremationOrderFragment,
    validation: {},
    onLoad: data => {
        // create a stub to detect new notes on save!
        if (!!data.AdditionalNotes) {
            data.OldAdditionalNotes = '' + (data.AdditionalNotes || '');
        }
        delete data.AdditionalNotes;
    },
    formatSaveData: (saveData, state) => {
        delete saveData.AssociatedOrders;
    }
};
