import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import RatingCard from './RatingCard';
import FlexGrid from '../layout/FlexGrid';

export default withStyles({})(({ rate }) => {
    return (
        <div>
            <h3>Your Conversion Rate</h3>
            <FlexGrid className="rating-bar-container">
                <div className="score-indicator-container" style={{ left: rate }}>
                    <div className="score-indicator" />
                </div>
                <RatingCard variant="twenty" />
                <RatingCard variant="forty" />
                <RatingCard variant="sixty" />
                <RatingCard variant="eighty" />
                <RatingCard variant="hundred" />
            </FlexGrid>
        </div>
    );
});
