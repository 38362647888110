import { getOffices } from '../environment';

export const BRAND = {
    // need to replace with real db values
    MOUNTAIN_VIEW: {
        label: 'Mountain View',
        letterCode: 'M',
        style: 'tablestyle0'
    },
    STAN_CRAP: {
        label: 'Stan Crapp',
        letterCode: 'K',
        style: 'tablestyle1'
    },
    H_PARSONS: {
        label: 'H. Parsons',
        letterCode: 'P',
        style: 'tablestyle5'
    },
    RANKINS: {
        label: 'Rankins',
        letterCode: 'R',
        style: 'tablestyle3'
    },
    WOLLONGONG_CITY: {
        label: 'Wollongong City',
        letterCode: 'W',
        style: 'tablestyle4'
    },
    /*   TENDER_FUNERALS: {
           label: 'Tender Funerals',
           letterCode: 'T',
           style: 'tablestyle6'
       },*/
    EASY_FUNERALS: {
        label: 'Easy Funerals',
        letterCode: 'E',
        style: 'tablestyle7'
    },
    PARSONS_LADIES: {
        label: 'Parsons Ladies',
        letterCode: 'L',
        style: 'tablestyle2'
    },
    UNKNOWN: {
        label: 'Other',
        letterCode: 'X',
        style: 'tablestyle6'
    }
};

export const BRANDS = Object.values(BRAND);

export function getBrandHomeByKey(key) {
    if (!key) return null;
    const letter = key.replace(/^[a-z]?\d+/gi, '').toUpperCase();
    return getBrandHomeByLetter(letter);
}

export function getBrandHomeByLetter(letter) {
    if (!letter) letter = 'X';
    const office = getOffices().find(h => h.RegistrantSuffix === letter);
    return office
        ? {
            ID: office.ID,
            letterCode: office.RegistrantSuffix,
            label: office.Title,
            color: office.OfficeColour || office.ForegroundColour,
            logo: office.Logo,
            style: 'tablestyle' + office.ID
        }
        : BRAND.UNKNOWN;
}
