import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';

//Table Base

const ClearTable = withStyles({})(({ className, children, variant }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `row--${variant}`;

    return (
        <table className={`clearTable ${className || ''} ${cssVariant || ''}`}>
            <tbody>{children}</tbody>
        </table>
    );
});

// Header row

const HeaderRow = withStyles({})(({ className, pad, children }) => {
    return <tr className={`row row--header ${pad ? 'row--padded' : ''} ${className || ''}`}>{children}</tr>;
});

// Simple Row

const Row = withStyles({})(({ className, pad, variant, children, onClick }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `row--${variant}`;

    return (
        <tr
            className={`row row--normal ${pad ? 'row--padded' : ''} ${className || ''} ${cssVariant || ''}`}
            onClick={onClick}
        >
            {children}
        </tr>
    );
});

const Cell = withStyles({})(({ className, pad, children, dataLabel, colSpan }) => {
    return (
        <td className={`td ${pad ? 'td--padded' : ''} ${className || ''}`} data-label={dataLabel} colSpan={colSpan}>
            {children}
        </td>
    );
});

export default ClearTable;
export { HeaderRow, Row, Cell };
