import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { isNullOrUndefined } from 'util';
import { stringIsNullOrEmpty } from '../../utils/strings';
import Separator from '../layout/Separator';
import { orientationMode } from '../../utils/objects';
import Typography from '../form/Typography';
import Inline from '../layout/Inline';

const ViewHeader = ({ children, title, subtitle, variant, className }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `view-header--${variant}`;

    return (
        <div className={`view-header ${cssVariant || ''} ${className || ''}`}>
            <Inline className="header">
                <Typography className="title" variant="h1">
                    {title}
                </Typography>
                {!stringIsNullOrEmpty(subtitle) && (
                    <Fragment>
                        <div>
                            <Separator orientation={orientationMode.vertical} />
                        </div>
                        <Typography className="subtitle" variant="h1" subtitle>
                            {subtitle}
                        </Typography>
                    </Fragment>
                )}
            </Inline>
            {!isNullOrUndefined(children) && <div className="header-children">{children}</div>}
        </div>
    );
};

export default withStyles({})(ViewHeader);
