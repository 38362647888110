import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import ViewHeader from '../../components/header/ViewHeader';
import ClearTable, { Cell, HeaderRow, Row } from '../../components/table/ClearTable';
import BrandDot from '../../components/form/BrandDot';
import UnderConstruction from '../../components/UnderConstruction';

class RecentlyUpdated extends Component {
    render() {
        return (
            <Grid container>
                <UnderConstruction />
                <Grid item>{this.renderHeader()}</Grid>
                <Grid item>
                    <Paper elevation={0}>{this.renderRecentOrders()}</Paper>
                </Grid>
            </Grid>
        );
    }

    renderHeader() {
        return <ViewHeader title="Recently updated records" />;
    }

    renderRecentOrders() {
        return (
            <Grid container>
                <Grid item>
                    <ClearTable>
                        <HeaderRow pad>
                            <Cell>Type</Cell>
                            <Cell>ID</Cell>
                            <Cell>Name</Cell>
                            <Cell>Date of Birth</Cell>
                            <Cell>Date of Cremation</Cell>
                            <Cell>Date of Dispatch</Cell>
                            <Cell>Dispatch Method</Cell>
                            <Cell>Recipient</Cell>
                        </HeaderRow>
                        <Row pad>
                            <Cell dataLabel="Type">
                                <BrandDot />
                            </Cell>
                            <Cell dataLabel="ID">10700P</Cell>
                            <Cell dataLabel="Name"> Tony Stark </Cell>
                            <Cell dataLabel="Date of Birth">18/10/1924</Cell>
                            <Cell dataLabel="Date of Cremation">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Date of Dispatch">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Dispatch Method">Collected</Cell>
                            <Cell dataLabel="Recipient">Applicant</Cell>
                        </Row>
                        <Row pad>
                            <Cell dataLabel="Type">
                                <BrandDot variant="parsons" />
                            </Cell>
                            <Cell dataLabel="ID">10700P</Cell>
                            <Cell dataLabel="Name"> Tony Stark </Cell>
                            <Cell dataLabel="Date of Birth">18/10/1924</Cell>
                            <Cell dataLabel="Date of Cremation">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Date of Dispatch">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Dispatch Method">Collected</Cell>
                            <Cell dataLabel="Recipient">Applicant</Cell>
                        </Row>
                        <Row pad>
                            <Cell dataLabel="Type">
                                <BrandDot />
                            </Cell>
                            <Cell dataLabel="ID">10700P</Cell>
                            <Cell dataLabel="Name"> Tony Stark </Cell>
                            <Cell dataLabel="Date of Birth">18/10/1924</Cell>
                            <Cell dataLabel="Date of Cremation">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Date of Dispatch">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Dispatch Method">Collected</Cell>
                            <Cell dataLabel="Recipient">Applicant</Cell>
                        </Row>
                        <Row pad>
                            <Cell dataLabel="Type">
                                <BrandDot variant="parsons" />
                            </Cell>
                            <Cell dataLabel="ID">10700P</Cell>
                            <Cell dataLabel="Name"> Tony Stark </Cell>
                            <Cell dataLabel="Date of Birth">18/10/1924</Cell>
                            <Cell dataLabel="Date of Cremation">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Date of Dispatch">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Dispatch Method">Collected</Cell>
                            <Cell dataLabel="Recipient">Applicant</Cell>
                        </Row>
                        <Row pad>
                            <Cell dataLabel="Type">
                                <BrandDot />
                            </Cell>
                            <Cell dataLabel="ID">10700P</Cell>
                            <Cell dataLabel="Name"> Tony Stark </Cell>
                            <Cell dataLabel="Date of Birth">18/10/1924</Cell>
                            <Cell dataLabel="Date of Cremation">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Date of Dispatch">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Dispatch Method">Collected</Cell>
                            <Cell dataLabel="Recipient">Applicant</Cell>
                        </Row>
                        <Row pad>
                            <Cell dataLabel="Type">
                                <BrandDot variant="parsons" />
                            </Cell>
                            <Cell dataLabel="ID">10700P</Cell>
                            <Cell dataLabel="Name"> Tony Stark </Cell>
                            <Cell dataLabel="Date of Birth">18/10/1924</Cell>
                            <Cell dataLabel="Date of Cremation">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Date of Dispatch">Friday, December 18, 2012</Cell>
                            <Cell dataLabel="Dispatch Method">Collected</Cell>
                            <Cell dataLabel="Recipient">Applicant</Cell>
                        </Row>
                    </ClearTable>
                </Grid>
            </Grid>
        );
    }
}

export default compose(
    withRouter,
    withStyles({})
)(RecentlyUpdated);
