import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import Typography from '../../components/form/Typography';
import TaskCard from '../../components/workQueue/TaskCard';
import FileUploadCard from '../../components/cards/FileUploadCard';
import createForm from '../../utils/createForm';
import Appointment from '../../components/workQueue/Appointment';
import ContactCard from '../../components/contact/ContactCard';
import FlexGrid from '../../components/layout/FlexGrid';
import ImageThumbnail from '../../components/cards/ImageThumbnail';
import landscape from '../../assets/plaque-landscape.jpg';
import portrait from '../../assets/plaque-portrait.jpg';
import TaskCardContainer from '../Dashboard/TaskCardContainer';

class TaskCardStyling extends Component {
    render() {
        return (
            <Grid container spacing={32}>
                {this.renderTaskCards()}
                {this.renderAppointments()}
                {this.renderFileUploadCard()}
                {this.renderImageUploadCard()}
                {this.renderContactCard()}
            </Grid>
        );
    }

    renderTaskCards() {
        const form = createForm(this, {
            Tasks: [
                {
                    ID: 1,
                    DueDate: '15.06.18',
                    Title: 'Re-Book Musician',
                    Description: 'Bob Smith has declined your booking request',
                    Action: 'Please book another musician',
                    Funeral: {
                        ID: '6795'
                    },
                    AssignedMembers: [
                        {
                            ID: 3,
                            FirstName: 'Jessica',
                            Surname: 'Williams'
                        },
                        {
                            ID: 4,
                            FirstName: 'Otis',
                            Surname: 'Blue'
                        }
                    ],
                    Comments: [
                        {
                            CreatedDate: '15.06.18',
                            Message: 'Do the thing',
                            Member: {
                                ID: 1,
                                FirstName: 'Sarah',
                                Surname: 'Smith'
                            }
                        },
                        {
                            CreatedDate: '15.07.18',
                            Message: 'Do other thing',
                            Member: {
                                ID: 2,
                                FirstName: 'Jane',
                                Surname: 'Smith'
                            }
                        }
                    ]
                },
                {
                    ID: 2,
                    DueDate: '15.06.18',
                    Title: 'Re-Book Musician',
                    Description: 'Bob Smith has declined your booking request',
                    Action: 'Please book another musician',
                    Funeral: {
                        ID: '6795'
                    },
                    AssignedMembers: [
                        {
                            ID: 3,
                            FirstName: 'Jessica',
                            Surname: 'Williams'
                        },
                        {
                            ID: 4,
                            FirstName: 'Otis',
                            Surname: 'Blue'
                        }
                    ],
                    Comments: [
                        {
                            CreatedDate: '15.06.18',
                            Message: 'Do the thing',
                            Member: {
                                ID: 1,
                                FirstName: 'Sarah',
                                Surname: 'Smith'
                            }
                        },
                        {
                            CreatedDate: '15.07.18',
                            Message: 'Do other thing',
                            Member: {
                                ID: 2,
                                FirstName: 'Jane',
                                Surname: 'Smith'
                            }
                        }
                    ]
                }
            ]
        });

        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h4">Task Card Styling - variant = card</Typography>
                            </Grid>
                            <Grid item>
                                <TaskCardContainer>
                                    {form.state.Tasks.map((task, index) => (
                                        <TaskCard
                                            variant="card"
                                            key={task.ID}
                                            task={task}
                                            propertyPath={`Tasks[${index}]`}
                                        />
                                    ))}
                                </TaskCardContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h4">Task Card Styling - variant = list</Typography>
                            </Grid>
                            <Grid item>
                                {form.state.Tasks.map((task, index) => (
                                    <TaskCard
                                        variant="list"
                                        key={task.ID}
                                        task={task}
                                        propertyPath={`Tasks[${index}]`}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderAppointments() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">Appointment Styling</Typography>
                    </Grid>
                    <Grid item>
                        <Appointment dateTime="9:30am" description="Appointment with Charlie Smith" />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderFileUploadCard() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">File Upload Card Styling</Typography>
                    </Grid>
                    <Grid item>
                        <FileUploadCard fileName="file1.pdf" />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderImageUploadCard() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h4">Image Thumbnail - no variant</Typography>
                            </Grid>
                            <Grid item>
                                <FlexGrid variant="justify">
                                    <ImageThumbnail image={landscape} productTitle="Image Title - image = Landscape" />
                                    <ImageThumbnail image={portrait} productTitle="Image Title - image = Portrait" />
                                    <ImageThumbnail image={portrait} productTitle="Image Title - image = Portrait" />
                                    <ImageThumbnail image={landscape} productTitle="Image Title - image = Landscape" />
                                </FlexGrid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Grid container>
                            <Grid item>
                                <Typography variant="h4">Image Thumbnail - variant = product</Typography>
                            </Grid>
                            <Grid item>
                                <FlexGrid variant="justify">
                                    <ImageThumbnail
                                        variant="product"
                                        image={landscape}
                                        productTitle="Plaque - Gold 165 x 125mm"
                                        productCost="600.00 per unit"
                                    />
                                    <ImageThumbnail
                                        variant="product"
                                        image={portrait}
                                        productTitle="Plaque - Gold 165 x 125mm"
                                        productCost="600.00 per unit"
                                    />
                                    <ImageThumbnail
                                        variant="product"
                                        image={portrait}
                                        productTitle="Plaque - Gold 165 x 125mm"
                                        productCost="600.00 per unit"
                                    />
                                    <ImageThumbnail
                                        variant="product"
                                        image={landscape}
                                        productTitle="Plaque - Gold 165 x 125mm"
                                        productCost="600.00 per unit"
                                    />
                                </FlexGrid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderContactCard() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                    </Grid>
                    <Grid item>
                        <Typography variant="h4">Contact Card Styling</Typography>
                    </Grid>
                    <Grid item>
                        <FlexGrid variant="justify">
                            <ContactCard
                                contact={{
                                    Name: 'Test Business Name Long Long name',
                                    Contact: 'Contact Name',
                                    AddressBookCategory: 'Category',
                                    AddressLine1: 'Level 4',
                                    AddressLine2: '123 Smith St',
                                    Suburb: 'Nowhere',
                                    State: 'NSW',
                                    Postcode: '2500',
                                    Country: 'Australiasia',
                                    Phone: '(01)1234 4564',
                                    Email: 'company.email@internet.com.au',
                                    Fax: '(01)1234 4564',
                                    Mobile: '0412 456 799'
                                }}
                                readOnly
                            />
                            <ContactCard
                                contact={{
                                    Name: 'Test Business Name Long Long name',
                                    Contact: 'Contact Name',
                                    AddressBookCategory: 'Category',
                                    AddressLine1: 'Level 4',
                                    AddressLine2: '123 Smith St',
                                    Suburb: 'Nowhere',
                                    State: 'NSW',
                                    Postcode: '2500',
                                    Country: 'Australiasia',
                                    Phone: '(01)1234 4564',
                                    Email: 'company.email@internet.com.au',
                                    Fax: '(01)1234 4564',
                                    Mobile: '0412 456 799'
                                }}
                                readOnly
                            />
                            <ContactCard
                                contact={{
                                    Name: 'Test Business Name Long Long name',
                                    Contact: 'Contact Name',
                                    AddressBookCategory: 'Category',
                                    AddressLine1: 'Level 4',
                                    AddressLine2: '123 Smith St',
                                    Suburb: 'Nowhere',
                                    State: 'NSW',
                                    Postcode: '2500',
                                    Country: 'Australiasia',
                                    Phone: '(01)1234 4564',
                                    Email: 'company.email@internet.com.au',
                                    Fax: '(01)1234 4564',
                                    Mobile: '0412 456 799'
                                }}
                                readOnly
                            />
                            <ContactCard
                                contact={{
                                    Name: 'Test Business Name Long Long name',
                                    Contact: 'Contact Name',
                                    AddressBookCategory: 'Category',
                                    AddressLine1: 'Level 4',
                                    AddressLine2: '123 Smith St',
                                    Suburb: 'Nowhere',
                                    State: 'NSW',
                                    Postcode: '2500',
                                    Country: 'Australia',
                                    Phone: '(01)1234 4564',
                                    Email: 'company.email@internet.com.au',
                                    Fax: '(01)1234 4564',
                                    Mobile: '0412 456 799'
                                }}
                                readOnly
                            />
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles({})(TaskCardStyling);
