import gql from "graphql-tag";

export default gql`
	fragment CommunicationNoteTemplate on CMCommunicationNoteTemplate {
		ID
		Title
		Description

		Attachments {
			... on File {
				ID
				Name
				Size
				AbsoluteLink
			}
			... on Image {
				ID
				Name
				Size
				AbsoluteLink
			}
		}
	}
`;
