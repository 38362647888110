import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { getValidationDecorations } from '../../utils/validation';

const ValidationPlaceholder = props => {
    const { children } = props;
    const decorations = getValidationDecorations(props, null, 'validation--');

    if (!decorations.inError) return children;

    return (
        <div className={`validation ${decorations.cssClass}`}>
            {children && <div className="target">{children}</div>}
            <div className="label">{decorations.errorMsg}</div>
        </div>
    );
};

export default withStyles({})(ValidationPlaceholder);
