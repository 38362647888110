import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import Route from '../components/Route';
import NotFound from './NotFound';
import Dashboard from './Dashboard/Dashboard';
import Enquiry from './Enquiry/Enquiry';
import EnquiryListing from './Enquiry/EnquiryListing';
import Account from './Account/Account';
import SignIn from './SignIn';
import Styles from './Style/Styles';
import Cremation from './Cremation/Cremation';
import Plaque from './Plaque/Plaque';
import PlaqueListing, { PlaqueAllListing } from './Plaque/PlaqueListing';
import CremationRecordList, { CremationRecordAllList } from './Cremation/CremationRecordList';
import Contacts from './Contacts/Contacts';
import CremationPendingList from './Cremation/CremationPendingList';
import CremationPending from './Cremation/CremationPending';
import RecentlyUpdated from './Recent/RecentlyUpdated';
import Calendar from './Calendar/Calendar';
import Runsheet from './Runsheet/Runsheet';
import WorkQueue from './WorkQueue/WorkQueue';
import AlertBar from '../components/form/AlertBar';
import Metrics from './MetricDashboard/Metrics';
import { isLocalClient, isLocalServer, serverUrl } from '../environment';
import { PAGE_ENQUIRIES, PAGE_ORDER_REQUESTS } from '../utils/strings';

const localLogin = isLocalClient() && !isLocalServer();

const Main = ({ signedIn }) => (
    <main className={signedIn ? 'main' : ''}>
        <Switch>
            <Route auth path="/" exact component={Dashboard} />
            <Route auth path="/metrics" exact component={Metrics} />

            <Route auth path="/work-queue/task/:id?" component={WorkQueue} />
            <Route auth path="/work-queue/:queue/:key?/:id?" component={WorkQueue} />
            <Route auth path="/work-queue" exact component={WorkQueue} />

            <Route auth path="/calendar/:date?" exact component={Calendar} />
            <Route auth path="/contacts" exact component={Contacts} />
            <Route auth path={PAGE_ENQUIRIES} exact component={EnquiryListing} />
            <Route auth path={PAGE_ORDER_REQUESTS} exact component={CremationPendingList} />
            <Route auth path={PAGE_ORDER_REQUESTS + ":id"} exact component={CremationPending} />
            <Route auth path={PAGE_ENQUIRIES + ":id"} exact component={Enquiry} />

            <Route auth path="/cremations" exact component={CremationRecordList} />
            <Route auth path="/cremations/view-all" exact component={CremationRecordAllList} />
            <Route auth path="/cremations/:id" exact component={Cremation} />

            <Route auth path="/plaques" exact component={PlaqueListing} />
            <Route auth path="/plaques/view-all" exact component={PlaqueAllListing} />
            <Route auth path="/plaques/:id" exact component={Plaque} />

            <Route auth path="/styles" exact component={Styles} />
            <Route auth exact path="/account" component={Account} />

            {localLogin && <Route anon path="/sign-in" component={SignIn} />}
            {!localLogin && <Route anon path="/sign-in/:id/:hash/:remember?" component={SignIn} />}
            {!localLogin && (
                <Route
                    anon
                    path="/sign-in"
                    component={() => {
                        window.location.href = serverUrl + '/Security/cmreact';
                        return null;
                    }}
                />
            )}

            {/*Footer Links*/}
            <Route auth path="/recent" exact component={RecentlyUpdated} />
            <Route auth path="/calendar" exact component={Calendar} />
            <Route auth path="/runsheet" exact component={Runsheet} />

            <Route component={NotFound} />
        </Switch>

        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={window.appData && window.appData.newContent}
            message={
                <div style={{ color: 'black', margin: '-30px -24px' }}>
                    <AlertBar variant={'fire'}>
                        We're on fire! We've updated the system with a hotfix.
                        <br />
                        It will be loaded when all tabs are closed.
                    </AlertBar>
                </div>
            }
        />
    </main>
);

export default withStyles({})(Main);
