import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M504,64H96V8a8,8,0,0,0-8-8H72a8,8,0,0,0-8,8V64H8a8,8,0,0,0-8,8V88a8,8,0,0,0,8,8H64V504a8,8,0,0,0,8,8H88a8,8,0,0,0,8-8V96h96v48H176a32,32,0,0,0-32,32V352a32,32,0,0,0,32,32H464a32,32,0,0,0,32-32V176a32,32,0,0,0-32-32H448V96h56a8,8,0,0,0,8-8V72A8,8,0,0,0,504,64ZM464,352H176V176H464ZM416,144H224V96H416Z"
        />
    </SvgIcon>
);