export var GQL_REFETCH_5S = 5000;
export var GQL_REFETCH_30S = 30000;
export var GQL_REFETCH_60S = 60000;
export var GQL_REFETCH_60M = 3600000;

var channel = new BroadcastChannel('refetch-subscription-controller-discussion');
var haveControl = false;
var lastHeartbeat = Date.now();
var references = [];

window.addEventListener('focus', () => {
    setHaveControl(true);
    channel.postMessage('assume-control');
});

setInterval(() => {
    if (haveControl) {
        channel.postMessage('heartbeat');
    }
    if (Date.now() - lastHeartbeat > GQL_REFETCH_5S) {
        setHaveControl(true);
        channel.postMessage('assume-control');
    }
}, 1000);

channel.onmessage = function(event) {
    if (event.data === 'assume-control') {
        setHaveControl(false);
        lastHeartbeat = Date.now();
    }
    if (event.data === 'heartbeat') {
        setHaveControl(false);
        lastHeartbeat = Date.now();
    }
};

function setHaveControl(value) {
    if (value !== haveControl) {
        haveControl = value;
        UpdateSubscriptions();
    }
}

function UpdateSubscriptions(newRefIDX = -1) {
    console.log('Updating subscriptions');
    if (haveControl) {
        console.log('Starting polling');
        references.forEach(ref => {
            if (ref.component && ref.component.current) {
                ref.component.current.lastRenderedResult.startPolling(ref.interval);
            }
        });
    } else {
        console.log('Stopping polling');
        references.forEach(ref => {
            if (ref.component && ref.component.current) {
                ref.component.current.lastRenderedResult.stopPolling();
            }
        });
    }
}

export function subscribe(componentRef, interval) {
    references.push({ component: componentRef, interval: interval });
}

setTimeout(() => {
    UpdateSubscriptions();
}, GQL_REFETCH_5S);
