import gql from 'graphql-tag';
import Member from './Member';

export default gql`
    fragment QuoteAction on CMQuoteAction {
        ID
        Created
        EmailTo
        EmailSubject
        EmailMe
        EmailBody
        ActionedBy {
            ...Member
        }
        Quote {
            ID
        }
        QuoteEmailTemplate {
            ID
        }
        Attachments {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
    ${Member}
`;
