import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import AlertBar from './form/AlertBar';
import Grid from './layout/Grid';
import ButtonWrapper from './form/Button';
import Separator from './layout/Separator';

export default ({ error, componentStack, resetError }) => (
    <WrapErrorFallbackComponent error={error} componentStack={componentStack} resetError={resetError} />
);

const ErrorFallbackComponent = ({ classes, error, resetError }) => {
    const [show, setShow] = React.useState(false);
    return (
        <>
            <Grid pc={1}>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    Whoops!
                </Typography>
                <Typography gutterBottom>There was an error. Probably nothing you did!</Typography>
                <Typography gutterBottom>
                    <ButtonWrapper variant="link" onClick={resetError}>Try again</ButtonWrapper> or try moving to
                    another page.
                </Typography>
                <div style={{ padding: '24px 0' }}><Separator margin={24} /></div>
                <Typography gutterBottom>
                    <ButtonWrapper variant="link" onClick={() => setShow(!show)}>
                        {!show ? 'Show' : 'Hide'}
                    </ButtonWrapper> the error message.
                    {!!show && <AlertBar variant="error">{error.toString()}</AlertBar>}
                </Typography>
            </Grid>
        </>
    );
};

const styles = ({ spacing, typography }) => ({
    pageTitle: {
        fontWeight: typography.fontWeightLight
    }
});

const WrapErrorFallbackComponent = withStyles(styles)(ErrorFallbackComponent);
