import React, { Component, createContext } from 'react';

const { Provider, Consumer } = createContext({ open: false });

export class EditAppointmentProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            //editAppointment: (anchorEl, appointment) => this.setState({ anchorEl, appointment, open: true }),
            //editStaffAllocation: (anchorEl, staffAllocation) => this.setState({ anchorEl, staffAllocation, open: true }),
            //editCalendarAllocationsById: calendarEvent => this.setState({ ...defaultState, calendarEvent, open: true }),
            editAppointmentById: appointmentId =>
                this.setState({
                    ...defaultState,
                    appointmentId,
                    queryViaStaffAllocation: true,
                    open: true
                }),
            editCalendarEventById: staffAllocationId =>
                // staffAllocation obsolete, replaced with editing event
                this.setState({
                    ...defaultState,
                    staffAllocationId,
                    open: true
                }),
            editCalendarAllocationsById: calendarEventId =>
                this.setState({
                    ...defaultState,
                    calendarEventId,
                    open: true
                }),
            createNewAppointment: (eventBegin, eventUntil, memberList) =>
                this.setState({
                    ...defaultState,
                    eventBegin,
                    eventUntil,
                    memberList,
                    open: true
                }),
            onAppointmentPopoverClosed: () => this.setState({ ...defaultState })
        };
    }

    render() {
        return <Provider value={this.state}>{this.props.children}</Provider>;
    }
}

const defaultState = {
    appointment: null,
    appointmentId: null,
    staffAllocation: null,
    staffAllocationId: null,
    calendarEvent: null,
    calendarEventId: null,
    queryViaStaffAllocation: null,
    eventBegin: null,
    eventUntil: null,
    memberList: [],
    allowLocation: true,
    open: false
};

export const EditAppointmentConsumer = Consumer;

export function withEditAppointment(C) {
    return props => <Consumer>{value => <C {...value} {...props} />}</Consumer>;
}
