import gql from 'graphql-tag';

export default gql`
    fragment RequiredDocument on RequiredDocument {
        ID
        Created
        Name
        Status
        Notes
        Actioned
        ActionedBy {
            ...Member
        }
        File {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
        CremationOrder {
            ID
            LegacyKey
        }
    }
`;
