import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import Checkbox from '../../../components/form/Checkbox';
import Filter from '../../../components/modal/Filter';

class FilterModal extends Component {
    render() {
        const { open, onClose, anchorEl } = this.props;
        return (
            <Filter open={open} anchorEl={anchorEl} variant="simple" className="popover--filter" onClose={onClose}>
                <Grid container>
                    <Grid item>
                        <h2>Filter</h2>
                        <h3>Enquiry Type</h3>
                        <Grid container spacing={12}>
                            <Grid item xs={12} md={6}>
                                <Checkbox label="Plaque" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Checkbox label="Cremation" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Checkbox label="General" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Checkbox label="Pre-need" />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <Inline alignment={inlineAlignment.right}>
                            <Button
                                onClick={() => this.setState({ showFilterModal: false })}
                                variant="modal-action modal-action--cancel"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={() => this.setState({ showFilterModal: false })}
                                variant="modal-action modal-action--ok"
                            >
                                Ok
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Filter>
        );
    }
}

export default withStyles({})(FilterModal);
