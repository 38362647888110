import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Select from '../../components/form/Select';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import EditIcon from '../../components/icon/EditIcon';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import AddressAutocomplete, { createAddressComponentFields } from '../../components/form/AddressAutocomplete';
import { MaritalStatusOptions } from '../../components/ComponentConstants';

const deceasedAddressComponentFields = createAddressComponentFields('Deceased.ResidentialAddress');
const clientPostalComponentFields = createAddressComponentFields('Client.PostalAddress');
const clientResidentialComponentFields = createAddressComponentFields('Client.ResidentialAddress');

class ContactDetailsTab extends Component {
    state = {
        readonlyDeceasedDetails: true,
        readonlyClientDetails: true
    };

    render() {
        return (
            <ColumnLayout>
                {this.renderLeftColumn()}
                {this.renderRightColumn()}
            </ColumnLayout>
        );
    }

    renderLeftColumn() {
        return <Fragment>{this.renderDeceasedDetails()}</Fragment>;
    }

    renderDeceasedDetails() {
        const { form } = this.props;
        const { readonlyDeceasedDetails } = this.state;

        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h4>Deceased Details</h4>
                        <IconButton
                            className="icon"
                            onClick={() => this.setState({ readonlyDeceasedDetails: !readonlyDeceasedDetails })}
                        >
                            <EditIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First name"
                        form={form}
                        name="Deceased.FirstName"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Middle name(s)"
                        form={form}
                        name="Deceased.MiddleName"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Surname" form={form} name="Deceased.Surname" readOnly={readonlyDeceasedDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date of Birth"
                        type="date"
                        form={form}
                        name="Deceased.DateOfBirth"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Date of Death"
                        type="date"
                        form={form}
                        name="Deceased.DateOfDeath1"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Marital Status"
                        options={MaritalStatusOptions}
                        form={form}
                        name="Deceased.MaritalStatus"
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Residential Address"
                        form={form}
                        name="Deceased.ResidentialAddress"
                        componentFields={deceasedAddressComponentFields}
                        readOnly={readonlyDeceasedDetails}
                    />
                </Grid>
            </Grid>
        );
    }

    renderRightColumn() {
        return <Fragment>{this.renderApplicant()}</Fragment>;
    }

    renderApplicant() {
        const { form } = this.props;
        const { readonlyClientDetails } = this.state;

        return (
            <Grid container bucket>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h4>Applicant Details</h4>
                        <IconButton
                            className="icon"
                            onClick={() => this.setState({ readonlyClientDetails: !readonlyClientDetails })}
                        >
                            <EditIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="First name"
                        form={form}
                        name="Client.FirstName"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Surname" form={form} name="Client.Surname" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Relationship to deceased"
                        form={form}
                        name="Client.RelationshipToDeceased"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Phone" form={form} name="Client.PrimaryNumber" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Mobile"
                        form={form}
                        name="Client.SecondaryNumber"
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Email" form={form} name="Client.Email" readOnly={readonlyClientDetails} />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Residential Address"
                        form={form}
                        name="Client.ResidentialAddress"
                        componentFields={clientResidentialComponentFields}
                        readOnly={readonlyClientDetails}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        allowCustomAddress
                        label="Postal Address"
                        form={form}
                        name="Client.PostalAddress"
                        componentFields={clientPostalComponentFields}
                        readOnly={readonlyClientDetails}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default {
    id: 'ContactDetails',
    label: 'Contact Details',
    component: withStyles({})(ContactDetailsTab),
    fragment: PlaqueOrder,
    validation: {
        required: ['Deceased.FirstName']
    },
    onLoad: data => {},
    formatSaveData: (saveData, state) => {}
};
