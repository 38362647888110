import React from 'react';
import { Line } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';
import Spinner from '../loading/Spinner';

export default withStyles({})(({ variant, data, label, loading }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `bar-fill--${variant}`;

    const myData = {
        labels: data && data.map(e => `${e.label}`),
        datasets: [
            {
                data: data && data.map(e => e.value),
                label: label || 'Amount',
                // fill: false,
                backgroundColor: '#7d9cc422',
                borderColor: '#7d9cc4'
            }
        ]
    };

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            {(!!loading && <Spinner />) || (
                <Line
                    className={cssVariant}
                    options={{ maintainAspectRatio: false, tooltips: { enabled: true } }}
                    height={400}
                    legend={{
                        position: 'bottom',
                        labels: {
                            fontColor: '#000000',
                            fontSize: 16
                        }
                    }}
                    data={myData}
                />
            )}
        </div>
    );
});
