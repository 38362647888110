import React, { Component } from 'react';
import gql from 'graphql-tag';
import { compose } from 'react-apollo/index';
import { Query } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Downshift from 'downshift';
import cx from 'classnames';
import TextField from './TextField';
import Spinner from '../loading/Spinner';
import SearchIcon from '../icon/SearchIcon';
import Table, { Cell, Row } from '../table/Table';
import { joinDefined, stringIsNullOrEmpty } from '../../utils/strings';
import Radio from './Radio';
import Funeral from '../../fragments/Funeral';
import { isNullOrUndefined } from '../../utils/objects';

class FuneralLookupTable extends Component {
    state = {
        searchTerm: null,
        initialSearchTerm: null
    };

    static getDerivedStateFromProps({ initialSearchTerm }, oldState) {
        if (initialSearchTerm === oldState.initialSearchTerm) return null;

        return { initialSearchTerm, searchTerm: initialSearchTerm };
    }

    render() {
        const variables = this.getQueryVariables();

        const { ignoreFuneralId } = this.props;

        return (
            <Query
                query={readFunerals}
                fetchPolicy={'network-only'}
                skip={stringIsNullOrEmpty(variables.contains) && stringIsNullOrEmpty(variables.key)}
                variables={variables}
                context={{ debounceKey: 2 }}
                notifyOnNetworkStatusChange
            >
                {({ data, loading, error }) => {
                    let results = data && data.readFunerals ? data.readFunerals : { edges: [] };
                    results = results.edges.map(e => e.node);
                    if (!stringIsNullOrEmpty(ignoreFuneralId) && ignoreFuneralId !== '0') {
                        results = results.filter(x => x.ID !== ignoreFuneralId);
                    }
                    return <Downshift>{d => this.renderContainer(results || [], loading, d, error)}</Downshift>;
                }}
            </Query>
        );
    }

    renderContainer(results, loading, downshiftData, error) {
        const { classes, className } = this.props;
        return (
            <div className={cx(classes.root, className)}>
                {this.renderInput(downshiftData, loading)}

                <div className={classes.resultContainer}>
                    {this.renderResults(results, loading, downshiftData, error)}
                </div>
            </div>
        );
    }

    renderInput(downshiftData, loading) {
        const { classes } = this.props;
        const { searchTerm } = this.state;

        return (
            <TextField
                label="Search for..."
                className={classes.search}
                id="FuneralSearch"
                fullWidth
                InputProps={{
                    ...downshiftData.getInputProps({ placeholder: 'Search for an existing enquiry...' }),
                    startAdornment: (
                        <InputAdornment position="start" className={classes.iconContainer}>
                            {loading && !stringIsNullOrEmpty(searchTerm) ? (
                                <Spinner size="xs" />
                            ) : (
                                <SearchIcon className={classes.icon} />
                            )}
                        </InputAdornment>
                    ),
                    inputProps: {
                        onChange: e => this.setState({ searchTerm: e.target.value }),
                        value: searchTerm || ''
                    }
                }}
            />
        );
    }

    renderResults(results, loading, downshiftData, error) {
        const { searchTerm } = this.state;
        if (stringIsNullOrEmpty(searchTerm) || loading) return null;
        const { classes } = this.props;

        if (error) {
            return <Typography className={classes.noResult}>{error.message || 'An unknown error occurred'}</Typography>;
        }

        if (results.length === 0) {
            return <span className={classes.noResults}>No funerals found.</span>;
        }

        return (
            <Table
                className={classes.resultTable}
                columns={[
                    { label: 'Funeral' },
                    { label: 'Name of Deceased' },
                    { label: 'Residential Address' },
                    { label: 'DOB' },
                    { label: 'Select' }
                ]}
            >
                {results.map(r => this.renderResult(r))}
            </Table>
        );
    }

    renderResult = result => {
        const { selectedFuneral, onSelectFuneral, classes, ignoreFuneralId } = this.props;

        return (
            <Row pad key={result.ID}>
                <Cell dataLabel="Ref">
                    {result.LegacyKey}
                    {ignoreFuneralId === result.LegacyKey ? '*' : ''}
                </Cell>
                <Cell dataLabel="Deceased">
                    {joinDefined([result.FirstName, result.MiddleName, result.Surname], ' ')}
                </Cell>
                <Cell dataLabel="Address">
                    {joinDefined(
                        [
                            result.ResidentialAddress1,
                            result.ResidentialAddress2,
                            result.ResidentialTown,
                            result.ResidentialState,
                            result.PostCode
                        ],
                        ' '
                    )}
                </Cell>
                <Cell dataLabel="DOB">{result.UnknownDOB === true ? 'Unknown' : result.DateOfBirth}</Cell>

                <Cell dataLabel="Select" className={classes.miniCol}>
                    <Radio
                        value={result.ID}
                        checked={(!isNullOrUndefined(selectedFuneral) && selectedFuneral.ID === result.ID) || false}
                        onChange={e => onSelectFuneral(e.target.checked ? result : null)}
                    />
                </Cell>
            </Row>
        );
    };

    getQueryVariables() {
        const { searchTerm } = this.state;

        let variables = {
            limit: 10
        };

        const term = searchTerm ? searchTerm.trim() : '';

        if (!isNaN(parseInt(term, 10))) {
            // If the term is non-numeric then we do a `contains` search
            // otherwise we search for a `key` match
            variables.key = term;
            variables.contains = null;
        } else {
            variables.key = null;
            variables.contains = term;
        }

        return variables;
    }
}

const readFunerals = gql`
    ${Funeral}
    
    query ReadFunerals($contains: String, $key: String, $limit: Int!) {
        readFunerals(contains: $contains, key: $key, limit: $limit, offset: 0) {
            edges {
                node {
                    ...Funeral
                }
            }
        }
    }
`;

const styles = ({ palette }) => ({
    root: {
        position: 'relative',
        flex: '1 1 auto'
    },
    search: {},
    miniCol: { width: 1 },
    resultContainer: {
        marginTop: '15px',
        overflow: 'auto'
        // height: '200px'
    },
    resultTable: {},
    spinner: {
        margin: '8px 16px'
    },
    noResult: {
        padding: '8px 16px'
    },
    iconContainer: {
        alignSelf: 'center'
    },
    icon: {
        fontSize: 16,
        color: palette.action.active
    }
});

// prettier-ignore
export default compose(
    withStyles(styles)
)(FuneralLookupTable);
