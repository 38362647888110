import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import burgerSvg from '../../assets/burger.svg';
import HeaderSearchInput from './HeaderSearchInput';
import HeaderActions from './HeaderActions';

class Header extends Component {
    state = {
        searchOpen: false
    };

    onToggleSearch = () => {
        this.setState({ searchOpen: !this.state.searchOpen });
    };

    render() {
        const { onToggleDrawerOpen, drawerMinimized } = this.props;
        return (
            <Fragment>
                <div position="absolute" className={`app-bar ${drawerMinimized ? 'app-bar--minimized' : ''}`}>
                    <Toolbar className="toolbar">
                        <Hidden mdUp>
                            <IconButton
                                style={{ width: 40, height: 40 }}
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onToggleDrawerOpen}
                                title={'Show'}
                            >
                                <img src={burgerSvg} alt="open menu" className="burger" />
                            </IconButton>
                        </Hidden>
                        <HeaderActions />
                    </Toolbar>
                </div>
            </Fragment>
        );
    }

    renderDesktopSearch() {
        return (
            <Hidden xsDown>
                <HeaderSearchInput className="desktop-search" />
            </Hidden>
        );
    }

    renderMobileSearch() {
        const { searchOpen } = this.state;
        return (
            <Hidden smUp>
                {searchOpen && (
                    <div
                        tabIndex="0"
                        onClick={this.onToggleSearch}
                        onKeyDown={this.onToggleSearch}
                        className="mobile-search-overlay"
                        role="button"
                    />
                )}
                <Paper
                    square
                    elevation={1}
                    onMouseDown={this.onSearchMouse}
                    onMouseUp={this.onSearchMouse}
                    onTouchStart={this.onSearchMouse}
                    onTouchEnd={this.onSearchMouse}
                    className={`mobile-search-paper ${!searchOpen ? 'mobile-search-paper--hidden' : ''}`}
                >
                    <HeaderSearchInput mobile open={searchOpen} />
                </Paper>
            </Hidden>
        );
    }
}

export default withStyles({})(Header);
