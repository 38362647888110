import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import { setToken } from '../utils/sessions';
import TextField from '../components/form/TextField';
import { joinDefined } from '../utils/strings';
import { getAuthClient, getServiceURLHostname } from '../utils/apollo';
import Anonymous from './Anonymous';
import UserIcon from '../components/icon/UserIcon';
import LockIcon from '../components/icon/LockIcon';
import Spinner from '../components/loading/Spinner';
import NextIcon from '../components/icon/NextIcon';
import { isLocalClient, isLocalServer } from '../environment';

class SignIn extends Component {
    state = {
        email: '',
        password: '',
        remember: false,
        error: false,
        busy: false
    };

    componentWillMount() {
        if (!(isLocalClient() && !isLocalServer())) {
            const { match } = this.props;
            const { id, hash, remember } = match.params;

            getAuthClient()
                .mutate({
                    mutation: createTokenFromHash,
                    variables: {
                        id: id,
                        hash: hash
                    }
                })
                .then(data => {
                    this.onCompleted(data, remember);
                });
        }
    }

    render() {
        const { remember } = this.state;
        return (
            <Anonymous>
                {(isLocalClient() && !isLocalServer() && (
                    <Mutation
                        client={getAuthClient()}
                        mutation={loginMutation}
                        onCompleted={m => this.onCompleted({ data: m }, remember)}
                    >
                        {this.renderForm}
                    </Mutation>
                )) || <CircularProgress />}
            </Anonymous>
        );
    }

    renderForm = (mutate, results = {}) => {
        const { loading } = results;
        const { email, password, remember, error, busy } = this.state;
        const onSubmit = e => {
            if (e) e.preventDefault();
            if (loading) return;
            if (!email) {
                this.setState({ error: 'Please enter an email address.' });
                return;
            }

            if (!password) {
                this.setState({ error: 'Please enter a password.' });
                return;
            }
            this.setState({ busy: true }, () => {
                window.localStorage.removeItem('notificationSeenDate');
                mutate({
                    variables: { email, password }
                });
            });
        };

        // const resetPassword = "/forgot-password";
        const resetPassword = '//' + getServiceURLHostname() + '/Security/lostpassword';

        return (
            <form className="sign-in" onSubmit={onSubmit}>
                <Typography variant="title" gutterBottom>
                    Sign In
                </Typography>
                <div className="divider-line" />
                <Typography component="p" variant="body1">
                    If you have a Cremation Manager account please sign in below.
                </Typography>
                {error && (
                    <Paper className="error">
                        <Typography component="p" variant="body1" className="error-text">
                            {error}
                        </Typography>
                    </Paper>
                )}
                <div style={{ marginTop: 16, marginBottom: 0 }}>
                    <TextField
                        fullWidth
                        onChange={this.onChangeText('email')}
                        placeholder="Email"
                        value={email}
                        inputMode="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <UserIcon className="icon-blue" />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div style={{ marginTop: 16, marginBottom: 8 }}>
                    <TextField
                        fullWidth
                        onChange={this.onChangeText('password')}
                        placeholder="Password"
                        value={password}
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon className="icon-blue" />
                                </InputAdornment>
                            )
                        }}
                    />
                </div>
                <div className="remember-links">
                    <FormControlLabel
                        control={<Checkbox checked={remember} onChange={this.onToggleRemember} color="primary" />}
                        label="Keep me signed in"
                    />
                    <Link to={resetPassword} className="link" target="_blank">
                        Lost/Reset Password?
                    </Link>
                </div>
                <Button disabled={busy} type="submit" variant="contained" className="button-base primary">
                    Sign In&nbsp;{busy ? <Spinner size="xs" /> : <NextIcon />}
                </Button>
            </form>
        );
    };

    onChangeText = name => e => {
        this.setState({
            [name]: e.target.value,
            error: false
        });
    };

    onToggleRemember = e => {
        this.setState({ remember: e.target.checked });
    };

    onCompleted = ({ data }, remember) => {
        const { Token, Member } = (data && (data.createTokenFromHash || data.createToken)) || {};
        if (!Token) {
            this.setState({ error: 'Incorrect email or password.', busy: false });
            return null;
        }
        const { ID, FirstName, Surname } = Member;
        setToken(
            {
                userid: ID,
                value: Token,
                name: joinDefined([FirstName, Surname], ' ')
            },
            parseInt(remember)
        );

        this.redirection();
    };

    redirection = () => {
        const { location, history } = this.props;
        const redirectPath = (location.state && location.state.referrer) || '/';
        history.replace(redirectPath);
    };
}

const loginMutation = gql`
    mutation Login($email: String!, $password: String!) {
        createToken(Email: $email, Password: $password) {
            Token
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

const createTokenFromHash = gql`
    mutation createTokenFromHash($id: ID!, $hash: String!) {
        createTokenFromHash(id: $id, hash: $hash) {
            Token
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

export default withStyles({})(SignIn);
