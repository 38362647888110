import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';
import DizzySmiley from '../icon/metricDashboard/DizzySmiley';
import SadSmiley from '../icon/metricDashboard/SadSmiley';
import MehSmiley from '../icon/metricDashboard/MehSmiley';
import HappySmiley from '../icon/metricDashboard/HappySmiley';
import StokedSmiley from '../icon/metricDashboard/StokedSmiley';

class RatingCard extends Component {
    render() {
        const { variant = 'twenty' } = this.props;

        if (variant === 'twenty') {
            return this.renderTwenty();
        }

        if (stringIsNullOrEmpty(variant) || variant === 'forty') {
            return this.renderForty();
        }

        if (variant === 'sixty') {
            return this.renderSixty();
        }

        if (variant === 'eighty') {
            return this.renderEighty();
        }

        if (variant === 'hundred') {
            return this.renderHundred();
        }

        return <span>unknown modal variant {variant}</span>;
    }

    renderTwenty() {
        return (
            <div className="rating-card twenty">
                <div className="center-content">
                    <DizzySmiley />
                </div>
                <p> 0 - 20%</p>
            </div>
        );
    }

    renderForty() {
        return (
            <div className="rating-card forty">
                <div className="center-content">
                    <SadSmiley />
                </div>
                <p> 20 - 40%</p>
            </div>
        );
    }

    renderSixty() {
        return (
            <div className="rating-card sixty">
                <div className="center-content">
                    <MehSmiley />
                </div>
                <p> 40 - 60%</p>
            </div>
        );
    }

    renderEighty() {
        return (
            <div className="rating-card eighty">
                <div className="center-content">
                    <HappySmiley />
                </div>
                <p> 60 - 80%</p>
            </div>
        );
    }

    renderHundred() {
        return (
            <div className="rating-card hundred">
                <div className="center-content">
                    <StokedSmiley />
                </div>
                <p> 80 - 100%</p>
            </div>
        );
    }
}
export default withStyles({})(RatingCard);
