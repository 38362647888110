import React from 'react';
import FileUploadCard from './FileUploadCard';
import Button from '../form/Button';

export default ({ fileName, fileLink, onClickRemoveAction, fileId, variant }) => {
    return (
        <div className="thumbnail-container">
            <FileUploadCard fileName={fileName} fileLink={fileLink} variant={variant || ''} />
            {!!onClickRemoveAction &&
                <Button title="Remove attachment" variant="link-red" onClick={() => onClickRemoveAction(fileId)}>
                    Remove
                </Button>
            }
        </div>
    );
};
