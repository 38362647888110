import gql from 'graphql-tag';
import { isNullOrUndefined } from './objects';
import { joinDefined } from './strings';

/**
 * Extends the base gql function with the ability
 * to pass in arrays of fragments as arguments.
 */
export function gqlArr(literals, ...args) {
    const newLiterals = [literals[0]];
    const newArgs = [];
    args.forEach((arg, i) => {
        if (Array.isArray(arg)) {
            newArgs.push(...arg);
            if (arg.length > 0) {
                const filler = Array(arg.length - 1).fill('');
                newLiterals.push(...filler);
                newLiterals.push(literals[i + 1]);
            }
        } else {
            newLiterals.push(literals[i + 1]);
            newArgs.push(arg);
        }
    });
    return gql(newLiterals, ...newArgs);
}

/**
 * Builds a single fragment combining the given list of fragments.
 * The combined fragment selects all necessary data for a particular form tab.
 */
export function buildTabDataFragment(rootFragmentName, fragments) {
    return gqlArr`
          fragment ${rootFragmentName}Fragment on ${rootFragmentName} {
              ID
              ${fragments.map(f => `...${f.definitions[0].name.value}`).join('\n')}
          }
          ${fragments}
      `;
}

/**
 * Builds a lookup of field names to the keys of the tabs that query/mutate those fields
 * @param {*} tabs
 */
export const buildTabFragmentLookup = tabs => {
    return tabs.reduce((lookup, tab) => {
        tab.fragment.definitions.forEach(d =>
            d.selectionSet.selections.forEach(selection => mapTabFragment(lookup, tab.id, selection))
        );
        return lookup;
    }, {});
};

/**
 * maps fragments and nested fragments
 * @param {*} lookup
 * @param {*} lookupKey
 * @param {*} selection
 * @param {*} prefix
 */
const mapTabFragment = (lookup, lookupKey, selection, prefix = null) => {
    if (isNullOrUndefined(selection.name)) return;

    const fieldName = joinDefined([prefix, selection.name.value], '_');

    if (fieldName === 'ID') return;
    if (!lookup[fieldName]) lookup[fieldName] = [];
    if (!lookup[fieldName].includes(lookupKey)) lookup[fieldName].push(lookupKey);
    if (selection.selectionSet) {
        selection.selectionSet.selections.forEach(s => {
            /*
			we only want to map kind==='Field'
			we don't want kind=='FragmentSpread'

			we are trying to pull out all of the Deeply_Nested_Fields_Inside this fragment.
			but we don't need to to that if the field is actually a ...FragmentSpread, because it'll be automatically included
			in the dataform when it saves
			*/
            if (s.kind === 'Field') {
                mapTabFragment(lookup, lookupKey, s, fieldName);
            }
        });
    }
};

/**
 * turns tabs into the Fragments object
 * @param {*} tabs
 */
export const createFragments = tabs => {
    let fragments = {};
    tabs.forEach(x => (fragments[x.id] = x.fragment));
    return fragments;
};
