import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import cloneDeep from 'lodash.clonedeep';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import RadioGroup from '../../components/form/RadioGroup';
import AshLocationHistoryModal from './Modals/AshLocationHistoryModal';
import Select from '../../components/form/Select';
import { CollectedByOptions, DispatchMethodOptions } from './PlaqueConstants';
import ContactCard from '../../components/contact/ContactCard';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import { numberToWords } from '../../utils/strings';
import DeleteIcon from '../../components/icon/DeleteIcon';
import AlertModal from '../../components/modal/AlertModal';
import EditIcon from '../../components/icon/EditIcon';
import ContactLookup from '../../components/contact/ContactLookup';
import PlaqueCombinationLocation from '../../fragments/PlaqueCombinationLocation';

class RecordDetailsTab extends Component {
    state = {
        showAshLocationHistoryModal: false,
        showDeleteAshOrderModal: false,
        editAshLocationHistoryIndex: null,
        showDeleteAshLocationAlertModal: false
    };

    render() {
        const {
            showAshLocationHistoryModal,
            showDeleteAshOrderModal,
            editAshLocationHistoryIndex,
            showDeleteAshLocationAlertModal
        } = this.state;

        const { form, index } = this.props;

        let editAshLocationHistory = {
            ID: null,
            Location: '',
            LocationDetail: '',
            Notes: '',
            Changes: []
        };

        if (editAshLocationHistoryIndex !== null && editAshLocationHistoryIndex !== undefined) {
            editAshLocationHistory = form.getState('AshLocations')[editAshLocationHistoryIndex];
        }

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <AlertModal
                    variant="warning"
                    open={showDeleteAshOrderModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteAshOrder()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteAshOrderModal()}
                    onClose={() => this.handleCloseDeleteAshOrderModal()}
                >
                    <p>You're about to delete this ash order.</p>
                    <p>Are you sure?</p>
                </AlertModal>

                <AshLocationHistoryModal
                    open={showAshLocationHistoryModal}
                    onClose={() => this.handleCloseAshLocationHistoryModal()}
                    onCancel={() => this.handleCloseAshLocationHistoryModal()}
                    onSubmit={ashLocationHistory => this.handleSaveAshLocationHistoryModal(ashLocationHistory)}
                    ashLocationHistory={editAshLocationHistory}
                    name={'editAshLocationHistory'}
                />

                <AlertModal
                    variant="warning"
                    open={showDeleteAshLocationAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteAshLocationHistory(index)}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteAshLocationAlertModal()}
                    onClose={() => this.handleCloseDeleteAshLocationAlertModal()}
                >
                    <p>You're about to delete this ash location history.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return <Fragment>{this.renderOrderDetails()}</Fragment>;
    }

    renderOrderDetails() {
        const { form } = this.props;
        const contact = form.getField('PlaqueLocation');

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Plaque Location</h4>
                </Grid>

                {(!contact && (
                    <Grid item>
                        <ContactLookup
                            name="PlaqueLocation.ID"
                            onSelect={(_, addressBook) =>
                                this.handleSelectBusinessContact('PlaqueLocation', addressBook)
                            }
                            onAddNewContact={addressBook =>
                                this.handleSelectBusinessContact('PlaqueLocation', addressBook)
                            }
                            readAllQuery={readAllAddressBooksQuery}
                        />
                    </Grid>
                )) || (
                    <Fragment>
                        <Grid item>
                            <div style={{ float: 'right', marginTop: -24 }}>
                                <Button variant="link-orange" onClick={() => form.setField({ PlaqueLocation: null })}>
                                    Change Location
                                </Button>
                            </div>

                            <ContactCard
                                variant="fullwidth" //Do no remove
                                contact={contact}
                            />
                        </Grid>

                        {!!contact.PlaqueCombinations && !!contact.PlaqueCombinations.length && (
                            <Grid item>
                                <Select
                                    label="Combination"
                                    options={contact.PlaqueCombinations.map(obj => {
                                        return {
                                            label: obj.LocationType,
                                            value: obj.ID
                                        };
                                    })}
                                    form={form}
                                    name="PlaqueCombination.ID"
                                    onChange={() => this.handleCombinationSelect()}
                                />
                            </Grid>
                        )}
                    </Fragment>
                )}

                <Grid item xs={12} sm={6}>
                    <TextField
                        type="date"
                        label="Plaque Installation Date"
                        form={form}
                        name="InstallationDate"
                        required
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        type="time"
                        label="Plaque Installation Time"
                        form={form}
                        name="InstallationTime"
                        required
                    />
                </Grid>

                <Grid item>
                    <Inline center>
                        <div>
                            <h5>Family in attendance?</h5>
                        </div>
                        <RadioGroup
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' }
                            ]}
                            value={form.getField('FamilyAttendance') ? 'yes' : 'no'}
                            onChange={e => form.setState({ FamilyAttendance: e.target.value === 'yes' })}
                        />
                    </Inline>
                </Grid>

                {form.getField('FamilyAttendance') && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                type="date"
                                label="Family Attendance Date"
                                form={form}
                                name="FamilyAttendanceDate"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Family Attendance Time" form={form} name="FamilyAttendanceTime" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label="Family Attendance Notes" form={form} name="FamilyContact" />
                        </Grid>
                    </Fragment>
                )}

                <Grid item>
                    <Inline center>
                        <div>
                            <h5>Placement by council?</h5>
                        </div>
                        <RadioGroup
                            options={[
                                { label: 'Yes', value: 'yes' },
                                { label: 'No', value: 'no' }
                            ]}
                            value={form.getField('PlacementByCouncil') ? 'yes' : 'no'}
                            onChange={e => form.setState({ PlacementByCouncil: e.target.value === 'yes' })}
                        />
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    handleSelectBusinessContact(propertyName, addressBook) {
        const { form } = this.props;
        delete addressBook['__typename'];

        if (!!addressBook) {
            form.setState({ [propertyName]: addressBook });
        }
    }

    handleCombinationSelect() {
        const { form } = this.props;
        const combination = this.getPlaqueCombination();
        if (combination) {
            const cloned = cloneDeep(combination);

            const Ashes = form.getState('Ashes') || [];
            if (Ashes.length) {
                Ashes[0].Ashes = cloned.AshesSelection;
                Ashes[0].ContainmentStyle = cloned.VaseType;
            } else {
                Ashes.push({
                    Ashes: cloned.AshesSelection,
                    ContainmentStyle: cloned.VaseType
                });
            }

            cloned.BorderDetails = cloned.BorderType;

            delete cloned.ID;
            delete cloned.LocationType;
            delete cloned.PlaqueSize;
            delete cloned.VaseType;
            delete cloned.AshesSelection;
            delete cloned.QuoteTemplatesAllowed;
            delete cloned.Location;
            delete cloned.WallPositions;
            delete cloned.MaterialOptions;
            delete cloned.Optionals;
            delete cloned.AttachmentMethods;
            delete cloned.BorderType;
            delete cloned.BorderTypes;
            delete cloned.__typename;

            const newState = Object.assign(form.state, cloned);
            newState.Ashes = Ashes;

            form.setState(newState);
        }
    }

    getPlaqueCombination() {
        const { form } = this.props;
        const combinationID = form.getField('PlaqueCombination.ID');
        const combinations = form.getField('PlaqueLocation.PlaqueCombinations');

        if (combinationID && combinations && combinations.length) {
            return combinations.find(obj => obj.ID === combinationID);
        }
        return null;
    }

    renderRightColumn() {
        return (
            <Fragment>
                {this.renderOrderConsiderations()}
                {this.renderOrderAshes()}
                {this.renderAshLocation()}
            </Fragment>
        );
    }

    renderOrderConsiderations() {
        const { form } = this.props;
        const combination = this.getPlaqueCombination();

        return (
            <Grid container bucket>
                <Grid item xs={12}>
                    <h4>Order Considerations</h4>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Plaque Position" form={form} name="PlaquePosition" />
                </Grid>

                <Grid item xs={12} sm={6}>
                    {!!combination && !!combination.MaterialOptions && !!combination.MaterialOptions.length ? (
                        <Select
                            label="Plaque Material"
                            options={combination.MaterialOptions.map(obj => {
                                return {
                                    label: obj.Title,
                                    value: obj.Title
                                };
                            })}
                            form={form}
                            name="PlaqueMaterial"
                        />
                    ) : (
                        <TextField label="Plaque Material" form={form} name="PlaqueMaterial" />
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {!!combination && !!combination.AttachmentMethods && !!combination.AttachmentMethods.length ? (
                        <Select
                            label="Attachment Method"
                            options={combination.AttachmentMethods.map(obj => {
                                return {
                                    label: obj.Title,
                                    value: obj.Title
                                };
                            })}
                            form={form}
                            name="AttachmentMethod"
                        />
                    ) : (
                        <TextField label="Attachment Method" form={form} name="AttachmentMethod" />
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    {!!combination && !!combination.WallPositions && !!combination.WallPositions.length ? (
                        <Select
                            label="Wall Position"
                            options={combination.WallPositions.map(obj => {
                                return {
                                    label: obj.Title,
                                    value: obj.Title
                                };
                            })}
                            form={form}
                            name="WallPosition"
                        />
                    ) : (
                        <TextField label="Wall Position" form={form} name="WallPosition" />
                    )}
                </Grid>
            </Grid>
        );
    }

    renderOrderAshes() {
        const { form } = this.props;
        const orderAshes = form.getState('Ashes') || [];

        return (
            <Grid container bucket>
                <Grid item xs={12}>
                    <h4>Ashes</h4>
                </Grid>
                <Grid item>
                    <Button variant="primary" onClick={() => this.handleAddAshOrder()}>
                        + Add Ash
                    </Button>
                </Grid>

                {orderAshes.map((ashOrder, index) => this.renderAshOrder(ashOrder, index))}
            </Grid>
        );
    }

    renderAshOrder(ashOrder, index) {
        const { form } = this.props;
        return (
            <Fragment key={'ashorder' + index}>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <h5>Ash Order {numberToWords(index + 1)}</h5>

                        {index > 0 && (
                            <IconButton
                                className="icon"
                                title={'Delete'}
                                onClick={() => this.handleShowDeleteAshOrderModal()}
                            >
                                <DeleteIcon />
                            </IconButton>
                        )}
                    </Inline>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Ashes" form={form} name={`Ashes[${index}].Ashes`} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Vase" form={form} name={`Ashes[${index}].ContainmentStyle`} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select
                        label="Dispatch Method"
                        options={DispatchMethodOptions}
                        form={form}
                        name={`Ashes[${index}].DispatchMethod`}
                    />
                </Grid>

                {form.getField(`Ashes[${index}].DispatchMethod`) === 'Collected by' && (
                    <Grid item xs={12} sm={6}>
                        <Select
                            label="Ashes collected by"
                            options={CollectedByOptions}
                            form={form}
                            name={`Ashes[${index}].AshesCollectedBy`}
                        />
                    </Grid>
                )}

                <Grid item>
                    <TextField
                        label="Comment"
                        name={`Ashes[${index}].Comment`}
                        form={form}
                        multiline
                        button={{
                            variant: 'secondary',
                            children: '+ Add Comments'
                        }}
                    />
                </Grid>
            </Fragment>
        );
    }

    renderAshLocation() {
        const { form } = this.props;
        const ashLocations = form.getState('AshLocations') || [];
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Ash Location</h4>
                </Grid>

                {ashLocations.length > 0 &&
                    ashLocations.map((ashLocation, index) => (
                        <Grid item key={ashLocation.ID}>
                            <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                                <p>{ashLocation.Location}</p>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowAshLocationHistoryModal(index)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowDeleteAshLocationAlertModal()}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {/*<IconButton className="icon" onClick={() => this.handleDeleteAshLocationHistory(index)}><DeleteIcon /></IconButton>*/}
                            </Inline>
                        </Grid>
                    ))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleShowAshLocationHistoryModal()}>
                        Log Ash Location...
                    </Button>
                </Grid>
            </Grid>
        );
    }

    handleAddAshOrder() {
        const { form } = this.props;
        const Ashes = form.getState('Ashes') || [];
        Ashes.push({});
        form.setState({ Ashes });
    }

    handleDeleteAshOrder(index) {
        const { form } = this.props;
        const Ashes = form.getState('Ashes') || [];

        if (index !== -1) {
            Ashes.splice(index, 1);
            form.setState({ Ashes });
        }

        this.handleCloseDeleteAshOrderModal();
    }

    handleDeleteAshLocationHistory(index) {
        const { form } = this.props;
        const AshLocations = form.getState('AshLocations') || [];

        if (index !== -1) {
            AshLocations.splice(index, 1);
            form.setState({ AshLocations });
        }
    }

    handleShowDeleteAshOrderModal() {
        this.setState({ showDeleteAshOrderModal: true });
    }

    handleCloseDeleteAshOrderModal() {
        this.setState({ showDeleteAshOrderModal: false });
    }

    handleShowAshLocationHistoryModal(index) {
        this.setState({ editAshLocationHistoryIndex: index });
        this.setState({ showAshLocationHistoryModal: true });
    }

    handleCloseAshLocationHistoryModal() {
        this.setState({ showAshLocationHistoryModal: false });
        this.setState({ editAshLocationHistoryIndex: null });
    }

    handleSaveAshLocationHistoryModal(ashLocation) {
        const { form } = this.props;
        const { editAshLocationHistoryIndex } = this.state;
        const AshLocations = form.getState('AshLocations') || [];

        if (
            editAshLocationHistoryIndex !== null &&
            editAshLocationHistoryIndex !== undefined &&
            AshLocations[editAshLocationHistoryIndex]
        ) {
            const existingAshLocationHistory = AshLocations[editAshLocationHistoryIndex];
            Object.assign(existingAshLocationHistory, ashLocation);
        } else {
            AshLocations.push(ashLocation);
        }
        this.setState({ showAshLocationHistoryModal: false });
        this.setState({ editAshLocationHistoryIndex: null });

        form.setState({ AshLocations });
        form.save();
    }

    handleShowDeleteAshLocationAlertModal() {
        this.setState({ showDeleteAshLocationAlertModal: true });
    }

    handleCloseDeleteAshLocationAlertModal() {
        this.setState({ showDeleteAshLocationAlertModal: false });
    }
}

const readAllAddressBooksQuery = gql`
    ${PlaqueCombinationLocation}

    query ReadCremationManagerAddressBooks($contains: String) {
        readCremationManagerAddressBooks(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...PlaqueCombinationLocation
                }
            }
        }
    }
`;
export default {
    id: 'PlaqueOrderDetails',
    label: 'Order Details',
    component: withStyles({})(RecordDetailsTab),
    fragment: PlaqueOrder,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {
        const location = state.PlaqueLocation;
        if (location && !!location.ID) {
            saveData.PlaqueLocationID = location.ID;
            delete saveData.PlaqueLocation;
        } else if (saveData.PlaqueLocation === null) {
            saveData.PlaqueLocationID = null;
            delete saveData.PlaqueLocation;
        }

        const combination = state.PlaqueCombination;
        if (combination && !combination.ID) {
            saveData.PlaqueCombinationID = 0;
            delete saveData.PlaqueCombination;
        }
    }
};
