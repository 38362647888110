import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BackArrow from '../icon/BackArrow';
import NextArrow from '../icon/NextArrow';
import Button from './Button';

export default withStyles({})(({ className }) => {
    return (
        <ul className="pagination">
            <li className="pagination-item arrow">
                <Button>
                    <BackArrow className="icon" />
                </Button>
            </li>
            <li className="pagination-item current">
                <Button>1</Button>
            </li>
            <li className="pagination-item">
                <Button>2</Button>
            </li>
            <li className="pagination-item">
                <Button>3</Button>
            </li>
            <li className="pagination-item">
                <Button>4</Button>
            </li>
            <li className="pagination-item arrow">
                <Button>
                    <NextArrow className="icon" />
                </Button>
            </li>
        </ul>
    );
});
