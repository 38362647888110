import gql from "graphql-tag";

export default gql`
	fragment QuoteEmailTemplate on CMQuoteEmailTemplate {
        ID
        Title
        EmailText
        EmailAttachments {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
	}
`;
