import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined, prettyNumber, stringIsNullOrEmpty } from '../../utils/strings';
import Table, { Cell, HeaderRow, Row } from './Table';
import Inline, { inlineAlignment } from '../layout/Inline';
import { niceDateFromString, niceDateTimeFromString, niceTimeFromString } from '../../utils/date';
import { getBrandHomeByKey } from '../../utils/brands';
import AlertBar from '../form/AlertBar';
import Button from '../form/Button';

export default withStyles({})(({ variant, runsheetType, runsheetStatus, cremation, children }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `runsheet--${variant}`;

    const cremationTime = niceTimeFromString(cremation.CremationTime);
    const deceased = cremation.Deceased || false;
    const deceasedName = !!deceased && joinDefined([deceased.FirstName, deceased.MiddleName, deceased.Surname], ' ');
    const recordID = cremation.ID;
    const legacyKey = cremation.LegacyKey;
    const funeralBrand = getBrandHomeByKey(legacyKey).label; // todo
    const assignedStaff = joinDefined(
        [cremation.CremationOperator.FirstName, cremation.CremationOperator.Surname],
        ' '
    );
    const witnessInsertion = ''; // todo
    const cremationDate = niceDateFromString(cremation.CremationDate);
    const deceasedWeight = cremation.DeceasedWeight;
    const estimatedDeceasedWeight = cremation.EstimatedDeceasedWeight;
    const cremationNumber = cremation.CremationNumber;
    const niche = ''; // todo
    const sameDay = !!cremation.SameDayCremation;
    const reflectionRoomAttendees = joinDefined(
        [
            !!cremation.ReflectionRoom.Applicant ? 'Applicant' : null,
            !!cremation.ReflectionRoom.AuthorisedContact ? 'Authorised Contact' : null,
            cremation.ReflectionRoom.AttendeeNames
        ],
        ', '
    );

    const renderReflectionRoomDetails = () => {
        return (
            !!parseInt(cremation.ReflectionRoom.ID) && (
                <Cell>
                    <h5>Reflection Room Booking:</h5>
                    <p>
                        <span className="info">Time:&nbsp;</span>
                        {(niceTimeFromString(cremation.ReflectionRoom.Start) || '(not set)') +
                            ' to ' +
                            (niceTimeFromString(cremation.ReflectionRoom.End) || '(not set)')}
                    </p>
                    <p>
                        <span className="info">Date:&nbsp;</span>
                        {niceDateTimeFromString(cremation.ReflectionRoom.Start)}
                    </p>
                    {!!reflectionRoomAttendees && (
                        <p>
                            <span className="info">Attendees:&nbsp;</span>
                            {reflectionRoomAttendees}
                        </p>
                    )}
                </Cell>
            )
        );
    };

    return (
        <div className={`runsheet ${cssVariant || ''}`}>
            <Table>
                <HeaderRow pad>
                    <Cell colSpan={2}>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <span className="runsheet-header">
                                    {runsheetStatus} | {runsheetType}:&nbsp;
                                    {deceasedName}
                                </span>
                            </div>
                            <p className="header-record">
                                <Button variant="link-white" href={'/cremations/' + recordID}>
                                    {legacyKey}
                                </Button>
                                {!!funeralBrand && ' | ' + funeralBrand}
                            </p>
                        </Inline>
                    </Cell>
                </HeaderRow>
                <Row pad>
                    <Cell rowSpan={2}>
                        {!!sameDay && (
                            <p>
                                <span className="info">SAME DAY CREMATION</span>
                            </p>
                        )}
                        <p>
                            <span className="info">Cremation Date:&nbsp;</span>
                            {cremationDate} {!!cremationTime && ' at ' + cremationTime}
                        </p>
                        <p>
                            <span className="info">Service:&nbsp;</span>
                            {cremation.IsDeliveryOnly ? 'Delivery only' : 'Full Service'}
                        </p>
                        {!!assignedStaff && (
                            <p>
                                <span className="info">Assigned Staff:&nbsp;</span>
                                {assignedStaff}
                            </p>
                        )}
                        {!!witnessInsertion && (
                            <p>
                                <span className="info">Witness of Insertion:&nbsp;</span>
                                {witnessInsertion}
                            </p>
                        )}
                        {!!estimatedDeceasedWeight && (
                            <p>
                                <span className="info">Estimated Deceased Weight:&nbsp;</span>
                                {prettyNumber(estimatedDeceasedWeight)} Kg
                            </p>
                        )}
                        {!!deceasedWeight && (
                            <p>
                                <span className="info">Deceased Weight:&nbsp;</span>
                                {prettyNumber(deceasedWeight)} Kg
                            </p>
                        )}
                        {!!cremationNumber && (
                            <p>
                                <span className="info">CR Number:&nbsp;</span>
                                {cremationNumber}
                            </p>
                        )}
                        {!!niche && (
                            <p>
                                <span className="info">Niche:&nbsp;</span>
                                {niche}
                            </p>
                        )}
                        {!!children.length && (
                            <Fragment>
                                <h5>Special Instructions:&nbsp;</h5>
                                {children}
                            </Fragment>
                        )}
                    </Cell>
                    {!!cremation.DispatchDates.length ? (
                        <Cell>
                            <h5>Dispatch Details:</h5>
                            {cremation.DispatchDates.map((dispatchDate, i) => {
                                return (
                                    <Fragment key={dispatchDate.ID}>
                                        {!!i && <hr />}
                                        <p>
                                            <span className="info">Dispatch #{i + 1}</span>
                                        </p>
                                        <p>
                                            <span className="info">Containment Style:&nbsp;</span>
                                            {dispatchDate.ContainmentStyle}
                                        </p>
                                        <p>
                                            <span className="info">Dispatch Date:&nbsp;</span>
                                            {niceDateFromString(dispatchDate.Date)}
                                            {!!dispatchDate.Time && ' at ' + niceTimeFromString(dispatchDate.Time)}
                                        </p>
                                        <p>
                                            <span className="info">Comments:&nbsp;</span>
                                            {dispatchDate.Comment}
                                        </p>
                                    </Fragment>
                                );
                            })}
                        </Cell>
                    ) : (
                        renderReflectionRoomDetails()
                    )}
                </Row>
                {!!cremation.DispatchDates.length && <Row pad>{renderReflectionRoomDetails()}</Row>}
            </Table>
            {runsheetStatus === 'NOT READY' && (
                <AlertBar variant="error">This record has not been marked as ready for cremation.</AlertBar>
            )}
        </div>
    );
});
