import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import StarIcon from '../icon/StarIcon';
import IconButton from '@material-ui/core/IconButton';
import { getProperty, setPropertyWrapper } from '../../utils/objects';
import cx from 'classnames';
import Spinner from '../loading/Spinner';

class CheckableStar extends Component {
    render() {
        const { classes, className, disabled, loading } = this.props;
        const checked = this.getValue();
        const title = checked ? 'Task is starred' : 'Task is not starred';

        return (
            <Fragment>
                <IconButton
                    disabled={disabled || loading}
                    title={title}
                    className={cx(classes.button, className)}
                    onClick={e => this.onCheckChanged(!checked, e)}
                >
                    {!!loading ? (
                        <Spinner className={classes.spinner} />
                    ) : (
                        <StarIcon className={classes.icon} filled={checked ? 'filled' : null} />
                    )}
                </IconButton>
            </Fragment>
        );
    }

    getValue() {
        const { name, form, convertFrom } = this.props;
        if (form && name) {
            const formValue = getProperty(form, name);
            return convertFrom ? convertFrom(formValue) : formValue;
        }

        return this.props.value || false;
    }

    onCheckChanged(value, e) {
        e.stopPropagation();
        const { onChange, name, form, convertTo } = this.props;
        const formValue = convertTo ? convertTo(value) : value;
        if (form && name) setPropertyWrapper(form, { [name]: formValue });
        if (onChange) onChange(value, formValue);
    }
}

const styles = () => ({
    spinner: {
        marginTop: -3,
        flexShrink: 0
    },
    icon: {
        color: '#FFDD26'
    },
    button: {
        height: 40,
        width: 40,
        padding: 0
        // '& span > svg':{
        //     width:'1.2em',
        //     height:'1.2em'
        // }
    }
});

export default withStyles(styles)(CheckableStar);
