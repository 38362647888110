import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { prettyNumber, stringIsNullOrEmpty } from '../../utils/strings';
import Spinner from '../loading/Spinner';

export default withStyles({})(({ variant, label, stats, goal, statsNumber, loading }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `bar-fill--${variant}`;

    const heightStyle = { height: stats };

    return (
        <div className="chart-container">
            {!!goal && <div className="horizontal-number">{goal}</div>}
            <div className="bar">
                <div className="bar-track">
                    {(!!loading && <Spinner />) || (
                        <div className={`bar-fill ${cssVariant || ''}`} style={heightStyle}>
                            <div className="stats-container">{prettyNumber(statsNumber)}</div>
                        </div>
                    )}
                </div>
            </div>
            <div className="label-container">
                <label className="chart-label">{label}</label>
            </div>
        </div>
    );
});
