import React from 'react';
import { Pie } from 'react-chartjs-2';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';
import Spinner from '../loading/Spinner';

export default withStyles({})(({ variant, data, loading }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `bar-fill--${variant}`;

    // some array reducers use first acc as first object, so need to check
    const total = (data && data.reduce((acc, val) => Number(val.value) + (isNaN(acc) ? Number(acc.value) : acc))) || 1;

    const myData = {
        labels: data && data.map(e => `${e.label} (${Number(((e.value / total) * 100).toFixed(2))}%)`),
        datasets: [
            {
                data: data && data.map(e => e.value),
                backgroundColor: ['#7d9cc4', '#44AF69', '#F7DD60', '#f89e53', '#D14F57'],
                hoverBackgroundColor: [],
                hoverBorderColor: '#ffffff'
            }
        ]
    };

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            {(!!loading && <Spinner />) || (
                <Pie
                    className={cssVariant}
                    options={{ maintainAspectRatio: false, tooltips: { enabled: true } }}
                    height={400}
                    legend={{
                        position: 'bottom',
                        labels: {
                            fontColor: '#000000',
                            fontSize: 16
                        }
                    }}
                    data={myData}
                />
            )}
        </div>
    );
});
