import isObject from 'lodash.isobject';
import transform from 'lodash.transform';
import isEqual from 'lodash.isequal';
import { stringIsNullOrEmpty } from './strings';

//wraps setProperty
export function setPropertyWrapper(state, newState) {
    for (let fieldName in newState) {
        setProperty(state, fieldName, newState[fieldName]);
    }
}

export function getProperty(object, property, create) {
    if (!object || !property) return undefined;
    return nestedGet(object, property.split('.'), create);
}

//note: set may not work if the end property is an array
//example. This should work:
//  some[0].property = value | works
//  some.properties[0] = value | may not work
export function setProperty(object, property, value) {
    if (!object || !property) return undefined;
    const properties = property.split('.');
    const finalProperty = properties.pop();
    const result = nestedGet(object, properties, true);
    return result && finalProperty ? (result[finalProperty] = value) : undefined;
}

const squareBracketPattern = /\[+(.*?)\]+/g;

const nestedGet = (object, properties, create) => {
    object = object || window;

    for (let i = 0; !isNullOrUndefined(object); i++) {
        let property = properties[i];
        if (isNullOrUndefined(property)) break;

        let match = property.match(squareBracketPattern);
        if (match && match.length === 1 && match[0].length > 0) {
            const index = match[0].substring(1, match[0].length - 1);
            property = property.replace('[' + index + ']', '');
            const array = object[property];
            if (array) object = array ? object[property][index] : create ? (object[property] = {}) : undefined;
            else object = create ? [] : undefined;
        } else {
            object = property in object ? object[property] : create ? (object[property] = {}) : undefined;
        }
    }

    return object;
};

/**
 * checks if an object is null or undefined
 */
export const isNullOrUndefined = obj => {
    return obj === undefined || obj === null;
};

/**
 * Apollo includes the `__typename` field on the data returned from queries
 * We recursively iterate over the given data to remove all `__typename` fields
 * so that the object is sanitized before before saving it to GQL
 *
 * Also destroy ghost objects with ID = 0, go Pacman!
 *
 * @param {*} input
 */
export const deleteTypeName = input => {
    Object.keys(input).forEach(key => {
        if (key === '__typename') {
            try {
                delete input[key];
            } catch (e) {
                //console.warn(e);
            }
        } else if (isObject(input[key])) {
            if (input[key].ID && (input[key].ID === 0 || input[key].ID === '0')) {
                delete input[key];
            } else deleteTypeName(input[key]);
        }
    });
    return input;
};

/**
 * Flattens edges and nodes in a connection data structure.
 * i.e. data => Children => edges => node becomes data => Children
 */
export const flattenConnection = (data, propertyName) => {
    if (!data || !data[propertyName] || data[propertyName].edges === undefined) return;
    data[propertyName] = data[propertyName].edges.map(e => e.node);
};

/**
 * turns collection of objects into their respective ids
 * used when there's a relation
 */
export const reduceToIds = array => {
    for (let x = 0; x < array.length; x++) {
        array[x] = {
            ID: array[x].ID
        };
    }
};

/**
 * turns an object to an id if it exists
 */
export const reduceToId = (obj, propertyName) => {
    if (!obj[propertyName]) return;

    obj[`${propertyName}ID`] = obj[propertyName].ID;
    delete obj[propertyName];
};

/* checks if a gql object is null or undefined
 */
export const isRelatedObjectUndefined = obj => {
    return isNullOrUndefined(obj) || obj.ID === '0' || isNullOrUndefined(obj.ID);
};

export const resetAllProperties = obj => {
    clearNestedValues(obj);
};

const clearNestedValues = obj => {
    if (typeof obj !== 'object') return;
    Object.keys(obj).forEach(key => (obj[key] = clearValue(obj[key])));
};

const clearValue = obj => {
    if (obj === null || obj === undefined) return null;

    var type = typeof obj;

    if (type === 'boolean') return false;

    if (type === 'string') return null;

    if (type === 'number') return 0;

    clearNestedValues(obj);

    return obj;
};

export const round = (value, decimals = 2) => {
    var factor = Math.pow(10, decimals);
    var result = Math.round(value * factor) / factor;
    return result;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @param  {bool} compareArrays Whether to compare individual indicies of arrays. If false will just return the whole array if arrays differ
 * @return {Object}        Return a new object who represent the diff
 */
export function diff(object, base, compareArrays) {
    return changes(object, base, compareArrays);
}

const changes = (object, base, compareArrays) => {
    return transform(object, (result, value, key) => {
        if (!isEqual(value, base[key])) {
            result[key] =
                isObject(value) && isObject(base[key]) && (compareArrays || !Array.isArray(base[key]))
                    ? changes(value, base[key], compareArrays)
                    : value;
        }
    });
};

/**
 * Returns a new function which when invoked will execute all of the given functions
 * and propagating the same parameters into each function.
 */
export const applyAll = (...functions) => {
    return (...params) => {
        functions.forEach(func => typeof func === 'function' && func(...params));
    };
};

/**
 *
 * @param e the event fired
 * @param context the form object, eg. TextField
 * @param isTickbox for checkboxes
 * @param isNumber for numeric values, otherwise you get a string
 */
export const onChangeWrapper = (e, context, isTickbox = false, isNumber = false) => {
    /*
     * onChanging: function that will execute BEFORE the value is applied. If e.preventDefault() is called, this function will terminate
     * onChange: function that will execute AFTER the value is applied
     * valueField: get a nested result from the target
     * emptyValue: replace empty string with null
     */
    const { onChanging, onChange, name, form, valueField, emptyValue = null } = context.props;

    if (onChanging) onChanging(e);

    if (e.defaultPrevented) return;
    const valToCheck = !!isTickbox ? e.target.checked : e.target.value;
    let value = !stringIsNullOrEmpty(valueField)
        ? !isNullOrUndefined(valToCheck)
            ? valToCheck[valueField]
            : null
        : valToCheck;

    if (isNullOrUndefined(value)) value = emptyValue;

    if (isNumber) value = parseFloat(value);

    if (!isNullOrUndefined(form)) {
        if (!form.getValidation) throw new Error(name + ' form does not contain validation function!');

        if (!stringIsNullOrEmpty(name)) form.setState({ [name]: value });

        if (form && form.getValidation(name, true).shouldUpdate) context.forceUpdate();
    } else {
        context.setState({ value });
    }

    if (onChange) onChange(e, value);
};

export const getElementValueWrapper = context => {
    const { value, form, name, emptyValue, customvalue } = context.props;

    //value takes precedence
    if (!isNullOrUndefined(value)) return value;

    //use form
    if (!isNullOrUndefined(form) && !stringIsNullOrEmpty(name)) return form.getState(name) || emptyValue;

    //use state
    if (!isNullOrUndefined(context.state.value)) return context.state.value;

    //use customvalue when setting value doesnt allow you to type in the field
    if (!isNullOrUndefined(customvalue)) return customvalue;

    //use empty value
    return emptyValue;
};

export const orientationMode = {
    horizontal: 0,
    vertical: 1
};

/**
 * counts to a specific number
 * @param {*} number
 */
export const countTo = number => {
    const arr = [];
    for (let x = 0; x < number; x++) arr.push(x);
    return arr;
};

/**
 * groups a collection by a property into a map
 */
export const groupBy = (list, getKeyFunc, getValueFunc, asArray = false) => {
    const map = new Map();
    for (let x = 0; x < list.length; x++) {
        const key = getKeyFunc(list[x]);
        const collection = map.get(key);
        const value = getValueFunc ? getValueFunc(list[x], x) : list[x];
        if (!collection) {
            map.set(key, [value]);
        } else {
            collection.push(value);
        }
    }

    if (!asArray) return map;

    const array = [];
    for (let [key, value] of map) array.push({ key, value });

    return array;
};
