import gql from 'graphql-tag';
import CremationManagerAddressBook from './AddressBook';
import PlaqueProduct from './PlaqueProduct';

export default gql`
    fragment PlaqueProductOrder on PlaqueProductOrder {
        ID
        Niche
        NicheCoordinates
        Location {
            ...CremationManagerAddressBook
        }
        Product {
            ...PlaqueProduct
        }
    }
    ${CremationManagerAddressBook}
    ${PlaqueProduct}
`;
