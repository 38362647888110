import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import Table, { Cell, HeaderRow, Row } from '../../../components/table/Table';
import TickIcon from '../../../components/icon/TickIcon';
import { GST } from '../../../utils/bookable';
import { prettyPrice } from '../../../utils/strings';
import { calculateTotals, getPriceIncGst } from '../../Enquiry/Modals/Quotation/QuoteModal';
import { round } from '../../../utils/objects';

class QuoteModal extends Component {
    state = {
        tabIndex: 0
    };

    render() {
        const { open, onClose, quote } = this.props;
        const { tabIndex } = this.state;

        if (!quote) return null;

        const { quoteDetailsTab, previewTab } = this.getSteps(quote);

        return (
            <Modal
                open={open}
                variant="stepped"
                onClose={onClose}
                canClickOut={false}
                activeTab={tabIndex}
                scrollButtons="on"
                steps={[quoteDetailsTab, previewTab]}
            />
        );
    }

    getSteps(quote) {
        const quoteDetailsTab = {
            abbreviation: 'Quote #' + quote.ID,
            onChangeTab: this.onChangeTab,
            content: this.renderQuoteDetailsTab()
        };
        const previewTab = {
            abbreviation: 'View PDF',
            onChangeTab: this.onChangeTab,
            content: this.renderPreview()
        };
        return { quoteDetailsTab, previewTab };
    }

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    renderQuoteDetailsTab() {
        const { onClose, quote } = this.props;

        const quoteItems = quote.QuoteItems;
        const totals = calculateTotals(quote);

        return (
            <Grid container>
                <Fragment>
                    <Grid item>
                        {!!quoteItems.length ? (
                            <Table>
                                <HeaderRow pad>
                                    <Cell collSpan={1}>Quote item description</Cell>
                                    <Cell collSpan={1}>Qty</Cell>
                                    <Cell collSpan={1}>Price</Cell>
                                    <Cell collSpan={1}>Has GST</Cell>
                                    <Cell collSpan={1}>Sub total</Cell>
                                    <Cell collSpan={1}>GST</Cell>
                                    <Cell collSpan={1}>Total</Cell>
                                    <Cell collSpan={1}>Complimentary</Cell>
                                    <Cell collSpan={1}>Optional</Cell>
                                </HeaderRow>

                                {quoteItems.map((item, index) => this.renderQuoteItem(item, index))}

                                <Row pad className="total-row-desktop">
                                    <Cell colSpan={4} className="empty-cell" />
                                    <Cell className="total-cell">Total</Cell>
                                    <Cell>{prettyPrice(totals.gst)}</Cell>
                                    <Cell>{prettyPrice(totals.totalWithGst)}</Cell>
                                    <Cell colSpan={2} className="empty-cell" />
                                </Row>
                                {/*Mobile version of total*/}
                                <Row pad className="total-row-mobile">
                                    <Cell colSpan={1} dataLabel="Total">
                                        {prettyPrice(totals.gst)}
                                    </Cell>
                                    <Cell colSpan={1} dataLabel="Total">
                                        {prettyPrice(totals.totalWithGst)}
                                    </Cell>
                                </Row>
                                {/*End Mobile version of total*/}
                            </Table>
                        ) : (
                            <p className="no-actions">There are no quote items added yet.</p>
                        )}
                    </Grid>
                </Fragment>

                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <div>
                            <Inline>
                                <Button variant="secondary" onClick={() => onClose()}>
                                    Close
                                </Button>
                            </Inline>
                        </div>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderQuoteItem(item, index) {
        const { quote } = this.props;
        const gstPrices = quote.QuoteItems.map(x => getPriceIncGst(x));

        let qty = parseInt(item.Qty);
        qty = !isNaN(qty) ? qty : 0;

        const priceInclGST = round(gstPrices[index], 2);

        const totalWithGST = round(priceInclGST * qty);
        const gstValue = item.HasGST ? totalWithGST / ((GST + 1) * 10) : 0;
        const subtotal = item.HasGST ? round(totalWithGST - gstValue) : totalWithGST;

        return (
            <Row pad key={index}>
                <Cell dataLabel="Description">{quote.QuoteItems[index].Title}</Cell>
                <Cell dataLabel="Qty">{quote.QuoteItems[index].Qty}</Cell>
                <Cell dataLabel="Price">{priceInclGST || '0'}</Cell>
                <Cell dataLabel="Has GST">{quote.QuoteItems[index].HasGST ? <TickIcon /> : 'N/A'}</Cell>
                <Cell dataLabel="Sub total">{prettyPrice(subtotal)}</Cell>
                <Cell dataLabel="GST">{prettyPrice(gstValue)}</Cell>
                <Cell dataLabel="Total">{prettyPrice(totalWithGST)}</Cell>
                <Cell dataLabel="Complimentary">{quote.QuoteItems[index].Complimentary ? <TickIcon /> : 'N/A'}</Cell>
                <Cell dataLabel="Optional">{quote.QuoteItems[index].Optional ? <TickIcon /> : 'N/A'}</Cell>
            </Row>
        );
    }

    renderPreview() {
        const { onClose } = this.props;
        return (
            <Grid container>
                <Grid item xs={12} sm={12}>
                    <Grid container>
                        <Grid item>{this.renderPdfView()}</Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <div>
                            <Inline>
                                <Button variant="secondary" onClick={() => onClose()}>
                                    Close
                                </Button>
                            </Inline>
                        </div>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderPdfView() {
        return (
            <iframe
                src="https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
                style={{ width: '100%', height: 510 }}
                title="quote-pdf"
            />
        );
    }
}

export default withStyles({})(QuoteModal);
