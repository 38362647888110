import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { isNullOrUndefined } from '../../utils/objects';
import burgerSvg from '../../assets/burger.svg';
import fmLogoSvg from '../../assets/logo.svg';
import GatedComponent from '../GatedComponent';
import DrawerListItem from './DrawerListItem';

class DrawerList extends Component {
    state = {
        activeItem: undefined
    };

    static getDerivedStateFromProps(props, state) {
        const activeItem = getMatchingItem(props.location.pathname, props.itemsList);
        if (activeItem !== state.activeItem) {
            return ({ activeItem });
        }
        return null;
    }

    render() {
        const { items, hideOnCollapse, minimized, footer } = this.props;
        const renderContent = !minimized || !hideOnCollapse;
        const listClass = `drawer-list${footer ? '--with-footer' : ''}`;
        return (
            <Fragment>
                <List component="nav" className={listClass}>
                    {this.renderTitle()}
                    {renderContent && items.map(this.renderItem)}
                </List>
                {renderContent && footer && <div className="drawer-list-footer">{footer()}</div>}
            </Fragment>
        );
    }

    renderTitle() {
        const { minimized, onClickHamburger, showTitleIcon, hamburgerAnchor, hamburgerIcon, drawerTitle } = this.props;

        const menuIcon = (
            <IconButton onClick={onClickHamburger} className="burger-button" title={minimized ? 'Show' : 'Hide'}>
                {hamburgerIcon ? hamburgerIcon : <img src={burgerSvg} alt="toggle menu" className="burger" />}
            </IconButton>
        );

        let before;
        let after;

        if (hamburgerAnchor === 'left') before = menuIcon;
        else after = menuIcon;

        return (
            <div className="title">
                {before}
                {!minimized && (
                    <Fragment>
                        {showTitleIcon && <img src={fmLogoSvg} alt="logo" className="title-icon" />}
                        <Typography variant="subtitle1" className="title-text">
                            {drawerTitle}
                        </Typography>
                    </Fragment>
                )}
                {after}
            </div>
        );
    }

    renderItem = item => {
        const { minimized, renderItem, renderGroup } = this.props;
        const { activeItem } = this.state;

        return (
            <GatedComponent key={item.id || item.label} canViewCode={item.permission}>
                {() => {
                    return (
                        <DrawerListItem
                            key={item.id || item.label}
                            item={item}
                            minimized={minimized}
                            activeItem={activeItem}
                            renderItem={renderItem}
                            renderGroup={renderGroup}
                        />
                    );
                }}
            </GatedComponent>
        );
    };
}

const getMatchingItem = (path, itemsList) => {
    for (let x = 0; x < itemsList.length; x++) {
        const item = itemsList[x];
        if (item.path === path) return item;
        if (isNullOrUndefined(item.items)) {
            if (path.startsWith(item.path)) return item;
        } else if (path.startsWith(item.path)) {
            const activeSubItem = item.items.find(subItem => path.startsWith(subItem.path));
            //we return the sub item. OR we return the parent item, if this is a breadcrumb
            if (activeSubItem) return activeSubItem;
            if (path.startsWith(item.path) && item.path !== '/') return item;
        }
    }

    return null;
};

export default withRouter(withStyles({})(DrawerList));
