import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import TextField from '../../../components/form/TextField';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import ImageThumbnail from '../../../components/cards/ImageThumbnail';
import GatedComponent from '../../../components/GatedComponent';
import PlaquePhoto from '../../../fragments/PlaquePhoto';

class ViewImageModal extends Component {
    state = {
        item: null,
        Caption: null,
        Comment: null
    };

    static getDerivedStateFromProps({ photo }, { item }) {
        if (photo && (!item || photo.ID !== item.ID)) {
            return {
                item: { ...photo },
                Caption: photo.Caption,
                Comment: photo.Comment
            };
        }

        return null;
    }

    render() {
        const { open, onClose } = this.props;
        const { item, Caption, Comment } = this.state;

        if (!item) return null;

        const { mutation, variables } = this.getRetirementMutation();

        return (
            <Modal open={open} variant="simple" onClose={() => onClose()}>
                <Grid container>
                    <Grid item>
                        <h4>Image Details</h4>
                    </Grid>

                    <Grid item>
                        <ImageThumbnail image={item.File.AbsoluteLink} productTitle={item.File.Name} />
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Caption"
                            value={Caption}
                            onChange={e => this.setState({ Caption: e.target.value })}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Comments"
                            multiline
                            value={Comment}
                            onChange={e => this.setState({ Comment: e.target.value })}
                        />
                    </Grid>

                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings}>
                            <div />
                            <GatedComponent>
                                {() => {
                                    return (
                                        <Mutation
                                            mutation={mutation}
                                            onCompleted={this.onSaveCompleted}
                                            onError={e => this.handleMutateError(e)}
                                        >
                                            {(mutate, { loading }) => {
                                                const disabled = loading;
                                                return (
                                                    <Button
                                                        variant="confirmation"
                                                        onClick={e =>
                                                            this.onUpdateButtonClicked(
                                                                mutate,
                                                                !disabled && variables,
                                                                e
                                                            )
                                                        }
                                                    >
                                                        Update Image Details
                                                    </Button>
                                                );
                                            }}
                                        </Mutation>
                                    );
                                }}
                            </GatedComponent>
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    onUpdateButtonClicked = (mutate, variables, e) => {
        e.preventDefault();

        const { onSave } = this.props;

        if (variables)
            mutate({ variables }).then(
                response => {
                    onSave(response.data.updatePlaquePhoto);
                },
                e => {
                    console.error('gql error', e);
                }
            );
    };

    getRetirementMutation() {
        const { item, Caption, Comment } = this.state;

        return {
            mutation: gql`
                mutation UpdatePlaquePhoto($input: UpdatePlaquePhotoInput!) {
                    updatePlaquePhoto(input: $input) {
                        ...PlaquePhoto
                    }
                }
                ${PlaquePhoto}
            `,
            variables: {
                input: {
                    Caption: Caption,
                    Comment: Comment,
                    ID: item.ID
                }
            }
        };
    }
}

export default withStyles({})(ViewImageModal);
