import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

function getUniqueResults(edges) {
	if (!edges) return [];
	return Object.values(
		edges.reduce((map, edge) => {
			map[edge.node.LegacyKey] = edge.node;
			return map;
		}, {})
	);
}

export default ({ term, children, limit, offset, skip, brand, sortBy }) => {
	const variables = {
		limit: limit || 10,
		offset: offset || 0,
		brand: brand || '',
		contains: null,
		key: null
	};
	variables.sortBy = sortBy || [];
	let prearrangements = false;
	if (brand && brand === 'prearrangements') {
		prearrangements = true;
		brand = null;
		variables.brand = null;
	} else if (brand && brand === 'recent') {
		variables.brand = '';
		variables.sortBy = [{ field: 'LastEdited', direction: 'DESC' }];
	}

	term = term ? term.trim() : '';
	// If the term is non-numeric then we do a `contains` search
	// otherwise we search for a `key` match
	if (term.length) {
		if (isNaN(parseInt(term, 10))) variables.contains = term;
		else variables.key = term;
		variables.solr = true;
		variables.sortBy = [];
	} else {
		variables.solr = false;
	}

	return (
		<Query
			query={prearrangements ? prearrangementsQuery : funeralsQuery}
			variables={variables}
			skip={skip || !(term || (brand || prearrangements))}
			context={{ debounceKey: 1 }}
			notifyOnNetworkStatusChange
		>
			{({ data, fetchMore, networkStatus, error }) => {
				// console.log(networkStatus);
				// if (data) {
				const { searchFunerals, searchPrearrangements } = data || {};
				const functionName =
					searchFunerals || searchPrearrangements || {};
				const prearrangements = searchPrearrangements === true;
				const { key, contains, edges, pageInfo, solr } =
					functionName || {};
				const { hasNextPage } = pageInfo || false;
				const resultsTerm = key || contains;
				const results = getUniqueResults(edges);

				const onLoadMore = () => {
					if (hasNextPage) {
						fetchMore({
							variables: {
								offset: results.length
							},
							updateQuery: (
								previousResult,
								{ fetchMoreResult }
							) => {
								if (!fetchMoreResult) return previousResult;

								if (searchFunerals) {
									return {
										...previousResult,
										searchFunerals: {
											...fetchMoreResult.searchFunerals,
											edges: [
												...previousResult.searchFunerals
													.edges,
												...fetchMoreResult
													.searchFunerals.edges
											]
										}
									};
								} else {
									return {
										...previousResult,
										searchPrearrangements: {
											...fetchMoreResult.searchPrearrangements,
											edges: [
												...previousResult
													.searchPrearrangements
													.edges,
												...fetchMoreResult
													.searchPrearrangements.edges
											]
										}
									};
								}
							}
						});
					}
				};

				return children(
					term,
					resultsTerm,
					results,
					brand,
					onLoadMore,
					networkStatus,
					solr,
					error,
					prearrangements
				);
			}}
		</Query>
	);
};

const funeralsQuery = gql`
	query SearchFunerals(
		$key: String
		$contains: String
		$limit: Int
		$offset: Int
		$brand: String
		$solr: Boolean
		$sortBy: [SortField]
	) {
		searchFunerals(
			key: $key
			contains: $contains
			limit: $limit
			offset: $offset
			brand: $brand
			sortBy: $sortBy
			solr: $solr
		) {
			key
			contains
			solr
			edges {
				node {
					ID
					LegacyKey
					FirstName
					Surname
					DateOfBirth
					DateOfService
					FuneralDataStatus
					ExportDate
					ExportStatus
				}
			}
			pageInfo {
				hasNextPage
				totalCount
			}
		}
	}
`;

const prearrangementsQuery = gql`
	query SearchPrearrangements(
		$key: String
		$contains: String
		$limit: Int
		$offset: Int
		$brand: String 
#       $sortBy: [SortField]
	) {
		searchPrearrangements(
			key: $key
			contains: $contains
			limit: $limit
			offset: $offset
			brand: $brand
		) {
			key
			contains
			solr
			edges {
				node {
					ID
					LegacyKey
					FirstName
					Surname
					DateOfBirth
					#                    DateOfService
					#                    FuneralDataStatus
					#                    ExportDate
					#                    ExportStatus
				}
			}
			pageInfo {
				hasNextPage
				totalCount
			}
		}
	}
`;
