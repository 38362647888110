import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import { stringIsNullOrEmpty } from '../../utils/strings';

const TooltipWrapper = ({ children, title, variant, ...props }) => {
    let variantClass = !stringIsNullOrEmpty(variant) ? `popper--${variant}` : '';

    return (
        <Tooltip
            className="tooltip"
            title={title}
            {...props}
            classes={{
                popper: `popper ${variantClass}`
            }}
        >
            {children}
        </Tooltip>
    );
};

export default withStyles({})(TooltipWrapper);
