import gql from 'graphql-tag';

export default gql`
    fragment ReflectionRoom on ReflectionRoom {
        ID
        Confirmed
        Start
        End
        Date
        Time
        Duration
        NumberOfPeople
        AuthorisedContact
        Applicant
        AttendeeNames
    }
`;
