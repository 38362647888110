import gql from "graphql-tag";
import Address from "./Address";

export default gql`
	fragment DispatchDate on CremationOrderDispatchDate {
		ID
		Date
		Time
		ContainmentStyle
		Ashes
		DispatchMethod
		AshesCollectedBy
		Comment
		DispatchComplete
		DestinationAddress {
			...Address
		}
	}
	${Address}
`;
