import React, { Component } from 'react';
import moment from 'moment';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import MetricsAshes from './MetricsAshes';
import '../../components/metrics/css/metrics.scss';
import NotFound from '../NotFound';
import { getUser } from '../../utils/sessions';
import GatedComponent from '../../components/GatedComponent';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import { dateTimeToString } from '../../utils/date';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Select from '../../components/form/Select';
import ViewHeader from '../../components/header/ViewHeader';
import Grid from '../../components/layout/Grid';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

class Metrics extends Component {
    state = {};

    render() {
        const { history } = this.props;
        const currentUser = getUser();
        const requestedMetrics = new URLSearchParams(window.location.search);

        const period = (requestedMetrics && requestedMetrics.get('period')) || 'year';
        let periodStart = moment().startOf(period);
        if (period === 'year') {
            if (moment().quarter() > 2) {
                periodStart = moment(periodStart).add(6, 'months');
            } else {
                periodStart = moment(periodStart).subtract(6, 'months');
            }
        }
        let periodUntil = moment(periodStart)
            .add(1, period)
            .subtract(1, 'second');
        const periodData = {
            team: null,
            type: period,
            start: dateTimeToString(periodStart),
            until: dateTimeToString(periodUntil),
            userID: currentUser.ID
        };

        return (
            <GatedComponent canEditCode="FM_ACCESS_Metrics">
                {(isEnabled, data) => {
                    if (!isEnabled) return <NotFound />;
                    const viewOptions = [];
                    const { readCurrentUser } = data;
                    const { AllPermissions } = readCurrentUser || null;
                    if (AllPermissions.indexOf('ADMIN') < 0) {
                        const { Groups } = readCurrentUser || [];
                        // TODO: proper permissions
                        if (Groups.find(e => e.Code === 'cm-users'))
                            viewOptions.push({ label: 'Ash Collection Metrics', value: 'ashes' });
                    } else {
                        periodData.userID = (requestedMetrics && requestedMetrics.get('user')) || currentUser.ID;
                        viewOptions.push({ label: 'Ash Collection Metrics', value: 'ashes' });
                    }

                    const viewSelected = (requestedMetrics && requestedMetrics.get('view')) || 'ashes';
                    const view =
                        (viewOptions.length && (viewOptions.length === 1 ? viewOptions[0].value : viewSelected)) ||
                        null;
                    periodData.team = view;

                    if (!(view === 'ashes')) return <NotFound />;

                    return (
                        <div className="metrics-page">
                            <Grid container>
                                <Grid item>
                                    <Inline className="metrics" alignment={inlineAlignment.rightAlignSiblings} center>
                                        <ViewHeader
                                            title="Performance Metrics"
                                            subtitle={(view === 'ashes' && 'Ash Collections') || ''}
                                        />
                                        {!!view && (
                                            <Inline>
                                                {AllPermissions.indexOf('ADMIN') >= 0 && (
                                                    <span style={{ minWidth: 200, width: '100%' }}>
                                                        <StaffAutoComplete
                                                            value={periodData.userID}
                                                            label="Person:"
                                                            selectProps={{ multiple: false }}
                                                            clearOnSelect={false}
                                                            onSelect={(_, result) => {
                                                                requestedMetrics.set('user', result.ID);
                                                                history.push('?' + requestedMetrics.toString());
                                                            }}
                                                        />
                                                    </span>
                                                )}
                                                {viewOptions.length > 1 && (
                                                    <Select
                                                        label="View:"
                                                        allowNone={false}
                                                        options={viewOptions}
                                                        value={view}
                                                        onChange={e => {
                                                            requestedMetrics.set('view', e.target.value);
                                                            history.push('?' + requestedMetrics.toString());
                                                        }}
                                                    />
                                                )}
                                                <Select
                                                    label="Period:"
                                                    allowNone={false}
                                                    options={[
                                                        { label: 'Financial Year', value: 'year' },
                                                        { label: 'Quarterly', value: 'quarter' },
                                                        { label: 'Monthly', value: 'month' }
                                                    ]}
                                                    value={period}
                                                    onChange={e => {
                                                        requestedMetrics.set('period', e.target.value);
                                                        history.push('?' + requestedMetrics.toString());
                                                    }}
                                                />
                                            </Inline>
                                        )}
                                    </Inline>
                                </Grid>
                            </Grid>
                            {(view === 'ashes' && <MetricsAshes period={periodData} />) || <NotFound />}
                        </div>
                    );
                }}
            </GatedComponent>
        );
    }
}

const styles = ({ spacing, typography, palette }) => ({
    paper: {
        padding: spacing.unit * 3
    },
    pageTitle: {
        color: palette.text.primary
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    },
    tableData: {
        marginTop: '1rem'
    }
});

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withStyles(styles))(Metrics), { fallback: ErrorFallback });
