import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import TextField from '../../../components/form/TextField';
import Checkbox from '../../../components/form/Checkbox';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import { CloseIcon, TickCircleIcon } from '../../../components/IconIndex';

class SpecialInstructionModal extends Component {
    state = {
        ID: null,
        Title: '',
        Comment: '',
        Confirmed: false
    };

    clearState() {
        this.setState({
            ID: null,
            Title: '',
            Comment: '',
            Confirmed: false
        });
    }

    componentWillReceiveProps(nextProperties) {
        const specialInstructionProperties = nextProperties.specialInstruction;

        if (specialInstructionProperties) {
            this.setState(specialInstructionProperties);
        }
    }

    onCancel() {
        const { onCancel } = this.props;

        if (onCancel) {
            onCancel();
        }

        this.clearState();
    }

    onClose() {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }

        this.clearState();
    }

    onSubmit() {
        const { onSubmit } = this.props;

        if (onSubmit) {
            onSubmit(this.state);
        }

        this.clearState();
    }

    onTextFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onCheckboxFieldChange = e => {
        this.setState({ [e.target.name]: e.target.checked });
    };

    render() {
        const { open, onClose } = this.props;

        return (
            <Modal open={open} variant="simple" onClose={onClose} canClickOut actions={this.renderActions()}>
                <Grid container>
                    <Grid item>
                        <h4>Enter Special Instruction Details</h4>
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Action"
                            name={`Title`}
                            value={this.state['Title'] || ''}
                            onChange={this.onTextFieldChange}
                            required
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Additional Comments"
                            name={`Comment`}
                            value={this.state['Comment'] || ''}
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item>
                        <Checkbox
                            label="Confirmed"
                            name={`Confirmed`}
                            checked={this.state['Confirmed'] || ''}
                            onChange={this.onCheckboxFieldChange}
                        />
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderActions = () => {
        const disabledSaveButton = !this.state['Title'];
        return <Inline alignment={inlineAlignment.right}>
            <Button onClick={() => this.onCancel()} variant="secondary" startIcon={<CloseIcon />}>
                Cancel
            </Button>
            <Button
                onClick={() => this.onSubmit()}
                disabled={disabledSaveButton}
                startIcon={<TickCircleIcon />}
            >
                Complete
            </Button>
        </Inline>;
    };
}

export default withStyles({})(SpecialInstructionModal);
