import gql from 'graphql-tag';

export default gql`
    fragment Funeral on Funeral {
        ID
        Created
        LegacyKey
        FirstName
        MiddleName
        Surname
        Gender
        DateOfBirth
        UnknownDOB
        ResidentialAddress1
        ResidentialAddress2
        ResidentialTown
        ResidentialState
        PostCode

        DateOfDeath1
        DateOfDeath2
        DateOfDeathType

        PlaceOfDeathType
        PlaceOfDeath {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
        }
        PlaceOfDeathResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
            Country
        }
        UseResidentialAddress

        Informant {
            ID
            FirstName
            MiddleName
            Surname
            Email
            Phone
            Mobile
            SuburbCityAust
            ResidentialState
            ResidentialAddress1
            ResidentialPostcode
            Country
        }
    }
`;
