import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import moment from 'moment';
import cloneDeep from 'lodash.clonedeep';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import TextField from '../../../components/form/TextField';
import Label from '../../../components/form/Label';
import Checkbox from '../../../components/form/Checkbox';
import Button from '../../../components/form/Button';
import FlexGrid from '../../../components/layout/FlexGrid';
import Spinner from '../../../components/loading/Spinner';
import Table, { Cell, Row } from '../../../components/table/Table';
import NotesReadOnly from '../../../components/form/NotesReadOnly';
import createForm from '../../../utils/createForm';
import { diff, isNullOrUndefined, round } from '../../../utils/objects';
import BillTo from '../Billing/BillTo';
import { createInvoiceFunc, submitToXeroFunc, updateInvoiceFunc } from '../Billing/GetSaveInvoice';
import { joinDefined, prettyPrice } from '../../../utils/strings';
import { GST } from '../../../utils/bookable';
import { niceTimeFromString } from '../../../utils/date';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import { BackIcon, CloseIcon, NextIcon, SaveIcon, TickIcon, UsdCircleIcon } from '../../../components/IconIndex';

class InvoiceModal extends Component {
    state = {
        tabIndex: 0,
        invoiceIndex: null,
        editingItemIndex: null,
        loading: false,
        form: null,
        gstPrices: [],
        notes: null
    };

    static getDerivedStateFromProps({ open, invoiceIndex, form, quotes }, state) {
        const invoice = form.getField(`Invoices[${invoiceIndex}]`);
        const legacyKey = invoice && invoice.LegacyKey;
        const xeroID = invoice && invoice.XeroID;
        const newState = { open };

        if (state.open !== open && open) {
            newState.tabIndex = 0;
            newState.cart = [];

            if (!isNullOrUndefined(invoice)) {
                let clonedInvoice = cloneDeep(invoice);

                if (!!clonedInvoice.Customer && !clonedInvoice.Customer.BillingSource)
                    clonedInvoice.Customer.BillingSource = !!clonedInvoice.Customer.RelationToDeceased
                        ? 'AuthorisedContact'
                        : 'Other';
                const oldContext = state.form !== null ? state.form.context : null;
                newState.form = createForm(oldContext, clonedInvoice);
                newState.invoiceIndex = invoiceIndex;
                newState.gstPrices = clonedInvoice.InvoiceItems.map(x => getPriceIncGst(x));
                newState.notes = clonedInvoice.Notes;
                delete clonedInvoice.Notes;

                const toggledQuotes = {};
                if (quotes && quotes.length && !clonedInvoice.InvoiceItems.length) {
                    quotes.forEach(e => {
                        setInvoiceFromQuotes({ value: e.quoteID, checked: true }, { quotes, invoice });
                        toggledQuotes['toggle' + e.quoteID] = true;
                    });
                }
                newState.toggledQuotes = toggledQuotes;
            } else {
                newState.form = null;
                newState.invoiceIndex = null;
            }

            // newState.defaultBillTo = defaultBillTo;
            newState.canEdit = !legacyKey && !xeroID;
            newState.hasExportedToXero = !!xeroID;
            newState.existing = invoiceIndex !== null;
        }

        return newState;
    }

    componentDidUpdate(_, oldState) {
        const { loading, open, invoiceIndex, form } = this.state;
        if (open && invoiceIndex === null && !loading) {
            //no invoice! create one
            this.createInvoice();
            return;
        }

        if (oldState.form === null && form !== null) form.context = this;
    }

    render() {
        const { open } = this.props;
        const { tabIndex } = this.state;

        const { form } = this.state;
        if (!form) return null;

        const steps = [];

        // if not authorised to xero, allow editing details.
        if (!form.getField('ID') || ['NOT EXPORTED', 'DRAFT', 'SUBMITTED'].indexOf(form.getField('XeroStatus')) > -1) {
            steps.push({
                abbreviation: 'Billing Details',
                title: 'Step One:',
                subtitle: 'Please confirm the billing details',
                onChangeTab: this.onChangeTab,
                content: <div>{this.renderBillingDetails()}</div>,
                actions: this.renderBillingDetailsActions()
            });
            steps.push({
                abbreviation: 'Invoice Items',
                title: 'Step Two:',
                subtitle: 'Please review the invoice items',
                onChangeTab: this.onChangeTab,
                content: <div>{this.renderInvoiceDetails(true)}</div>,
                actions: this.renderInvoiceDetailsActions(true)
            });
        }

        // if invoice exported show pdf, otherwise show export
        if (
            !form.getField('XeroStatus') ||
            ['NOT EXPORTED'].indexOf(form.getField('XeroStatus')) === 0 ||
            this.invoiceIsModified() === true
        ) {
            steps.push({
                abbreviation: 'Submit',
                title: 'Step Three:',
                subtitle: 'Please submit the invoice',
                onChangeTab: this.onChangeTab,
                content: <div>{this.renderInvoiceDetails(false)}</div>,
                actions: this.renderInvoiceDetailsActions(false)
            });
        } else {
            steps.push({
                abbreviation: 'View PDF',
                title: 'Invoice:',
                subtitle: '#' + form.getField('ID'),
                onChangeTab: this.onChangeTab,
                content: <div>{this.renderPreview()}</div>,
                actions: this.renderPreviewActions()
            });
        }
        // if invoice saved, allow notes
        if (!!form.getField('ID')) {
            steps.push({
                abbreviation: 'Notes',
                title: 'Invoice:',
                subtitle: '#' + form.getField('ID'),
                onChangeTab: this.onChangeTab,
                content: this.renderNoteHistory(),
                actions: this.renderNoteHistoryActions()
            });
        }

        return (
            <Modal
                open={open}
                variant="stepped"
                onClose={this.closeModal}
                canClickOut={false}
                activeTab={tabIndex}
                steps={steps}
            />
        );
    }

    closeModal = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.setState({
            tabIndex: 0,
            invoiceIndex: null,
            editingItemIndex: null,
            loading: false,
            form: null,
            gstPrices: [],
            notes: null
        });
    };

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    onSubmit = () => {
        this.updateInvoice();
    };

    onAddNote = () => {
        this.updateInvoice(true);
    };

    createInvoice() {
        const { type: orderType, form: parentForm } = this.props;
        if (parentForm.loading !== false) return;

        this.setState({ loading: true });
        const that = this;

        let invoiceObj = {
            IsPurchase: this.isPurchaseInvoice(),
            InvoiceItems: [{ Quantity: 0, Price: 0, HasGST: true }],
            InvoiceDate: moment().format('YYYY-MM-DD')
        };
        if (orderType === 'Plaque') {
            invoiceObj.PlaqueOrder = { ID: parentForm.getField('ID') };
        } else {
            const dos = moment(parentForm.getField('CremationDate'));
            const due = (!!dos && dos.isValid() && dos) || moment();

            invoiceObj.InvoiceDueDate = due.add(21, 'days').format('YYYY-MM-DD');
            invoiceObj.CremationOrder = { ID: parentForm.getField('ID') };
        }

        //add form details
        const form = createForm(that, invoiceObj);
        form.setField({ Customer: this.defaultCustomer() });

        const Invoices = parentForm.getField('Invoices');
        const invoiceIndex = Invoices.length;
        that.setState({ loading: false, invoiceIndex, form });
        return null;
    }

    invoiceIsModified(skipNotes = true) {
        const { invoiceIndex, form } = this.state;

        if (!form) return null;

        let invoice = form.state;
        const { form: parentForm } = this.props;
        const original = parentForm.getField(`Invoices[${invoiceIndex}]`);

        if (!original) return true;

        const trimmedOriginal = cloneDeep(original);
        const trimmedCurrent = cloneDeep(invoice);
        if (skipNotes) {
            delete trimmedOriginal.Notes;
            delete trimmedCurrent.Notes;
        }

        const test = diff(trimmedCurrent, trimmedOriginal, false);
        // onload we fudge the billing source, so ignore it too
        if (
            Object.keys(test).length === 1 &&
            test.Customer &&
            test.Customer.BillingSource === 'Other' &&
            original.Customer &&
            original.Customer.ID &&
            !original.Customer.BillingSource
        )
            delete test.Customer;

        return !!Object.keys(test).length;
    }

    updateInvoice(noteOnly = false) {
        const { invoiceIndex } = this.state;
        let invoice = this.state.form.state;
        const parentForm = this.props.form;

        this.setState({ loading: 'save' });
        const that = this;
        const original = parentForm.getField(`Invoices[${invoiceIndex}]`);

        if (!invoice.ID || invoice.ID === '0')
            return createInvoiceFunc(invoice).then(
                ({ data }) => {
                    const Invoices = parentForm.getField('Invoices');
                    Invoices.push(cloneDeep(data.createCMInvoice));
                    parentForm.setField({ Invoices }, true);

                    const createdInvoice = cloneDeep(data.createCMInvoice);
                    const invoiceIndex = Invoices.length - 1;

                    const notes = createdInvoice.Notes;
                    delete createdInvoice.Notes;
                    that.setState({
                        loading: false,
                        invoiceIndex,
                        form: createForm(that, createdInvoice),
                        editingItemIndex: null,
                        notes: notes
                    });
                },
                e => that.onGqlError('Failed to create invoice.', e)
            );

        if (!invoice.XeroID || !!noteOnly) {
            return updateInvoiceFunc(invoice, original).then(
                ({ data }) => {
                    const Invoices = parentForm.getField('Invoices');
                    let updatedInvoice = cloneDeep(data.updateCMInvoice);

                    const notes = updatedInvoice.Notes;
                    delete updatedInvoice.Notes;
                    Object.assign(Invoices[invoiceIndex], cloneDeep(data.updateCMInvoice));
                    parentForm.setField({ Invoices }, true);
                    that.setState({
                        loading: false,
                        form: createForm(that, updatedInvoice),
                        editingItemIndex: null,
                        notes: notes
                    });
                },
                e => that.onGqlError('Failed to update invoice.', e)
            );
        } else {
            // update case after exported to xero
            return updateInvoiceFunc(invoice, original).then(
                () => {
                    return submitToXeroFunc(invoice).then(
                        result => {
                            const Invoices = parentForm.getField('Invoices');
                            const updatedInvoice = cloneDeep(result.data.submitCMInvoiceToXero);
                            Object.assign(Invoices[invoiceIndex], cloneDeep(result.data.submitCMInvoiceToXero));
                            parentForm.setField({ Invoices }, true);
                            that.setState({
                                loading: false,
                                invoice: updatedInvoice,
                                hasExportedToXero: true,
                                canEdit: false,
                                editingItemIndex: null
                            });
                        },
                        e => that.onGqlError('Failed to submit invoice to xero.', e)
                    );
                },
                e => that.onGqlError('Failed to update invoice.', e)
            );
        }
    }

    submitToXero() {
        const parentForm = this.props.form;
        const { invoiceIndex, form } = this.state;

        this.setState({ loading: 'xero' });
        const that = this;
        const original = parentForm.getField(`Invoices[${invoiceIndex}]`);

        const writeFunc = form.getField('ID') ? updateInvoiceFunc : createInvoiceFunc;

        return writeFunc(form.state, original).then(
            ({ data }) => {
                const newInvoice = data.createCMInvoice || data.updateCMInvoice;
                if (!newInvoice) {
                    that.onGqlError('No result received.');
                    return null;
                }
                const editInvoice = cloneDeep(newInvoice);
                const Invoices = parentForm.getField('Invoices');
                if (data.createCMInvoice) {
                    Invoices[Invoices.length] = newInvoice;
                    that.setState({ invoiceIndex: Invoices.length - 1 });
                } else {
                    Invoices[invoiceIndex] = newInvoice;
                }
                parentForm.setField({ Invoices }, true);
                form.setState({ ...editInvoice });
                submitToXeroFunc(form.state).then(
                    result => {
                        if (result && result.data.submitCMInvoiceToXero) {
                            const Invoices = parentForm.getField('Invoices');
                            const updatedInvoice = cloneDeep(result.data.submitCMInvoiceToXero);
                            Object.assign(Invoices[invoiceIndex], updatedInvoice);
                            parentForm.setField({ Invoices }, true);
                            that.setState({
                                loading: false,
                                form: createForm(that, cloneDeep(updatedInvoice)),
                                hasExportedToXero: true,
                                canEdit: false
                            });
                        } else {
                            that.onGqlError('No result received.');
                        }
                    },
                    e => that.onGqlError('Failed to submit invoice to xero.', e)
                );
            },
            e => that.onGqlError('Failed to update invoice.', e)
        );
    }

    renderBillingDetails() {
        const { form } = this.state;
        return (
            <Grid container>
                <BillTo billingTargetForm={form} billingSourceForm={this.props.form} />
            </Grid>
        );
    }

    renderBillingDetailsActions = () => {
        return <Inline alignment={inlineAlignment.rightAlignSiblings} center>
            <div>
                <Button variant="secondary" onClick={this.closeModal} startIcon={<CloseIcon />}>
                    Cancel
                </Button>
            </div>
            {this.renderSaveButton()}
            <Button onClick={() => this.setState({ tabIndex: 1 })} endIcon={<NextIcon />}>
                Next
            </Button>
        </Inline>;
    };

    renderInvoiceDetails(editingItemsMode) {
        const { quotes } = this.props;
        const { form } = this.state;

        if (!form) return null;

        const invoice = form.state;
        const cremation = this.props.form.state;

        const quoteOptions =
            !!quotes &&
            quotes.map(e => {
                return { label: `Quote #${e.quoteID}`, value: '' + e.quoteID };
            });

        return (
            <Grid container>
                <Grid item>
                    <FlexGrid>
                        <div className="billing-details">
                            <h5>Address to:</h5>
                            {!!invoice.Customer && (
                                <Fragment>
                                    <p>
                                        {invoice.Customer.CustomerName
                                            ? invoice.Customer.CustomerName
                                            : joinDefined(
                                                [
                                                    invoice.Customer.FirstName,
                                                    invoice.Customer.MiddleName,
                                                    invoice.Customer.Surname
                                                ],
                                                ' '
                                            )}
                                    </p>
                                    {invoice.Customer.AddressLine1 && (
                                        <p>
                                            {joinDefined(
                                                [invoice.Customer.AddressLine1, invoice.Customer.AddressLine2],
                                                ' '
                                            )}
                                            <br />
                                            {joinDefined(
                                                [
                                                    invoice.Customer.Suburb,
                                                    invoice.Customer.State,
                                                    invoice.Customer.Postcode,
                                                    invoice.Customer.Country !== 'Australia'
                                                        ? invoice.Customer.Country
                                                        : ''
                                                ],
                                                ' '
                                            )}
                                        </p>
                                    )}
                                </Fragment>
                            )}
                        </div>
                        <div className="billing-details">
                            <Label>Invoice Date</Label>
                            <TextField type="date" name="InvoiceDate" form={form} disabled={!editingItemsMode} />
                        </div>
                        <div className="billing-details">
                            <Label>Due Date</Label>
                            <TextField type="date" name="InvoiceDueDate" form={form} disabled={!editingItemsMode} />
                        </div>
                        <div className="billing-details">
                            <Label>Invoice Number</Label>
                            <p>{form.getField('ID')}</p>
                        </div>
                        <div className="billing-details">
                            <Label>Reference</Label>
                            <p>
                                {!!cremation.Deceased
                                    ? joinDefined(
                                        [
                                            cremation.LegacyKey,
                                            joinDefined(
                                                [cremation.Deceased.FirstName, cremation.Deceased.Surname],
                                                ' '
                                            ),
                                            cremation.CremationNumber
                                        ],
                                        ' - '
                                    )
                                    : ''}
                            </p>
                        </div>
                    </FlexGrid>
                </Grid>

                {quoteOptions && !!quoteOptions.length && (
                    <Grid item>
                        <Inline center>
                            <div>
                                <h5>Source Line Items from: </h5>
                            </div>
                            {quoteOptions.map(quote => (
                                <Checkbox
                                    key={'quote' + quote.value}
                                    checked={!!this.state['toggle' + quote.value]}
                                    label={quote.label}
                                    value={'' + quote.value}
                                    onChange={e => this.onChooseQuote(e.target)}
                                />
                            ))}
                        </Inline>
                    </Grid>
                )}

                {this.renderLineItemsTable(editingItemsMode)}
            </Grid>
        );
    }

    renderInvoiceDetailsActions = (editingItemsMode = false) => {
        const { form, tabIndex, loading } = this.state;
        if (!form) return null;
        const invoice = form.state;
        const hasItems = invoice.InvoiceItems && invoice.InvoiceItems.filter(e => e.Quantity > 0).length > 0;


        return <Inline alignment={inlineAlignment.rightAlignSiblings} center>
            <Inline>
                <Button variant="secondary" onClick={this.closeModal} startIcon={<CloseIcon />}>
                    Cancel
                </Button>
                <Button onClick={() => this.setState({ tabIndex: tabIndex - 1 })} startIcon={<BackIcon />}>
                    Back
                </Button>
            </Inline>

            {(!!editingItemsMode && this.renderSaveButton()) || (
                <Button
                    variant="urgent"
                    disabled={!!loading || !hasItems}
                    onClick={() => this.submitToXero()}
                    startIcon={loading === 'xero' ? <Spinner size="xs" /> : <UsdCircleIcon />}
                >
                    {loading === 'xero' ? ' Submitting...' : ' Submit to Xero'}
                </Button>
            )}

            {!!invoice.ID && (
                <Button onClick={() => this.setState({ tabIndex: tabIndex + 1 })} endIcon={<NextIcon />}>
                    Next
                </Button>
            )}
        </Inline>;
    };

    onChooseQuote(target) {
        const { quotes } = this.props;
        const { form } = this.state;
        const invoice = form.state;

        setInvoiceFromQuotes(target, { quotes, invoice });
        const updateToggle = 'toggle' + target.value;

        this.setState({
            [updateToggle]: !!target.checked,
            gstPrices: invoice.InvoiceItems.map(x => getPriceIncGst(x))
        });
    }

    renderLineItemsTable(editingItemsMode) {
        const { form } = this.state;
        const invoice = form.state;
        const lineItems = invoice.InvoiceItems;
        const totals = calculateTotals(invoice);
        const hasItems = invoice.InvoiceItems && invoice.InvoiceItems.filter(e => e.Quantity > 0).length > 0;
        return (
            <Grid item>
                {hasItems || editingItemsMode ? (
                    <Table
                        columns={[
                            { label: 'Line item description' },
                            { label: 'Quantity' },
                            { label: 'Price' },
                            { label: 'Has GST' },
                            { label: 'SubTotal' },
                            { label: 'GST' },
                            { label: 'Total' }
                        ]}
                    >
                        {lineItems.map((item, index) => this.renderInvoiceItem(item, index, editingItemsMode))}

                        <Row pad className="total-row-desktop">
                            <Cell colSpan={3} className="empty-cell" />
                            <Cell colSpan={2} className="total-cell">
                                Invoice Total:
                            </Cell>
                            <Cell>
                                <strong>{prettyPrice(totals.gst)}</strong>
                            </Cell>
                            <Cell>
                                <strong>{prettyPrice(totals.totalWithGst)}</strong>
                            </Cell>
                        </Row>

                        {/*Mobile version of total*/}
                        <Row pad className="total-row-mobile">
                            <Cell colSpan={1} dataLabel="Invoice GST">
                                {prettyPrice(totals.gst)}
                            </Cell>
                            <Cell colSpan={1} dataLabel="Invoice Total">
                                {prettyPrice(totals.totalWithGst)}
                            </Cell>
                        </Row>
                        {/*End Mobile version of total*/}
                    </Table>
                ) : (
                    <p className="no-actions">There are no invoice items added yet.</p>
                )}
                {!!editingItemsMode && (
                    <Button variant="tertiary" onClick={() => this.onCreateNewInvoiceItem()}>
                        + Add New Item
                    </Button>
                )}
            </Grid>
        );
    }

    renderSaveButton() {
        const { loading } = this.state;
        return (
            <Button
                variant="confirmation"
                disabled={!!loading || !this.invoiceIsModified()}
                onClick={this.onSubmit}
                startIcon={loading === 'save' ? <Spinner size="xs" /> : <SaveIcon />}
            >
                {loading === 'save' ? ' Saving...' : this.invoiceIsModified() ? ' Save as draft' : ' Saved'}
            </Button>
        );
    }

    isPurchaseInvoice() {
        return false;
    }

    onCreateNewInvoiceItem() {
        const { form } = this.state;

        const invoice = form.state;
        const InvoiceItems = invoice.InvoiceItems;
        InvoiceItems.push({
            Price: '0.00',
            Quantity: 0,
            Title: '',
            HasGST: false
        });
        form.setField({ InvoiceItems });
        this.setState({
            gstPrices: InvoiceItems.map(x => getPriceIncGst(x)),
            editingItemIndex: InvoiceItems.length - 1
        });
    }

    renderInvoiceItem(item, index, canEdit) {
        const { form } = this.state;
        const { gstPrices } = this.state;

        let qty = parseInt(item.Quantity);
        qty = !isNaN(qty) ? qty : 0;

        if (!canEdit && !qty) return null;

        const priceInclGST = round(gstPrices[index], 2);

        const totalWithGST = round(priceInclGST * qty);
        const gstValue = item.HasGST ? totalWithGST / ((GST + 1) * 10) : 0;
        const subtotal = item.HasGST ? round(totalWithGST - gstValue) : totalWithGST;

        return (
            <Row pad key={index}>
                <Cell dataLabel="Description">
                    {canEdit ? (
                        <TextField type="text" required name={`InvoiceItems[${index}].Title`} form={form} />
                    ) : (
                        form.getField(`InvoiceItems[${index}].Title`)
                    )}
                </Cell>
                <Cell dataLabel="Qty">
                    {canEdit ? (
                        <TextField
                            type="number"
                            required
                            onChange={e => this.onChangeQuantity(index, e.target.value)}
                            name={`InvoiceItems[${index}].Quantity`}
                            form={form}
                            value={qty || '0'}
                        />
                    ) : (
                        form.getField(`InvoiceItems[${index}].Quantity`)
                    )}
                </Cell>
                <Cell dataLabel="Price">
                    {canEdit ? (
                        <TextField
                            type="number"
                            required
                            value={priceInclGST || '0'}
                            onChange={e => this.onChangeTotalPrice(index, e.target.value)}
                        />
                    ) : (
                        priceInclGST || '0'
                    )}
                </Cell>
                <Cell dataLabel="Has GST">
                    {canEdit ? (
                        <Checkbox
                            name={`InvoiceItems[${index}].HasGST`}
                            form={form}
                            onChange={e => this.onChangeHasGST(index, e.target.checked)}
                        />
                    ) : form.getField(`InvoiceItems[${index}].HasGST`) ? (
                        <TickIcon />
                    ) : (
                        'N/A'
                    )}
                </Cell>
                <Cell dataLabel="Sub total">{prettyPrice(subtotal)}</Cell>
                <Cell dataLabel="GST">{prettyPrice(gstValue)}</Cell>
                <Cell dataLabel="Total">{prettyPrice(totalWithGST)}</Cell>
            </Row>
        );
    }

    onChangeHasGST(lineItemIndex, checked) {
        const { form } = this.state;
        const invoice = form.state;

        const lineItem = invoice.InvoiceItems[lineItemIndex];
        const price = lineItem.Price;
        recalculatePrice(lineItem, checked ? price : price * (GST + 1));
    }

    onChangeTotalPrice(lineItemIndex, stringValue) {
        const { form } = this.state;
        const invoice = form.state;

        const value = typeof stringValue === 'number' ? stringValue : parseFloat(stringValue);
        if (isNaN(value)) return 0;

        const { gstPrices } = this.state;
        const lineItem = invoice.InvoiceItems[lineItemIndex];

        gstPrices[lineItemIndex] = value;
        recalculatePrice(lineItem, value);

        this.setState({ gstPrices });
    }

    onChangeQuantity(index, stringValue) {
        const { form } = this.state;
        const invoice = form.state;
        const value = typeof stringValue === 'number' ? stringValue : parseInt(stringValue, 10);
        if (isNaN(value) || value < 0) return 0;

        const lineItem = invoice.InvoiceItems[index];
        lineItem.Quantity = value;

        this.forceUpdate();
        this.setState({ gstPrices: invoice.InvoiceItems.map(x => getPriceIncGst(x)) });
    }

    renderPreview() {
        const { form } = this.state;

        if (!form) return null;

        const invoice = form.state;
        const createdBy = invoice.CreatedBy;
        let userName = '';
        if (createdBy && !!parseInt(createdBy.ID)) {
            userName = joinDefined(
                [joinDefined([createdBy.FirstName, createdBy.Surname], ' '), niceTimeFromString(invoice.Created)],
                ', '
            );
        }
        return (
            <Grid container>
                {this.renderPdfView()}
                <Grid item xs={12} sm={4}>
                    <Grid container>
                        <Grid item>
                            <h4>Invoice Details</h4>
                        </Grid>
                        <Grid item>
                            <TextField label="Invoice Status" name="XeroStatus" form={form} readOnly />
                        </Grid>
                        <Grid item>
                            <TextField label="Created Date" type="date" name="InvoiceDate" form={form} readOnly />
                        </Grid>
                        <Grid item>
                            <TextField label="Created By" value={userName} readOnly />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderPreviewActions() {
        const { tabIndex } = this.state;
        return <Inline alignment={inlineAlignment.rightAlignSiblings}>
            <div>
                <Button variant="secondary" onClick={this.closeModal} startIcon={<CloseIcon />}>
                    Close
                </Button>
            </div>
            <Button onClick={() => this.setState({ tabIndex: tabIndex + 1 })} endIcon={<NextIcon />}>
                Review Notes
            </Button>
        </Inline>;
    }

    renderPdfView() {
        const { form } = this.state;
        const pdfLink = form.getField('XeroID')
            ? false
            : 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf';
        if (pdfLink) return null;

        return (
            <Grid item xs={12} sm={8}>
                <Grid container>
                    <Grid item>
                        <h4>Your quote will appear as below:</h4>
                        <Query query={queryReadInvoicePDF} variables={{ ID: form.getField('ID') }}>
                            {({ data, error, loading }) => {
                                if (loading) {
                                    return (
                                        <Fragment>
                                            <Spinner /> ...fetching invoice from Xero
                                        </Fragment>
                                    );
                                }
                                let { readInvoicePDF } = data || {};

                                if (readInvoicePDF) {
                                    readInvoicePDF = 'data:application/pdf;base64,' + readInvoicePDF;
                                    return (
                                        <iframe
                                            src={readInvoicePDF}
                                            style={{ width: '100%', height: 510 }}
                                            title="invoice-pdf"
                                        />
                                    );
                                }

                                return 'PDF not found.';
                            }}
                        </Query>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderNoteHistory() {
        return (
            <Grid container>
                <Grid item xs={12} md={7}>
                    {this.renderLeftNotes()}
                </Grid>
                <Grid item xs={12} md={5}>
                    {this.renderRightNotes()}
                </Grid>
            </Grid>
        );
    }

    renderNoteHistoryActions() {
        const { tabIndex } = this.state;
        return <Inline>
            <Button variant="secondary" onClick={this.closeModal} startIcon={<CloseIcon />}>
                Close
            </Button>
            <Button onClick={() => this.setState({ tabIndex: tabIndex - 1 })} startIcon={<BackIcon />}>
                Back
            </Button>
        </Inline>;
    }

    renderLeftNotes() {
        const { form, loading } = this.state;
        if (!form) return null;
        const buttonDisable = !!loading || !form.getField('Notes');
        return (
            <Grid bucket>
                <Grid item>
                    <Label>Add a note</Label>
                    <TextField name="Notes" form={form} placeholder="Type a note here..." multiline rows={10} />
                </Grid>
                <Grid item>
                    <Button variant="confirmation" disabled={buttonDisable} onClick={this.onAddNote}
                            startIcon={!!loading ? <Spinner size="xs" /> : <SaveIcon />}>
                        Add Note
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderRightNotes() {
        const { notes } = this.state;

        return (
            <Grid bucket>
                <Grid item>
                    <h4>Notes History</h4>
                </Grid>
                <Grid item>
                    <NotesReadOnly name="NotesValue" value={notes} />
                </Grid>
            </Grid>
        );
    }

    onGqlError(action, error) {
        const { setSnackbarMessage } = this.props;
        setSnackbarMessage(action + (error ? ' - ' + error.message : ''));
        this.setState({ loading: false });
    }

    defaultCustomer() {
        return {
            AddressLine1: null,
            AddressLine2: null,
            Suburb: null,
            State: null,
            Postcode: null,
            BillingId: null,
            Country: null,
            Mobile: null,
            Phone: null,
            FirstName: null,
            MiddleName: null,
            Surname: null,
            CustomerName: null,
            BillingSource: null,
            RelationToDeceased: null,
            Email: null
        };
    }
}

const getPriceIncGst = invoiceLineItem => {
    const price = parseFloat(invoiceLineItem.Price);
    return invoiceLineItem.HasGST ? price * (GST + 1) : price;
};

const recalculatePrice = (invoiceLineItem, newGstPrice) => {
    invoiceLineItem.Price = invoiceLineItem.HasGST ? newGstPrice / (GST + 1) : newGstPrice;
};

export const calculateTotals = invoice => {
    let total = 0,
        gst = 0,
        totalWithGst = 0;

    for (let x = 0; x < invoice.InvoiceItems.length; x++) {
        const item = invoice.InvoiceItems[x];
        if (item.Quantity > 0) {
            const itemTotal = item.Price * item.Quantity;

            total += itemTotal;

            const itemTotalWithGst = item.HasGST ? round(itemTotal * (GST + 1), 2) : itemTotal;
            totalWithGst += itemTotalWithGst;

            gst += item.HasGST ? itemTotalWithGst / ((GST + 1) * 10) : 0;
        }
    }

    return { total, gst, totalWithGst };
};

const setInvoiceFromQuotes = ({ value, checked }, { quotes, invoice }) => {
    const newItems = { list: [] };
    const oldList = invoice.InvoiceItems.filter(e => !!e); // clone
    if (value !== false) {
        const quote = quotes.find(e => e.quoteID === value);
        if (quote) {
            //strip the quote items
            newItems.list = oldList.filter(e => e.QuoteID !== value);
            // add back in if wanted
            if (checked) newItems.list = [].concat(quote.items, newItems.list);
        }
    }
    invoice.InvoiceItems = newItems.list;
};

const queryReadInvoicePDF = gql`
    query($ID: ID!) {
        readInvoicePDF(ID: $ID)
    }
`;

export default compose(withSnackbarMessage)(InvoiceModal);
export const InvoiceModalExtendable = InvoiceModal; // for SupplierBillingModal
