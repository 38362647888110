import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/form/Button';
import AddressBookFragment from '../../../fragments/AddressBook';
import DataFormView from '../../../components/DataFormView';
import ContactDetail from '../ContactDetail';
import { CloseIcon } from '../../../components/IconIndex';

const contactTabs = [
    {
        id: 'Contact',
        label: 'Contact',
        component: withStyles({})(ContactDetail),
        fragment: AddressBookFragment,
        onLoad: data => {},
        formatSaveData: (saveData, state) => {}
    }
];

class Contact extends Component {
    state = {
        showContactModal: false
    };

    render() {
        const { showContactModal } = this.state;
        if (!showContactModal)
            return (
                <Button variant="secondary" size="tiny" onClick={() => this.setState({ showContactModal: true })}>
                    Details...
                </Button>
            );
        const { contact } = this.props;
        return (
            <Modal open={showContactModal} title="Contact Details">
                <DataFormView
                    tabs={contactTabs}
                    createNewFunc={() => ({
                        Name: null,
                        AddressLine1: null,
                        AddressLine2: null,
                        Contact: null,
                        Phone: null,
                        Email: null,
                        Fax: null
                    })}
                    objectType="CremationManagerAddressBook"
                    onUpdated={this.onCompleted}
                    itemId={contact.ID}
                    name="CremationManagerAddressBook"
                    context={this}
                    additionalActions={this.getAdditionalActions()}
                />
            </Modal>
        );
    }

    onCompleted = result => {
        this.setState({ showContactModal: false });

        const { onClose } = this.props;
        onClose(result);
    };

    getAdditionalActions() {
        const { showContactModal } = this.state;

        const action = {
            label: 'Close',
            variant: 'secondary',
            skipSave: true,
            startIcon: <CloseIcon />,
            onClick: () => {
                this.setState({ showContactModal: !showContactModal });
            }
        };

        return [action];
    }
}

export default withStyles({})(Contact);
