import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 138 138" {...props}>

        <path fill="currentColor"
            d="M91.1,39.5V96c0,10.9-8.8,19.7-19.7,19.7S51.8,106.9,51.8,96V34.6c0-6.8,5.5-12.3,12.3-12.3c6.8,0,12.3,5.5,12.3,12.3v51.6
	c0,2.7-2.2,4.9-4.9,4.9c-2.7,0-4.9-2.2-4.9-4.9V39.5h-7.4v46.7c0,6.8,5.5,12.3,12.3,12.3c6.8,0,12.3-5.5,12.3-12.3V34.6
	c0-10.9-8.8-19.7-19.7-19.7s-19.7,8.8-19.7,19.7V96c0,14.9,12.1,27,27,27s27-12.1,27-27V39.5H91.1z"/>


    </SvgIcon>
);
