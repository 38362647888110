import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import ColourBlock from '../../components/form/ColourBlock';

const colours = {
    primary: [
        {
            label: 'Denim',
            palette: ['#7d9cc4', '#a1bbdc', '#5a769b']
        },
        {
            label: 'Aqua',
            palette: ['#48c3d1', '#6dd6e2', '#F0FBFC', '#399ea5']
        },
        {
            label: 'Orange',
            palette: ['#f89e53', '#ffba81', '#FDF2EB', '#e87b28']
        },
        {
            label: 'Mustard',
            palette: ['#ded062', '#f6ec9d', '#aba152']
        }
    ],
    secondary: [
        {
            label: 'Green',
            palette: ['#26cc6f', '#67f7a6', '#E9FAF0', '#14a554']
        },
        {
            label: 'Stan',
            palette: ['#ffbd26', '#ffe788', '#FFFCF5', '#d59e20']
        },
        {
            label: 'City',
            palette: ['#e67d40', '#fbb891', '#bc581d']
        },
        {
            label: 'Red',
            palette: ['#e64040', '#f8adad', '#FDEBEB', '#b51a1a']
        },
        {
            label: 'Ladies',
            palette: ['#8b3983', '#da83d2', '#78126d']
        },
        {
            label: 'Tender',
            palette: ['#5C0D10', '#FFE6E7']
        }
    ],
    monotones: [
        {
            label: 'Black to White',
            palette: ['#000000', '#555555', '#777777', '#999999', '#cdcdcd', '#d5d5d5', '#eeeeee', '#ffffff']
        }
    ]
};

class ColourPalette extends Component {
    render() {
        return (
            <Fragment>
                {this.renderPrimaryColours()}
                {this.renderSecondaryColours()}
                {this.renderMonotones()}
            </Fragment>
        );
    }

    renderPrimaryColours() {
        return (
            <Fragment>
                <Grid item xs={12}>
                    <Separator />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Primary Colours</Typography>
                    <br />
                </Grid>
                <Grid item xs={12} container spacing={32}>
                    {colours.primary.map(colour => (
                        <Grid key={colour.label} item xs={12} sm={6} md={4}>
                            <ColourBlock colours={colour.palette} label={colour.label} />
                        </Grid>
                    ))}
                </Grid>
            </Fragment>
        );
    }

    renderSecondaryColours() {
        return (
            <Fragment>
                <Grid item xs={12}>
                    <Separator />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Secondary Colours</Typography>
                    <br />
                </Grid>
                <Grid item xs={12} container spacing={32}>
                    {colours.secondary.map(colour => (
                        <Grid key={colour.label} item xs={12} sm={6} md={4}>
                            <ColourBlock colours={colour.palette} label={colour.label} />
                        </Grid>
                    ))}
                </Grid>
            </Fragment>
        );
    }

    renderMonotones() {
        return (
            <Fragment>
                <Grid item xs={12}>
                    <Separator />
                    <br />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">Monotones</Typography>
                    <br />
                </Grid>
                <Grid item xs={12} container spacing={32}>
                    {colours.monotones.map(colour => (
                        <Grid key={colour.label} item xs={12} sm={6} md={4}>
                            <ColourBlock colours={colour.palette} label={colour.label} />
                        </Grid>
                    ))}
                </Grid>
            </Fragment>
        );
    }
}

export default withStyles({})(ColourPalette);
