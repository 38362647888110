import React from 'react';
import { withStyles } from '@material-ui/core/styles';

/*
Column Layout
Automatically stacks 1 to 12 children in a column style layout
When view size is less than LG, the view rotates to vertical

Note: 'pad' will add ${spacing-unit} to each nested child. Works well with Grid bucket=true children.
specify pad=false to remove this padding
*/
const ColumnLayout = withStyles({})(({ className, pad = true, children }) => {

	if (children.length <= 0 || children.length > 12)
		throw new Error("ColumnLayout expects 1 to 12 children");
	
	if (!children.map)
		children = [children];
	
	return (
		<div className={`column-layout ${className || ''}`}>
			{children.map((child, i) => (
				// eslint-disable-next-line react/no-array-index-key
				<div className={`child col--${children.length} ${pad ? 'pad' : ''}`} key={i}>
					{child}
				</div>
			))}
		</div>
	);
});

export default ColumnLayout;
