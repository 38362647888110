import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '../../components/form/Checkbox';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Button from '../../components/form/Button';
import SendProofSupplierModal from './Modals/SendProofSupplierModal';
import Inline from '../../components/layout/Inline';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import InvoiceModal, { calculateTotals } from '../Cremation/Modals/InvoiceModal';
import ContactCard from '../../components/contact/ContactCard';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import SupplierBillingModal from './Modals/SupplierBillingModal';
import { joinDefined, PAGE_ENQUIRIES, prettyPrice } from '../../utils/strings';
import { dateToString, niceTimeFromString } from '../../utils/date';
import { quoteColumns } from '../Enquiry/EnquiryDetail';
import QuoteModal from './Modals/QuoteModal';
import ContactLookup from '../../components/contact/ContactLookup';
import AssociatedOrders from '../../components/AssociatedOrders';
import TextField from '../../components/form/TextField';
import Select from '../../components/form/Select';
import NotesReadOnly from '../../components/form/NotesReadOnly';
import AlertBar from '../../components/form/AlertBar';

class BillingTab extends Component {
    state = {
        showSendProofSupplierModal: false,
        supplierBillingModal: {
            open: false,
            invoiceIndex: null
        },
        invoiceModal: {
            open: false,
            invoiceIndex: null
        },
        quoteModal: {
            open: false,
            quote: null
        }
    };

    render() {
        const { showSendProofSupplierModal, supplierBillingModal, invoiceModal, quoteModal } = this.state;
        const { form } = this.props;

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <SendProofSupplierModal
                    open={showSendProofSupplierModal}
                    plaqueOrderID={form.getField('ID')}
                    plaqueOrderSupplier={form.state.PlaqueSupplier}
                    onClose={() => this.handleCloseSendProofSupplierModal()}
                    parentForm={form}
                />
                <SupplierBillingModal
                    open={supplierBillingModal.open}
                    invoiceIndex={supplierBillingModal.invoiceIndex}
                    type="Plaque"
                    form={form}
                    onClose={() => this.handleCloseSupplierBillingModal()}
                />
                <InvoiceModal
                    open={invoiceModal.open}
                    quotes={this.collectQuotes()}
                    invoiceIndex={invoiceModal.invoiceIndex}
                    form={form}
                    type="Plaque"
                    onClose={() => this.setState({ invoiceModal: { open: false, invoiceIndex: null } })}
                />
                <QuoteModal
                    open={quoteModal.open}
                    onClose={() => this.handleCloseQuoteModal()}
                    quote={quoteModal.quote}
                />
            </Fragment>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderSupplierProofOrder()}
                {this.renderSupplierProofEmailsHistory()}
                {this.renderBillHistory()}
            </Fragment>
        );
    }

    renderRightColumn() {
        return (
            <Fragment>
                {this.renderKeyOverride()}
                {this.renderAssociatedQuotes()}
                {this.renderAssociatedInvoices()}
                {this.renderAssociatedOrders()}
            </Fragment>
        );
    }

    renderKeyOverride() {
        const { form } = this.props;
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Job Status</h4>
                    <Checkbox label="This job is cancelled" form={form} name="Cancelled" />
                </Grid>

                {form.getField('Cancelled') && (
                    <Fragment>
                        <Grid pc={0.5}>
                            <TextField
                                type="date"
                                label="Date Cancelled"
                                required
                                form={form}
                                name="CancelledDate"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Button
                                                variant="link add-new-button"
                                                onClick={() =>
                                                    form.setField({
                                                        CancelledDate: moment().format('YYYY-MM-DD')
                                                    })
                                                }
                                                title="Add Today's Date"
                                            >
                                                Add Today
                                            </Button>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid pc={0.5}>
                            <Select
                                required
                                form={form}
                                name="CancelledReason"
                                label="Reason"
                                options={[
                                    { label: 'Accidental/unwanted record', value: 'invalid' },
                                    { label: 'Went with someone cheaper', value: 'competition' }
                                ]}
                            />
                        </Grid>
                    </Fragment>
                )}
                <Grid item>
                    <h4>Additional Notes </h4>
                </Grid>

                <Grid item>
                    <TextField placeholder="Notes are written here..." multiline name="AdditionalNotes" form={form} />
                </Grid>

                {form.getField('OldAdditionalNotes') && (
                    <Fragment>
                        <Grid item>
                            <h4>Note History</h4>
                        </Grid>
                        <Grid item>
                            <NotesReadOnly name="OldAdditionalNotes" form={form} />
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderSupplierProofOrder() {
        const { form } = this.props;
        const contact = form.getField('PlaqueSupplier');

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Order Supplier</h4>
                </Grid>

                {!contact && (
                    <Grid item>
                        <ContactLookup
                            name="PlaqueSupplier.ID"
                            onSelect={(_, addressBook) =>
                                this.handleSelectSupplierContact('PlaqueSupplier', addressBook)
                            }
                            onAddNewContact={addressBook =>
                                this.handleSelectSupplierContact('PlaqueSupplier', addressBook)
                            }
                        />
                    </Grid>
                )}

                {!!contact && (
                    <Grid item>
                        <div style={{ float: 'right', marginTop: -24 }}>
                            <Button variant="link-orange" onClick={() => form.setField({ PlaqueSupplier: null })}>
                                Change Supplier
                            </Button>
                        </div>

                        <ContactCard
                            variant="fullwidth" //Do no remove
                            contact={contact}
                        />
                    </Grid>
                )}

                {!!contact && !!contact.Email && (
                    <Grid item>
                        <Button
                            variant="primary"
                            disabled={!!form.isDirty}
                            onClick={() => this.handleShowSendProofSupplierModal()}
                        >
                            Send Proof To Supplier...
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderSupplierProofEmailsHistory() {
        const { form } = this.props;
        const supplier = form.getField('PlaqueSupplier');
        let proofEmails = form.getField('ProofEmails');

        proofEmails = proofEmails.filter(obj => obj.EmailSupplier);

        if (!proofEmails || !proofEmails.length) {
            return null;
        }

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Proof Emails History</h4>
                </Grid>

                <Grid item>
                    <Table>
                        <HeaderRow pad>
                            <Cell colSpan={1}>Date</Cell>
                            <Cell colSpan={1}>Time</Cell>
                            <Cell colSpan={1}>Sent To</Cell>
                            <Cell colSpan={1}>Approved</Cell>
                        </HeaderRow>
                        {proofEmails.map(proofEmail => (
                            <Row pad key={'email-' + proofEmail.ID}>
                                <Cell dataLabel="Date">{dateToString(proofEmail.EmailSent) || ' - '}</Cell>
                                <Cell dataLabel="Time">{niceTimeFromString(proofEmail.EmailSent) || ' - '}</Cell>
                                <Cell dataLabel="Sent to">{supplier.Email}</Cell>
                                <Cell dataLabel="Approved">
                                    <Checkbox label="" disabled checked={proofEmail.Approved} />
                                </Cell>
                            </Row>
                        ))}
                    </Table>
                </Grid>
            </Grid>
        );
    }

    renderBillHistory() {
        const { form } = this.props;
        const allInvoices = form.getState('Invoices') || [];
        const invoices = allInvoices.filter(invoice => !!invoice.IsPurchase);
        const supplier = form.getField('PlaqueSupplier');
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Bills</h4>
                </Grid>

                <Grid item>
                    {(invoices.length > 0 && (
                        <Table
                            columns={[
                                { label: 'Bill No.' },
                                { label: 'Supplier Name' },
                                { label: 'Due Date' },
                                { label: 'Status' },
                                { label: 'Amount' }
                            ]}
                        >
                            {invoices.map((invoice, index) => {
                                const totals = calculateTotals(invoice);
                                return (
                                    <Row pad key={'invoice' + invoice.ID}>
                                        <Cell dataLabel="Bill Number">
                                            <Button
                                                onClick={() => this.handleShowSupplierBillingModal(index)}
                                                variant="link-orange"
                                            >
                                                #{invoice.ID}
                                            </Button>
                                        </Cell>
                                        <Cell dataLabel="Date">
                                            {(invoice.Customer && invoice.Customer.CustomerName) || ' - '}
                                        </Cell>
                                        <Cell dataLabel="Date">{invoice.InvoiceDueDate || ' - '}</Cell>
                                        <Cell dataLabel="Status">{invoice.XeroStatus || ' - '}</Cell>
                                        <Cell dataLabel="Amount">{prettyPrice(totals.totalWithGst)}</Cell>
                                    </Row>
                                );
                            })}
                        </Table>
                    )) || <span>There are currently no bills.</span>}
                </Grid>
                <Grid item>
                    <Button
                        variant="primary"
                        disabled={!supplier}
                        onClick={() => this.handleShowSupplierBillingModal(null)}
                    >
                        Add New Bill...
                    </Button>
                    {!supplier && (
                        <AlertBar variant="info">New bills cannot be added without an Order Supplier.</AlertBar>
                    )}
                </Grid>
            </Grid>
        );
    }

    handleSelectSupplierContact(propertyName, addressBook) {
        const { form } = this.props;
        delete addressBook['__typename'];

        if (!!addressBook) {
            form.setState({ [propertyName]: addressBook });
        }
    }

    handleShowSendProofSupplierModal() {
        this.setState({ showSendProofSupplierModal: true });
    }

    handleCloseSendProofSupplierModal() {
        this.setState({ showSendProofSupplierModal: false });
    }

    handleShowSupplierBillingModal(index) {
        this.setState({
            supplierBillingModal: { open: true, invoiceIndex: index }
        });
    }

    handleCloseSupplierBillingModal() {
        this.setState({
            supplierBillingModal: { open: false, invoiceIndex: null }
        });
    }

    renderAssociatedInvoices() {
        const { form } = this.props;
        const allInvoices = form.getState('Invoices') || [];
        const invoices = allInvoices.filter(inv => !!inv.Customer && !!Number(inv.Customer.ID)); // hack to ignore bad invoices
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Invoices</h4>
                </Grid>
                <Grid item>
                    {(!!invoices.filter(e => !e.IsPurchase).length && (
                        <Table
                            columns={[
                                { label: 'Invoice No.' },
                                { label: 'Billing Name' },
                                { label: 'Status' },
                                { label: 'Due Date' },

                                { label: 'Amount' },
                                { label: 'Sent' }
                            ]}
                        >
                            {invoices.map((invoice, index) => this.renderInvoice(invoice, index))}
                        </Table>
                    )) || <span>There are currently no invoices.</span>}
                </Grid>
                <Grid item>
                    <Inline>
                        <Button variant="primary" onClick={() => this.handleShowInvoiceModal()}>
                            Add new invoice...
                        </Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderInvoice(invoice, index) {
        const totals = calculateTotals(invoice);

        if (invoice.IsPurchase) {
            return null;
        }

        return (
            <Row pad key={index}>
                <Cell dataLabel="Invoice No">
                    <Button
                        onClick={() =>
                            this.setState({
                                invoiceModal: { open: true, invoiceIndex: index }
                            })
                        }
                        variant="link-orange"
                    >
                        {'#' + invoice.ID}
                    </Button>
                </Cell>
                <Cell dataLabel="Billing Name">
                    {invoice.Customer.CustomerName
                        ? invoice.Customer.CustomerName
                        : joinDefined(
                              [invoice.Customer.FirstName, invoice.Customer.MiddleName, invoice.Customer.Surname],
                              ' '
                          )}
                </Cell>
                <Cell dataLabel="Status">{invoice.XeroStatus}</Cell>
                <Cell dataLabel="Due Date">{invoice.InvoiceDueDate}</Cell>
                <Cell dataLabel="Amount">{prettyPrice(totals.totalWithGst)}</Cell>
                <Cell dataLabel="Sent">
                    {invoice.XeroStatus === 'AUTHORISED' || invoice.XeroStatus === 'PAID'
                        ? invoice.InvoiceSent
                            ? 'YES'
                            : 'NO'
                        : ' - '}
                </Cell>
            </Row>
        );
    }

    renderAssociatedQuotes() {
        const { form } = this.props;
        const enquiry = form.getField('Enquiry');
        if (!enquiry) {
            return null;
        }
        const quotes = enquiry.Quotes;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Quotes</h4>
                </Grid>
                {!!quotes && quotes.length > 0 && (
                    <Grid item>
                        <Table>
                            <HeaderRow pad>
                                {quoteColumns.map(column => (
                                    <Cell key={column.label}>{column.label}</Cell>
                                ))}
                            </HeaderRow>
                            {quotes.map((row, index) => {
                                return (
                                    <Row pad key={row.ID}>
                                        {quoteColumns.map(column => {
                                            return (
                                                <Cell key={column.label} dataLabel={column.label}>
                                                    {column.renderCell(row, this, index)}
                                                </Cell>
                                            );
                                        })}
                                    </Row>
                                );
                            })}
                        </Table>
                    </Grid>
                )}

                <Grid item>
                    <Inline>
                        <Button target="_blank" variant="secondary" href={PAGE_ENQUIRIES + enquiry.ID}>
                            View Initial Enquiry
                        </Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    onClickViewQuote(index) {
        const { form } = this.props;
        const enquiry = form.getField('Enquiry');
        if (!enquiry) {
            return null;
        }
        const quotes = enquiry.Quotes;

        this.setState({
            quoteModal: { open: true, quote: quotes[index] }
        });
    }

    renderAssociatedOrders() {
        const { form } = this.props;
        return <AssociatedOrders parentType="PlaqueOrder" parentID={form.getField('ID')} />;
    }

    collectQuotes() {
        const { form } = this.props;
        const enquiry = form.getField('Enquiry') || { Quotes: [] };
        const quotes = enquiry.Quotes.filter(e => e.Accepted) || [];
        return quotes.map(quote => {
            const quoteItems = Object.assign([], quote.QuoteItems.edges || quote.QuoteItems);
            return {
                quoteID: quote.ID,
                quoteType: quote.QuoteType,
                items: quoteItems.map(z => {
                    const e = z.node ? z.node : z;
                    return {
                        ID: null,
                        Title: e.Title,
                        Quantity: e.Qty,
                        Price: e.Price,
                        HasGST: e.HasGST,
                        Prepaid: e.Prepaid || false,
                        QuoteID: quote.ID
                    };
                })
            };
        });
    }

    handleAddNotes() {
        const { form } = this.props;
        const addNotes = form.getState('AddNotes') || [];
        addNotes.map(index => this.renderAdditionalNotes(index));
    }

    handleShowInvoiceModal() {
        this.setState({
            invoiceModal: { open: true, invoiceIndex: null }
        });
    }

    handleCloseInvoiceModal() {
        this.setState({ invoiceModal: { open: false, invoiceIndex: null } });
    }

    handleCloseQuoteModal() {
        this.setState({
            quoteModal: {
                open: false,
                quote: null
            }
        });
    }
}

export default {
    id: 'Billing',
    label: 'Billing',
    component: withStyles({})(BillingTab),
    fragment: PlaqueOrder,
    onLoad: data => {
        // create a stub to detect new notes on save!
        if (!!data.AdditionalNotes) {
            data.OldAdditionalNotes = '' + (data.AdditionalNotes || '');
        }
        delete data.AdditionalNotes;
    },
    formatSaveData: (saveData, state) => {
        delete saveData.AssociatedOrders;
        const supplier = state.PlaqueSupplier;
        if (supplier && !!supplier.ID) {
            saveData.PlaqueSupplierID = supplier.ID;
            delete saveData.PlaqueSupplier;
        } else if (saveData.PlaqueSupplier === null) {
            saveData.PlaqueSupplierID = null;
            delete saveData.PlaqueSupplier;
        }
    }
};
