import React, { Fragment } from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Popover } from '@material-ui/core';
import FilterIcon from '../../components/icon/FilterIcon';
import Button from '../../components/form/Button';
import { appointmentTypesByView, CALENDAR_TYPES } from './CalendarConstants';
import Grid from '../../components/layout/Grid';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import { getUser } from '../../utils/sessions';
import { joinDefined } from '../../utils/strings';
import Checkbox from '../../components/form/Checkbox';

class CalendarFilters extends React.Component {
    state = {
        open: false,
        anchorEl: null,
        filter: {
            staff: [],
            types: [],
            groups: []
        }
    };

    render() {
        const { classes, view, filters } = this.props;
        const { anchorEl, open } = this.state;

        const hasNoFilters =
            (view !== 'day' || (filters.staff.every(e => !e) && Object.keys(filters.groups).length === 0)) &&
            filters.types.every(e => !e || !appointmentTypesByView({ type: e, view }));

        return (
            <div className="rbc-btn-group" style={{ position: 'relative', display: 'inline-block' }}>
                <Button
                    onClick={this.handleOpen}
                    variant={'filterBtn' + (!!hasNoFilters ? ' filterBtnOff' : '')}
                    size="lg"
                    style={{ whiteSpace: 'pre' }}
                >
                    <FilterIcon />
                    <Hidden smDown>Filters</Hidden>
                    {!hasNoFilters ? ' ON' : ''}
                    <Hidden smDown>...</Hidden>
                </Button>
                <Popover
                    anchorEl={anchorEl}
                    open={open}
                    onClose={this.handleClose}
                    anchorReference="anchorEl"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                    <div className={classes.paper}>
                        <Typography variant="headline" id="modal-title">
                            Filter
                        </Typography>
                        <div className={classes.paperScroll}>
                            {view === 'day' && this.renderGroupFilters()}
                            {view === 'day' && this.renderStaffFilters()}
                            {this.renderTypeFilters()}
                        </div>
                        <div className={cx(classes.buttonGroup, classes.actionButtons)}>
                            <Button onClick={this.handleClose} variant="modal-action modal-action--cancel">
                                CANCEL
                            </Button>{' '}
                            <Button onClick={this.handleApplyFilters} variant="modal-action modal-action--ok">
                                OK
                            </Button>
                        </div>
                        <div style={{ clear: 'both' }} />
                    </div>
                </Popover>
            </div>
        );
    }

    handleBulkSelectChange = name => {
        const filter = { ...this.state.filter };
        const { resources, groups } = this.props;

        if (name === 'staff') {
            filter.staff = resources.map(opt => opt.resourceId);
        } else if (name === 'groups') {
            filter.groups = groups.map(opt => opt.ID);
        } else {
            filter.types = CALENDAR_TYPES.map(opt => opt.type);
        }

        this.setState({ filter });
    };

    handleBulkUnselectChange = name => {
        const filter = { ...this.state.filter };
        filter[name] = [];
        this.setState({ filter });
    };

    onRemoveStaff = itemID => {
        const filter = { ...this.state.filter };
        const delIdx = filter.staff.indexOf(itemID);
        filter.staff.splice(delIdx, 1);
        this.setState({ filter });
    };

    handleStaffPick = event => {
        const filter = { ...this.state.filter };
        const { value } = event.target;
        filter.staff.push(value);
        this.setState({ filter });
    };

    handleGroupsChange = event => {
        const filter = { ...this.state.filter };
        const { value, checked } = event.target;
        if (checked) {
            filter.groups.push(value);
        } else {
            filter.groups.splice(filter.groups.indexOf(value), 1);
        }
        this.setState({ filter });
    };

    handleTypesChange = event => {
        const filter = { ...this.state.filter };
        const { value, checked } = event.target;
        if (checked) {
            filter.types.push(value);
        } else {
            filter.types.splice(filter.types.indexOf(value), 1);
        }
        this.setState({ filter });
    };

    handleApplyFilters = () => {
        const {
            filter: { staff, types, groups }
        } = this.state;
        this.props.setFilters({ staff, types, groups });
        this.setState({ open: false });
    };

    handleOpen = e => {
        const { filters } = this.props;
        let staff = [];
        let types = [];
        let groups = [];
        filters.staff.forEach(val => staff.push(val));
        filters.groups.forEach(val => groups.push(val));
        filters.types.forEach(val => types.push(val));

        const filter = { staff, types, groups };
        this.setState({ open: true, anchorEl: e.currentTarget, filter });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    renderGroupFilters() {
        const { classes, groups } = this.props;
        const { filter } = this.state;
        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="title" className={classes.titleGap}>
                            Show Team Staff
                        </Typography>

                        <div className={classes.buttonGroup}>
                            <Button variant="secondary" size="tiny"
                                    onClick={() => this.handleBulkSelectChange('groups')}>
                                Select All
                            </Button>
                            <Button variant="secondary" size="tiny"
                                    onClick={() => this.handleBulkUnselectChange('groups')}>
                                Unselect All
                            </Button>
                        </div>

                        <Grid container spacing={0}>
                            {groups.map((obj, idx) => (
                                <Grid key={idx} item xs={6}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={filter.groups.includes(obj.ID) || false}
                                                onChange={this.handleGroupsChange}
                                                value={obj.ID}
                                                color="primary"
                                            />
                                        }
                                        label={obj.Title}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderStaffFilters() {
        const { classes, resources } = this.props;
        const { filter } = this.state;
        const me = getUser();
        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <Typography variant="title" id="contact-filter-staff" className={classes.titleGap}>
                            Show Individual Staff
                        </Typography>

                        <StaffAutoComplete
                            preloadAll
                            defaultMe={false}
                            category="cm-witness"
                            onSelect={(picked, pepper) => {
                                this.handleStaffPick({ target: { value: pepper.ID } });
                            }}
                        />

                        <div className={classes.buttonGroup}>
                            <Button variant="secondary" size="tiny"
                                    onClick={() => this.handleBulkSelectChange('staff')}>
                                Select All
                            </Button>
                            <Button variant="secondary" size="tiny"
                                    onClick={() => this.handleBulkUnselectChange('staff')}>
                                Unselect All
                            </Button>
                        </div>

                        <Grid container spacing={0}>
                            {filter.staff.length > 0 &&
                                filter.staff.map((checkbox, idx) => {
                                    const mappedOption = resources.find(
                                        e => checkbox === e.resourceId && Number(e.resourceId) !== Number(me.ID)
                                    );
                                    if (!!mappedOption) {
                                        const optVal = mappedOption.resourceId;
                                        const text = joinDefined([mappedOption.FirstName, mappedOption.Surname], ' ');
                                        return (
                                            <Button variant={'tag'}
                                                    key={idx}
                                                    onClick={() => this.onRemoveStaff(optVal)}
                                            >{text}</Button>
                                        );
                                    }
                                    return null;
                                })}
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    renderTypeFilters() {
        const { classes, view } = this.props;
        const { filter } = this.state;
        return (
            <Fragment>
                <Grid container spacing={24}>
                    <Grid pc={1}>
                        <Typography variant="title" id="contact-filter-clergy" className={classes.titleGap}>
                            Show Appointment Types
                        </Typography>

                        <div className={classes.buttonGroup}>
                            <Button variant="secondary" size="tiny"
                                    onClick={() => this.handleBulkSelectChange('types')}>
                                Select All
                            </Button>
                            <Button variant="secondary" size="tiny"
                                    onClick={() => this.handleBulkUnselectChange('types')}>
                                Unselect All
                            </Button>
                        </div>

                        <Grid container spacing={0}>
                            {CALENDAR_TYPES.filter(e => appointmentTypesByView({ type: e.type, view })).map(
                                mappedOption => {
                                    const optVal = mappedOption.type;
                                    return (
                                        <Grid key={optVal} item xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={filter.types.includes(optVal) || false}
                                                        onChange={this.handleTypesChange}
                                                        value={optVal}
                                                        color="primary"
                                                    />
                                                }
                                                label={mappedOption.label}
                                            />
                                        </Grid>
                                    );
                                }
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const styles = ({ breakpoints, spacing }) => ({
    buttonGroup: {
        marginBottom: 6,
        clear: 'both',
        '& button': {
            marginRight: 12
        },
        '& button:last-child': {
            marginRight: 0
        }
    },
    filterBtn: {
        [breakpoints.down('sm')]: {
            minWidth: '56px'
        },
        [breakpoints.down('xs')]: {
            borderRadius: '0 30px 30px 0 !important',
            borderLeft: 'none!important'
        }
    },
    paper: {
        width: '100%',
        maxWidth: 440,
        maxHeight: 'calc(100vh - 32px)',
        backgroundColor: '#fafafa',
        padding: spacing.unit * 2
    },
    paperScroll: {
        overflow: 'hidden',
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)',
        padding: spacing.unit * 2
    },
    selectButton: {
        marginRight: 10,
        marginTop: 15
    },
    titleGap: { padding: spacing.unit, paddingLeft: 0, paddingRight: 0 },
    actionButtons: {
        textAlign: 'right',
        marginTop: 12
    },
    buttonCancel: { color: '#E64040' },
    buttonOk: { color: '#26CC6F' },
    selectedGroups: {
        margin: '-20px -6px 0',
        '& > *': {
            margin: '6px'
        }
    }
});

export default compose(withRouter, withStyles(styles))(CalendarFilters);
