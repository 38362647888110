import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tabs } from '@material-ui/core';

export default withStyles({})(({ children, ...props }) => {
    return (
        <Tabs
            className="tabs"
            variant="scrollable"
            TabIndicatorProps={{ component: 'indicator' }}
            scrollButtons="on"
            {...props}
        >
            {children}
        </Tabs>);
});