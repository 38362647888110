import React, { Component, Fragment } from 'react';
import { Typography } from '@material-ui/core';
import { BILL_TO_OPTIONS } from './BillingConstants';
import TextField from '../../../components/form/TextField';
import Inline from '../../../components/layout/Inline';
import RadioGroup from '../../../components/form/RadioGroup';
import AddressAutocomplete, { createAddressComponentFields } from '../../../components/form/AddressAutocomplete';
import ContactLookup, { getAddressBookById } from '../../../components/contact/ContactLookup';
import Grid from '../../../components/layout/Grid';
import { joinDefined, prettyPhone, stringIsNullOrEmpty } from '../../../utils/strings';
import { isNullOrUndefined } from '../../../utils/objects';
import AuthorisedContactSelector from '../../../components/form/AuthorisedContactSelector';
import ContactCard from '../../../components/contact/ContactCard';
import Button from '../../../components/form/Button';

const residentialAddressComponentFields = createAddressComponentFields('Customer');

export const BILL_TO_MAPPING = {
    billingSource: 'billingSource',
    relationToDeceased: 'relationToDeceased',
    fullName: 'fullName',
    givenName: 'givenName',
    middleName: 'middleName',
    surname: 'surname',
    phone: 'phone',
    mobile: 'mobile',
    email: 'email',
    fax: 'fax',
    line1: 'line1',
    line2: 'line2',
    city: 'city',
    state: 'state',
    country: 'country',
    code: 'code',
    billingId: 'billingId'
};

const createBillToMapping = () => {
    return {
        [BILL_TO_MAPPING.billingSource]: `Customer.BillingSource`,
        [BILL_TO_MAPPING.relationToDeceased]: `Customer.RelationToDeceased`,
        [BILL_TO_MAPPING.givenName]: `Customer.FirstName`,
        [BILL_TO_MAPPING.middleName]: `Customer.MiddleName`,
        [BILL_TO_MAPPING.surname]: `Customer.Surname`,
        [BILL_TO_MAPPING.fullName]: `Customer.CustomerName`,
        [BILL_TO_MAPPING.phone]: `Customer.Phone`,
        [BILL_TO_MAPPING.mobile]: `Customer.Mobile`,
        [BILL_TO_MAPPING.line1]: `Customer.AddressLine1`,
        [BILL_TO_MAPPING.line2]: `Customer.AddressLine2`,
        [BILL_TO_MAPPING.city]: `Customer.Suburb`,
        [BILL_TO_MAPPING.state]: `Customer.State`,
        [BILL_TO_MAPPING.country]: `Customer.Country`,
        [BILL_TO_MAPPING.code]: `Customer.Postcode`,
        [BILL_TO_MAPPING.email]: `Customer.Email`,
        [BILL_TO_MAPPING.billingId]: `Customer.BillingId`
    };
};

class BillTo extends Component {
    state = {
        billingContact: null,
        mapping: createBillToMapping()
    };

    componentDidMount() {
        const { billingContact } = this.state;
        const { billingTargetForm } = this.props;
        const mapping = createBillToMapping();
        const billingSource = billingTargetForm.getField(mapping.billingSource);
        const billingId = billingTargetForm.getField(mapping.billingId);

        if (billingSource === 'AddressBook' && isNullOrUndefined(billingContact) && !isNullOrUndefined(billingId)) {
            getAddressBookById(billingId).then(x => {
                this.setState({ billingContact: x });
                billingTargetForm.setField({ [mapping.billingId]: x.ID });
            });
        }
    }

    render() {
        const { disabled, billingTargetForm } = this.props;
        const { mapping } = this.state;
        const billingSource = billingTargetForm.getField(mapping.billingSource);

        return (
            <Fragment>
                <Grid item>
                    <h4>Billing Details</h4>
                </Grid>

                <Grid item>
                    <Inline center>
                        <h5>Bill To:</h5>
                        <RadioGroup
                            disabled={disabled}
                            options={BILL_TO_OPTIONS}
                            value={billingSource}
                            onChange={e => this.onBillingSourceChanged(e.target.value)}
                        />
                    </Inline>
                </Grid>

                {stringIsNullOrEmpty(billingSource) && this.renderEmpty()}
                {billingSource === 'Applicant' && this.renderApplicantDetails()}
                {billingSource === 'AddressBook' && this.renderAddressBook()}
                {billingSource === 'AuthorisedContact' && this.renderAuthorisedContact()}
                {billingSource === 'Other' && this.renderOtherBillingTarget()}
            </Fragment>
        );
    }

    renderEmpty() {
        return (
            <Grid item xs={12}>
                <Typography variant="caption">Please select a billing source...</Typography>
            </Grid>
        );
    }

    renderApplicantDetails() {
        return this.renderBillingTarget(false, false);
    }

    renderAddressBook() {
        const { billingContact } = this.state;
        return (
            <Fragment>
                {(!billingContact && (
                    <Grid item xs={12}>
                        <ContactLookup
                            placeholder="Search for a Billing Entity"
                            onSelect={(_, result) => this.onSelectBillerFromAddressBook(result)}
                            onAddNewContact={result => this.onSelectBillerFromAddressBook(result)}
                            value={billingContact}
                        />
                    </Grid>
                )) || (
                    <Grid item xs={12}>
                        <div style={{ float: 'right', marginTop: -24 }}>
                            <Button variant="link-orange" onClick={() => this.clearBillingTarget('AddressBook')}>
                                Change Contact
                            </Button>
                        </div>
                        <div className="container">
                            <ContactCard
                                variant="fullwidth" //Do no remove
                                contact={billingContact}
                            />
                        </div>
                    </Grid>
                )}
            </Fragment>
        );
    }

    renderAuthorisedContact() {
        const { billingSourceForm, billingTargetForm } = this.props;
        const { mapping } = this.state;
        const cremationId = billingSourceForm.getField('ID');
        const authorisedContactName =
            joinDefined(
                [
                    billingTargetForm.getField(mapping.givenName),
                    billingTargetForm.getField(mapping.middleName),
                    billingTargetForm.getField(mapping.surname)
                ],
                ' '
            ) +
            ' (' +
            billingTargetForm.getField(mapping.relationToDeceased) +
            ')';

        return (
            <Fragment>
                <Grid item xs={12} md={6}>
                    <AuthorisedContactSelector
                        name="AuthorisedContact_Billing"
                        onChange={this.onChangeBillingTargetAuthorisedContact}
                        cremationId={cremationId}
                        value={authorisedContactName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Inline>
                        {billingTargetForm.getField(mapping.phone) && (
                            <span>Phone: {prettyPhone(billingTargetForm.getField(mapping.phone))}</span>
                        )}
                        {billingTargetForm.getField(mapping.mobile) && (
                            <span>Mobile: {prettyPhone(billingTargetForm.getField(mapping.mobile))}</span>
                        )}
                    </Inline>
                    <p>
                        {joinDefined(
                            [
                                billingTargetForm.getField(mapping.line1),
                                billingTargetForm.getField(mapping.line2),
                                billingTargetForm.getField(mapping.city),
                                billingTargetForm.getField(mapping.state),
                                billingTargetForm.getField(mapping.code),
                                billingTargetForm.getField(mapping.country)
                            ],
                            ', '
                        )}
                    </p>
                </Grid>
            </Fragment>
        );
    }

    renderOtherBillingTarget() {
        return this.renderBillingTarget(true, true);
    }

    renderBillingTarget(canEditNames, canEditContactDetails) {
        const { billingTargetForm, disabled } = this.props;
        const { mapping } = this.state;
        const fullName = billingTargetForm.getField(mapping.fullName);

        return (
            <Grid container spacing={8}>
                {!stringIsNullOrEmpty(fullName) ? (
                    <Grid item>
                        <TextField
                            capitalize
                            name={mapping.fullName}
                            disabled={!canEditNames || disabled}
                            label="Name"
                            form={billingTargetForm}
                        />
                    </Grid>
                ) : (
                    <Fragment>
                        <Grid item xs={12} md={4}>
                            <TextField
                                capitalize
                                name={mapping.givenName}
                                label="First name"
                                form={billingTargetForm}
                                disabled={!canEditNames || disabled}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <TextField
                                capitalize
                                name={mapping.middleName}
                                label="Middle Name(s) (optional)"
                                form={billingTargetForm}
                                disabled={!canEditNames || disabled}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                capitalize
                                name={mapping.surname}
                                label="Surname"
                                form={billingTargetForm}
                                disabled={!canEditNames || disabled}
                            />
                        </Grid>
                    </Fragment>
                )}
                <Grid item xs={12} md={6}>
                    <TextField
                        name={mapping.phone}
                        label="Phone (optional)"
                        disabled={!canEditContactDetails || disabled}
                        form={billingTargetForm}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        name={mapping.mobile}
                        label="Mobile (optional)"
                        disabled={!canEditContactDetails || disabled}
                        form={billingTargetForm}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name={mapping.email}
                        label="Email (optional)"
                        type="email"
                        disabled={!canEditContactDetails || disabled}
                        form={billingTargetForm}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AddressAutocomplete
                        id="BillingAddress"
                        label="Billing Address"
                        placeholder="Enter an address..."
                        componentFields={residentialAddressComponentFields}
                        form={billingTargetForm}
                        disabled={!canEditContactDetails || disabled}
                        allowCustomAddress
                        required
                    />
                </Grid>
            </Grid>
        );
    }

    onBillingSourceChanged(billingSource) {
        const { billingSourceForm, billingTargetForm } = this.props;
        const { mapping } = this.state;

        if (billingSource === 'Applicant') {
            const clientFullNamePiece = billingSourceForm.getField('Client.InformantsName') || '';
            let clientSurname = billingSourceForm.getField('Client.Surname');
            let clientFirstName = billingSourceForm.getField('Client.FirstName');
            let clientMiddleName = billingSourceForm.getField('Client.MiddleName');
            // need to reconstruct clientormant name from legacy data...?
            if (!!clientFullNamePiece && !clientSurname && !clientFirstName && !clientMiddleName) {
                const bits = ('' + clientFullNamePiece).split(' ');
                if (!clientSurname) clientSurname = bits.pop();
                else bits.pop();
                if (!clientFirstName) clientFirstName = bits.shift();
                else bits.shift();
                if (!clientMiddleName) clientMiddleName = bits.join(' ');
            }

            const clientFullName =
                clientFullNamePiece || joinDefined([clientFirstName, clientMiddleName, clientSurname], ' ');

            let state = {
                [mapping.givenName]: clientFirstName,
                [mapping.middleName]: clientMiddleName,
                [mapping.surname]: clientSurname,
                [mapping.fullName]: clientFullName,
                [mapping.phone]: billingSourceForm.getField('Client.PrimaryNumber'),
                [mapping.mobile]: billingSourceForm.getField('Client.SecondaryNumber'),
                [mapping.email]: billingSourceForm.getField('Client.Email'),
                [mapping.relationToDeceased]: billingSourceForm.getField('Client.RelationToDeceased'),
                [mapping.billingSource]: billingSource
            };

            const useResidential = billingSourceForm.getField('Client.PostalAddressSameAsResidentialAddress');
            if (useResidential) {
                state[mapping.line1] = billingSourceForm.getField('Client.ResidentialAddress.AddressLine1');
                state[mapping.line2] = billingSourceForm.getField('Client.ResidentialAddress.AddressLine2');
                state[mapping.city] = billingSourceForm.getField('Client.ResidentialAddress.Suburb');
                state[mapping.state] = billingSourceForm.getField('Client.ResidentialAddress.State');
                state[mapping.code] = billingSourceForm.getField('Client.ResidentialAddress.Postcode');
                state[mapping.country] = null;
            } else {
                state[mapping.line1] = billingSourceForm.getField('Client.PostalAddress.AddressLine1');
                state[mapping.line2] = billingSourceForm.getField('Client.PostalAddress.AddressLine2');
                state[mapping.city] = billingSourceForm.getField('Client.PostalAddress.Suburb');
                state[mapping.state] = billingSourceForm.getField('Client.PostalAddress.State');
                state[mapping.code] = billingSourceForm.getField('Client.PostalAddress.Postcode');
                state[mapping.country] = billingSourceForm.getField('Client.PostalAddress.Country');
            }

            billingTargetForm.setField(state);
        } else if (billingSource === 'AddressBook') {
            this.clearBillingTarget(billingSource);
        } else if (billingSource === 'AuthorisedContact') {
            // AuthorisedContact
            this.clearBillingTarget(billingSource);
        } else if (billingSource === 'Other') {
            this.clearBillingTarget(billingSource);
        } else {
            this.clearBillingTarget();
        }

        this.setState({ billingContact: null });
    }

    clearBillingTarget(billingSource = '') {
        const { billingTargetForm } = this.props;
        const { mapping } = this.state;
        let state = {
            [mapping.relationToDeceased]: null,
            [mapping.givenName]: null,
            [mapping.middleName]: null,
            [mapping.surname]: null,
            [mapping.fullName]: null,
            [mapping.phone]: null,
            [mapping.mobile]: null,
            [mapping.email]: null,
            [mapping.line1]: null,
            [mapping.line2]: null,
            [mapping.city]: null,
            [mapping.state]: null,
            [mapping.code]: null,
            [mapping.country]: null,
            [mapping.billingSource]: billingSource
        };

        if (!isNullOrUndefined(mapping.billingId)) state[mapping.billingId] = null;

        billingTargetForm.setField(state);
        this.setState({ billingContact: null });
    }

    onSelectBillerFromAddressBook(contact) {
        const { billingTargetForm } = this.props;
        const { mapping } = this.state;
        let state = {
            [mapping.relationToDeceased]: null,
            [mapping.fullName]: contact.Name,
            [mapping.givenName]: '',
            [mapping.middleName]: '',
            [mapping.surname]: '',
            [mapping.phone]: contact.Phone,
            [mapping.mobile]: contact.Mobile,
            [mapping.email]: contact.Email,
            [mapping.line1]: contact.AddressLine1,
            [mapping.line2]: contact.AddressLine2,
            [mapping.city]: contact.Suburb,
            [mapping.state]: contact.State,
            [mapping.code]: contact.Postcode,
            [mapping.country]: null,
            [mapping.billingSource]: 'AddressBook'
        };

        if (!isNullOrUndefined(mapping.billingId)) state[mapping.billingId] = contact.ID;
        billingTargetForm.setField(state);
        this.setState({ billingContact: contact });
    }

    onChangeBillingTargetAuthorisedContact = item => {
        if (item && item.metadata) {
            const { source, relationship } = item.metadata;
            const { billingTargetForm } = this.props;
            const { mapping } = this.state;
            let state = {
                [mapping.relationToDeceased]: relationship,
                [mapping.givenName]: source.FirstName,
                [mapping.middleName]: source.MiddleName,
                [mapping.surname]: source.Surname,
                [mapping.phone]: source.PrimaryNumber,
                [mapping.mobile]: source.SecondaryNumber,
                [mapping.email]: source.Email,
                [mapping.line1]: source.ResidentialAddress.AddressLine1,
                [mapping.line2]: source.ResidentialAddress.AddressLine2,
                [mapping.city]: source.ResidentialAddress.Suburb,
                [mapping.state]: source.ResidentialAddress.State,
                [mapping.code]: source.ResidentialAddress.Postcode,
                [mapping.country]: source.ResidentialAddress.Country,
                [mapping.billingSource]: 'AuthorisedContact' // AuthorisedContact
            };

            if (!isNullOrUndefined(mapping.billingId)) state[mapping.billingId] = null;
            billingTargetForm.setField(state);
        } else {
            this.clearBillingTarget('AuthorisedContact'); // AuthorisedContact
        }
    };
}

export default BillTo;
