import gql from 'graphql-tag';

export default gql`
    fragment QuoteTemplate on CMQuoteTemplate {
        ID
        LegacyKey
        Created
        Description
        QuoteType
        QuoteTemplateItems {
            nodes {
                ID
                Title
                Content
                Price
                Qty
                Complimentary
                Optional
                HasGST
            }
        }
    }
`;
