import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import FuneralLookupTable from '../../../components/form/FuneralLookupTable';
import Grid from '../../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import Modal from '../../../components/modal/Modal';
import { isNullOrUndefined } from '../../../utils/objects';
import { CloseIcon, TickCircleIcon } from '../../../components/IconIndex';

class FuneralSearchModal extends Component {
    state = {
        selectedFuneral: null
    };

    render() {
        const { open, initialSearchTerm, ignoreFuneralId } = this.props;
        const { selectedFuneral } = this.state;
        const actions = (
            <Inline alignment={inlineAlignment.rightAlignSiblings}>
                <div>
                    <Button onClick={this.handleClose} variant="secondary" startIcon={<CloseIcon />}>
                        Cancel
                    </Button>
                </div>
                <Button onClick={this.handleAccept} variant="primary" disabled={isNullOrUndefined(selectedFuneral)}
                        startIcon={<TickCircleIcon />}>
                    Use Funeral Data
                </Button>
            </Inline>
        );
        return (
            <Modal
                open={open}
                onClose={this.handleClose}
                actions={actions}
                variant="primary"
                title="Prefill From Existing Funeral"
            >
                <Grid container>
                    <Grid item>
                        <FuneralLookupTable
                            initialSearchTerm={initialSearchTerm}
                            selectedFuneral={selectedFuneral}
                            ignoreFuneralId={ignoreFuneralId}
                            onSelectFuneral={e => this.setState({ selectedFuneral: e })}
                        />
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    handleClose = e => {
        const { onClose } = this.props;
        onClose();
        this.setState({ selectedFuneral: null });
    };

    handleAccept = e => {
        const { onAccept } = this.props;
        const { selectedFuneral } = this.state;
        onAccept(selectedFuneral);
        this.setState({ selectedFuneral: null });
    };
}

const styles = () => ({
    root: {},
    body: {
        padding: '50px 50px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    paper: {
        width: '90vw',
        maxWidth: '1100px'
    },
    enquiryLookup: {
        margin: '20px 0',
        minHeight: 250
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between'
    }
});

export default compose(withStyles(styles))(FuneralSearchModal);
