import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import Button from '../../components/form/Button';
import ViewHeader from '../../components/header/ViewHeader';
import DataTable from '../../components/dataTable/DataTable';
import AttentionDot from '../../components/table/AttentionDot';
import { joinDefined, prettyPhone } from '../../utils/strings';
import { niceDateTimeFromString } from '../../utils/date';
import ErrorFallback from '../../components/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

class PlaqueListing extends Component {
    render() {
        const { history } = this.props;
        const viewAll = history.location.pathname.indexOf('/view-all') > 0;
        return (
            <Grid container>
                <Grid item>{this.renderHeader(viewAll)}</Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <Grid item>{this.renderPlaqueListingsTable(viewAll)}</Grid>
                    </Paper>
                </Grid>
            </Grid>
        );
    }

    renderHeader(viewAll) {
        return <ViewHeader title="Plaques" subtitle={viewAll ? 'View All Orders' : 'Current Orders'} />;
    }

    renderPlaqueListingsTable(viewAll) {
        const filters =
            (!viewAll && [
                { field: 'Cancelled', value: '0' },
                { field: 'Complete', value: '0' }
            ]) ||
            null;
        return (
            <Fragment>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <DataTable
                                queryName="readPlaqueOrders"
                                fragment={plaqueFragment}
                                filterByFragment={plaqueFilterByFragment}
                                columns={plaqueColumns}
                                variables={{ limit: 10, filters, filterBy: [] }}
                                brandProperty="LegacyKey"
                                searchVariable="contains"
                                tableTitle={viewAll ? 'Orders Archive' : 'Outstanding Orders'}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }
}

const plaqueColumns = [
    {
        label: 'Ref',
        propertyPath: 'LegacyKey',
        renderCell: (row, value) => {
            const urgent = row.Status === 'Urgent' || row.Status === 'Overdue';
            return (
                <Fragment>
                    {!!urgent && <AttentionDot />}
                    <Button href={'/plaques/' + row.ID} variant="link-orange">
                        {value || row.ID}
                    </Button>
                </Fragment>
            );
        }
    },
    {
        label: 'Deceased',
        renderCell: row => <span>{row.NameOfDeceased}</span>
    },
    {
        label: 'Installation Date',
        propertyPath: 'InstallationDate',
        renderCell: row => (
            <span>{niceDateTimeFromString(joinDefined([row.InstallationDate, row.InstallationTime], ' '))}</span>
        )
    },
    {
        label: 'Plaque Type',
        renderCell: row => <span>{row.ProductOrder && row.ProductOrder.Product && row.ProductOrder.Product.Title}</span>
    },
    {
        label: 'Enquiry Date',
        renderCell: row => {
            return <span>{niceDateTimeFromString(row.Enquiry.Created)}</span>;
        }
    },
    {
        label: 'Status',
        renderCell: row => {
            return <span>{row.Status}</span>;
        }
    },
    {
        label: 'Applicant',
        renderCell: row => (
            <span>
                {row.Client && joinDefined([row.Client.FirstName, row.Client.MiddleName, row.Client.Surname], ' ')}
                {row.Client &&
                    (row.Client.PrimaryNumber || row.Client.SecondaryNumber) &&
                    (row.Client.FirstName || row.Client.MiddleName || row.Client.Surname) &&
                    ' - '}
                {row.Client && prettyPhone(joinDefined([row.Client.PrimaryNumber, row.Client.SecondaryNumber], ' / '))}
            </span>
        )
    }
];

const plaqueFragment = gql`
    fragment StylingPlaqueOrder on PlaqueOrder {
        ID
        LegacyKey
        InstallationDate
        InstallationTime
        Status
        NameOfDeceased
        Client {
            ID
            FirstName
            MiddleName
            Surname
            PrimaryNumber
            SecondaryNumber
        }
        Enquiry {
            ID
            Created
        }
        ProductOrder {
            ID
            Product {
                ID
                Title
            }
        }
    }
`;

const plaqueFilterByFragment = `
    filterByInfo{
        Brand {
            Label
            Options{
                Key
                Value
            }
        }
        Status{
            Label
            Options
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(PlaqueListing), { fallback: ErrorFallback });
export const PlaqueAllListing = withStyles({})(({ ...props }) => {
    return <PlaqueListing {...props} />;
});
