import React, { Component } from 'react';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '../icon/AddIcon';
import TextField from '../form/TextField';
import Button from '../form/Button';
import { getMyClient } from '../../utils/apollo';
import { getUser } from '../../utils/sessions';
import Spinner from '../loading/Spinner';
import AlertModal from '../modal/AlertModal';

class AddLabel extends Component {
    state = { text: '', loading: false, deletingLabel: null };

    render() {
        const { LabelList } = this.props || [];
        const { text, loading, deletingLabel } = this.state;
        const disabled = !text || loading;
        const me = getUser();
        return (
            <div className="add-label task-checklist">
                <AlertModal
                    variant="warning"
                    open={!!deletingLabel}
                    title="Prompt - Delete Label"
                    primaryAction="Oh yeah, chuck it out"
                    onClickPrimaryAction={() => this.handleDeleteLabel(deletingLabel.ID)}
                    secondaryAction="Nah, keep it"
                    onClickSecondaryAction={() => this.setState({ deletingLabel: null })}
                    onClose={() => this.setState({ deletingLabel: null })}
                >
                    <p>
                        Woah there {me.FirstName}! Looks like you're deleting the{' '}
                        <strong>{deletingLabel && deletingLabel.title}</strong> label.
                    </p>
                    <p>Are you sure?</p>
                </AlertModal>

                <form onSubmit={disabled ? undefined : this.handleAddSubTask}>
                    <h3> Your Labels </h3>
                    <div className="label-list tag-list">
                        <span
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            {LabelList.sort((a, b) =>
                                a.title.toUpperCase() < b.title.toUpperCase() ? -1 : 1
                            ).map(labelItem => this.renderTaskLabel(labelItem))}
                        </span>
                    </div>
                    <div className="checklist-form-container">
                        <TextField
                            name="add"
                            value={text}
                            disabled={loading}
                            onChange={e => this.setState({ text: e.target.value })}
                            placeholder="Add a new label..."
                        />
                        <IconButton disabled={disabled} onClick={this.handleAddSubTask}>
                            {loading ? <Spinner /> : <AddIcon />}
                        </IconButton>
                    </div>
                </form>
            </div>
        );
    }

    renderTaskLabel(labelItem) {
        const { setFilters, filters } = this.props;
        const isActive = filters.labels.indexOf(labelItem.ID) > -1;
        const newLabels = [].concat(filters.labels.filter(e => !(e === labelItem.ID && isActive)));
        if (!isActive) newLabels.push(labelItem.ID);

        return (
            <span key={labelItem.ID} onClick={() => setFilters(filters.type, filters.value, filters.title, newLabels)}>
                {(1 === 2 && (
                    <ListItem button onClick={() => setFilters('label', labelItem.ID)} selected={isActive}>
                        <ListItemText primary={labelItem.title} />
                        <p className="tasknumber"> {labelItem.count || '0'} </p>
                    </ListItem>
                )) || (
                    <Button
                        size="tiny"
                        variant={`tag ${isActive ? '' : 'secondary'}`}
                        onClick={e => {
                            e.stopPropagation();
                            this.setState({ deletingLabel: { ...labelItem } });
                        }}
                    >
                        {labelItem.title} ({labelItem.count || '0'})
                    </Button>
                )}
            </span>
        );
    }

    handleAddSubTask = e => {
        if (e) e.preventDefault();
        const me = this;
        const { text } = this.state || '';
        const { LabelList, reloadBucket } = this.props || [];
        if (!LabelList.find(e => e.title === text.trim())) {
            this.updateLabels(text).then(
                () => me.setState({ loading: false, text: '' }, reloadBucket),
                error => console.error(error));
        }
    };

    updateLabels = async text => {
        this.setState({ loading: true });
        const { ID } = getUser();
        const asyncQuery = await getMyClient().mutate({
            mutation: gql`
                mutation CreateTaskLabel($input: CreateTaskLabelInput!) {
                    createTaskLabel(input: $input) {
                        ID
                        Title
                        Member {
                            ID
                        }
                    }
                }
            `,
            variables: {
                input: {
                    Title: text,
                    MemberID: ID
                }
            }
        });

        return asyncQuery && asyncQuery.data ? asyncQuery.data : false;
    };

    handleDeleteLabel = ID => {
        if (!ID) return null;
        const me = this;
        const { LabelList, reloadBucket } = this.props || [];
        LabelList.find(e => e.ID === ID) &&
            this.deleteLabel(ID).then(
                () => me.setState({ loading: false, text: '', deletingLabel: null }, reloadBucket),
                error => console.error(error)
            );
    };

    deleteLabel = async ID => {
        this.setState({ loading: true });
        const asyncQuery = await getMyClient().mutate({
            mutation: gql`
                mutation UpdateTaskLabel($input: UpdateTaskLabelInput!) {
                    updateTaskLabel(input: $input) {
                        ID
                        Title
                        NotActive
                        Member {
                            ID
                        }
                    }
                }
            `,
            variables: {
                input: {
                    ID: ID,
                    NotActive: true
                }
            }
        });

        return asyncQuery && asyncQuery.data ? asyncQuery.data : false;
    };
}

export default withStyles({})(AddLabel);
