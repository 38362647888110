import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import FooterItem from './FooterItem';
import { items } from './footerConstants';
import GatedComponent from '../GatedComponent';

class Footer extends Component {
    state = {
        activeItem: undefined
    };

    componentWillMount() {
        this.updateActiveItem(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.updateActiveItem(nextProps);
    }

    render() {
        return <div className="footer">{items.map(this.renderItem)}</div>;
    }

    renderItem = item => {
        const { activeItem } = this.state;
        return (
            <GatedComponent key={item.id || item.label} canViewCode={item.permission}>
                {() => {
                    return (
                        <FooterItem
                            key={item.label}
                            item={item}
                            active={item === activeItem}
                            onClick={e => this.onClickItem(e, item)}
                        />);
                }}
            </GatedComponent>
        );
    };

    updateActiveItem(props) {
        const path = props.location.pathname;
        const matchingActiveItem = items.find(item => path === item.path);
        const { activeItem } = this.state;
        if (matchingActiveItem !== activeItem) {
            this.setState({ activeItem: matchingActiveItem });
        }
    }

    onClickItem = (e, item) => {
        e.preventDefault();
        const { history } = this.props;
        history.push(item.path);
    };
}

export default withStyles({})(withRouter(Footer));
