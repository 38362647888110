import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 640 512" {...props}>
        <path
            fill="currentColor"
            d="M408.79 244.28C423.84 224.79 432 201.02 432 176c0-61.85-50.04-112-112-112-61.85 0-112 50.04-112 112 0 25.02 8.15 48.79 23.20 68.28-12.93 3.69-71.20 25.76-71.20 92.757v60.677C160 425.44 182.55 448 210.28 448h219.429C457.44 448 480 425.44 480 397.714v-60.677c0-66.98-58.23-89.05-71.20-92.757zM320 96c44.18 0 80 35.81 80 80s-35.81 80-80 80-80-35.81-80-80 35.81-80 80-80zm128 301.714c0 10.09-8.18 18.28-18.28 18.286H210.286C200.18 416 192 407.81 192 397.714v-60.677c0-28.57 18.94-53.68 46.41-61.538l20.21-5.775C276.70 281.61 297.86 288 320 288s43.29-6.38 61.36-18.275l20.21 5.775C429.05 283.35 448 308.46 448 337.037v60.677zm-304 0V384H45.714C38.14 384 32 377.86 32 370.286v-45.508c0-21.43 14.20-40.26 34.81-46.153l12.89-3.684C93.90 283.23 110.40 288 128 288a95.58 95.58 0 0 0 29.23-4.564c5.80-10.54 13.46-20.10 22.90-28.48 9.29-8.24 18.91-14.14 27.09-18.247C197.22 218.20 192 197.55 192 176c0-16.21 2.99-31.96 8.70-46.618C183.09 108.95 157.03 96 128 96c-52.93 0-96 43.06-96 96 0 21.77 7.29 41.87 19.55 58.003C25.67 259.79 0 286.42 0 324.778v45.508C0 395.49 20.50 416 45.71 416h100.871A66.07 66.07 0 0 1 144 397.714zM128 128c35.34 0 64 28.65 64 64s-28.65 64-64 64-64-28.65-64-64 28.65-64 64-64zm460.44 122.003C600.70 233.87 608 213.77 608 192c0-52.93-43.06-96-96-96-29.03 0-55.09 12.95-72.71 33.385C445.00 144.04 448 159.78 448 176c0 21.55-5.21 42.20-15.23 60.70 8.19 4.10 17.81 10.00 27.11 18.25 9.43 8.37 17.09 17.93 22.89 28.478A95.57 95.57 0 0 0 512 288c17.59 0 34.09-4.76 48.29-13.06l12.89 3.684C593.79 284.51 608 303.34 608 324.778v45.508c0 7.57-6.14 13.71-13.71 13.714H496v13.714c0 6.34-.91 12.47-2.58 18.286h100.871C619.49 416 640 395.49 640 370.286v-45.508c0-38.36-25.68-64.98-51.55-74.775zM512 256c-35.34 0-64-28.65-64-64s28.65-64 64-64 64 28.65 64 64-28.65 64-64 64z"
        />
    </SvgIcon>
);
