import gql from 'graphql-tag';

export default gql`
    fragment Customer on CMCustomer {
        ID
		RelationToDeceased
		CustomerName
		FirstName
		MiddleName
		Surname
		Phone
		Mobile
		Email
		AddressLine1
		AddressLine2
		Suburb
		State
		Postcode
		Country
		XeroID
		BillingSource
		BillingId
    }
`;
