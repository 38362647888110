import React, { Component } from 'react';
import gql from 'graphql-tag';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import Query from 'react-apollo/Query';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import TextField from '../../../components/form/TextField';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import SaveIcon from '../../../components/icon/SaveIcon';
import ContactCard from '../../../components/contact/ContactCard';
import BackIcon from '../../../components/icon/BackIcon';
import NextIcon from '../../../components/icon/NextIcon';
import ImageThumnail from '../../../components/cards/ImageThumbnail';
import FlexGrid from '../../../components/layout/FlexGrid';
import ContactLookup from '../../../components/contact/ContactLookup';
import Spinner from '../../../components/loading/Spinner';
import { deleteTypeName, diff, isNullOrUndefined } from '../../../utils/objects';
import createForm from '../../../utils/createForm';
import PlaqueProduct from '../../../fragments/PlaqueProduct';
import PlaqueProductOrder from '../../../fragments/PlaqueProductOrder';
import { getClient } from '../../../utils/apollo';
import { isRelatedObjectDefined } from '../../../utils/bookable';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';

class PlaqueOrderModal extends Component {
    state = {
        tabIndex: 0,
        loading: false,
        form: null,
        open: false
    };

    static getDerivedStateFromProps({ productOrder, open, parentForm }, state) {
        const newState = { open };
        if (!state.form || !state.form.state || (productOrder && state.form.state.ID !== productOrder.ID)) {
            if (!isNullOrUndefined(productOrder)) {
                let clonedOrder = cloneDeep(productOrder);
                const oldContext = state.form !== null ? state.form.context : null;
                newState.form = createForm(oldContext, clonedOrder);
                newState.form.state.Location = parentForm.getField('PlaqueLocation');
            } else {
                newState.form = null;
            }
            newState.tabIndex = 0;
            return newState;
        }

        return null;
    }

    componentDidUpdate(_, oldState) {
        const { open, loading, form } = this.state;
        const { orderLocation } = this.props;

        if (open && form === null && !loading) {
            this.createOrder();
            return;
        }

        if (orderLocation && orderLocation.ID && form && !form.getField('Location')) {
            form.setField({ Location: orderLocation });
            return;
        }

        if (oldState.form === null && form !== null) form.context = this;
    }

    render() {
        const { open, onClose } = this.props;
        const { form, tabIndex } = this.state;

        if (!form) return null;

        const steps = [
            {
                abbreviation: 'Location',
                title: 'Step One:',
                subtitle: 'Choose Location',
                onChangeTab: this.onChangeTab,
                content: <div>{this.renderLocation()}</div>
            },
            {
                abbreviation: 'Plaque Type',
                title: 'Step Two:',
                subtitle: 'Choose Niche and Plaque',
                onChangeTab: this.onChangeTab,
                content: <div>{this.renderNichePlaque()}</div>
            }
        ];
        //if (this.canSubmit()) {
        steps.push({
            abbreviation: 'Order Summary',
            title: 'Confirm:',
            subtitle: 'Plaque Order',
            onChangeTab: this.onChangeTab,
            content: <div>{this.renderSummary()}</div>
        });
        //}

        return (
            <Modal
                open={open}
                variant="stepped"
                onClose={onClose}
                activeTab={tabIndex}
                canClickOut={false}
                steps={steps}
            />
        );
    }

    canSubmit() {
        const { form } = this.state;

        return !!form.getField('Location') && !!form.getField('Product');
    }

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    createOrder() {
        const order = {
            Niche: null,
            NicheCoordinates: null,
            Location: null,
            Product: null
        };

        //add form details
        const form = createForm(this, order);
        this.setState({ loading: false, form });
        return null;
    }

    renderLocation() {
        const { onClose } = this.props;
        const { form } = this.state;
        const location = form.getField('Location');

        return (
            <Grid container>
                <Grid item>
                    <h2>Confirm the plaque location</h2>
                </Grid>
                <Grid item>
                    <ContactLookup
                        name="Location"
                        form={form}
                        onSelect={(_, addressBook) => this.handleSelectBusinessContact('Location', addressBook)}
                        onAddNewContact={addressBook => this.handleSelectBusinessContact('Location', addressBook)}
                    />

                    {!!location && <ContactCard variant="fullwidth" contact={location} />}
                </Grid>

                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <div>
                            <Button variant="secondary" onClick={() => onClose()}>
                                Cancel
                            </Button>
                        </div>
                        <Button variant="primary" onClick={() => this.setState({ tabIndex: 1 })}>
                            Next Step
                            <NextIcon />
                        </Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    handleSelectBusinessContact(propertyName, addressBook) {
        const { form } = this.state;
        delete addressBook['__typename'];

        if (!!addressBook) {
            form.setState({ [propertyName]: addressBook });
        }
    }

    renderNichePlaque() {
        const { onClose } = this.props;
        const { form } = this.state;

        const selectedProduct = form.getField('Product');

        return (
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <TextField label="Select Niche" placeholder="Select Niche" name="Niche" form={form} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Niche Coordinates"
                        placeholder="Niche Preselected"
                        name="NicheCoordinates"
                        form={form}
                    />
                </Grid>
                <Grid item>
                    <h2>Which plaque would you like to add to your order?</h2>
                </Grid>
                <Grid item>
                    <Query query={query}>
                        {results => {
                            const { loading, data } = results;
                            if (loading)
                                return (
                                    <div>
                                        <Spinner />
                                    </div>
                                );

                            // const products = data.readPlaqueProducts.edges.map(e => e.node);

                            return (
                                <FlexGrid variant="justify">
                                    {data.readPlaqueProducts.edges.map(e => (
                                        <ImageThumnail
                                            key={'product-' + e.node.ID}
                                            variant="product"
                                            product={e.node}
                                            image={!!e.node.Image ? e.node.Image.AbsoluteLink : ''}
                                            productTitle={e.node.Title}
                                            productCost={e.node.UnitPrice + ' per unit'}
                                            selectedID={!!selectedProduct && selectedProduct.ID}
                                            onSelect={e => this.onProductSelection(e)}
                                        />
                                    ))}
                                </FlexGrid>
                            );
                        }}
                    </Query>
                </Grid>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <div>
                            <Button variant="secondary" onClick={() => onClose()}>
                                Cancel
                            </Button>
                        </div>
                        <Button variant="primary" onClick={() => this.setState({ tabIndex: 0 })}>
                            <BackIcon />
                            Previous Step
                        </Button>

                        {this.canSubmit() && (
                            <Button variant="tertiary" onClick={() => this.setState({ tabIndex: 2 })}>
                                Next Step
                                <NextIcon />
                            </Button>
                        )}
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    onProductSelection(product) {
        const { form } = this.state;

        form.setField({
            Product: product
        });
    }

    renderSummary() {
        const { onClose } = this.props;
        const { form, loading } = this.state;

        const location = form.getField('Location');
        const product = form.getField('Product');

        return (
            <Grid container>
                <Grid item xs={12} sm={6}>
                    <TextField label="Selected Niche" placeholder="Select Niche" name="Niche" form={form} readOnly />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Niche Coordinates"
                        placeholder="Niche Preselected"
                        readOnly
                        name="NicheCoordinates"
                        form={form}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <h4>Plaque Type:</h4>
                    {(!!product && (
                        <ImageThumnail
                            productTitle={product.Title}
                            image={!!product.Image ? product.Image.AbsoluteLink : ''}
                        />
                    )) ||
                        'No product selected.'}
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <h4>Location:</h4>
                    {(!!location && <ContactCard variant="fullwidth" contact={location} />) || 'No location selected.'}
                </Grid>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <div>
                            <Button variant="secondary" onClick={() => onClose()}>
                                Cancel
                            </Button>
                        </div>
                        <Button variant="primary" onClick={() => this.setState({ tabIndex: 1 })}>
                            <BackIcon />
                            Previous Step
                        </Button>
                        <Button
                            variant="confirmation"
                            disabled={!this.isModified() || !this.canSubmit()}
                            onClick={() => this.onSubmit()}
                        >
                            {loading ? <Spinner /> : <SaveIcon />}

                            {this.isModified() ? 'Save Order' : 'Saved'}
                        </Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    onSubmit() {
        const { form } = this.state;
        const { parentForm, onClose } = this.props;

        let order = form.state;

        this.setState({ loading: true });
        const that = this;
        const original = parentForm.getField('ProductOrder');

        if (!order.ID || order.ID === '0') {
            return createPlaqueProductOrderFunc(order).then(
                ({ data }) => {
                    const createdOrder = data.createPlaqueProductOrder;
                    parentForm.setField({
                        ProductOrder: cloneDeep(createdOrder)
                    });

                    that.setState({
                        loading: false,
                        form: createForm(that, cloneDeep(createdOrder)),
                        open: false
                    });
                    onClose();
                    parentForm.save();
                },
                e => that.onGqlError('Failed to create order.', e)
            );
        } else {
            return updatePlaqueProductOrderFunc(order, original).then(
                result => {
                    let updatedInvoice = result.data.updatePlaqueProductOrder;
                    parentForm.setField({
                        ProductOrder: cloneDeep(updatedInvoice)
                    });

                    that.setState({
                        loading: false,
                        form: createForm(that, cloneDeep(updatedInvoice)),
                        open: false
                    });
                    onClose();
                    parentForm.save();
                },
                e => that.onGqlError('Failed to update order.', e)
            );
        }
    }

    isModified() {
        const { form } = this.state;
        const { parentForm } = this.props;

        if (!form) return null;

        let order = form.state;
        const original = parentForm.getField('ProductOrder');

        if (!original) return true;

        const test = diff(order, original, false);

        return !!Object.keys(test).length;
    }

    onGqlError(action, error) {
        const { setSnackbarMessage } = this.props;
        setSnackbarMessage(action + (error ? ' - ' + error.message : ''));
        this.setState({ loading: false });
    }
}

const query = gql`
    ${PlaqueProduct}

    query {
        readPlaqueProducts {
            edges {
                node {
                    ...PlaqueProduct
                }
            }
        }
    }
`;

const createPlaqueProductOrderMutation = gql`
    ${PlaqueProductOrder}
    mutation CreatePlaqueProductOrder($input: CreatePlaqueProductOrderInput!) {
        createPlaqueProductOrder(input: $input) {
            ...PlaqueProductOrder
        }
    }
`;

const updatePlaqueProductOrderMutation = gql`
    ${PlaqueProductOrder}
    mutation UpdatePlaqueProductOrder($input: UpdatePlaqueProductOrderInput!) {
        updatePlaqueProductOrder(input: $input) {
            ...PlaqueProductOrder
        }
    }
`;

const createPlaqueProductOrderFunc = async PlaqueProductOrder => {
    return await getClient().mutate({
        mutation: createPlaqueProductOrderMutation,
        variables: {
            input: trimOrder(PlaqueProductOrder)
        }
    });
};

const updatePlaqueProductOrderFunc = async (PlaqueProductOrder, original) => {
    return await getClient().mutate({
        mutation: updatePlaqueProductOrderMutation,
        variables: {
            input: trimOrder(PlaqueProductOrder, original)
        }
    });
};

const trimOrder = (newOrder, original) => {
    // create a clone with any changes, so data is not disturbed during save or lost on failure.
    let order = original
        ? JSON.parse(JSON.stringify(original ? diff({ ...newOrder }, original) : { ...newOrder }))
        : JSON.parse(JSON.stringify({ ...newOrder }));

    if (!!order.PlaqueOrder && Number(order.PlaqueOrder.ID) > 0) {
        order.PlaqueOrderID = order.PlaqueOrder.ID;
        delete order.PlaqueOrder;
    }

    if (!!order.Product && Number(order.Product.ID) > 0) {
        order.ProductID = order.Product.ID;
        delete order.Product;
    }

    if (!!order.Location && Number(order.Location.ID) > 0) {
        order.LocationID = order.Location.ID;
        delete order.Location;
    }

    if (isRelatedObjectDefined(original)) {
        order.ID = original.ID;
    }

    if (order.PlaqueCombinations) {
        delete order.PlaqueCombinations;
    }

    deleteTypeName(order);

    return { ...order };
};

export default compose(withSnackbarMessage, withStyles({}))(PlaqueOrderModal);
