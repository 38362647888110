import gql from 'graphql-tag';
import Quote from '../../../../fragments/Quote';
import { getClient } from '../../../../utils/apollo';
import { deleteTypeName, diff } from '../../../../utils/objects';

const createQuoteMutation = gql`
    ${Quote}
    mutation CreateQuote($input: CreateCMQuoteInput!) {
        createCMQuote(input: $input) {
            ...Quote
        }
    }
`;

const updateQuoteMutation = gql`
    ${Quote}
    mutation UpdateQuote($input: UpdateCMQuoteInput!) {
        updateCMQuote(input: $input) {
            ...Quote
        }
    }
`;

export const createQuoteFunc = async quote => {
    return await getClient().mutate({
        mutation: createQuoteMutation,
        variables: {
            input: trimQuote(quote)
        }
    });
};

export const updateQuoteFunc = async (quote, original) => {
    return await getClient().mutate({
        mutation: updateQuoteMutation,
        variables: {
            input: trimQuote(quote, original)
        }
    });
};

const trimQuote = (update, original) => {
    // create a clone with any changes, so data is not disturbed during save or lost on failure.
    let quote = JSON.parse(JSON.stringify(original ? diff({ ...update }, original) : { ...update }));
    if (original) {
        quote.ID = original.ID;
    }

    deleteTypeName(quote);

    if (quote.QuoteItems) {
        for (let x = 0; x < quote.QuoteItems.length; x++) {
            let quoteItem = quote.QuoteItems[x];
            delete quoteItem.TotalIncGST;
            deleteTypeName(quoteItem);
        }
    }

    return { ...quote };
};
