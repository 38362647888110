import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import cloneDeep from 'lodash.clonedeep';
import Checkbox from '../form/Checkbox';
import AddIcon from '../icon/AddIcon';
import TextField from '../form/TextField';
import { updateTaskFunc } from './TaskConstants';
import Inline, { inlineAlignment } from '../layout/Inline';
import DeleteIcon from '../icon/DeleteIcon';
import Grid from '../layout/Grid';

class TaskChecklist extends Component {
    state = {
        text: null,
        item: null,
        taskList: []
    };

    static getDerivedStateFromProps({ task }, state) {
        if (!state.item || state.item.ID !== task.ID) {
            const newState = {};
            newState.item = task;
            newState.taskList = task.ChecklistItems;
            return newState;
        }

        return null;
    }

    render() {
        const { text, taskList } = this.state;
        const { canCreate } = this.props;
        return (
            <div className="add-label task-checklist">
                <form onSubmit={this.handleAddSubTask}>
                    <Grid container>
                        {canCreate && <h3 className="spacing">Checklist:</h3>}
                        <Grid item pc>
                            <div className="sub-tasks">
                                {taskList && taskList.map((subTask, index) => this.renderSubTasks(subTask, index))}
                            </div>

                            {canCreate && (
                                <div className="checklist-form-container" style={{ marginTop: 10 }}>
                                    <TextField
                                        value={text}
                                        onChange={e => this.setState({ text: e.target.value })}
                                        placeholder="Enter a new item for the checklist"
                                    />
                                    <IconButton disabled={!text} onClick={() => this.handleAddSubTask()}>
                                        <AddIcon />
                                    </IconButton>
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }

    renderSubTasks(subTask, index) {
        const { canCreate } = this.props;
        return (
            <Fragment key={index}>
                <Inline alignment={inlineAlignment.rightAlignSiblings} center className="checklist-item">
                    <Checkbox
                        label={subTask.Title}
                        name={`taskList[${index}]`}
                        checked={!!subTask.Completed}
                        onChange={e => this.handleTaskState(e, subTask)}
                        title={!!subTask.Completed ? 'Untick to reopen' : 'Tick to complete'}
                    />
                    {canCreate && (
                        <IconButton
                            className="icon"
                            onClick={() => this.handleDeleteItem(subTask.ID)}
                            title={'Remove item'}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )}
                </Inline>
            </Fragment>
        );
    }

    handleTaskState(e, subTask) {
        const { taskList } = this.state;
        const { task } = this.props;
        const clonedTask = cloneDeep(task);

        const newList = taskList.map(item =>
            item.ID === subTask.ID
                ? {
                      ID: item.ID,
                      Title: item.Title,
                      Completed: e.target.checked ? new Date() : null
                  }
                : item
        );
        this.setState({
            taskList: newList
        });

        clonedTask.ChecklistItems = newList;
        updateTaskFunc(clonedTask);
    }

    handleDeleteItem = ID => {
        if (!ID) return;
        const { taskList } = this.state;
        const { task } = this.props;
        const clonedTask = cloneDeep(task);
        clonedTask.ChecklistItems = taskList.filter(e => e.ID !== ID);
        updateTaskFunc(clonedTask).then(({ data }) => this.setState({ taskList: data.updateTask.ChecklistItems }));
    };

    handleAddSubTask = e => {
        if (e) e.preventDefault();
        const { taskList, text } = this.state;
        if (!text) return;
        const { task } = this.props;
        const clonedTask = cloneDeep(task);

        taskList.push({
            ID: null,
            Title: '' + text,
            Completed: null
        });
        this.setState({
            text: ''
        });

        clonedTask.ChecklistItems = taskList;

        updateTaskFunc(clonedTask).then(({ data }) => this.setState({ taskList: data.updateTask.ChecklistItems }));
    };
}

export default withStyles({})(TaskChecklist);
