import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 384 512" {...props}>
        <path
            fill="currentColor"
            d="M216 24.01c0-23.8-31.16-33.11-44.15-13.04C76.55 158.25 200 238.73 200 288c0 22.06-17.94 40-40 40s-40-17.94-40-40V182.13c0-19.39-21.85-30.76-37.73-19.68C30.75 198.38 0 257.28 0 320c0 105.87 86.13 192 192 192s192-86.13 192-192c0-170.29-168-192.85-168-295.99zM192 480c-88.22 0-160-71.78-160-160 0-46.94 20.68-97.75 56-128v96c0 39.7 32.3 72 72 72s72-32.3 72-72c0-65.11-112-128-45.41-248C208 160 352 175.3 352 320c0 88.22-71.78 160-160 160z"        />
    </SvgIcon>
);
