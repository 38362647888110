import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { getUser } from '../../utils/sessions';
import Button from '../form/Button';
import ScrollingWrapper from '../form/ScrollingWrapper';
import SearchWithButton from '../form/SearchWithButton';
import SortByDropdown from '../form/SortByDropdown';
import SearchIcon from '../icon/SearchIcon';
import FlexGrid from '../layout/FlexGrid';
import Inline from '../layout/Inline';
import Separator from '../layout/Separator';
import Spinner from '../loading/Spinner';
import SearchPopout from './Modals/SearchPopout';
import TaskCard from './TaskCard';
import { assembleFilters, readTasksQuery } from './TaskConstants';
import TaskDetail from './TaskDetail';
import Task from '../../fragments/Task';
import { getMyClient } from '../../utils/apollo';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { compose, Query } from 'react-apollo';

class TaskList extends Component {
    state = {
        loading: false,
        showFilterModal: false,
        loadedTask: null,
        loadingRHS: false,
        limit: 10,
        sortField: 'Created',
        sortDirection: 'DESC',
        searchKeyword: null
    };

    refetch = () => null;

    componentDidUpdate(prevProps) {
        const { props } = this;
        if (props.taskIdLoaded !== prevProps.taskIdLoaded) {
            const { loadedTask } = this.state;
            if (!loadedTask || loadedTask.ID !== props.taskIdLoaded) {
                this.loadTask(props.taskIdLoaded);
            }
        }
    }

    render() {
        const { filterBy, taskIdLoaded } = this.props;
        const { searchKeyword, sortField, sortDirection, limit } = this.state;
        const user = getUser();
        const sortBy = [{ field: sortField, direction: sortDirection }];
        const variables = {
            userId: user.ID,
            offset: 0,
            limit,
            contains: searchKeyword,
            sortBy,
            ...assembleFilters(filterBy)
        };
        if (
            filterBy.type === 'member' ||
            filterBy.type === 'team' ||
            filterBy.type === 'Funeral' ||
            filterBy.type === 'EnquiryFM'
        ) {
            delete variables.userId;
        }

        return (
            <Fragment>
                <FlexGrid variant="white" className="full-height">
                    <div className="task-list">
                        <Query
                            client={getMyClient()}
                            query={readTasksQuery}
                            variables={variables}
                            fetchPolicy="cache-and-network"
                            notifyOnNetworkStatusChange
                        >
                            {result => this.renderList(result)}
                        </Query>
                    </div>

                    {this.renderTaskDetail(taskIdLoaded)}
                </FlexGrid>
            </Fragment>
        );
    }

    loadTask(ID) {
        this.setState({ loadingRHS: true }, () =>
            readTaskByID(ID, readOneTaskQuery).then(result => {
                this.setState({ loadedTask: result, loadingRHS: false });
            })
        );
    }

    renderTaskDetail() {
        const { loadedTask, loadingRHS } = this.state;
        const { history, labels } = this.props;

        // if (!loadedTask && !this.task) return null;

        return (
            <TaskDetail
                labels={labels}
                formContext={this}
                task={loadedTask || null}
                loading={loadingRHS}
                onUpdate={task => this.onUpdateTask(task)}
                onClose={() => {
                    this.setState({ loadedTask: null });
                    history.push('/work-queue');
                }}
            />
        );
    }

    onUpdateTask(task) {
        this.setState({ loadedTask: task }, () => {
            !!task &&
                getMyClient().writeQuery({
                    id: task.ID,
                    query: readTasksQuery,
                    data: { readTasks: { ...task, __typename: 'Task' } }
                });
        });
    }

    onSearchSubmit = value => {
        this.setState({ searchKeyword: value });
    };

    renderTaskListHeader(total, networkStatus) {
        const { /*showFilterModal,*/ sortField, sortDirection, showSearchMobile, searchKeyword } = this.state;
        const { filterBy } = this.props;
        return (
            <Fragment>
                <div className="task-list-header">
                    <Inline alignment={1} className="header" center>
                        <h2 className="title wq">{filterBy.title}</h2>
                        <Inline alignment={2} center>
                            <span>
                                {networkStatus > 2 ? total : <Spinner />} result{total === 1 ? '' : 's'}.
                            </span>
                            <div className="task-desktop-search">
                                <SearchWithButton
                                    placeholder="Search for a task"
                                    onSearchSubmit={this.onSearchSubmit}
                                    searchKeyword={searchKeyword}
                                />
                            </div>

                            <Button
                                variant="white icon-button shadow mobile-search"
                                title="Search for a task"
                                size="tiny"
                                onClick={event => this.setState({ showSearchMobile: event.currentTarget })}
                            >
                                <SearchIcon />
                            </Button>

                            <div className="dropdown">
                                <SortByDropdown
                                    allowNone={false}
                                    options={[
                                        { value: 'CreatedDESC', label: 'Newest first' },
                                        { value: 'CreatedASC', label: 'Oldest first' },
                                        { value: 'IDDESC', label: 'Task ID (DESC)' },
                                        { value: 'TitleASC', label: 'Title (A to Z)' }
                                    ]}
                                    sortField={sortField}
                                    sortDirection={sortDirection}
                                    setSortField={this.setSortField}
                                />
                            </div>
                            {/*<Button variant="primary filter-button" size="sm"*/}
                            {/*        onClick={(event) => this.setState({showFilterModal: event.currentTarget})}>*/}
                            {/*    <FilterIcon/>*/}
                            {/*    Filter*/}
                            {/*</Button>*/}
                            {/*<Button variant="primary icon-button shadow mobile-filter" title="Add a new custom task"*/}
                            {/*        size="tiny" onClick={(event) => this.setState({showFilterModal: event.currentTarget})}>*/}
                            {/*    <FilterIcon/>*/}
                            {/*</Button>*/}
                        </Inline>
                    </Inline>

                    <SearchPopout
                        open={!!showSearchMobile}
                        anchorEl={showSearchMobile}
                        onClose={() => this.setState({ showSearchMobile: false })}
                        onSearchSubmit={this.onSearchSubmit}
                        searchKeyword={searchKeyword}
                    />

                    {/*<FilterModal*/}
                    {/*    open={!!showFilterModal}*/}
                    {/*    anchorEl={showFilterModal}*/}
                    {/*    onClose={() => this.setState({ showFilterModal: false })}*/}
                    {/*/>*/}
                </div>
                <Separator />
            </Fragment>
        );
    }

    renderList({ data: { readTasks }, loading, error, fetchMore, networkStatus, refetch }) {
        const { limit, loadedTask } = this.state;
        this.refetch = refetch;
        const filteredTasks = (!!readTasks && readTasks.edges.map(e => e.node)) || [];
        const offset = filteredTasks.length;
        const onLoadMore = () => {
            if (!(readTasks && readTasks.pageInfo.hasNextPage)) {
                return;
            }
            fetchMore({
                variables: { offset },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return previousResult;
                    return {
                        readTasks: {
                            ...fetchMoreResult.readTasks,
                            edges: [...previousResult.readTasks.edges, ...fetchMoreResult.readTasks.edges]
                        }
                    };
                }
            });
        };

        const { history } = this.props;

        let more = '';
        let total = 0;

        if (readTasks && readTasks.pageInfo) {
            total = readTasks.pageInfo.totalCount;
            more = (
                <div style={{ textAlign: 'center', padding: 12 }}>
                    End of {total} result{total === 1 ? '' : 's'}.
                </div>
            );
            if (offset < total) {
                const moreCount = offset + limit < total ? limit : total - offset;
                more = (
                    <div style={{ textAlign: 'center', padding: 12 }}>
                        <Button disabled={loading} onClick={onLoadMore}>
                            {loading ? 'Loading...' : `Load next ${moreCount} result` + (moreCount === 1 ? '' : 's')}
                        </Button>
                    </div>
                );
            }
        }

        return (
            <Fragment>
                {this.renderTaskListHeader(total, networkStatus)}
                <ScrollingWrapper>
                    <div className="list-item-container">
                        {networkStatus === 1 ? (
                            <Spinner />
                        ) : (
                            (!!filteredTasks.length && (
                                <ul>
                                    {filteredTasks.map(task => (
                                        <li
                                            className={`list-item${
                                                loadedTask && loadedTask.ID === task.ID ? ' selected' : ''
                                            }`}
                                            key={'list-item-' + task.ID}
                                        >
                                            <TaskCard
                                                variant="list"
                                                task={task}
                                                onClickAction={e => {
                                                    this.setState({ loadedTask: e });
                                                    history.push('/work-queue/task/' + e.ID);
                                                }}
                                                onStarClick={e => this.handleStarClick(e)}
                                                loading={loading}
                                            />
                                        </li>
                                    ))}
                                </ul>
                            )) ||
                            'There are currently no tasks that match this query.'
                        )}
                        {total > 0 && more}
                    </div>
                </ScrollingWrapper>
            </Fragment>
        );
    }

    setSortField = field => {
        this.setState({
            sortField: field.replace(/(ASC|DESC)/, ''),
            sortDirection: field.replace(/.*(ASC|DESC)/, '$1')
        });
    };

    handleStarClick(e) {
        const { loadedTask } = this.state;
        if (loadedTask && loadedTask.ID === e.ID) {
            this.setState({ loadedTask: e });
        }
    }
}

export const readTaskByID = async (id, readOneQuery) => {
    const asyncQuery = await getMyClient().query({
        query: readOneQuery,
        fetchPolicy: 'network-only',
        variables: { id: id }
    });

    const data = asyncQuery.data;
    const propertyName = Object.keys(data)[0];
    return data[propertyName];
};

const readOneTaskQuery = gql`
    ${Task}
    query readOneTask($id: ID!) {
        readOneTask(ID: $id) {
            ID
            ...TaskFragment
        }
    }
`;

export default compose(withSnackbarMessage, withStyles({}))(TaskList);
