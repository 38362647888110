import React from 'react';
import ImageThumbnail from './ImageThumbnail';
import TextField from '../form/TextField';
import Button from '../form/Button';

export default ({ photo, onClickRemoveAction, onClickOpenImage }) => {
    return (
        <div className="uploaded-image-details">
            <div className="hasClick" onClick={() => onClickOpenImage()}>
                <ImageThumbnail image={photo.File.AbsoluteLink} productTitle={photo.File.Name} />
            </div>

            {!!photo.Caption && <TextField label="Captions" value={photo.Caption} readOnly />}
            {!!photo.Comment && <TextField label="Comments" value={photo.Comment} readOnly />}
            <Button title="Remove image" variant="link-red" onClick={() => onClickRemoveAction()}>
                Remove
            </Button>
        </div>
    );
};
