import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import PerformanceGoals from './components/PerformanceGoals';
import AshesPerformance from './components/AshesPerformance';
import AshesCorrespondence from './components/AshesCorrespondence';

class MetricAshes extends Component {
    state = {};

    render() {
        const { period } = this.props;
        return (
            <Fragment>
                {this.renderAdminPerformanceIndividual(period)}
                {this.renderAdminPerformanceTeam(period)}
                {this.renderCorrespondence(period)}
                {this.renderPerformanceGoals(period)}
            </Fragment>
        );
    }

    renderAdminPerformanceIndividual(period) {
        return <AshesPerformance variant="individual" period={period} />;
    }

    renderAdminPerformanceTeam(period) {
        return <AshesPerformance variant="team" period={period} />;
    }

    renderCorrespondence(period) {
        return <AshesCorrespondence variant="team" period={period} />;
    }
    renderPerformanceGoals(period) {
        return <PerformanceGoals period={period} />;
    }
}

const styles = ({ palette, typography }) => ({
    pageTitle: {
        color: palette.text.primary
    },
    header: {
        fontWeight: typography.fontWeightLight,
        marginLeft: 16,
        paddingLeft: 16,
        borderLeft: '1px solid ' + palette.text.primary,
        color: palette.text.primary
    }
});
export default compose(withRouter, withStyles(styles))(MetricAshes);
