import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import { joinDefined } from '../../utils/strings';

const StaffAutoComplete = ({ category, className, name, clearOnSelect = true, ...props }) => {
    return (
        <GqlAutocomplete
            findById
            className={className}
            readAllQuery={readAllStaff}
            readOneQuery={readOneStaff}
            placeholder="Staff member..."
            labelFieldFunc={e => joinDefined([e.FirstName, e.Surname], ' ')}
            multiple
            name={name}
            clearOnSelect={clearOnSelect}
            {...props}
        />
    );
};

const memberFragment = gql`
    fragment MemberFragment on Member {
        ID
        Email
        Surname
        FirstName
    }
`;

const readOneStaff = gql`
    ${memberFragment}
    query ReadMember($id: ID!) {
        readOneMember(ID: $id) {
            ...MemberFragment
        }
    }
`;

const readAllStaff = gql`
    ${memberFragment}

    query ReadMembers($contains: String) {
        readMembers(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...MemberFragment
                }
            }
        }
    }
`;

export default StaffAutoComplete;
