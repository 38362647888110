import MetricDashboardIcon from '../components/icon/metricDashboard/MetricDashboardIcon';
import { PAGE_ENQUIRIES, PAGE_ORDER_REQUESTS } from '../utils/strings';
import {
    ContactsIcon,
    DashboardIcon,
    EnquiriesIcon,
    FileIcon,
    FireIcon,
    PlaqueSignIcon
} from '../components/IconIndex';

export default [
    {
        path: '/',
        label: 'Dashboard',
        icon: DashboardIcon,
        permission: 'FM_ACCESS_Dashboard',
        items: [
            {
                path: '/metrics',
                permission: 'FM_ACCESS_metrics',
                label: 'Performance Metrics',
                icon: MetricDashboardIcon
            }
        ]
    },
    {
        path: PAGE_ENQUIRIES,
        label: 'Enquiries',
        icon: EnquiriesIcon,
        permission: 'FM_ACCESS_Dashboard',
        items: [
            {
                path: PAGE_ORDER_REQUESTS,
                permission: 'FM_ACCESS_Dashboard',
                label: 'Order Requests',
                icon: FileIcon
            }
        ]
    },
    {
        path: '/cremations/',
        label: 'Cremations',
        icon: FireIcon,
        permission: 'FM_ACCESS_Dashboard',
        items: [
            {
                path: '/cremations/view-all',
                permission: 'FM_ACCESS_Dashboard',
                label: 'View All Records',
                icon: FireIcon
            }
        ]
    },
    {
        path: '/plaques',
        label: 'Plaques',
        icon: PlaqueSignIcon,
        permission: 'FM_ACCESS_Dashboard',
        items: [
            {
                path: '/plaques/view-all',
                permission: 'FM_ACCESS_Dashboard',
                label: 'View All Orders',
                icon: PlaqueSignIcon
            }
        ]
    },
    {
        path: '/contacts',
        label: 'Contacts',
        icon: ContactsIcon,
        permission: 'FM_ACCESS_Dashboard'
    },
    {
        path: '/styles',
        label: 'Style Guide',
        icon: DashboardIcon,
        permission: 'CMS_ACCESS'
    }
];
