import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Hidden } from '@material-ui/core';
import moment from 'moment';
import gql from 'graphql-tag';
import Icon from '@material-ui/core/Icon';
import Select from '../../components/form/Select';
import Grid from '../../components/layout/Grid';
import TextField from '../../components/form/TextField';
import Checkbox from '../../components/form/Checkbox';
import AddressAutoComplete, { createAddressComponentFields } from '../../components/form/AddressAutocomplete';
import UserCircle, { GetInitials } from '../../components/form/UserCircle';
import Label from '../../components/form/Label';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import { adjustTimeString, stringToDate } from '../../utils/date';
import { indexOf } from '../../utils/arrays';
import { joinDefined } from '../../utils/strings';
import { getUser } from '../../utils/sessions';
import { APPOINTMENT_OPTIONS } from './CalendarConstants';
import { getClient } from '../../utils/apollo';
import { getProperty, setPropertyWrapper } from '../../utils/objects';
import ContactLookup from '../../components/contact/ContactLookup';
import Button from '../../components/form/Button';
import RadioGroup from '../../components/form/RadioGroup';
import EnquiryAutoComplete from '../../components/form/EnquiryAutoComplete';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import CremationOrderAutoComplete from '../../components/form/CremationOrderAutoComplete';
import PlaqueOrderAutoComplete from '../../components/form/PlaqueOrderAutoComplete';
import Client from '../../fragments/Client';
import Deceased from '../../fragments/Deceased';

class AppointmentPopover extends Component {
    state = {
        errorVal: [] // for those can't be validated with HTML5
    };

    render() {
        const { appointmentType, classes, form } = this.props;
        const user = getUser();
        const appointment = form.state;
        const assignToMe = user && indexOf(appointment.Members, x => Number(x.ID) === Number(user.ID)) >= 0;
        const requireLocation = form.getField('Type') !== 'Unavailable';

        return (
            <Fragment>
                <Grid container spacing={16}>
                    {(!!appointment.Type && (
                        <Fragment>
                            <Grid item xs sm={6}>
                                <Select
                                    id="AppointmentType"
                                    options={
                                        appointmentType
                                            ? APPOINTMENT_OPTIONS.filter(e => e.value === appointmentType)
                                            : APPOINTMENT_OPTIONS
                                    }
                                    label="Appointment Type"
                                    placeholder="Select an Appointment Type..."
                                    name="Type"
                                    form={form}
                                />
                            </Grid>

                            {appointment.Type === 'AshCollection' && (
                                <Grid item>
                                    <RadioGroup
                                        name="LinkTo"
                                        label="Relates to..."
                                        onChange={e => form.setState({ LinkTo: e.target.value })}
                                        options={[
                                            { label: 'Enquiry', value: 'Enquiry' },
                                            { label: 'Cremation', value: 'Cremation' },
                                            { label: 'Plaque', value: 'Plaque' },
                                            { label: 'None', value: '' }
                                        ]}
                                        form={form}
                                        className="spacing"
                                    />
                                </Grid>
                            )}

                            {(appointment.LinkTo === 'Enquiry' && (
                                <Grid item>
                                    <EnquiryAutoComplete
                                        readAllQuery={readAllEnquiries}
                                        label="Enquiry"
                                        onSelect={(_, enquiry) => {
                                            const newState = assembleReasonFromEnquiry(enquiry, appointment);
                                            form.setState({ ...newState });
                                        }}
                                        name="EnquiryCM"
                                        form={form}
                                    />
                                </Grid>
                            )) ||
                                (appointment.LinkTo === 'Cremation' && (
                                    <Grid item>
                                        <CremationOrderAutoComplete
                                            label="Cremation Order"
                                            onSelect={(_, cremation) => {
                                                const Reason = `Cremation appointment for ${joinDefined(
                                                    [cremation.Deceased.FirstName, cremation.Deceased.Surname],
                                                    ' '
                                                )}`;
                                                const Phone = cremation.Deceased.PrimaryNumber || null;

                                                form.setField({
                                                    EnquiryCM: null,
                                                    Cremation: cremation,
                                                    Plaque: null,
                                                    Reason: Reason,
                                                    Phone: Phone
                                                });

                                                // const LocationResidentialAddress = {
                                                //     ...enquiry.EnquirerResidentialAddress
                                                // };
                                                // this.setObjectProperty(
                                                //     'appointment',
                                                //     'LocationResidentialAddress',
                                                //     LocationResidentialAddress
                                                // );
                                            }}
                                            name="Cremation"
                                            form={form}
                                        />
                                    </Grid>
                                )) ||
                                (appointment.LinkTo === 'Plaque' && (
                                    <Grid item>
                                        <PlaqueOrderAutoComplete
                                            label="Plaque Order"
                                            onSelect={(_, plaque) => {
                                                const Reason = `Plaque appointment for ${joinDefined(
                                                    [plaque.Deceased.FirstName, plaque.Deceased.Surname],
                                                    ' '
                                                )}`;
                                                const Phone = plaque.Deceased.PrimaryNumber || null;

                                                form.setField({
                                                    EnquiryCM: null,
                                                    Cremation: null,
                                                    Plaque: plaque,
                                                    Reason: Reason,
                                                    Phone: Phone
                                                });

                                                // const LocationResidentialAddress = {
                                                //     ...enquiry.EnquirerResidentialAddress
                                                // };
                                                // this.setObjectProperty(
                                                //     'appointment',
                                                //     'LocationResidentialAddress',
                                                //     LocationResidentialAddress
                                                // );
                                            }}
                                            name="Plaque"
                                            form={form}
                                        />
                                    </Grid>
                                ))}

                            <Grid item xs={12}>
                                <TextField
                                    label="Reason"
                                    placeholder="Enter the reason for this appointment..."
                                    name="Reason"
                                    form={form}
                                />
                            </Grid>

                            <Fragment>
                                <Grid item xs={12}>
                                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                                        <Label>{`Location${requireLocation ? ' *' : ''}`}</Label>
                                        <Checkbox
                                            label="Phone call only"
                                            checked={appointment.LocationType === 'Phone'}
                                            onChange={e => {
                                                form.setField({
                                                    LocationType: !!e.target.checked ? 'Phone' : 'Residential',
                                                    TimeTo: adjustTimeString(
                                                        appointment.TimeFrom,
                                                        !!e.target.checked ? 15 : 120
                                                    )
                                                });
                                            }}
                                            name="PhoneOnly"
                                            form={form}
                                        />
                                        <Checkbox
                                            label="Use address book"
                                            checked={appointment.LocationType === 'AddressBook'}
                                            onChange={e =>
                                                form.setField({
                                                    LocationType: !!e.target.checked ? 'AddressBook' : 'Residential'
                                                })
                                            }
                                            name="UseAddressBook"
                                            form={form}
                                        />
                                    </Inline>
                                </Grid>

                                {appointment.LocationType === 'AddressBook' && (
                                    <Grid item xs={12}>
                                        <ContactLookup
                                            form={form}
                                            name="LocationCM"
                                            //label={'Address'}
                                            placeholder="Search for an address book entry..."
                                            // labelFieldFunc={getLabelWithSuburb}
                                            required={requireLocation}
                                            onSelect={(_, addressBook) =>
                                                this.onSelectLocationFromAddressBook('LocationCM', addressBook)
                                            }
                                            onAddNewContact={addressBook =>
                                                this.onSelectLocationFromAddressBook('LocationCM', addressBook)
                                            }
                                            value={appointment.LocationCM}
                                        />
                                    </Grid>
                                )}
                                {appointment.LocationType === 'Residential' && (
                                    <Grid item xs={12}>
                                        <AddressAutoComplete
                                            style={{ width: '100%' }}
                                            placeholder="Search for an address..."
                                            componentFields={createAddressComponentFields('LocationResidentialAddress')}
                                            name="LocationResidentialAddress"
                                            form={form}
                                            required={requireLocation}
                                            allowCustomAddress
                                        />
                                    </Grid>
                                )}
                            </Fragment>

                            <Grid item xs={12}>
                                <TextField
                                    //label="Phone number"
                                    placeholder={'Enter a phone number...'}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Icon className="icon">phone</Icon>
                                            </InputAdornment>
                                        )
                                    }}
                                    required={appointment.LocationType === 'Phone'}
                                    name="Phone"
                                    form={form}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Label>Date & Time</Label>
                                <Inline center>
                                    <TextField
                                        placeholder="From Date"
                                        type="date"
                                        onChange={e => {
                                            form.setField({ Date: e.target.value });
                                        }}
                                        required
                                        name="DateTo"
                                        form={form}
                                    />
                                    <TextField
                                        placeholder="From Time"
                                        disabled={appointment.AllDay}
                                        type="time"
                                        onChange={e => {
                                            const diff = moment(appointment.Date + ' ' + appointment.TimeFrom).diff(
                                                moment(appointment.DateTo + ' ' + appointment.TimeTo),
                                                'minutes'
                                            );
                                            const timeTo = adjustTimeString(e.target.value, -diff);
                                            form.setField({ TimeTo: timeTo });
                                        }}
                                        required
                                        name="TimeFrom"
                                        form={form}
                                    />
                                    <Label>To</Label>
                                    <TextField
                                        placeholder="To Time"
                                        disabled={appointment.AllDay}
                                        type="time"
                                        required
                                        name="TimeTo"
                                        form={form}
                                    />
                                </Inline>
                            </Grid>

                            <Grid item xs={6}>  
                                <Button
                                    target="_blank"
                                    onClick={() => {
                                        const win = window.open(
                                            '/calendar/' + moment(stringToDate(appointment.Date)).format('YYYYMMDD')
                                        );
                                        win.focus();
                                    }}
                                >
                                    Open Calendar
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <Inline>
                                    <Checkbox
                                        label={appointment.Recuring ? 'Until' : 'Recurring?'}
                                        name="Recuring"
                                        form={form}
                                    />
                                    {appointment.Recuring && (
                                        <TextField
                                            placeholder="To Date"
                                            type="date"
                                            required
                                            name="DateTo"
                                            form={form}
                                        />
                                    )}
                                </Inline>
                            </Grid>

                            <Grid item xs={12}>
                                <Checkbox
                                    label="This appointment is for me"
                                    checked={assignToMe}
                                    onChange={e => this.toggleAssignToMe(e.target.checked)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <StaffAutoComplete
                                    label={'Assign Staff (multiple)'}
                                    selectProps={{ multiple: true }}
                                    onSelect={(_, user) => this.onAssignStaff(user)}
                                    error={this.state.errorVal['Members'] ? this.state.errorVal['Members'] : ''}
                                />
                            </Grid>

                            {appointment.Members.length > 0 && (
                                <Grid item xs={12}>
                                    <div className={classes.memberIcons}>
                                        {appointment.Members.map((obj, i) => (
                                            <UserCircle
                                                key={i}
                                                user={joinDefined([obj.FirstName, obj.Surname], ' ')}
                                                abbreviation={GetInitials(obj)}
                                                onDelete={() => this.onUnassignStaff(i)}
                                            />
                                        ))}
                                    </div>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    rows={6}
                                    label="Comments"
                                    placeholder="Enter comments here..."
                                    multiline
                                    name="Comment"
                                    form={form}
                                />
                            </Grid>
                        </Fragment>
                    )) || (
                        <Fragment>
                            <Grid item xs={12}>
                                <RadioGroup
                                    row={false}
                                    required
                                    id="AppointmentType"
                                    options={APPOINTMENT_OPTIONS}
                                    name="Type"
                                    form={form}
                                />
                            </Grid>
                            <Hidden smDown>
                                <Grid item xs={12}>
                                    <p>Choose a type of Appointment to continue...</p>
                                </Grid>
                            </Hidden>
                        </Fragment>
                    )}
                </Grid>
            </Fragment>
        );
    }

    onSelectLocationFromAddressBook(propertyName, addressBook) {
        const { form } = this.props;
        delete addressBook['__typename'];

        if (!!addressBook) {
            form.setState({ [propertyName]: addressBook });
        }
    }

    onAssignStaff(user) {
        const { form } = this.props;
        const Members = form.getField('Members');
        Members.push(user);
        form.setState({ Members });
    }

    onUnassignStaff(index) {
        const { form } = this.props;
        const Members = form.getField('Members');
        Members.splice(index, 1);
        form.setState({ Members });
    }

    toggleAssignToMe(assignToMe) {
        const user = getUser();
        const { form } = this.props;
        const Members = form.getField('Members');
        const myIndex = indexOf(Members, x => Number(x.ID) === Number(user.ID));

        if (assignToMe && myIndex < 0) {
            Members.push(user);
        } else if (!assignToMe && myIndex >= 0) {
            Members.splice(myIndex, 1);
        }

        form.setState({ Members });
    }

    onClose =() => {
        const { onClose } = this.props;
        if (onClose) onClose();
    }

    onError(msg, obj, state) {
        const errorMessage = msg + (obj && obj.message ? '. Reason: ' + obj.message : '');
        this.props.setSnackbarMessage(errorMessage);
        console.error(errorMessage, obj);
        if (state) this.setState(state);
    }

    getState = property => {
        return getProperty(this.state, property);
    };

    setState = newState => {
        setPropertyWrapper(this.state, newState);
        this.forceUpdate();
    };

    handleDeleteAppointment = eventID => {
        if (!window.confirm('Are you sure you want to delete this appointment?')) return null;
        this.doDelete(eventID, deleteAppointment);
    };

    handleDeleteCalendarEvent = eventID => {
        if (!window.confirm('Are you sure you want to delete this event?')) return null;
        this.doDelete(eventID, deleteCalendarEvent);
    };

    doDelete = async (eventID, mutation) => {
        const me = this;
        const deletion = await getClient()
            .mutate({ mutation: mutation, variables: { IDs: [eventID] } })
            .then(data => {
                const { onMutate } = me.props;
                if (onMutate) onMutate();
                me.onClose();
            });
        return !!deletion;
    };
}

export const assembleReasonFromEnquiry = (enquiry, appointment) => {
    if (!enquiry || !appointment) return null;
    const type = appointment.Type;
    const client = enquiry.ClientSameAsEnquirer
        ? enquiry.EnquiryByBusiness
            ? enquiry.Business
            : enquiry.Enquirer
        : enquiry.Client;
    const enquirer = (enquiry.EnquiryByBusiness ? enquiry.Business : enquiry.Enquirer) || {};
    const Reason =
        ((type === 'Appointment' && enquiry.EnquiryType) || type) +
        ` appointment ${
            !!Number(enquiry.Deceased.ID)
                ? 'for ' + joinDefined([enquiry.Deceased.FirstName, enquiry.Deceased.Surname], ' ')
                : ''
        } ${((!enquirer || enquirer.RelationshipToDeceased !== 'self') &&
            !!client &&
            joinDefined(
                [
                    '- with ',
                    client.Contact || client.Name,
                    client.FirstName,
                    client.Surname,
                    enquirer && enquirer.RelationshipToDeceased && '(' + enquirer.RelationshipToDeceased + ')'
                ],
                ' '
            )) ||
            ''}`;
    const Phone = (!!client && (client.PrimaryNumber || client.Phone)) || null;
    return { EnquiryCM: enquiry, Cremation: null, Plaque: null, Reason: Reason, Phone: Phone, Type: type };
};

const deleteAppointment = gql`
    mutation deleteAppointment($IDs: [ID]!) {
        deleteAppointments(ids: $IDs)
    }
`;

const deleteCalendarEvent = gql`
    mutation deleteCalendarEvent($IDs: [ID]!) {
        deleteCalendarEvents(ids: $IDs)
    }
`;

const readAllEnquiries = gql`
    fragment EnquiryFragment on CMEnquiry {
        ID
        LegacyKey
        EnquiryType
        Deceased {
            ...Deceased
        }
        Enquirer {
            ...Client
        }
        ClientSameAsEnquirer
        EnquiryByBusiness
        Business {
            ID
            Name
            Contact
        }
        Client {
            ...Client
        }
    }
    ${Client}
    ${Deceased}

    query ReadEnquiries($contains: String) {
        readCMEnquiries(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...EnquiryFragment
                }
            }
        }
    }
`;

export default withStyles({})(AppointmentPopover);
