import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M5.49 142.993c0-6.44 3.40-9.84 9.84-9.847h17.365V79.798c0-4.83.17-9.84.17-9.847h-.359s-1.79 3.93-3.93 5.729l-1.61 1.432c-4.65 4.29-9.48 4.11-13.78-.537l-4.65-5.013c-4.47-4.65-4.29-9.48.35-13.962l22.2-20.767C34.85 33.43 38.25 32 43.26 32h10.205c6.44 0 9.84 3.4 9.84 9.846v91.301h17.544c6.44 0 9.84 3.40 9.84 9.847v7.162c0 6.44-3.40 9.84-9.84 9.846H15.341c-6.44 0-9.84-3.40-9.84-9.846v-7.163zm-1.77 163.45c0-46.48 54.40-54.75 54.40-72.89 0-8.80-7.04-12.50-13.55-12.50-5.63 0-10.38 2.99-13.38 6.16-4.40 4.22-8.98 5.98-13.73 2.112l-6.86-5.458c-4.93-3.87-6.16-8.27-2.46-13.029C14.28 202.74 26.43 192 47.38 192c20.59 0 42.96 11.79 42.96 38.55 0 39.26-50.88 46.83-52.46 63.031h44.72c6.33 0 9.68 3.34 9.68 9.684v7.044c0 6.33-3.34 9.68-9.68 9.683H14.108c-5.98 0-10.38-3.34-10.38-9.683v-3.875zm2.87 149.436l4.75-7.747c3.34-5.45 7.74-5.81 13.20-2.46 4.75 2.64 10.91 5.28 17.95 5.28 11.44 0 17.95-5.63 17.95-12.50 0-9.85-9.86-13.90-23.41-13.909h-.704c-5.63 0-8.45-1.76-10.91-6.691l-.88-1.761c-2.11-4.04-1.23-8.45 2.28-12.5l11.44-13.91c5.98-7.21 10.91-11.79 10.91-11.797v-.352s-4.04.88-11.97.88H16.805c-6.33 0-9.68-3.34-9.68-9.683v-7.043c0-6.33 3.34-9.68 9.68-9.683h60.039c6.33 0 9.68 3.34 9.68 9.507v2.817c0 4.93-1.23 8.27-4.40 11.973l-21.12 24.298c18.66 4.04 30.45 18.83 30.45 36.445C91.45 458.52 76.31 480 44.8 480c-17.07 0-29.05-5.98-36.09-10.73-4.93-3.52-5.28-8.27-2.11-13.382zM512 108V84a6 6 0 0 0-6-6H134a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h372a6 6 0 0 0 6-6zm0 160v-24a6 6 0 0 0-6-6H134a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h372a6 6 0 0 0 6-6zm0 160v-24a6 6 0 0 0-6-6H134a6 6 0 0 0-6 6v24a6 6 0 0 0 6 6h372a6 6 0 0 0 6-6z"
        />
    </SvgIcon>
);
