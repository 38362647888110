export const metricColour = percentage => {
    if (percentage > 1) percentage = percentage / 100;
    if (percentage === null) return '';
    return percentage <= 0.2
        ? 'red'
        : percentage <= 0.4
        ? 'orange'
        : percentage <= 0.6
        ? 'yellow'
        : percentage <= 0.8
        ? 'green'
        : '';
};
