import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Button from '../../components/form/Button';
import Checkbox from '../../components/form/Checkbox';
import SendProofModal from './Modals/SendProofModal';
import Inline from '../../components/layout/Inline';
import UploadField, { getFileName } from '../../components/form/UploadField';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import PlaqueOrderModal from './Modals/PlaqueOrderModal';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import Select from '../../components/form/Select';
import FlexGrid from '../../components/layout/FlexGrid';
import ImageDetailsCard from '../../components/cards/ImageDetailsCard';
import AlertModal from '../../components/modal/AlertModal';
import ViewImageModal from './Modals/ViewImageModal';
import EditProofModal from './Modals/EditProofModal';
import { deleteTypeName } from '../../utils/objects';
import { niceDateTimeFromString } from '../../utils/date';
import { joinDefined } from '../../utils/strings';
import { MAX_PLAQUE_LINES } from './PlaqueConstants';
import { TickIcon } from '../../components/IconIndex';

class PlaqueDetailsTab extends Component {
    state = {
        showSendProofModal: false,
        showPlaqueOrderModal: false,
        showDeleteImageModal: false,
        showImageDetailsModal: false,
        viewingPhotoIndex: null,
        deletingPhoto: null,
        proofEmailEditModal: {
            open: false,
            proofEmailId: null
        }
    };

    render() {
        const {
            showSendProofModal,
            showPlaqueOrderModal,
            showDeleteImageModal,
            showImageDetailsModal,
            proofEmailEditModal,
            viewingPhotoIndex
        } = this.state;
        const { form } = this.props;

        const photos = form.getField('PlaquePhotos');

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <SendProofModal
                    open={showSendProofModal}
                    plaqueOrderID={form.getField('ID')}
                    plaqueOrderClient={form.state.Client}
                    onClose={() => this.handleCloseSendProofModal()}
                    parentForm={form}
                />

                <EditProofModal
                    open={!!proofEmailEditModal.open}
                    plaqueOrderID={form.getField('ID')}
                    plaqueOrderClient={form.state.Client}
                    plaqueOrderProofEmails={form.state.ProofEmails}
                    emailId={proofEmailEditModal.proofEmailId}
                    onClose={() => this.handleCloseEditProofModal()}
                    parentForm={form}
                />

                <PlaqueOrderModal
                    open={showPlaqueOrderModal}
                    onClose={() => this.handleClosePlaqueOrderModal()}
                    parentForm={form}
                    orderLocation={form.getField('PlaqueLocation')}
                    productOrder={form.getField('ProductOrder')}
                />

                <ViewImageModal
                    open={showImageDetailsModal}
                    onClose={() => this.handleCloseImageDetailsModal()}
                    onSave={photo => this.handleSaveImageDetailsModal(photo)}
                    photo={photos[viewingPhotoIndex]}
                />

                <AlertModal
                    variant="warning"
                    open={showDeleteImageModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeletePlaqueImage()}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteImageModal()}
                    onClose={() => this.handleCloseDeleteImageModal()}
                >
                    <p>You're about to delete an image.</p>
                    <p>Are you sure?</p>
                </AlertModal>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderPlaqueOrder()}
                {this.renderBorderDetails()}
                {this.renderPlaqueWording()}
            </Fragment>
        );
    }

    renderPlaqueOrder() {
        const { form } = this.props;
        const product = form.getField('ProductOrder.Product');
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Plaque Order</h4>
                </Grid>

                {!!form.getField('ProductOrder') && !!form.getField('ProductOrder').ID ? (
                    <Fragment>
                        <Grid item>
                            <h5>{!!product && product.Title}</h5>
                        </Grid>

                        <Grid item>
                            <Inline>
                                <Button variant="primary" onClick={() => this.handleShowPlaqueOrderModal()}>
                                    Edit Order...
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={() =>
                                        form.setField({
                                            ProductOrderID: 0,
                                            ProductOrder: {
                                                Niche: null,
                                                NicheCoordinates: null,
                                                Product: null,
                                                Location: null
                                            }
                                        })
                                    }
                                >
                                    Remove Order
                                </Button>
                            </Inline>
                        </Grid>
                    </Fragment>
                ) : (
                    <Grid item>
                        <Inline>
                            <Button variant="primary" onClick={() => this.handleShowPlaqueOrderModal()}>
                                Add Order...
                            </Button>
                        </Inline>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderBorderDetails() {
        const { form } = this.props;
        const combination = this.getPlaqueCombination();

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Border Details</h4>
                </Grid>

                <Grid item sm={12} md={6}>
                    <Inline>
                        <Checkbox label="Flower border" form={form} name="FlowerBorder" />
                        <Checkbox label="Raised border" form={form} name="RaisedBorder" />
                    </Inline>
                </Grid>

                {!!combination && !!combination.BorderTypes && combination.BorderTypes.length > 0 && (
                    <Grid item sm={12} md={6}>
                        <Select
                            label="Border Details"
                            placeholder="Select a border type"
                            name="BorderDetails"
                            form={form}
                            options={combination.BorderTypes.map(obj => {
                                return {
                                    label: obj.Title,
                                    value: obj.Title
                                };
                            })}
                        />
                    </Grid>
                )}

                {!!combination && !!combination.Optionals && (
                    <Grid item sm={12} md={6}>
                        <Select
                            label="Optionals"
                            placeholder="Select an optional"
                            options={combination.Optionals.map(obj => {
                                return {
                                    label: obj.Title,
                                    value: obj.Title
                                };
                            })}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    renderPlaqueWording() {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Plaque Wording</h4>
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        placeholder="Enter the plaque wording here..."
                        multiline
                        name="PlaqueWordingLines"
                        form={form}
                        onChange={this.updateAdditionalLines}
                        onBlur={e => {
                            form.setField({ PlaqueWordingLines: e.target.value ? e.target.value.trim() : null });
                            this.updateAdditionalLines();
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Number of lines included" value={MAX_PLAQUE_LINES} readOnly />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Additional lines"
                        type="Number"
                        name="PlaqueWordingAdditionalLines"
                        form={form}
                        emptyValue="0"
                        readOnly
                    />
                </Grid>
            </Grid>
        );
    }

    renderRightColumn() {
        return (
            <Fragment>
                {this.renderPlaquePhoto()}
                {this.renderEmailProofToClient()}
            </Fragment>
        );
    }

    renderPlaquePhoto() {
        const { form } = this.props;
        const photos = form.getField('PlaquePhotos');

        return (
            <Grid container bucket>
                <Grid item>
                    <Checkbox label="Match plaque to photo?" name="MatchPlaqueToPhoto" form={form} />
                </Grid>
                {!!form.getField('MatchPlaqueToPhoto') && (
                    <Fragment>
                        <Grid item>
                            <h4>Upload an image</h4>
                        </Grid>

                        <Grid item>
                            <UploadField
                                folderPath={`/documents/plaque/${form.getField('ID')}`}
                                onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, photos)}
                            />
                        </Grid>

                        {!!photos && !!photos.length && (
                            <Fragment>
                                <Grid item>
                                    <h4>The following images have been uploaded</h4>
                                </Grid>

                                <Grid item>
                                    {/*Image cards will only appear after images have been added*/}
                                    <FlexGrid variant="justify">
                                        {photos.map((photo, index) => (
                                            <ImageDetailsCard
                                                key={'image-' + photo.ID}
                                                photo={photo}
                                                onClickOpenImage={() => this.handleShowImageDetailsModal(index)}
                                                onClickRemoveAction={() => this.handleShowDeleteImageModal(photo)}
                                            />
                                        ))}
                                    </FlexGrid>
                                </Grid>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </Grid>
        );
    }

    getPlaqueCombination() {
        const { form } = this.props;
        const combinationID = form.getField('PlaqueCombination.ID');
        const combinations = form.getField('PlaqueLocation.PlaqueCombinations');

        if (combinationID && combinations && combinations.length) {
            return combinations.find(obj => obj.ID === combinationID);
        }
        return null;
    }

    handleUploadAttachment(uploadFile, photos) {
        const { form } = this.props;
        photos = photos || [];

        photos.push({
            File: {
                ID: uploadFile.ID,
                AbsoluteLink: uploadFile.AbsoluteLink,
                Name: getFileName(uploadFile.FileName)
            }
        });

        form.setField({
            PlaquePhotos: photos
        });
        form.save();
    }

    renderEmailProofToClient() {
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Email a proof copy to client</h4>
                </Grid>

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleShowSendProofModal()}>
                        Send Proof Email...
                    </Button>
                </Grid>

                {this.renderProofEmailsHistory()}
            </Grid>
        );
    }

    renderProofEmailsHistory() {
        const { form } = this.props;
        let proofEmails = form.getField('ProofEmails');
        const applicant = form.getField('Client');

        proofEmails = proofEmails.filter(obj => !obj.EmailSupplier);

        if (!proofEmails || !proofEmails.length) {
            return null;
        }

        return (
            <Fragment>
                <Grid item>
                    <h4>Proof Emails History</h4>
                </Grid>

                <Grid item>
                    <Table>
                        <HeaderRow pad>
                            <Cell colSpan={1}>ID</Cell>
                            <Cell colSpan={1}>Sent</Cell>
                            <Cell colSpan={1}>Send To</Cell>
                            <Cell colSpan={1}>Approved</Cell>
                        </HeaderRow>
                        {proofEmails.map(proofEmail => (
                            <Row pad key={'email-' + proofEmail.ID}>
                                <Cell dataLabel="Email No.">
                                    <Button
                                        variant="link-orange"
                                        onClick={() => this.handleShowEditProofModal(proofEmail.ID)}
                                    >
                                        #{proofEmail.ID}
                                    </Button>
                                </Cell>
                                <Cell dataLabel="Sent">{niceDateTimeFromString(proofEmail.EmailSent) || 'Not sent'}</Cell>
                                <Cell dataLabel="Send to">
                                    {joinDefined(
                                        [
                                            proofEmail.EmailApplicant && !!applicant ? applicant.Email : null,
                                            proofEmail.EmailOther ? proofEmail.OtherEmailAddress : null
                                        ],
                                        ', '
                                    )}
                                </Cell>
                                <Cell dataLabel="Approved">
                                    {!!proofEmail.Approved && <TickIcon />}
                                </Cell>
                            </Row>
                        ))}
                    </Table>
                </Grid>
            </Fragment>
        );
    }

    updateAdditionalLines = () => {
        const { form } = this.props;
        const maxLines = /* form.getField('???') || */ MAX_PLAQUE_LINES;
        const text = form.getField('PlaqueWordingLines') || '';
        const lines = text.split(/\r|\r\n|\n/);
        form.setField({ PlaqueWordingAdditionalLines: Math.max(0, lines.length - maxLines) });
    };

    handleShowSendProofModal() {
        this.setState({ showSendProofModal: true });
    }

    handleCloseSendProofModal() {
        this.setState({ showSendProofModal: false });
    }

    handleShowEditProofModal(id) {
        this.setState({
            proofEmailEditModal: {
                open: true,
                proofEmailId: id
            }
        });
    }

    handleCloseEditProofModal() {
        this.setState({
            proofEmailEditModal: {
                open: false,
                proofEmail: null
            }
        });
    }

    handleShowPlaqueOrderModal() {
        this.setState({ showPlaqueOrderModal: true });
    }

    handleClosePlaqueOrderModal() {
        this.setState({ showPlaqueOrderModal: false });
    }

    handleShowDeleteImageModal(photo) {
        this.setState({
            showDeleteImageModal: true,
            deletingPhoto: photo
        });
    }

    handleDeletePlaqueImage() {
        const { deletingPhoto } = this.state;
        const { form } = this.props;

        let PlaquePhotos = form.getField('PlaquePhotos');
        PlaquePhotos = PlaquePhotos.filter(obj => deletingPhoto.ID !== obj.ID);

        form.setField({ PlaquePhotos });
        form.save();

        this.setState({ showDeleteImageModal: false });
    }

    handleCloseDeleteImageModal() {
        this.setState({ showDeleteImageModal: false });
    }

    handleShowImageDetailsModal(index) {
        this.setState({
            showImageDetailsModal: true,
            viewingPhotoIndex: index
        });
    }

    handleSaveImageDetailsModal(photo) {
        const { form } = this.props;
        const PlaquePhotos = form.getField('PlaquePhotos');

        const { viewingPhotoIndex } = this.state;

        PlaquePhotos[viewingPhotoIndex] = photo;

        form.setField({ PlaquePhotos }, true);

        this.handleCloseImageDetailsModal();
    }

    handleCloseImageDetailsModal() {
        this.setState({ showImageDetailsModal: false });
    }
}

export default {
    id: 'PlaqueDetails',
    label: 'Plaque Details',
    component: withStyles({})(PlaqueDetailsTab),
    fragment: PlaqueOrder,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {
        if (!!state.ProductOrder && Number(state.ProductOrder.ID) > 0) {
            saveData.ProductOrder = state.ProductOrder;
            // saveData.ProductOrder.Niche = null;
            // saveData.ProductOrder.NicheCoordinates = null;
            // saveData.ProductOrder.Product = null;
            // saveData.ProductOrder.Location = null;
            if (saveData.ProductOrder.Product && saveData.ProductOrder.Product.Image) {
                saveData.ProductOrder.Product.ImageID = state.ProductOrder.Product.Image.ID;
                delete saveData.ProductOrder.Product.Image;
            }
            if (saveData.ProductOrder.Location) {
                saveData.ProductOrder.LocationID = state.ProductOrder.Location.ID;
                delete saveData.ProductOrder.Location;
            }

            deleteTypeName(saveData.ProductOrder);
        }

        const localPlaquePhotos = [];
        if (saveData && saveData.PlaquePhotos) {
            state.PlaquePhotos.forEach(function(plaquePhoto) {
                const photo = {};
                if (!!plaquePhoto.ID) {
                    // existing item, just include ID to retain
                    photo.ID = plaquePhoto.ID;
                    photo.Caption = plaquePhoto.Caption;
                    photo.Comment = plaquePhoto.Comment;
                } else {
                    // new item, omit ID to create
                    photo.FileID = plaquePhoto.File.ID;
                }
                localPlaquePhotos.push(photo);
            });
            saveData.PlaquePhotos = localPlaquePhotos;
        }
    }
};
