import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import construction from '../assets/under-construction-section.svg';

function UnderConstruction(props) {
	const { variant, children, forced = false } = props;

	if (!forced && window.location.hostname === 'localhost') {
		return children || null;
	}

	const style = children
		? variant === 'feature'
			? 'feature'
			: 'section'
		: null;
	return style ? innerConstruction(style, children) : underConstruction();
}

function innerConstruction(style, children) {
	return (
		<div
			style={{ position: 'relative', width: '100%', overflow: 'hidden' }}
		>
			{underConstruction(style)}
			{children}
		</div>
	);
}

function underConstruction(style) {
	return (
		<div className={`disabled-overlay ${style}`}>
			{(style === 'feature' && (
				<div className="message">
					<div className="dev-image">
						<img
							src={construction}
							alt="logo"
							className="dev-image"
						/>
					</div>
					<div className="text">
						<h1>Coming Soon!</h1>
						<h2>We are currently working on this feature!</h2>
					</div>
				</div>
			)) || (
				<div className="message">
					<h1>Coming Soon!</h1>
					<h2>We are currently working on this feature!</h2>
				</div>
			)}
		</div>
	);
}

export default withStyles({})(UnderConstruction);
