import React from 'react';
import {withStyles} from '@material-ui/core/styles';

export default withStyles({})(({ label, total, data}) => {

    return (
        <div className="chart-container">
            <div className="horizontal-number">{total}</div>
            <div className="bar">
                <div className="stackedBar">
                    {data &&
                    data.map((d) => {
                        return (
                            <div
                                className={`bar-fill ${d.color || ''}`}
                                style={{ height: `${d.percent}%` }}
                            >
                                <p className="percent-text">{d.percent}</p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="label-container">
                <label className="chart-label">{label}</label>
            </div>
        </div>
    );
});
