import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import gql from 'graphql-tag';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import CremationOrderFragment from '../../fragments/CremationOrder';
import Table, { Cell, Row } from '../../components/table/Table';
import Task from '../../fragments/Task';
import { getMyClient } from '../../utils/apollo';
import { getUser } from '../../utils/sessions';
import Spinner from '../../components/loading/Spinner';
import Button from '../../components/form/Button';
import { getTaskStatus } from '../../components/workQueue/TaskConstants';

class SummaryTab extends Component {
    state = {
        outstandingTasks: null,
        completedTasks: null,
        loading: false
    };

    componentDidMount() {
        const that = this;
        const user = getUser();

        this.setState({ loading: true });

        this.loadCremationTasks(user.ID).then(
            data => {
                that.setState({
                    outstandingTasks: cloneDeep(data.OutstandingTasks).edges.map(e => e.node),
                    completedTasks: cloneDeep(data.CompletedTasks).edges.map(e => e.node),
                    loading: false
                });
            },
            error => {
                that.onGqlError('Failed to load tasks', error);
                that.setState({ loading: false });
            }
        );
    }

    render() {
        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>
            </Fragment>
        );
    }

    renderLeftColumn() {
        return <Fragment>{this.renderOutstandingTask()}</Fragment>;
    }

    renderOutstandingTask() {
        const { outstandingTasks, loading } = this.state;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Outstanding Tasks</h4>
                </Grid>

                <Grid item>
                    {loading ? (
                        <Spinner />
                    ) : !!outstandingTasks && !!outstandingTasks.length ? (
                        <Table
                            columns={[{ label: 'Task' }, { label: 'Status' }, { label: 'Details' }]}
                            columnspans={[1, 1, 1]}
                        >
                            {outstandingTasks.map(task => {
                                return this.getTaskRow(task, 'outstanding-task');
                            })}
                        </Table>
                    ) : (
                        'There are not outstanding tasks available for this cremation.'
                    )}
                </Grid>
            </Grid>
        );
    }

    renderRightColumn() {
        return <Fragment>{this.renderCompletedTask()}</Fragment>;
    }

    renderCompletedTask() {
        const { completedTasks, loading } = this.state;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Completed Tasks</h4>
                </Grid>

                <Grid item>
                    {loading ? (
                        <Spinner />
                    ) : !!completedTasks && !!completedTasks.length ? (
                        <Table
                            columns={[{ label: 'Task' }, { label: 'Status' }, { label: 'Details' }]}
                            columnspans={[1, 1, 1]}
                        >
                            {completedTasks.map(task => {
                                return this.getTaskRow(task, 'complete-task');
                            })}
                        </Table>
                    ) : (
                        'There are not completed tasks available for this cremation.'
                    )}
                </Grid>
            </Grid>
        );
    }

    getTaskRow = (task, type) => {
        const { form } = this.props;
        return (
            <Row pad key={type + '-' + task.ID}>
                <Cell dataLabel="Task">{task.Title}</Cell>
                <Cell dataLabel="Status">
                    <p className="task-status urgent">
                        {' '}
                        <span className="status-label">{getTaskStatus(task)}</span>
                    </p>
                </Cell>
                <Cell dataLabel="Details">
                    {/*Link will open respective task*/}
                    <Button
                        variant="orange-link"
                        onClick={() => {
                            const newPath = `/work-queue/cremation/${form.getField('ID')}/${task.ID}`;
                            let win = window.open(newPath, '_blank');
                            win.focus();
                        }}
                    >
                        View Details
                    </Button>
                </Cell>
            </Row>
        );
    };

    loadCremationTasks = async userID => {
        const { form } = this.props;
        const cremationId = form.getField('ID');

        const asyncQuery = await getMyClient().query({
            fetchPolicy: 'network-only',
            query: gql`
                ${Task}
                query ReadCremationTasks($cremationId: Int!) {
                    OutstandingTasks: readTasks(isComplete: false, cremationId: $cremationId) {
                        edges {
                            node {
                                ...TaskFragment
                            }
                        }
                    }
                    CompletedTasks: readTasks(isComplete: true, cremationId: $cremationId) {
                        edges {
                            node {
                                ...TaskFragment
                            }
                        }
                    }
                }
            `,
            variables: {
                cremationId: Number(cremationId)
            }
        });
        return asyncQuery && asyncQuery.data ? asyncQuery.data : false;
    };
}

export default {
    id: 'Summary',
    label: 'Summary',
    component: withStyles({})(SummaryTab),
    fragment: CremationOrderFragment,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {}
};
