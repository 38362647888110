import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import Typography from '../../components/form/Typography';
import AlertBar from '../../components/form/AlertBar';
import Tooltip from '../../components/form/Tooltip';
import Stepper from '../../components/stepper/Stepper';
import Inline from '../../components/layout/Inline';
import LinearProgressIndicator from '../../components/loading/LinearProgressIndicator';
import Spinner from '../../components/loading/Spinner';
import Button from '../../components/form/Button';
import UnderConstruction from '../../components/UnderConstruction';

class AlertStyling extends Component {
    render() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h3">Progress</Typography>
                    <Separator />
                    {this.renderLoaders()}
                    <br />
                    <Separator />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="h3">Messages</Typography>
                    <Separator />
                    {this.renderAlerts()}
                </Grid>
                <Grid container item xs={12} md={8}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">Tooltips</Typography>
                        <Separator />
                        {this.renderTooltips()}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3">Stepper</Typography>
                        <Separator />
                        {this.renderStepper()}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Construction</Typography>
                        <Separator />
                        <Typography variant="h6">
                            {'<UnderConstruction />'} without a variant - will take over the whole page
                        </Typography>
                        {this.renderConstruction()}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderStepper() {
        return (
            <Grid container>
                <Grid item>
                    <div className="stepper">
                        {/*Active step*/}
                        <Stepper
                            variant="active"
                            stepNumber="3"
                            ashDetails="Active Step"
                            noteDetails="Entered by Kylie Minogue, June 3rd 2017 at 9:23am."
                        />

                        {/*Previous step*/}
                        <Stepper
                            stepNumber="2"
                            ashDetails="Previous Step"
                            noteDetails="Entered by Kylie Minogue, June 3rd 2017 at 9:22am."
                        />

                        {/*Previous step*/}
                        <Stepper
                            stepNumber="1"
                            ashDetails="First Step"
                            noteDetails="Entered by Kylie Minogue, June 3rd 2017 at 9:21am."
                        />
                    </div>
                </Grid>
            </Grid>
        );
    }

    renderAlerts() {
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h6">Success</Typography>
                    <pre>variant = success</pre>
                    <AlertBar variant="success">This is a successful message</AlertBar>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Info</Typography>
                    <pre>variant = info</pre>
                    <AlertBar variant="info">This is an information message</AlertBar>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Warning</Typography>
                    <pre>variant = warning</pre>
                    <AlertBar variant="warning">This is a warning message</AlertBar>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Error</Typography>
                    <pre>variant = error</pre>
                    <AlertBar variant="error">This is an error message</AlertBar>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Attention</Typography>
                    <pre>variant = attention</pre>
                    <AlertBar variant="attention">This is an attention message</AlertBar>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Fire</Typography>
                    <pre>variant = fire</pre>
                    <AlertBar variant="fire">
                        We're on fire! We've updated the system with a hotfix. Refresh to see he changes, by pressing{' '}
                        <b>Ctrl + R</b>
                    </AlertBar>
                </Grid>
            </Grid>
        );
    }

    renderTooltips() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography variant="h6">Regular</Typography>
                    <pre>default variant</pre>

                    <Tooltip title="Tooltip: your message here" open placement="right">
                        <span>&nbsp;</span>
                    </Tooltip>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Regular</Typography>
                    <pre>variant = error</pre>

                    <Tooltip variant="error" title="Tooltip: your message here" open placement="right">
                        <span>&nbsp;</span>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    }

    renderLoaders() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography variant="h6">Linear Progress Indicators</Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <Inline>
                                <LinearProgressIndicator progress={78} />
                            </Inline>
                            loaded progress=78 (percent)
                        </Grid>
                        <Grid item xs={6} style={{ verticalAlign: 'bottom' }}>
                            <Inline>
                                <LinearProgressIndicator />
                            </Inline>
                            loading
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Spinner</Typography>
                    <Inline>
                        <Spinner size="xs" />
                        <Spinner size="sm" />
                        <Spinner size="md" />
                        <Spinner size="lg" />
                        <Button variant="confirmation">
                            <Spinner />
                            &nbsp; Action
                        </Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderConstruction() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">variant=section</Typography>
                    <UnderConstruction variant="section" forced>
                        <Typography variant="h6">This is a new area.</Typography>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Inline>
                                    <LinearProgressIndicator progress={78} />
                                </Inline>
                                loaded progress=78 (percent)
                            </Grid>
                            <Grid item xs={12}>
                                <Inline>
                                    <LinearProgressIndicator />
                                </Inline>
                                loading
                            </Grid>
                        </Grid>
                        <Typography variant="p">This is the end of the new area.</Typography>
                        <Typography variant="p">Actually, this is the end of the new area.</Typography>
                    </UnderConstruction>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6">variant=feature</Typography>
                    <UnderConstruction variant="feature" forced>
                        <Typography variant="h6">This is a new area.</Typography>
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Inline>
                                    <LinearProgressIndicator progress={78} />
                                </Inline>
                                loaded progress=78 (percent)
                            </Grid>
                        </Grid>
                    </UnderConstruction>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles({})(AlertStyling);
