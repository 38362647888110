import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import debounce from 'lodash.debounce';
import Downshift from 'downshift';
import SearchIcon from '../icon/SearchIcon';
import Spinner from '../loading/Spinner';
import SearchQuery from '../query/SearchQuery';
import HeaderSearchResults from './HeaderSearchResults';
import { withSearchTerm } from '../../context/SearchTerm';
import UnderConstruction from '../UnderConstruction';

class HeaderSearchInput extends Component {
    state = {
        focused: false,
        isOnSearchPage: false,
        isPrearrangements: false
    };

    componentWillMount() {
        const { history, location } = this.props;
        this.unlisten = history.listen(debounce(this.onUpdatePage, 100));
        this.onUpdatePage(location);
    }

    componentDidUpdate(prevProps) {
        const { open } = this.props;
        if (!prevProps.open && open && this.input) this.input.focus();
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        const { searchTerm } = this.props;
        const { focused, isOnSearchPage } = this.state;
        return (
            <SearchQuery term={searchTerm} skip={!focused || isOnSearchPage}>
                {(term, resultsTerm, results) => (
                    <Downshift>
                        {downshiftData => this.renderContainer(term, resultsTerm, results, downshiftData)}
                    </Downshift>
                )}
            </SearchQuery>
        );
    }

    renderContainer(term, resultsTerm, results, downshiftData) {
        const { className } = this.props;
        const { focused, isOnSearchPage } = this.state;
        const elevated = isOnSearchPage || focused;
        return (
            <div className={`header-search ${className || ''}`}>
                <UnderConstruction variant="feature">
                    <Paper
                        className={`input-wrapper ${elevated ? 'input-wrapper--elevated' : ''}`}
                        elevation={elevated ? 1 : 0}
                    >
                        {this.renderInput(downshiftData, !isOnSearchPage && term && term !== resultsTerm)}
                    </Paper>
                    {focused && (
                        <HeaderSearchResults
                            term={term}
                            resultsTerm={resultsTerm}
                            results={results}
                            downshiftData={downshiftData}
                            onItemSelected={this.onItemSelected}
                        />
                    )}
                </UnderConstruction>
            </div>
        );
    }

    renderInput(downshiftData, loading) {
        const { open, searchTerm, setSearchTerm } = this.props;
        const otherProps = {
            inputProps: {
                ref: this.onInputRef,
                tabIndex: open ? 0 : -1,
                onChange: e => setSearchTerm(e.target.value),
                value: searchTerm,
                onFocus: this.onInputFocus,
                onBlur: this.onInputBlur
            }
        };

        return (
            <form onSubmit={this.onSubmit}>
                <TextField
                    fullWidth
                    {...otherProps}
                    InputProps={{
                        disableUnderline: true,
                        startAdornment: (
                            <InputAdornment position="start">
                                {loading ? <Spinner /> : <SearchIcon className="icon" />}
                            </InputAdornment>
                        ),
                        ...downshiftData.getInputProps({
                            placeholder: 'Type in ID or name to search existing records'
                        })
                    }}
                />
            </form>
        );
    }

    onUpdatePage = location => {
        const { isOnSearchPage, isPrearrangements } = this.state;
        const currentIsOnSearchPage = location.pathname.substr(0, 7) === '/search';
        if (isOnSearchPage !== currentIsOnSearchPage) {
            this.setState({ isOnSearchPage: currentIsOnSearchPage });
        }

        const currentIsPrearrangements = location.pathname.includes('prearrangements');
        if (isPrearrangements !== currentIsPrearrangements) {
            this.setState({ isPrearrangements: currentIsPrearrangements });
        }
    };

    onInputRef = input => {
        this.input = input;
    };

    onInputFocus = () => {
        this.setState({ focused: true });
    };

    onInputBlur = () => {
        this.setState({ focused: false });
    };

    onItemSelected = () => {
        this.input.blur();
        const { setSearchTerm } = this.props;
        setSearchTerm('');
    };

    onSubmit = e => {
        e.preventDefault();
        const { isOnSearchPage } = this.state;
        const { history } = this.props;
        if (!isOnSearchPage) {
            history.push('/search');
        }
    };
}

// prettier-ignore
export default compose(
    withSearchTerm,
    withRouter,
	withStyles({})
)(HeaderSearchInput);
