import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React, { Component } from 'react';
import Grid from '../../../components/layout/Grid';
import Spinner from '../../../components/loading/Spinner';
import Modal from '../../../components/modal/Modal';
import Table, { Cell, HeaderRow, Row } from '../../../components/table/Table';
import DocumentTemplate from '../../../fragments/DocumentTemplate';
import { getClient, getServiceURLHostname } from '../../../utils/apollo';
import IconButton from '@material-ui/core/IconButton';
import { DownloadIcon } from '../../../components/IconIndex';
import AlertBar from '../../../components/form/AlertBar';

class TemplateSelectionModal extends Component {
    state = {
        documents: null
    };

    componentWillMount() {
        const that = this;

        getClient()
            .query({
                query: query
            })
            .then(
                ({ data }) => {
                    if (!data || !data.readCMDocumentTemplates) {
                        that.onError('failed to get document template data. data empty', data);
                        return;
                    }

                    if (data.readCMDocumentTemplates.length > 0) {
                        that.setState({
                            documents: data.readCMDocumentTemplates.filter(e => !!e.Template && !!e.Template.AbsoluteLink)
                        });
                    }
                },
                error => that.onError('failed to get document template data', error)
            );
    }

    render() {
        const { open, onClose, cremationID, plaqueID, classes } = this.props;
        const { documents } = this.state;
        const hasDocs = documents !== null;
        const noDocs = (
            <AlertBar variant="warning">
                No documents were found.
                <br />
                <small>Check for Document Templates with files for this office.</small>
            </AlertBar>
        );

        return (
            <Modal open={open} variant="simple" onClose={onClose} canClickOut={false}>
                <Grid container>
                    <Grid item>
                        <h1> Generate Merge Documents </h1>
                        <p> All documents will be in MSWord (.docx) format </p>
                    </Grid>

                    <Grid item>
                        {!documents ? (
                            <Spinner />
                        ) : hasDocs ? (
                            <Table>
                                <HeaderRow pad>
                                    <Cell>Document Name</Cell>
                                    <Cell>Download</Cell>
                                </HeaderRow>

                                {documents.length > 0 ? (
                                    documents
                                        .sort((a, b) => (a.Title < b.Title ? -1 : 1))
                                        .map(obj => {
                                            const link = cremationID
                                                ? '/merge-doc-cremation/download/' + obj.ID + '/' + cremationID
                                                : '/merge-doc-plaque/download/' + obj.ID + '/' + plaqueID;
                                            return (
                                                !!parseInt(obj.Template.ID) && (
                                                    <Row pad key={obj.ID}>
                                                        <Cell>
                                                            <strong>{obj.Title}</strong>
                                                            <br />
                                                            <small>{obj.Template.Name} ({obj.Template.Size})</small>
                                                            <iframe
                                                                title={'downloader' + obj.ID}
                                                                name={'downloader' + obj.ID}
                                                                className={classes.iframe}
                                                            />
                                                        </Cell>
                                                        <Cell pad={false} className={'table-center'}>
                                                            <IconButton
                                                                href={'//' + getServiceURLHostname() + link}
                                                                target={'downloader' + obj.ID}
                                                            >
                                                                <DownloadIcon />
                                                            </IconButton>

                                                        </Cell>
                                                    </Row>
                                                )
                                            );
                                        })
                                ) : (
                                    <Row pad>
                                        <Cell colSpan={2}>
                                            {noDocs}
                                        </Cell>
                                    </Row>
                                )}
                            </Table>
                        ) : (
                            { noDocs }
                        )}
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    onError(msg, obj) {
        const errorMessage = msg + (obj && obj.message ? '. Reason: ' + obj.message : '');
        console.error(errorMessage, obj);
    }
}

const query = gql`
    ${DocumentTemplate}

    query {
        readCMDocumentTemplates {
            ...DocumentTemplate
        }
    }
`;

const styles = () => ({
    iframe: {
        position: 'absolute',
        background: 'transparent',
        border: 0,
        marginTop: -8,
        pointerEvents: 'none'
    }
});

export default withStyles(styles)(TemplateSelectionModal);
