import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Line, Pie} from 'react-chartjs-2';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import Graph from '../../components/metricDashboard/Graph';
import BarChart from '../../components/metricDashboard/BarChart';
import FlexGrid from '../../components/layout/FlexGrid';
import RatingBar from '../../components/metricDashboard/RatingBar';
import DataHighlight from '../../components/metricDashboard/DataHighlight';
import StarRating from '../../components/metricDashboard/StarRating';
import Stars from '../../components/metricDashboard/Stars';
import ScorePill from '../../components/metricDashboard/ScorePill';
import MetricContent from '../../components/metricDashboard/MetricContent';
import Stats from '../../components/metricDashboard/Stats';
import TaskCard from '../../components/workQueue/TaskCard';
import createForm from '../../utils/createForm';
import MetricCard from '../../components/metricDashboard/MetricCard';
import StackedBarChart from "../../components/metricDashboard/StackedBarChart";

class MetricDashboardStyling extends Component {
    render() {
        return (
            <Grid container spacing={32}>
                <Separator />
                {this.renderGraphs()}
                {this.renderRatingBar()}
                {this.renderDataHighlight()}
                {this.renderStarRating()}
                {this.renderScorePills()}
                {this.renderMetricContent()}
                {this.renderStats()}
                {this.renderPieChart()}
                {this.renderLineChart()}
                {this.renderStackedBar()}
                {this.renderGoals()}
            </Grid>
        );
    }

    renderGraphs() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <h3>Graph Square</h3>
                        <p>
                            tag = Graph
                            <br />
                            tag = BarChart (wrapped inside Graph tag)
                        </p>
                        <p>BarChart displayed inside of &#60;MetricCard&#62;</p>
                        <h4>Colour Variants:</h4>
                        <ul>
                            <li>Default = denim-primary</li>
                            <li>orange</li>
                            <li>green</li>
                            <li>yellow</li>
                            <li>red</li>
                        </ul>
                    </Grid>
                    <Grid item>
                        <FlexGrid variant="justify">
                            <div className="flex-item col-3">
                                <MetricCard variant="orange">
                                    <Graph variant="two" title="variant = two">
                                        <BarChart label="Enquires Taken" goal="10" stats="20%" variant="yellow" />
                                        <BarChart label="Avg Conversion Rate" goal="12" stats="35%" variant="yellow" />
                                    </Graph>
                                </MetricCard>
                            </div>
                            <div className="flex-item col-3">
                                <MetricCard variant="green">
                                    <Graph variant="three" title="variant = three">
                                        <BarChart label="Phone" stats="60%" variant="orange" />
                                        <BarChart label="Pre-need" stats="87%" variant="green" />
                                        <BarChart label="At-need" stats="12.5%" variant="red" />
                                    </Graph>
                                </MetricCard>
                            </div>
                            <div className="flex-item col-3">
                                <MetricCard>
                                    <Graph variant="four" title="variant = four">
                                        <BarChart label="Enquires Taken" goal="Goal: 80%" stats="87.7%" />
                                        <BarChart label="Avg Conversion Rate" goal="Goal: 80%" stats="50%" />
                                        <BarChart label="Enquires Taken" goal="Goal: 80%" stats="9.8%" />
                                        <BarChart label="Avg Conversion Rate" goal="Goal: 80%" stats="100%" />
                                    </Graph>
                                </MetricCard>
                            </div>
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderRatingBar() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <h2> Rating Bar</h2>
                        <Separator />
                        <RatingBar rate="55%" />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderDataHighlight() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Data Highlight</h3>
                        <p>tag = DataHighlight</p>
                        <p>DataHighlight displayed inside of &#60;MetricCard&#62;</p>
                        <h4>Colour Variants:</h4>
                        <ul>
                            <li>Default = denim-primary</li>
                            <li>orange</li>
                            <li>green</li>
                            <li>yellow</li>
                            <li>red</li>
                        </ul>
                    </Grid>
                    <Grid item>
                        <FlexGrid>
                            <div className="flex-item col-3">
                                <MetricCard>
                                    <DataHighlight topText="You've taken" data="10 enquiries" bottomText="this year" />
                                </MetricCard>
                            </div>
                            <div className="flex-item col-3">
                                <MetricCard variant="red">
                                    <DataHighlight
                                        variant="red"
                                        topText="You've taken"
                                        data="10 enquiries"
                                        bottomText="this year"
                                    />
                                </MetricCard>
                            </div>
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderStarRating() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Star Rating</h3>
                        <p>
                            tag = StarRating <br />
                            tag = Stars (wrapped inside StarRating)
                        </p>
                        <p>variant is for the coloured background</p>
                    </Grid>
                    <Grid item>
                        <FlexGrid>
                            <div className="flex-item col-3">
                                <StarRating data="5" variant="yellow">
                                    <Stars percent="60%" />
                                    <Stars percent="40%" />
                                </StarRating>
                            </div>

                            <div className="flex-item col-3">
                                <StarRating data="9.5">
                                    <Stars />
                                    <Stars percent="90%" />
                                </StarRating>
                            </div>
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderScorePills() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Score Pills</h3>
                        <p>tag = ScorePill</p>
                    </Grid>
                    <Grid item>
                        <FlexGrid>
                            <div className="flex-item col-3">
                                <ScorePill title="Individual" data="20.5" variant="red" />
                            </div>

                            <div className="flex-item col-3">
                                <ScorePill title="Company" data="55" />
                            </div>
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderMetricContent() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Metric Content</h3>
                        <p>tag = MetricContent</p>
                    </Grid>
                    <Grid item>
                        <FlexGrid>
                            <div className="flex-item col-3">
                                <MetricContent heading="Uho spaghettio!">
                                    <p>Looks like you're a little behind where you wanted to be this quarter.</p>
                                    <p>
                                        But don't worry, it's not too late to turn these tinned tomatoes into a tasty
                                        Bolognese sauce! Keep at it!
                                    </p>
                                </MetricContent>
                            </div>
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderStats() {
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Stats Component</h3>
                        <p>tag = Stats</p>
                        <h4>Variants:</h4>
                        <ul>
                            <li>Default = no variant</li>
                            <li>Stat increased = up</li>
                            <li>Stat decreased = down</li>
                        </ul>
                    </Grid>
                    <Grid item>
                        <FlexGrid>
                            <MetricCard>
                                <FlexGrid>
                                    <Stats heading="Arrangement Meetings" stats="120" />
                                    <Stats heading="Records Created" variant="up" stats="79%" />
                                    <Stats heading="Conversion Rate" variant="down" stats="50%" />
                                    <Stats heading="Arrangement Meetings" stats="120" />
                                    <Stats heading="Records Created" variant="up" stats="79%" />
                                    <Stats heading="Conversion Rate" variant="down" stats="50%" />
                                    <Stats heading="Arrangement Meetings" stats="120" />
                                    <Stats heading="Records Created" variant="up" stats="79%" />
                                    <Stats heading="Conversion Rate" variant="down" stats="50%" />
                                </FlexGrid>
                            </MetricCard>
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderPieChart() {
        const data = {
            labels: [
                "Number of L1's sent",
                "Number of L2's sent",
                "Number of L3's sent",
                "Number of L4's sent",
                "Number of L5's sent"
            ],
            datasets: [
                {
                    data: [300, 50, 100, 33, 6],
                    backgroundColor: ['#7d9cc4', '#44AF69', '#F7DD60', '#f89e53', '#D14F57'],
                    hoverBackgroundColor: [],
                    borderWidth: 3,
                    hoverBorderColor: '#ffffff'
                }
            ]
        };

        const legendOpts = {
            position: 'right',
            labels: {
                fontColor: '#000000'
            }
        };

        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Pie Chart</h3>
                        <p>tag = Pie</p>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Pie data={data} legend={legendOpts} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderLineChart() {
        const data = {
            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
            datasets: [
                {
                    label: 'My First dataset',
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: '#1F9BC9',
                    borderColor: '#1F9BC9',
                    borderWidth: 6,
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#1F9BC9',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#FFFFFF',
                    pointHoverBorderColor: '#1F9BC9',
                    pointHoverBorderWidth: 2,
                    pointRadius: 2,
                    pointHitRadius: 15,
                    data: [65, 59, 80, 81, 56, 55, 40]
                }
            ]
        };

        const legendOpts = {
            display: false
        };

        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Line Graph</h3>
                        <p>tag = Line</p>
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Line data={data} legend={legendOpts} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderStackedBar() {
        const data = [{color:'', percent:50}, {color:'orange', percent:31}, {color:'green', percent:19}];
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Stacked Bar Chart</h3>
                        <p>tag = StackedBarChart</p>
                    </Grid>
                    <Grid item>
                        <FlexGrid variant="justify">
                            <div className="flex-item col-3">
                                <MetricCard variant="orange">
                                    <Graph variant="two" title="variant = two">
                                        <StackedBarChart data={data} label="Stacked Bar"/>
                                        <BarChart label="Avg Conversion Rate" goal="12" stats="35%" variant="yellow" />
                                    </Graph>
                                </MetricCard>
                            </div>
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderGoals() {
        const form = createForm(this, {
            Tasks: [
                {
                    ID: 1,
                    DueDate: '15.06.18',
                    Title: 'Re-Book Musician',
                    Description: 'Bob Smith has declined your booking request',
                    Action: 'Please book another musician',
                    Status: 'Due!',
                    AssignedMembers: [
                        {
                            ID: 3,
                            FirstName: 'Jessica',
                            Surname: 'Williams'
                        }
                    ],
                    Comments: [
                        {
                            CreatedDate: '15.06.18',
                            Message: 'Do the thing',
                            Member: {
                                ID: 1,
                                FirstName: 'Sarah',
                                Surname: 'Smith'
                            }
                        },
                        {
                            CreatedDate: '15.07.18',
                            Message: 'Do other thing',
                            Member: {
                                ID: 2,
                                FirstName: 'Jane',
                                Surname: 'Smith'
                            }
                        }
                    ]
                },
                {
                    ID: 2,
                    DueDate: '15.06.18',
                    Title: 'Re-Book Musician',
                    Description: 'Bob Smith has declined your booking request',
                    Action: 'Please book another musician',
                    Status: 'Due!',
                    AssignedMembers: [
                        {
                            ID: 3,
                            FirstName: 'Jessica',
                            Surname: 'Williams'
                        }
                    ],
                    Comments: [
                        {
                            CreatedDate: '15.06.18',
                            Message: 'Do the thing',
                            Member: {
                                ID: 1,
                                FirstName: 'Sarah',
                                Surname: 'Smith'
                            }
                        },
                        {
                            CreatedDate: '15.07.18',
                            Message: 'Do other thing',
                            Member: {
                                ID: 2,
                                FirstName: 'Jane',
                                Surname: 'Smith'
                            }
                        }
                    ]
                },
                {
                    ID: 3,
                    DueDate: '15.06.18',
                    Title: 'Re-Book Musician',
                    Description: 'Bob Smith has declined your booking request',
                    Action: 'Please book another musician',
                    Status: 'Due!',
                    AssignedMembers: [
                        {
                            ID: 3,
                            FirstName: 'Jessica',
                            Surname: 'Williams'
                        }
                    ],
                    Comments: [
                        {
                            CreatedDate: '15.06.18',
                            Message: 'Do the thing',
                            Member: {
                                ID: 1,
                                FirstName: 'Sarah',
                                Surname: 'Smith'
                            }
                        },
                        {
                            CreatedDate: '15.07.18',
                            Message: 'Do other thing',
                            Member: {
                                ID: 2,
                                FirstName: 'Jane',
                                Surname: 'Smith'
                            }
                        }
                    ]
                },
                {
                    ID: 4,
                    DueDate: '15.06.18',
                    Title: 'Re-Book Musician',
                    Description: 'Bob Smith has declined your booking request',
                    Action: 'Please book another musician',
                    Status: 'Due!',
                    AssignedMembers: [
                        {
                            ID: 3,
                            FirstName: 'Jessica',
                            Surname: 'Williams'
                        }
                    ],
                    Comments: [
                        {
                            CreatedDate: '15.06.18',
                            Message: 'Do the thing',
                            Member: {
                                ID: 1,
                                FirstName: 'Sarah',
                                Surname: 'Smith'
                            }
                        },
                        {
                            CreatedDate: '15.07.18',
                            Message: 'Do other thing',
                            Member: {
                                ID: 2,
                                FirstName: 'Jane',
                                Surname: 'Smith'
                            }
                        }
                    ]
                },
                {
                    ID: 5,
                    DueDate: '15.06.18',
                    Title: 'Re-Book Musician',
                    Description: 'Bob Smith has declined your booking request',
                    Action: 'Please book another musician',
                    Status: 'Due!',
                    AssignedMembers: [
                        {
                            ID: 3,
                            FirstName: 'Jessica',
                            Surname: 'Williams'
                        }
                    ],
                    Comments: [
                        {
                            CreatedDate: '15.06.18',
                            Message: 'Do the thing',
                            Member: {
                                ID: 1,
                                FirstName: 'Sarah',
                                Surname: 'Smith'
                            }
                        },
                        {
                            CreatedDate: '15.07.18',
                            Message: 'Do other thing',
                            Member: {
                                ID: 2,
                                FirstName: 'Jane',
                                Surname: 'Smith'
                            }
                        }
                    ]
                }
            ]
        });
        return (
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Separator />
                        <h3>Goals Card</h3>
                        <p>tag = TaskCard</p>
                        <p>variant = goals</p>
                        <p>TaskCard must be wrapped in FlexGrid component</p>
                    </Grid>
                    <Grid item>
                        <FlexGrid variant="justify">
                            {form.state.Tasks.map((task, index) => (
                                <TaskCard variant="goal" key={task.ID} task={task} propertyPath={`Tasks[${index}]`} />
                            ))}
                        </FlexGrid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles({})(MetricDashboardStyling);
