import React, { Fragment, PureComponent } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import cx from 'classnames';
import { isNullOrUndefined } from '../../utils/objects';
import { joinDefined } from '../../utils/strings';
import Tooltip from '../form/Tooltip';

class DrawerListItem extends PureComponent {
    state = {
        expanded: false
    };

    componentDidMount() {
        const { activeItem, item } = this.props;
        if (isActive(item, activeItem)) this.setState({ expanded: true });
        else if (item.expanded) this.setState({ expanded: true });
    }

    render() {
        const { item, activeItem, nested, hidden, minimized, renderItem, renderGroup } = this.props;

        let renderFunc;
        if (item.items && renderGroup) renderFunc = renderGroup;
        else if (!item.items && renderItem) renderFunc = renderItem;

        const classNames = `drawer-list-item ${joinDefined(
            [
                isActive(item, activeItem) ? 'drawer-list-item--active' : null,
                nested ? 'drawer-list-item--nested' : null,
                minimized ? 'drawer-list-item--minimized' : null
            ],
            ' '
        )}`;

        return (
            <Fragment>
                <ListItem
                    button
                    onClick={this.onClick}
                    className={classNames}
                    aria-hidden={hidden}
                    tabIndex={hidden ? -1 : 0}
                >
                    {item.icon && this.renderIcon()}
                    {renderFunc ? renderFunc(item) : this.renderText()}
                    {this.renderAction()}
                </ListItem>
                {this.renderChildItems()}
            </Fragment>
        );
    }

    renderIcon() {
        const { item, activeItem } = this.props;
        return (
            <Tooltip title={item.label}>
                <ListItemIcon>
                    <item.icon className={cx('icon', { 'icon--active': item === activeItem })} />
                </ListItemIcon>
            </Tooltip>
        );
    }

    renderText() {
        const { item, activeItem } = this.props;
        return (
            <ListItemText
                primary={item.label}
                classes={{
                    primary: cx('text', item.newTab && 'targetNewPage', {
                        'text--active': item === activeItem
                    })
                }}
            />
        );
    }

    renderAction() {
        const { item, minimized } = this.props;
        if (minimized || !item.items) return;
        const { expanded } = this.state;
        return (
            <IconButton onClick={this.onExpand}>{expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
        );
    }

    renderChildItems() {
        const { item, minimized } = this.props;
        if (minimized || !item.items) return;
        const { expanded } = this.state;
        return (
            <Collapse in={expanded}>
                <List component="div" disablePadding>
                    {item.items.map(this.renderChildItem)}
                </List>
            </Collapse>
        );
    }

    renderChildItem = item => {
        const { minimized, activeItem, hidden, renderItem, renderGroup } = this.props;
        const { expanded } = this.state;
        return (
            <DrawerListItemConnected
                key={item.id || item.label}
                item={item}
                nested
                minimized={minimized}
                activeItem={activeItem}
                hidden={hidden || !expanded}
                renderItem={renderItem}
                renderGroup={renderGroup}
            />
        );
    };

    onClick = () => {
        const { history, item } = this.props;
        const { expanded } = this.state;

        if (item.path) {
            if (item.newTab) {
                let win = window.open(item.path, '_blank');
                win.focus();
            } else {
                history.push(item.path);
            }
        } else if (item.items) {
            this.setState({ expanded: !expanded });
        }
    };

    onExpand = e => {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        const { expanded } = this.state;
        this.setState({ expanded: !expanded });
    };
}

const isActive = (self, activeItem) => {
    if (self === activeItem) return true;

    if (isNullOrUndefined(self.items)) return false;

    return !isNullOrUndefined(self.items.find(x => x === activeItem));
};

const DrawerListItemConnected = withRouter(withStyles({})(DrawerListItem));
export default DrawerListItemConnected;
