import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 576 512" {...props}>
        <path
            fill="currentColor"
            d="M288,288c-9.5,0-18.4,2.2-26.5,5.9l-97.1-119.9c-5.5-6.9-15.6-7.9-22.5-2.4c-6.9,5.6-7.9,15.6-2.4,22.5l97.1,120
	c-7.9,10.6-12.7,23.7-12.7,37.9c0,35.4,28.6,64,64,64s64-28.6,64-64S323.4,288,288,288z M288,384c-17.7,0-32-14.3-32-32
	s14.3-32,32-32s32,14.3,32,32S305.7,384,288,384z M288,32C128.9,32,0,160.9,0,320c0,52.8,14.3,102.3,39.1,144.8
	c5.6,9.6,16.3,15.2,27.4,15.2h443c11.1,0,21.8-5.6,27.4-15.2c24.8-42.5,39.1-92,39.1-144.8C576,160.9,447.1,32,288,32z M509.5,448
	l-442.8,0.7C44,409.8,32,365.3,32,320C32,178.8,146.8,64,288,64s256,114.8,256,256C544,365.3,532,409.8,509.5,448z"
        />
    </SvgIcon>
);
