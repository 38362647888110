import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import Button from '../../../components/form/Button';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import { getRequiredDocumentTypes } from '../CremationConstants';
import { niceDateTimeFromString } from '../../../utils/date';
import { joinDefined } from '../../../utils/strings';

class RejectNotesModal extends Component {
    render() {
        const { open, onClose } = this.props;
        const documentTypes = getRequiredDocumentTypes();
        const typeName = documentTypes.find(doc => open.fieldName === doc.Name);
        if (!open) return null;
        return (
            <Modal open={!!open} variant="primary" onClose={onClose} title="Note History" subtitle="Rejected Document">
                <Grid container>
                    <Grid item>
                        <h4 className="notes-readonly">Note history for {typeName && typeName.description}.</h4>
                    </Grid>
                    <Grid item>
                        <div className="notes-readonly">
                            <h5>
                                {niceDateTimeFromString(open.Actioned)},{' '}
                                {(open.ActionedBy &&
                                    joinDefined([open.ActionedBy.FirstName, open.ActionedBy.Surname], ' ')) ||
                                    'someone'}{' '}
                                rejected this document:
                            </h5>
                            <p style={{ whiteSpace: 'pre-wrap' }}>{open.Notes}</p>
                        </div>
                    </Grid>
                </Grid>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <div />
                        <Button onClick={() => onClose()} variant="modal-action modal-action--cancel">
                            Close
                        </Button>
                    </Inline>
                </Grid>
            </Modal>
        );
    }
}

export default withStyles({})(RejectNotesModal);
