import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M96 96H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h48c26.51 0 48-21.49 48-48V144c0-26.51-21.49-48-48-48zm16 368c0 8.822-7.178 16-16 16H48c-8.822 0-16-7.178-16-16V144c0-8.822 7.178-16 16-16h48c8.822 0 16 7.178 16 16v320zm208-180v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12zm0 96v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12zm96-96v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12zm0 96v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12zm64-217.258V88.569a39.996 39.996 0 0 0-11.716-28.284l-48.569-48.569A39.996 39.996 0 0 0 391.431 0H200c-22.091 0-40 17.909-40 40v424c0 26.51 21.49 48 48 48h256c26.51 0 48-21.49 48-48V208c0-20.898-13.359-38.667-32-45.258zM192 40c0-4.411 3.589-8 8-8h184v40c0 13.203 10.797 24 24 24h40v96H192V40zm288 424c0 8.822-7.178 16-16 16H208c-8.822 0-16-7.178-16-16V224h288v240z"
        />
    </SvgIcon>
);
