import gql from "graphql-tag";

export default gql`
	fragment Address on Address {
		ID
		Name
		AddressLine1
		AddressLine2
		Suburb
		Postcode
		State
		Country
	}
`;