import gql from 'graphql-tag';
import CremationManagerAddressBook from './AddressBook';
import PlaqueCombinationLocation from './PlaqueCombinationLocation';
import PlaqueProductOrder from './PlaqueProductOrder';

export default gql`
    fragment PlaqueCombination on PlaqueCombination {
        ID
        LocationType
        PlaqueSize
        AttachmentMethod
        PlaquePosition
        PlaqueMaterial
        WallPosition
        PlaqueWordingAdditionalLines
        PlaqueWordingLines
        VaseType
        AshesSelection
        Motif
        FlowerBorder
        RaisedBorder
        BorderType
        PlacementByCouncil
        QuoteTemplatesAllowed
        Location {
            ...PlaqueCombinationLocation
        }
        ProductOrder {
            ...PlaqueProductOrder
        }
        PlaqueSupplier {
            ...CremationManagerAddressBook
        }
        WallPositions {
            ID
            Title
        }
        MaterialOptions {
            ID
            Title
        }
        Optionals {
            ID
            Title
        }
        AttachmentMethods {
            ID
            Title
        }
        BorderTypes {
            ID
            Title
        }
    }
    ${CremationManagerAddressBook}
    ${PlaqueCombinationLocation}
    ${PlaqueProductOrder}
`;
