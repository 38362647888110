import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import moment from 'moment';
import Query from 'react-apollo/Query';
import Grid from '../../../components/layout/Grid';
import Stats from '../../../components/metrics/Stats';
import BarChart from '../../../components/metrics/BarChart';
import Graph from '../../../components/metrics/Graph';
import MetricCard from '../../../components/metrics/MetricCard';
import { getMyClient } from '../../../utils/apollo';
import { metricColour } from '../../../components/metrics/metricConstatnts';
import DataHighlight from '../../../components/metrics/DataHighlight';

const styles = () => ({
    gridThis: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: '7em 10em 10em',
        justifyItems: 'center',
        '& > div.stats-content': {
            width: '100%'
        },
        '& h3': {
            justifySelf: 'left',
            alignSelf: 'center',
            gridArea: '1 / 1 / 1 / 3'
        }
    },
    rightCol: {
        gridArea: '1 / 3 / 4 / 3'
    }
});

export default withStyles(styles)(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period, variant } = props;
    const { type } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myProductAssociations', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myAshesAppointments', variables: JSON.stringify({ ...period, variant }) }
        ]
    };

    const query = gql`
        query ScoreArrangerMyPerformance($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const titles = variant === 'team' ? 'Your team have' : "You've";
    const { classes } = props;

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);

        const { loadMetrics } = (!error && data) || null;

        const associationsMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myProductAssociations')) || null;
        const associationsResult = (associationsMetric && JSON.parse(associationsMetric.result)) || {};
        const conductedCount = Number(associationsResult.Results && associationsResult.Results.Cremations);
        const convertedPlaquesCount = Number(
            associationsResult.Results && associationsResult.Results.AssociatedPlaques
        );
        const convertedProductsCount = Number(
            associationsResult.Results && associationsResult.Results.AssociatedProducts
        );
        const convertedCount = convertedPlaquesCount + convertedProductsCount;

        const conductedColour = metricColour(1);
        const convertedColour = metricColour(Math.min(1, conductedCount / convertedCount));
        const convertedProductsColour = metricColour(Math.min(1, convertedCount / convertedProductsCount));
        const convertedPlaquesColour = metricColour(Math.min(1, convertedCount / convertedPlaquesCount));

        const ashAppsMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myAshesAppointments')) || null;
        const ashAppsResult = (ashAppsMetric && JSON.parse(ashAppsMetric.result)) || {};
        const ashAppsDoneCount =
            (ashAppsResult.Results &&
                ashAppsResult.Results.Appointments.filter(e => moment().isAfter(e.Until)).length) ||
            0;
        const ashAppsSoonCount =
            (ashAppsResult.Results &&
                ashAppsResult.Results.Appointments.filter(e => moment().isBefore(e.Start)).length) ||
            0;

        const ashAppsCollectionCount = 0.5;
        const ashAppsCollectionColour = metricColour(ashAppsCollectionCount);

        const pills = (
            <Grid item md={12} lg={6}>
                <MetricCard>
                    <div className={classes.gridThis}>
                        <h3 className="metrics">Ashes Collected by Appointment</h3>
                        <Stats heading="Appointments Scheduled" stats={ashAppsSoonCount || 0} />
                        <Stats heading="Appointments Conducted" stats={ashAppsDoneCount || 0} />
                        <div className={classes.rightCol}>
                            <Graph variant="one">
                                <BarChart
                                    label="Collection Rate"
                                    goal="Goal: 100%"
                                    stats={`${Math.min(100, (ashAppsCollectionCount * 100).toFixed(0))}%`}
                                    statsNumber={`${ashAppsCollectionCount}`}
                                    variant={ashAppsCollectionColour}
                                    loading={reloading}
                                />
                            </Graph>
                        </div>
                        <Stats
                            heading="Ashes Heldover (1+ month)"
                            stats={`${(ashAppsCollectionCount * 100).toFixed(0)}%`}
                        />
                        <Stats heading="Ashes Collected" stats={ashAppsCollectionCount} />
                    </div>
                </MetricCard>
            </Grid>
        );

        // todo
        const graphs = (
            <Fragment>
                <Grid item xs={12} sm={6} lg={3}>
                    <div className="highlight-container">
                        <MetricCard variant={conductedColour}>
                            <DataHighlight
                                topText={`${titles} organised`}
                                data={`${conductedCount} ${conductedCount === 1 ? 'cremation' : 'cremations'}`}
                                bottomText={`this ${type}`}
                                variant={conductedColour}
                            />
                        </MetricCard>
                        <MetricCard variant={convertedProductsColour}>
                            <DataHighlight
                                topText={`${titles} associated`}
                                data={`${convertedProductsCount} ${
                                    convertedProductsCount === 1 ? 'product' : 'products'
                                }`}
                                bottomText={`to cremations this ${type}`}
                                variant={convertedProductsColour}
                            />
                        </MetricCard>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} lg={3}>
                    <MetricCard>
                        <Graph variant="three">
                            <BarChart
                                label="Associations"
                                // goal="Goal: 100%"
                                stats={`${Math.min(100, (convertedCount * 100).toFixed(0))}%`}
                                statsNumber={`${convertedCount}`}
                                variant={convertedColour}
                                loading={reloading}
                            />
                            <BarChart
                                label="Products"
                                // goal="Goal: 60%"
                                stats={`${Math.min(100, (convertedProductsCount * 100).toFixed(0))}%`}
                                statsNumber={`${convertedProductsCount}`}
                                variant={convertedProductsColour}
                                loading={reloading}
                            />
                            <BarChart
                                label="Plaques"
                                // goal="Goal: 40%"
                                stats={`${Math.min(100, (convertedPlaquesCount * 100).toFixed(0))}%`}
                                statsNumber={`${convertedPlaquesCount}`}
                                variant={convertedPlaquesColour}
                                loading={reloading}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>
            </Fragment>
        );

        return (
            <Fragment>
                {variant !== 'individual' ? pills : graphs}
                {variant !== 'individual' ? graphs : pills}
            </Fragment>
        );
    };

    return (
        <Grid container spacing={24}>
            <Grid item pc>
                <h2>{variant === 'individual' ? 'Your' : "Your Team's"} Performance</h2>
            </Grid>
            <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                {result => renderResult(result)}
            </Query>
        </Grid>
    );
});
