import React from 'react';
import FileIcon from '../icon/FileIcon';

export default ({ fileName, fileLink, variant }) => {
    const icon = (fileLink || '').search(/\.(png|jpe?g|gif)$/i) !== -1
        ? <img className="file-documents" src={fileLink} alt="" />
        : <FileIcon className="file-documents" />;
    return (
        <a className={`file-upload-card ${variant}`} href={fileLink} target="_blank" rel="noopener noreferrer">
            {icon}
            <p className="filename-description">{fileName}</p>
            {!fileLink && <small style={{ color: 'red' }}><br />File is missing!</small>}
        </a>
    );
};
