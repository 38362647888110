import gql from "graphql-tag";
import Member from "./Member";

export default gql`
	fragment CremationOrderStaffMember on CremationOrderStaffMember
	{
		ID
		Role
		Allocation
		Staff {
			...Member
		}
	}
	${Member}
`;
