import React from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Label from './Label';
import Select from './Select';
import { stringIsNullOrEmpty } from '../../utils/strings';

class SortByDropdown extends React.Component {
    render() {
        const { className, variant, label, placeholder, sortField, sortDirection, options, allowNone } = this.props;

        let cssVariant = null;
        if (!stringIsNullOrEmpty(variant)) cssVariant = `label--${variant}`;

        return (
            <div className={`sort-by-container ${className || ''}`}>
                <Label className={`label ${cssVariant || ''}`}>{label}</Label>
                <Select
                    value={sortField + sortDirection}
                    placeholder={placeholder}
                    options={options}
                    onChange={this.handleChange}
                    allowNone={allowNone}
                />
            </div>
        );
    }

    handleChange = event => {
        const { setSortField } = this.props;
        setSortField(event.target.value);
    };
}

export default compose(withRouter)(SortByDropdown);
