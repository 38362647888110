import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';

export default withStyles({})(({ variant, label, stats, goal, statsNumber }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `bar-fill--${variant}`;

    return (
        <div className="chart-container">
            <div className="horizontal-number">{goal}</div>
            <div className="bar">
                <div className="bar-track">
                    <div className={`bar-fill ${cssVariant || ''}`} style={{ height: stats }}>
                        <div className="stats-container">
                            <span>{statsNumber}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="label-container">
                <label className="chart-label">{label}</label>
            </div>
        </div>
    );
});
