import gql from 'graphql-tag';
import Address from './Address';
import AshLocation from './AshLocation';
import AuthorisedContact from './AuthorisedContact';
import Client from './Client';
import CremationDateHistory from './CremationDateHistory';
import CremationOrderStaffMember from './CremationOrderStaffMember';
import Deceased from './Deceased';
import DispatchDate from './DispatchDate';
import Enquiry from './Enquiry';
import Member from './Member';
import SpecialInstruction from './SpecialInstruction';
import ReflectionRoom from './ReflectionRoom';
import RequiredDocument from './RequiredDocument';
import Invoice from './Invoice';

export default gql`
    fragment CremationOrder on CremationOrder {
        ID
        LegacyKey
        CremationDate
        CremationTime
        ContainmentStyle
        Recipient
        IsDeliveryOnly
        EstimatedDeliveryDate
        EstimatedDeliveryTime
        EstimatedDeceasedWeight
        EstimatedDeliveredBy
        DeliveryDate
        DeliveryTime
        DeceasedWeight
        DeliveredBy
        EstimatedInsertionDate
        InsertionDate
        InsertionTime
        CremationEndDate
        CremationNumber
        CremationEndTime
        CremationHeldOver
        CremationActionNotes
        UseCoronerCertificates
        HasMissingCertificates
        CremationPending
        CremationComplete
        CremationCancelled
        CancelledDate
        CancelledReason
        ReadyForCremation
        AdditionalNotes
        Deceased {
            ...Deceased
        }
        DestinationAddress {
            ...Address
        }
        SpecialInstructions {
            ...SpecialInstruction
        }
        DispatchDates {
            ...DispatchDate
        }
        CremationDateHistories {
            ...CremationDateHistory
        }
        Enquiry {
            ...Enquiry
        }
        Funeral {
            ID
            LegacyKey
            LegacyDocuments {
                ... on File {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
                ... on Image {
                    ID
                    Name
                    Size
                    AbsoluteLink
                }
            }
        }
        AshLocations {
            ...AshLocation
        }
        CremationOperator {
            ...Member
        }
        DeliveryReceivedBy {
            ...Member
        }
        AuthorisedContacts {
            ...AuthorisedContact
        }
        Invoices {
            ...Invoice
        }
        Client {
            ...Client
        }
        StaffMembers {
            ...CremationOrderStaffMember
        }
        SignificantDocuments {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
        RequiredDocuments {
            ...RequiredDocument
        }
        ReflectionRoomRequired
        ReflectionRoom {
            ...ReflectionRoom
        }
        SameDayCremation
    }
    ${Address}
    ${AshLocation}
    ${AuthorisedContact}
    ${Client}
    ${CremationDateHistory}
    ${CremationOrderStaffMember}
    ${Deceased}
    ${DispatchDate}
    ${Enquiry}
    ${Member}
    ${SpecialInstruction}
    ${ReflectionRoom}
    ${RequiredDocument}
    ${Invoice}
`;
