import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { compose } from 'react-apollo';
import './css/app.scss';
import Header from './components/header/Header';
import AppNavDrawer from './components/drawer/AppNavDrawer';
import Footer from './components/footer/Footer';
import Main from './views/Main';
import { isSignedIn } from './utils/sessions';
import { SnackbarMessageConsumer, withSnackbarMessage } from './context/SnackbarMessage';
import NavigationItems from './views/NavigationItems';
import IconButton from '@material-ui/core/IconButton';
import { CloseIcon, ExclamationCircleIcon, TickCircleIcon } from './components/IconIndex';
import { getOffices, loadSiteConfig } from './environment';
import { WhiteLoader } from './components/WhiteLoader';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import cx from 'classnames';

class App extends Component {
    state = {
        signedIn: false,
        drawerOpen: false,
        drawerMinimized: false,
        loadedConfig: false
    };

    static getDerivedStateFromProps(newProps, oldState) {
        const newState = {};
        const newSignedIn = isSignedIn();
        const { signedIn } = oldState;
        if (signedIn !== newSignedIn) {
            newState.signedIn = newSignedIn;
        }

        if (Object.keys(newState).length) {
            return newState;
        }
        return null;
    }

    getFuneralTheme() {
        const allOffices = getOffices().map(({ ID, RegistrantSuffix, OfficeColour, ForegroundColour }) => ({
            ID: ID,
            key: RegistrantSuffix,
            colour: OfficeColour || ForegroundColour
        }));
        const recolour = (originalColour, comparisonColour = '#FFFFFF', percentDiff = 0.9) => {
            const f = percentDiff <= 1 ? percentDiff : percentDiff / 100;
            let c0 = (originalColour || '').replace(/#/, '');
            let c1 = (comparisonColour || '').replace(/#/, '');
            c0 = c0.match(/.{1,2}/g).map(oct => parseInt(oct, 16) * (1 - f));
            c1 = c1.match(/.{1,2}/g).map(oct => parseInt(oct, 16) * f);
            let ci = [0, 1, 2].map(i => Math.min(Math.round(c0[i] + c1[i]), 255));
            return (
                '#' +
                ci
                    .reduce((a, v) => (a << 8) + v, 0)
                    .toString(16)
                    .padStart(6, '0')
            );
        };
        return theme => {
            theme = { ...theme };
            theme.palette = { ...theme.palette };
            theme.palette.contentBackground = Object.fromEntries([
                ...allOffices.map(({ key, colour }) => [key, recolour(colour, '#FFFFFF', 0.9)]),
                ['none', '#EBF6FD']
            ]);
            theme.palette.contentForeground = Object.fromEntries([
                ...allOffices.map(({ key, colour }) => [key, colour]),
                ['none', '#35327c']
            ]);
            const head = document.head;
            if (!head.querySelector('#theme-css') && allOffices.length) {
                const styleSheet = document.createElement('style');
                styleSheet.rel = 'stylesheet';
                styleSheet.id = 'theme-css';
                head.appendChild(styleSheet);
                allOffices.forEach(obj => {
                    const tableStyle = `tablestyle${obj.ID}`;
                    const cDark = theme.palette.contentForeground[obj.key];
                    const cLight = theme.palette.contentBackground[obj.key];
                    [
                        `.table .row--${tableStyle} { outline-color: ${cDark}}`,
                        `.table .row--${tableStyle} > .td { background-color: ${cLight}44; border-color: ${cDark};}`,
                        `.table .row--${tableStyle}:hover > .td { background-color: ${cLight}; border-color: ${cDark};}`,
                        `.table .row--${tableStyle} > .td svg, .table .row--${tableStyle} > .td a, .table .row--${tableStyle} > .td a > span {color: ${cDark};}`,
                        `@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) { .table .row--${tableStyle} > .td:after { border-color: ${cDark}; } }`,
                        `.table-key--${tableStyle} {background-color: ${cLight}; color: ${cDark}; border-color: ${cDark};}`
                    ].forEach(rule => styleSheet.sheet.insertRule(rule, styleSheet.sheet.cssRules.length));
                });
            }
            return theme;
        };
    }

    onToggleDrawerOpen = () => {
        this.setState({ drawerOpen: !this.state.drawerOpen });
    };

    onToggleDrawerMinimized = () => {
        this.setState({ drawerMinimized: !this.state.drawerMinimized });
    };

    render() {
        const { signedIn, loadedConfig } = this.state;
        if (signedIn && !loadedConfig) {
            loadSiteConfig().then(() => this.setState({ loadedConfig: true })); // load siteConfig into cache
        }
        return (
            <div className={signedIn ? 'app' : ''}>
                {signedIn && (
                    <Fragment>
                        <Header
                            onToggleDrawerOpen={this.onToggleDrawerOpen}
                            onToggleDrawerMinimized={this.onToggleDrawerMinimized}
                            drawerMinimized={this.state.drawerMinimized}
                        />
                        <AppNavDrawer
                            items={NavigationItems}
                            onToggleOpen={this.onToggleDrawerOpen}
                            onToggleMinimized={this.onToggleDrawerMinimized}
                            open={this.state.drawerOpen}
                            minimized={this.state.drawerMinimized}
                        />
                    </Fragment>
                )}
                <MuiThemeProvider theme={this.getFuneralTheme()}>
                    {!signedIn || loadedConfig ? (
                        <Main signedIn={signedIn} />
                    ) : (
                        <div style={{ margin: 'auto' }}>
                            <WhiteLoader actionWord="Loading" />
                        </div>
                    )}
                </MuiThemeProvider>
                <SnackbarMessageConsumer>{this.renderSnackbar}</SnackbarMessageConsumer>
                {signedIn && <Footer />}
            </div>
        );
    }

    handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        this.props.setSnackbarMessage('');
    };

    renderSnackbar = ({ snackbarMessage, snackbarHappy, snackbarDuration, snackbarException }) => {
        const open = !!snackbarMessage;
        const snackbarExceptionText =
            !!snackbarException &&
            (Array.isArray(snackbarException) ? (
                <ul>
                    {snackbarException.map(e => {
                        return <li>{e.message}</li>;
                    })}{' '}
                </ul>
            ) : (
                snackbarException.message
            ));
        const { classes } = this.props;
        const key = Math.random();
        return (
            <Snackbar
                key={key}
                message={
                    <span id="message-id" className={classes.message}>
                    {snackbarMessage &&
                        (snackbarHappy ? (
                            <TickCircleIcon style={{ color: 'white' }} />
                        ) : (
                            <ExclamationCircleIcon style={{ color: 'white' }} />
                        ))}
                        <span>
                            {snackbarMessage}
                            {!!snackbarException && (
                                <>
                                    <br />
                                    <small>{snackbarExceptionText}</small>
                                </>
                            )}
                        </span>
                    </span>
                }
                open={open}
                action={
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={this.handleCloseSnackbar}
                    >
                        <CloseIcon />
                    </IconButton>
                }
                autoHideDuration={snackbarDuration}
                onClose={this.handleCloseSnackbar}
                className={open ? cx(classes.snackbar, 'snackbar', (snackbarHappy ? 'success' : 'whoops')) : ''}

            />
        );
    };
}

const styles = ({ spacing, sizes, palette }) => ({
    snackbar: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        '& > div': {
            borderRadius: '6px 6px 0 0',
            flexWrap: 'nowrap',
            maxHeight: '50vh',
            overflow: 'hidden',
            overflowY: 'auto',
            alignItems: 'start',
            '& > div:last-of-type': {
                paddingLeft: 0
            }
        }
    },
    message: {
        display: 'flex',
        //alignItems: 'center',
        '& > span': {
            fontSize: '1.25em',
            marginLeft: spacing.unit * 2,
            marginRight: spacing.unit,
            '& small': {
                fontFamily: 'monospace',
                display: 'block',
                borderTop: '1px dotted white',
                paddingTop: spacing.unit * 2,
                marginTop: spacing.unit * 4
            }
        }
    }
});

// prettier-ignore
export default compose(
    withSnackbarMessage,
    withRouter,
    withStyles(styles)
)(App);
