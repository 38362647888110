import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined } from '../../utils/strings';

/**
 * defaults how the alignment of the inline field works
 */
export const inlineAlignment = {
    default: 0,
    rightAlignSiblings: 1,
    right: 2
};

//inline grid that contains elements in a left-to-right line
const Inline = withStyles({})(
    ({ children, className, alignment = inlineAlignment.default, center = false, nowrap, footer }) => {
        let defaultClassName = null;

        if (alignment === inlineAlignment.rightAlignSiblings) {
            defaultClassName = 'inline inline-right-align-siblings';
        } else if (alignment === inlineAlignment.right) {
            defaultClassName = 'inline inline--right';
        } else {
            defaultClassName = 'inline';
        }
        const classNames = joinDefined([defaultClassName, className, (center ? 'inline--vertical-center' : ''), (footer ? 'inline--as-footer' : '')],' ');
        return (
            <div
                style={nowrap && { flexWrap: 'nowrap' }}
                className={classNames}
            >
                {children}
            </div>
        );
    }
);

export default Inline;

export const InlineField = withStyles({})(({ ...props }) => {
    return <Inline alignment={0} {...props} />;
});

export const InlineFieldRightAlignChildren = withStyles({})(({ ...props }) => {
    return <Inline alignment={1} {...props} />;
});
