import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { isRelatedObjectUndefined } from '../utils/objects';
import Button from './form/Button';
import GatedComponent from './GatedComponent';
import { NoteIcon } from './IconIndex';
import { Tooltip } from '@material-ui/core';
import { getSiteConfig } from '../environment';

function GoFuneral(props) {
    const { funeral } = props;
    return (
        (!isRelatedObjectUndefined(funeral) && (
            <GatedComponent canViewCode="ADMIN">
                {() => {
                    const { FuneralManagerDomain } = getSiteConfig();
                    return <Tooltip title="View Funeral Record">
                        <Button
                            target="_blank"
                            variant="primary"
                            startIcon={<NoteIcon />}
                            onClick={() => window.open(
                                `//${FuneralManagerDomain}/funeral/${funeral.LegacyKey}/${funeral.ID}`,
                                '_blank')
                            }
                        >
                            Funeral
                        </Button>
                    </Tooltip>;
                }}
            </GatedComponent>
        )) ||
        null
    );
}

export default withStyles({})(GoFuneral);
