import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 640 512" {...props}>
        <path
            fill="currentColor"
            d="M608,0H192c-17.7,0-32,14.3-32,32v96c-53,0-96,43-96,96s43,96,96,96s96-43,96-96c0-41.7-26.8-76.9-64-90.1V32h416v352h-64
	v-64c0-17.7-14.3-32-32-32H384c-17.7,0-32,14.3-32,32v64h-46.7c-0.6-0.9-1-2-1.6-2.9C286.4,355.5,257,342,226.8,342
	c-27.8,0-34.8,10-66.8,10c-32,0-38.9-10-66.8-10c-30.2,0-59.7,13.5-76.9,39.1C6,396.4,0,414.8,0,434.7V472c0,22.1,17.9,40,40,40h240
	c22.1,0,40-17.9,40-40v-37.3c0-6.4-0.8-12.6-2-18.7h290c17.7,0,32-14.3,32-32V32C640,14.3,625.7,0,608,0z M224,224
	c0,35.3-28.7,64-64,64s-64-28.7-64-64s28.7-64,64-64S224,188.7,224,224z M288,472c0,4.4-3.6,8-8,8H40c-4.4,0-8-3.6-8-8v-37.3
	c0-12.8,3.8-25.1,10.8-35.7c10.5-15.6,29.3-25,50.4-25c21.8,0,30,10,66.8,10c36.8,0,45-10,66.8-10c21,0,39.8,9.3,50.4,25
	c7.1,10.5,10.9,22.9,10.9,35.7L288,472L288,472z M512,384H384v-64h128V384z"
        />
    </SvgIcon>
);
