import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ArrowSolidIcon from '../icon/metricDashboard/ArrowSolidIcon';
import { stringIsNullOrEmpty } from '../../utils/strings';

export default withStyles({})(({ heading, stats, variant }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `arrow--${variant}`;

    return (
        <div className="stats-content">
            <h4>{heading}</h4>
            <div className="container">
                <p>{stats}</p>
                <ArrowSolidIcon className={`arrow ${cssVariant || ''}`} />
            </div>
        </div>
    );
});
