import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined, stringIsNullOrEmpty } from '../../utils/strings';
import Table, { Cell, HeaderRow, Row } from './Table';
import Inline, { inlineAlignment } from '../layout/Inline';
import { getBrandHomeByKey } from '../../utils/brands';
import { niceDateFromString, niceTimeFromString } from '../../utils/date';
import Button from '../form/Button';

const styles = () => ({
    flexy: {
        display: 'flex',
        '& > div': {
            flexGrow: 1,
            flexBasis: 0
        },
        '& > div:not(:first-child)': {
            borderLeft: '1px solid #999',
            paddingLeft: 5,
            marginLeft: 2
        }
    }
});

export default withStyles(styles)(({ variant, runsheetType, runsheetStatus, plaque, classes }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `runsheet--${variant}`;

    const client = plaque.Deceased || false;
    const clientName = !!client && joinDefined([client.FirstName, client.Middlename, client.Surname], ' ');
    const recordID = plaque.ID;
    const legacyKey = plaque.LegacyKey;
    const office = getBrandHomeByKey(legacyKey).label;
    const familyAttendanceDate = niceDateFromString(plaque.FamilyAttendanceDate);
    const familyAttendanceTime = niceTimeFromString(plaque.FamilyAttendanceTime);
    const plaqueInstallDate = niceDateFromString(plaque.InstallationDate);
    const plaqueInstallTime = niceTimeFromString(plaque.InstallationTime);
    const hasAshes = plaque.Ashes.length > 0;
    const plaqueNiche = plaque.Niche;
    const plaqueMaterial = plaque.PlaqueMaterial;
    const plaqueWall = plaque.WallPosition;
    const plaquePlacement = plaque.PlacementByCouncil ? 'Council' : office;
    const familyAttendance = plaque.FamilyAttendance && plaque.FamilyContact;

    const location = plaque.PlaqueLocation;
    const hasCemetery = !!location && !!location.ID;
    const cemetery = hasCemetery && location.Name;
    const cemeteryAddress =
        hasCemetery &&
        joinDefined(
            [location.AddressLine1, location.AddressLine2, location.Postcode, location.Suburb, location.State],
            ', '
        );

    const renderAttendance = () => {
        return (
            familyAttendance && (
                <Cell>
                    <h5>Family Attendance Notes:</h5>
                    {(!!familyAttendanceDate || !!familyAttendanceTime) && (
                        <p>
                            <span className="info">Date:&nbsp;</span>
                            {familyAttendanceDate}
                            {!!familyAttendanceTime && ' at ' + familyAttendanceTime}
                        </p>
                    )}
                    <p>{familyAttendance}</p>
                </Cell>
            )
        );
    };

    const renderAshes = () => {
        return (
            hasAshes && (
                <Cell>
                    <h5>Ashes:</h5>
                    <div className={classes.flexy}>
                        {plaque.Ashes.map(ashes => (
                            <div>
                                <p>
                                    <span className="info">Ashes:&nbsp;</span>
                                    {ashes.Ashes || '(none)'}
                                </p>
                                <p>
                                    <span className="info">Vase:&nbsp;</span>
                                    {ashes.ContainmentStyle || '(none)'}
                                </p>
                                {ashes.Comment && <p>{ashes.Comment}</p>}
                            </div>
                        ))}
                    </div>
                </Cell>
            )
        );
    };

    return (
        <div className={`runsheet ${cssVariant || ''}`}>
            <Table>
                <HeaderRow pad>
                    <Cell colSpan={2}>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <span className="runsheet-header">
                                    {!!runsheetStatus && runsheetStatus + ' | '} {runsheetType}:&nbsp;
                                    {clientName}
                                </span>
                            </div>
                            <p className="header-record">
                                <Button variant="link-white" href={'/plaques/' + recordID}>
                                    {legacyKey}
                                </Button>
                                {!!office && ' | ' + office}
                            </p>
                        </Inline>
                    </Cell>
                </HeaderRow>
                <Row pad>
                    <Cell rowSpan={3}>
                        <p>
                            <span className="info">Installation Date: </span>
                            {plaqueInstallDate} {!!plaqueInstallTime && ' at ' + plaqueInstallTime}
                        </p>
                        <p>
                            <span className="info">Plaque material:&nbsp;</span>
                            {plaqueMaterial || '(not set)'}
                        </p>
                        <p>
                            <span className="info">Wall:&nbsp;</span>
                            {plaqueWall || '(none)'}
                        </p>
                        <p>
                            <span className="info">Attachment method:&nbsp;</span>
                            {plaque.AttachmentMethod || '(none)'}
                        </p>
                        <p>
                            <span className="info">Position:&nbsp;</span>
                            {plaque.PlaquePosition || '(none)'}
                        </p>
                        <p>
                            <span className="info">Niche:&nbsp;</span>
                            {plaqueNiche || '(none)'}
                        </p>
                        <p>
                            <span className="info">Placement by:&nbsp;</span>
                            {plaquePlacement || '(not set)'}
                        </p>
                        <p>
                            <span className="info">Family in attendance:&nbsp;</span>
                            {plaque.FamilyAttendance ? 'Yes' : 'No'}
                        </p>
                    </Cell>
                    {hasCemetery ? (
                        <Cell>
                            <h5>Installation Location:</h5>
                            <p>
                                <span className="info">Name:&nbsp;</span>
                                {cemetery}
                            </p>
                            <p>
                                <span className="info">Address:&nbsp;</span>
                                {cemeteryAddress}
                            </p>
                        </Cell>
                    ) : (
                        renderAttendance()
                    )}
                </Row>
                {hasCemetery && <Row pad>{renderAttendance()}</Row>}
                {hasAshes && <Row pad>{renderAshes()}</Row>}
            </Table>
        </div>
    );
});
