import gql from 'graphql-tag';

export default gql`
	fragment PlaqueOrderAsh on PlaqueOrderAsh
	{
		ID
		Ashes
		ContainmentStyle
		DispatchMethod
		AshesCollectedBy
		Comment
	}
`;
