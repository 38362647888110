import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import IconButton from '@material-ui/core/IconButton';
import Grid from './layout/Grid';
import Table, { Cell, HeaderRow, Row } from './table/Table';
import Button from './form/Button';
import { niceDateFromString } from '../utils/date';
import DeleteIcon from './icon/DeleteIcon';
import AlertModal from './modal/AlertModal';
import Spinner from './loading/Spinner';
import ExclamationCircleIcon from './icon/ExclamationCircleIcon';
import EditIcon from './icon/EditIcon';
import AssociatedOrdersModal from './modal/AssociatedOrdersModal';
import DataFormView from './DataFormView';
import AssociatedOrder from '../fragments/AssociatedOrder';
import Modal from './modal/Modal';
import { getClient } from '../utils/apollo';
import { withSnackbarMessage } from '../context/SnackbarMessage';
import { PAGE_ENQUIRIES } from '../utils/strings';

class AssociatedOrders extends Component {
    state = {
        deletePrompt: null,
        createModal: null,
        orderID: null
    };

    render() {
        const { parentID, parentType } = this.props;
        const { deletePrompt, createModal, orderID } = this.state;
        if (!parentType) return null;
        const pickQuery =
            parentType === 'CremationOrder'
                ? cremationQuery
                : parentType === 'PlaqueOrder'
                ? plaqueQuery
                : enquiryQuery;

        const closeModal = refresh => {
            this.setState(
                { createModal: null, orderID: null },
                () => !!refresh && !!this.refetchAssociatedOrders && this.refetchAssociatedOrders()
            );
        };
        return (
            <Grid container bucket>
                {!!deletePrompt && (
                    <AlertModal
                        open={!!deletePrompt}
                        primaryAction="yeah, begone"
                        onClickPrimaryAction={this.deleteAssociatedOrder}
                        secondaryAction="oops, abort"
                        onClickSecondaryAction={() => this.setState({ deletePrompt: null })}
                        onClose={() => this.setState({ deletePrompt: null })}
                        variant="warning"
                    >
                        Are you sure you want to delete this association?
                    </AlertModal>
                )}
                {!!createModal && (
                    <Modal onClose={closeModal} variant="simple" open canClickOut={false}>
                        <DataFormView
                            tabs={[
                                {
                                    id: 'AssociatedOrder',
                                    label: 'AssociatedOrder',
                                    component: withStyles({})(AssociatedOrdersModal),
                                    fragment: AssociatedOrder,
                                    onLoad: data => {},
                                    formatSaveData: (saveData, state) => {}
                                }
                            ]}
                            createNewFunc={() => ({
                                OrderItems: [{ [parentType]: { ID: parentID } }, {}]
                            })}
                            objectType="AssociatedOrder"
                            onBeforeSave={dataForm => {
                                const input = dataForm.getTabState();
                                if (input.OrderItems)
                                    input.OrderItems = input.OrderItems.map(item => {
                                        const obj = {};
                                        if (item.ID) obj.ID = item.ID;
                                        if (!!item[parentType] && item[parentType].ID === parentID) {
                                            obj[`${parentType}ID`] = parentID;
                                        } else {
                                            if (input.Type === 'ProductOrder') return item;
                                            obj[`ProductOrderID`] = 0;
                                            obj[`CremationOrderID`] = 0;
                                            obj[`PlaqueOrderID`] = 0;
                                            obj[`EnquiryID`] = 0;
                                            obj[`${input.Type}ID`] = item[input.Type].ID;
                                        }
                                        return obj;
                                    });
                                delete input.Type;
                                dataForm.input = input;
                                return true;
                            }}
                            itemId={orderID}
                            name="AssociatedOrder"
                            context={this}
                            onUpdated={() => closeModal(false)}
                            onCreated={() => closeModal(true)}
                            additionalActions={[
                                {
                                    label: 'Cancel',
                                    variant: 'secondary',
                                    onClick: closeModal,
                                    skipSave: true
                                }
                            ]}
                        />
                    </Modal>
                )}
                <Grid item>
                    <h4>Associated Orders</h4>
                </Grid>
                <Grid item>
                    <Query fetchPolicy="cache-and-network" variables={{ id: parentID || 0 }} query={pickQuery}>
                        {({ data, loading, error, refetch }) => {
                            if (!!loading)
                                return (
                                    <span>
                                        <Spinner /> Loading...
                                    </span>
                                );
                            if (!!error)
                                return (
                                    <span>
                                        <ExclamationCircleIcon /> Error! Can't load associated orders.
                                    </span>
                                );
                            this.refetchAssociatedOrders = refetch;
                            const associatedOrders =
                                (!!data &&
                                    !!data['readOne' + parentType] &&
                                    data['readOne' + parentType].AssociatedOrders) ||
                                [];

                            return (
                                (associatedOrders.length > 0 && (
                                    <Table>
                                        <HeaderRow pad>
                                            <Cell colSpan={1}>Type</Cell>
                                            <Cell colSpan={1}>Reference</Cell>
                                            <Cell colSpan={1}>Details</Cell>
                                            <Cell colSpan={1}>Actions</Cell>
                                        </HeaderRow>

                                        {associatedOrders.map(associatedOrder =>
                                            this.renderAssociatedOrder(associatedOrder, associatedOrders)
                                        )}
                                    </Table>
                                )) || <span>There are currently no associated orders.</span>
                            );
                        }}
                    </Query>
                </Grid>
                <Grid item>
                    <Button variant="primary" onClick={() => this.handleAddAssociatedOrder()}>
                        Add Order or Enquiry...
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderAssociatedOrder(thisOrder) {
        const { parentType, parentID, classes } = this.props;
        return thisOrder.OrderItems.map(item => {
            let referenceID = null;
            let associatedOrderType = null;
            let notes = '';

            if (!!item.ProductOrder && !!Number(item.ProductOrder.ID)) {
                associatedOrderType = 'MVC Product';
                if (!!item.ProductOrder.Link) {
                    referenceID = (
                        <Button
                            variant="link-orange"
                            href={item.ProductOrder.Link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Go to Product
                        </Button>
                    );
                } else {
                    referenceID = item.ProductOrder.ProductCode;
                }
                notes = item.ProductOrder.Description;
            } else if (
                !!item.Enquiry &&
                !!Number(item.Enquiry.ID) &&
                !(parentType === 'Enquiry' && parentID === item.Enquiry.ID)
            ) {
                let link = PAGE_ENQUIRIES + item.Enquiry.ID;
                associatedOrderType = 'Enquiry';
                referenceID = (
                    <Button variant="link-orange" href={link}>
                        {item.Enquiry.LegacyKey}
                    </Button>
                );
                notes = `${item.Enquiry.EnquiryType} Order`;
            } else if (
                !!item.PlaqueOrder &&
                !!Number(item.PlaqueOrder.ID) &&
                !(parentType === 'PlaqueOrder' && parentID === item.PlaqueOrder.ID)
            ) {
                let link = '/plaques/' + item.PlaqueOrder.ID;
                associatedOrderType = 'Plaque Order';
                referenceID = (
                    <Button variant="link-orange" href={link}>
                        {item.PlaqueOrder.LegacyKey}
                    </Button>
                );

                notes = item.PlaqueOrder.Status;

                if (item.PlaqueOrder.InstallationDate) {
                    notes += ` (${niceDateFromString(item.PlaqueOrder.InstallationDate)})`;
                } else {
                    notes += ' (date not set)';
                }
            } else if (
                !!item.CremationOrder &&
                !!Number(item.CremationOrder.ID) &&
                !(parentType === 'CremationOrder' && parentID === item.CremationOrder.ID)
            ) {
                let link = '/cremations/' + item.CremationOrder.ID;
                associatedOrderType = 'Cremation Record';
                referenceID = (
                    <Button variant="link-orange" href={link}>
                        {item.CremationOrder.LegacyKey}
                    </Button>
                );

                notes = item.CremationOrder.Status;

                if (item.CremationOrder.CremationDate) {
                    notes += ` (${niceDateFromString(item.CremationOrder.CremationDate)})`;
                } else {
                    notes += ' (date not set)';
                }
            } else {
                return null;
            }

            return (
                <Row pad key={item.ID}>
                    <Cell dataLabel="Type">{associatedOrderType}</Cell>
                    <Cell dataLabel="Reference">{referenceID}</Cell>
                    <Cell dataLabel="Details">{notes}</Cell>
                    <Cell dataLabel="Actions" className={classes.tinyCell}>
                        <IconButton
                            className="icon"
                            title="Edit"
                            onClick={() =>
                                this.setState({
                                    createModal: true,
                                    orderID: thisOrder.ID
                                })
                            }
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            className="icon"
                            title="Delete"
                            onClick={() => this.handleDeleteAssociatedOrder(thisOrder.ID)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Cell>
                </Row>
            );
        });
    }

    handleDeleteAssociatedOrder = ID => {
        this.setState({ deletePrompt: ID });
    };

    deleteAssociatedOrder = async () => {
        const that = this;
        const { deletePrompt } = this.state;

        await getClient()
            .mutate({ mutation: deleteAssociatedOrderMutation, variables: { IDs: [deletePrompt] } })
            .then(() => {
                that.setState(
                    { deletePrompt: null },
                    () => !!that.refetchAssociatedOrders && that.refetchAssociatedOrders()
                );
            })
            .catch(error => {
                const { setSnackbarMessage } = this.props;
                setSnackbarMessage('Unable to delete Associated Order');
                console.error(error);
                that.setState({ deletePrompt: null });
            });
    };

    handleAddAssociatedOrder = () => {
        this.setState({ createModal: true, orderID: null });
    };

    refetchAssociatedOrders = null;
}

const deleteAssociatedOrderMutation = gql`
    mutation DeleteAssociatedOrder($IDs: [ID]!) {
        deleteAssociatedOrders(ids: $IDs)
    }
`;

const cremationQuery = gql`
    ${AssociatedOrder}
    query CremationAssociatedOrders($id: ID!) {
        readOneCremationOrder(ID: $id) {
            ID
            AssociatedOrders {
                ...AssociatedOrder
            }
        }
    }
`;

const plaqueQuery = gql`
    ${AssociatedOrder}
    query PlaqueAssociatedOrders($id: ID!) {
        readOnePlaqueOrder(ID: $id) {
            ID
            AssociatedOrders {
                ...AssociatedOrder
            }
        }
    }
`;

const enquiryQuery = gql`
    ${AssociatedOrder}
    query EnquiryAssociatedOrders($id: ID!) {
        readOneCMEnquiry(ID: $id) {
            ID
            AssociatedOrders {
                ...AssociatedOrder
            }
        }
    }
`;

const styles = {
    tinyCell: {
        width: 1,
        whiteSpace: 'pre'
    }
};

export default compose(withSnackbarMessage, withStyles(styles))(AssociatedOrders);
