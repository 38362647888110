import gql from 'graphql-tag';
import Client from './Client';
import Deceased from './Deceased';
import Member from './Member';
import CremationManagerAddressBook from './AddressBook';
import Quote from './Quote';
import AshLocation from './AshLocation';
import Appointment from './Appointment';

export default gql`
    fragment Enquiry on CMEnquiry {
        ID
        Created
        LegacyKey
        EnquiryByBusiness
        EnquiryType
        ClientSameAsEnquirer
        Notes
        CremationDate
        CremationTime
        Client {
            ...Client
        }
        Enquirer {
            ...Client
        }
        CreatedBy {
            ...Member
        }
        CremationOrder {
            ID
            LegacyKey
        }
        PlaqueOrder {
            ID
            LegacyKey
        }
        Deceased {
            ...Deceased
        }
        Business {
            ...CremationManagerAddressBook
        }
        PlaqueLocation {
            ...CremationManagerAddressBook
        }
        Quotes {
            ...Quote
        }
        Appointments {
            ...Appointment
        }
        AshLocations {
            ...AshLocation
        }
    }
    ${Client}
    ${Deceased}
    ${Member}
    ${CremationManagerAddressBook}
    ${AshLocation}
    ${Quote}
    ${Appointment}
`;
