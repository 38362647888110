import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { compose, Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import DataFormView from '../../components/DataFormView';
import ContactDetails from './ContactDetailsTab';
import OrderDetails from './OrderDetailsTab';
import PlaqueDetails from './PlaqueDetailsTab';
import Billing from './BillingTab';
import CorrespondenceTab from './CorrespondenceTab';
import Button from '../../components/form/Button';
import ViewHeader from '../../components/header/ViewHeader';
import BackIcon from '../../components/icon/BackIcon';
import Spinner from '../../components/loading/Spinner';
import SummaryTab from './SummaryTab';
import PlaqueOrder from '../../fragments/PlaqueOrder';
import { joinDefined } from '../../utils/strings';
import AlertBar from '../../components/form/AlertBar';
import RecordDrawer from '../../components/workQueue/RecordDrawer';
import Inline from '../../components/layout/Inline';
import TemplateSelectionModal from '../Cremation/Modals/TemplateSelectionModal';
import DocumentsIcon from '../../components/icon/DocumentsIcon';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

/**
 * Tab Definition
 */
const PlaqueTabs = [ContactDetails, OrderDetails, PlaqueDetails, Billing, CorrespondenceTab, SummaryTab];

class Plaque extends Component {
    constructor(props) {
        super(props);
        this.plaqueRecord = null;
    }

    state = {
        showTemplateSelectionModal: false,
        isUrgent: 0
    };

    render() {
        const { match } = this.props;
        const { showTemplateSelectionModal } = this.state;
        return (
            <Grid container>
                <Query query={readQuery} variables={{ id: match.params.id }}>
                    {results => {
                        const { loading, data } = results;
                        if (loading)
                            return (
                                <div>
                                    <Spinner />
                                </div>
                            );

                        this.plaqueRecord = data.readOnePlaqueOrder;

                        return this.renderContent();
                    }}
                </Query>
                <TemplateSelectionModal
                    open={showTemplateSelectionModal}
                    plaqueID={match.params.id}
                    onClose={() => this.handleCloseTemplateSelectionModal()}
                />
            </Grid>
        );
    }

    renderContent() {
        const { match } = this.props;
        return (
            <Fragment>
                <Grid item>
                    {this.renderHeader()}
                    {this.renderAlerts()}
                </Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <DataFormView
                            tabs={PlaqueTabs}
                            createNewFunc={() => ({ Title: null })}
                            objectType="PlaqueOrder"
                            itemId={match.params.id}
                            name="Plaque"
                            context={this}
                        />
                    </Paper>
                </Grid>
            </Fragment>
        );
    }

    renderHeader() {
        const plaque = this.plaqueRecord;
        let subtitle = '';
        if (plaque && plaque.LegacyKey && plaque.Enquiry) {
            subtitle = joinDefined(
                [plaque.LegacyKey, plaque.Deceased.FirstName, plaque.Deceased.MiddleName, plaque.Deceased.Surname],
                ' '
            );
        }
        const { history } = this.props;
        const { isUrgent } = this.state;
        const urgent = plaque && (isUrgent || plaque.Urgent);
        return (
            <ViewHeader variant="record" title="Plaque" subtitle={subtitle}>
                <Inline>
                    <Button variant="secondary" onClick={() => history.push('/plaques')}>
                        <BackIcon />
                        Back
                    </Button>
                    {!!plaque && (
                        <Fragment>
                            {!plaque.Complete && <Mutation
                                mutation={updateQuery}
                                onCompleted={() => {}}
                                onError={e => this.handleMutateError(e)}
                            >
                                {(mutate, { loading }) => {
                                    const disabled = loading;
                                    return (
                                        <Button
                                            variant="urgent"
                                            disabled={disabled}
                                            onClick={e =>
                                                this.onMakeUrgentButtonClicked(
                                                    mutate,
                                                    !disabled && { input: { ID: plaque.ID, Urgent: !plaque.Urgent } },
                                                    e
                                                )
                                            }
                                        >
                                            {loading && <Spinner />}
                                            {urgent ? 'Unmark As Urgent' : 'Mark As Urgent'}
                                        </Button>
                                    );
                                }}
                            </Mutation>}
                            <Button
                                variant="primary icon-button white shadow"
                                size="lg"
                                title="Generate Merge Documents"
                                onClick={() => this.handleShowTemplateSelectionModal()}
                            >
                                <DocumentsIcon />
                            </Button>
                            <RecordDrawer plaqueId={plaque.ID} legacyKey={plaque.LegacyKey} />
                        </Fragment>
                    )}
                </Inline>
            </ViewHeader>
        );
    }

    renderAlerts() {
        const cremation = this.plaqueRecord;
        if (!cremation) return null;
        const cancelled = cremation.Cancelled;
        const urgent = cremation.Urgent && !cremation.Complete;
        return (
            <Fragment>
                {(cancelled && <AlertBar variant="error">This order was cancelled.</AlertBar>) ||
                    (urgent && (
                        <AlertBar variant="warning">
                            Attention required: This order is marked as urgent. Please complete ASAP.
                        </AlertBar>
                    ))}
            </Fragment>
        );
    }

    handleShowTemplateSelectionModal() {
        this.setState({ showTemplateSelectionModal: true });
    }

    handleCloseTemplateSelectionModal() {
        this.setState({ showTemplateSelectionModal: false });
    }

    onMakeUrgentButtonClicked(mutate, variables, e) {
        e.preventDefault();

        const that = this;

        if (variables) {
            mutate({ variables }).then(
                response => {
                    that.setState({ isUrgent: response.data.updatePlaqueOrder.Urgent });
                },
                e => {
                    console.error('gql error', e);
                }
            );
        }
    }
}

const updateQuery = gql`
    ${PlaqueOrder}
    mutation MarkPlaqueOrderAsUrgent($input: UpdatePlaqueOrderInput!) {
        updatePlaqueOrder(input: $input) {
            ID
            ...PlaqueOrder
        }
    }
`;

const readQuery = gql`
    ${PlaqueOrder}
    query readOnePlaqueOrder($id: ID!) {
        readOnePlaqueOrder(ID: $id) {
            ID
            ...PlaqueOrder
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(Plaque), { fallback: ErrorFallback });
