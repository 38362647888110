import gql from 'graphql-tag';
import CremationOrderProductOrder from './CremationOrderProductOrder';

export default gql`
    fragment AssociatedOrder on AssociatedOrder {
        ID
        OrderItems {
            ID
            Enquiry {
                ID
                LegacyKey
                NameOfDeceased
                EnquiryType
            }
            PlaqueOrder {
                ID
                LegacyKey
                NameOfDeceased
                InstallationDate
                Status
            }
            CremationOrder {
                ID
                LegacyKey
                NameOfDeceased
                CremationDate
                Status
            }
            ProductOrder {
                ...CremationOrderProductOrder
            }
        }
    }
    ${CremationOrderProductOrder}
`;
