import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Button from '../form/Button';
import Inline from '../layout/Inline';
import { stringIsNullOrEmpty } from '../../utils/strings';
import GqlAutocomplete, { getValueById } from '../form/GqlAutocomplete';
import CremationManagerAddressBook from '../../fragments/AddressBook';
import ContactModal from '../../views/Contacts/Modals/ContactModal';
import { getClient } from '../../utils/apollo';

class ContactLookup extends Component {
    state = {
        showCreateContactModal: false
    };

    render() {
        const { form, readAllQuery, clearOnSelect, variant, name, ...props } = this.props;
        const { showCreateContactModal } = this.state;

        let cssVariant = null;
        if (!stringIsNullOrEmpty(variant)) cssVariant = `contactcard--${variant}`;

        return (
            <Fragment>
                <ContactModal
                    open={showCreateContactModal}
                    onClose={contact => this.handleCloseCreateContactModal(contact)}
                />
                <div className={`lookup ${cssVariant || ''}`}>
                    <div className="container">
                        <Inline centre>
                            <GqlAutocomplete
                                readAllQuery={readAllQuery || readAllAddressBooks}
                                placeholder="Search for business or organisation..."
                                labelFieldFunc={e => e.Name}
                                name={name}
                                clearOnSelect={clearOnSelect}
                                {...props}
                            />
                            <Button
                                variant="orange-link"
                                onClick={() => this.setState({ showCreateContactModal: true })}
                            >
                                Add new
                            </Button>
                        </Inline>
                    </div>
                </div>
            </Fragment>
        );
    }

    handleCloseCreateContactModal(contact) {
        if (contact) {
            const { form, name, onAddNewContact } = this.props;
            form.setField({ [name]: contact });

            if (onAddNewContact) onAddNewContact(contact);
        }
        this.setState({ showCreateContactModal: false });
    }
}

export default withStyles({})(ContactLookup);

export const getAddressBookById = id => {
    return getValueById(getClient(), id, readOneAddressBook);
};

const readAllAddressBooks = gql`
    ${CremationManagerAddressBook}

    query ReadCremationManagerAddressBooks($contains: String) {
        readCremationManagerAddressBooks(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...CremationManagerAddressBook
                }
            }
        }
    }
`;

const readOneAddressBook = gql`
    ${CremationManagerAddressBook}

    query ReadOneCremationManagerAddressBook($id: ID!) {
        readOneCremationManagerAddressBook(ID: $id) {
            ...CremationManagerAddressBook
        }
    }
`;
