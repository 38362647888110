import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import Button from '../../components/form/Button';
import Typography from '../../components/form/Typography';
import Inline from '../../components/layout/Inline';
import ViewHeader from '../../components/header/ViewHeader';

class TypographyStyling extends Component {
    render() {
        return (
            <Grid container spacing={32}>
                {this.renderFontTypes()}
                {this.renderFontWeights()}
                {this.renderViewHeaders()}
            </Grid>
        );
    }

    renderFontWeights() {
        return (
            <Grid item xs={12} md={4}>
                <Grid container spacing={32}>
                    <Grid item xs={12}>
                        <Separator />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Font Weights</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" weight="light">
                            Roboto Light
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" weight="italic">
                            Roboto Italics
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" weight="regular">
                            Roboto Regular
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" weight="medium">
                            Roboto Medium
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" weight="bold">
                            Roboto Bold
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderFontTypes() {
        return (
            <Grid item xs={12} md={4}>
                <Grid container spacing={32}>
                    <Grid item xs={12}>
                        <Separator />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Font Types</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Inline>
                            <Typography variant="h1">Header 1 -</Typography>
                            <Typography variant="h1" subtitle>
                                Header 1
                            </Typography>
                        </Inline>
                    </Grid>
                    <Grid item xs={12}>
                        <Inline>
                            <Typography variant="h2">Header 2 -</Typography>
                            <Typography variant="h2" subtitle>
                                Header 2
                            </Typography>
                        </Inline>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3">Header 3</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">Header 4</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="paragraph">Paragraph</Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderViewHeaders() {
        return (
            <Grid item xs={12} md={4}>
                <Grid container spacing={32}>
                    <Grid item xs={12}>
                        <Separator />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h4">View Headers</Typography>
                    </Grid>
                    <Grid item>
                        <ViewHeader title="View Title 1" />
                    </Grid>
                    <Grid item>
                        <ViewHeader title="View Title 2" subtitle="Subtitle details" />
                    </Grid>
                    <Grid item>
                        <ViewHeader title="View Title 3" subtitle="Subtitle details">
                            <Button>Action 1</Button>
                            <Button variant="confirmation">Action 2</Button>
                        </ViewHeader>
                    </Grid>
                    <Grid item>
                        <ViewHeader title="View Title 4">
                            <Button>Action 1</Button>
                            <Button variant="confirmation">Action 2</Button>
                        </ViewHeader>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles({})(TypographyStyling);
