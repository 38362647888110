import React, { Component, createContext } from 'react';

const { Provider, Consumer } = createContext({ searchTerm: '' });

export class SearchTermProvider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			searchTerm: '',
			setSearchTerm: searchTerm =>
				this.setState({ searchTerm: sanitizeTerm(searchTerm) }),
			sortBy: [{ field: 'DateOfService', direction: 'DESC' }],
			setSortBy: sortBy => this.setState({ sortBy: sortBy })
		};
	}

	render() {
		const { children } = this.props;
		return <Provider value={{ ...this.state }}>{children}</Provider>;
	}
}

const sanitizeTerm = term => {
	if (!term || !term.trim()) return '';
	return term.replace(/(\s)+/g, ' ');
};

export const SearchTermConsumer = Consumer;

export function withSearchTerm(C) {
	return props => <Consumer>{value => <C {...value} {...props} />}</Consumer>;
}
