import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import moment from 'moment';
import { cloneDeep } from 'apollo-utilities';
import Query from 'react-apollo/Query';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import TaskCard from '../../components/workQueue/TaskCard';
import Card from '../../components/layout/Card';
import ClearTable, { Cell, HeaderRow, Row } from '../../components/table/ClearTable';
import BrandDot from '../../components/form/BrandDot';
import Button from '../../components/form/Button';
import { getUser } from '../../utils/sessions';
import { isRelatedObjectDefined } from '../../utils/bookable';
import { groupBy, isNullOrUndefined } from '../../utils/objects';
import { getUserTasksFunc } from '../../components/workQueue/TaskConstants';
import { getPats, joinDefined, stringIsNullOrEmpty } from '../../utils/strings';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import Typography from '../../components/form/Typography';
import { CALENDAR_TYPES } from '../Calendar/CalendarConstants';
import TaskCardContainer from './TaskCardContainer';
import { GQL_REFETCH_30S, subscribe } from '../../utils/subscriptions';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';
import { compose } from 'react-apollo';

class Dashboard extends Component {
    state = {
        user: {},
        tasks: { edges: [] },
        objectTasks: [],
        loading: false,
        loadingTaskId: null
    };

    constructor(props) {
        super(props);
        this.staffQueryRef = React.createRef();
        subscribe(this.staffQueryRef, GQL_REFETCH_30S);
    }

    componentWillMount() {
        const user = getUser();
        const taskStatistics = {
            completedThisWeek: 0,
            newThisWeek: 0,
            overdue: 0,
            outstanding: 0
        };

        this.setState({ user, taskStatistics });
        this.loadTasks(user, true);
    }

    render() {
        return (
            <Fragment>
                {this.renderWelcome()}
                {this.renderStaffSchedule()}
                {this.renderSummary()}
                {this.renderTopTasks()}
                {/*{this.renderAssignedFunerals()}*/}
            </Fragment>
        );
    }

    renderWelcome() {
        const { user } = this.state;
        return (
            <Grid container>
                <Grid item>
                    <h1 className="title">G'day {user.FirstName || user.Surname}, welcome to Cremation Manager!</h1>
                </Grid>
            </Grid>
        );
    }

    renderSummary() {
        const { taskStatistics } = this.state;
        return (
            <Grid container>
                <Grid item>
                    <p>
                        {taskStatistics.completedThisWeek > 0 ? (
                            <Fragment>
                                You've completed {taskStatistics.completedThisWeek} task
                                {taskStatistics.completedThisWeek === 1 ? '' : 's'} this week!{' '}
                            </Fragment>
                        ) : (
                            <Fragment>You haven't completed any tasks this week. </Fragment>
                        )}
                        {getPats(taskStatistics.completedThisWeek)}
                    </p>
                    <p>
                        {taskStatistics.outstanding > 0 ? (
                            <Fragment>
                                {' '}
                                You have{' '}
                                <Button variant="link-orange" href="/work-queue">
                                    {taskStatistics.outstanding}
                                    {taskStatistics.outstanding.length === 1 ? ' task' : ' tasks'}
                                </Button>{' '}
                                outstanding.
                            </Fragment>
                        ) : (
                            <Fragment> You have no outstanding tasks.</Fragment>
                        )}
                        {taskStatistics.newThisWeek > 0 && (
                            <Fragment>
                                {' '}
                                You have been assigned {taskStatistics.newThisWeek} new task
                                {taskStatistics.newThisWeek === 1 ? '' : 's'} this week.
                            </Fragment>
                        )}
                        {taskStatistics.overdue > 0 && (
                            <Fragment>
                                {' '}
                                You
                                have {taskStatistics.overdue} task{taskStatistics.overdue === 1 ? '' : 's'} overdue.
                            </Fragment>
                        )}
                    </p>
                </Grid>
            </Grid>
        );
    }

    renderStaffSchedule() {
        const { classes, history } = this.props;
        const { user } = this.state;

        const date = moment(new Date());
        const myCalendarUrl = `/calendar/${date.format('YYYYMMDD')}`;
        const staffQueryVariables = {
            filterStaff: [user.ID],
            from: moment(date, 'YYYYMMDD').startOf('day'),
            to: moment(date, 'YYYYMMDD').endOf('day')
        };

        return (
            <div className={classes.section}>
                <div className={classes.right}>
                    <Button
                        variant="link-orange"
                        text="View Your Calendar"
                        onClick={() => history.push(myCalendarUrl)}
                    />
                </div>
                <Typography variant="h4" align="left" className={classes.paperTitle} gutterBottom>
                    Your Day Ahead:
                </Typography>
                <div className={classes.appointmentContainer}>
                    <Query query={staffQuery} variables={staffQueryVariables} ref={this.staffQueryRef}>
                        {results => this.renderAppointments(results)}
                    </Query>
                </div>
            </div>
        );
    }

    renderAppointments({ loading, error, data }) {
        const { classes } = this.props;

        if (loading) {
            //return <CircularProgress />;
        }

        if (!stringIsNullOrEmpty(error)) {
            return error;
        }

        if (isNullOrUndefined(data) || isNullOrUndefined(data.readStaffAllocations)) {
            return 'Failed to load appointments';
        }

        const appointments = data.readStaffAllocations;

        return (
            <Fragment>
                {appointments.length > 0 ? (
                    appointments
                        .sort((a, b) => (a.Start < b.Start ? -1 : 1))
                        .map(appointment => {
                            const { Funeral, Appointment } = appointment;
                            const hours = moment(appointment.End).diff(appointment.Start, 'hours', true);
                            const calType = CALENDAR_TYPES.find(e => e.type === appointment.Type);
                            return (
                                <div
                                    key={appointment.ID}
                                    className={classes.appointmentRow}
                                    style={{ opacity: moment(appointment.End) < moment() ? 0.25 : null }}
                                >
                                    <div
                                        className={classes.appointmentTime}
                                        style={{
                                            backgroundColor: calType && calType.color
                                        }}
                                    >
                                        {moment(appointment.Start).format('h:mma')}
                                        <br />
                                        <small>{appointment.Type}</small>
                                    </div>
                                    <div className={classes.appointmentDescription}>
                                        <p>
                                            {!!Funeral && Funeral.ID !== '0' ? (
                                                <Fragment>
                                                    <Button
                                                        variant="link-orange"
                                                        text={Funeral.LegacyKey}
                                                        onClick={e =>
                                                            this.props.history.push(
                                                                `/funeral/${Funeral.LegacyKey}/${Funeral.ID}`
                                                            )
                                                        }
                                                    />
                                                    {' | '}
                                                    {joinDefined(
                                                        [Funeral.FirstName, Funeral.MiddleName, Funeral.Surname],
                                                        ' '
                                                    )}
                                                    &nbsp;-&nbsp;
                                                </Fragment>
                                            ) : (
                                                Appointment.Reason
                                            )}
                                            {appointment.LocationFlattened && ` at ${appointment.LocationFlattened}`}
                                            {`, for ${+hours.toFixed(2)} hour${hours === 1 ? '' : 's'}.`}
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                ) : (
                    <div style={{ marginLeft: '0.5rem' }}>You currently have no appointments for today.</div>
                )}
            </Fragment>
        );
    }

    renderTopTasks() {
        const { classes } = this.props;
        const { tasks, loading, loadingTaskId } = this.state;
        const outstandingTasks = tasks.edges
            .filter(task => !task.node.Completed)
            .sort((a, b) => {
                // put overdue dates first
                if (!a.node.Due) return 1;
                if (!b.node.Due) return -1;
                return a.node.Due < b.node.Due ? -1 : 1;
            })
            .sort((a, b) => {
                // new to old
                if (!!a.node.Due) return 0;
                if (!!b.node.Due) return 0;
                return a.node.Created > b.node.Created ? -1 : 1;
            })
            .slice(0, 12);
        return (
            <div className={classes.section}>
                <div style={{ float: 'right' }}>
                    <Button
                        variant="orange-link"
                        text={'View All Tasks'}
                        onClick={e => this.props.history.push('/work-queue')}
                    />
                </div>
                <Typography variant="h4" align="left" className={classes.paperTitle} gutterBottom>
                    Your Top Priority Tasks:
                </Typography>
                {loading && loadingTaskId === null ? (
                    <span className={classes.loading}>
                        <CircularProgress />
                    </span>
                ) : outstandingTasks.length > 0 ? (
                    <div>
                        <TaskCardContainer>
                            {outstandingTasks.map(task => (
                                <TaskCard
                                    variant="card"
                                    key={'list-item-' + task.node.ID}
                                    task={task.node}
                                    loading={loading}
                                    loadingTaskId={loadingTaskId}
                                />
                            ))}
                        </TaskCardContainer>
                    </div>
                ) : (
                    <Fragment>You have no top priority tasks.</Fragment>
                )}
            </div>
        );
    }

    renderAssignedFunerals() {
        return (
            <Grid container>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h2>Your assigned funerals:</h2>
                    </Inline>
                    <Card className="height-auto">
                        <ClearTable>
                            <HeaderRow pad>
                                <Cell>Type</Cell>
                                <Cell>Id</Cell>
                                <Cell>Name</Cell>
                                <Cell>Cremation Date</Cell>
                                <Cell>Confirmed</Cell>
                                <Cell>Outstanding Tasks</Cell>
                                <Cell>Completion Status</Cell>
                            </HeaderRow>
                            <Row pad>
                                <Cell dataLabel="Type">
                                    <BrandDot />
                                </Cell>
                                <Cell dataLabel="ID">10700P</Cell>
                                <Cell dataLabel="Name"> Tony Stark </Cell>
                                <Cell dataLabel="Cremation Date">Friday, March 18, 2018</Cell>
                                <Cell dataLabel="Confirmed">Yes</Cell>
                                <Cell dataLabel="Outstanding Tasks">19</Cell>
                                <Cell dataLabel="Completion Status">40% Complete</Cell>
                            </Row>
                            <Row pad>
                                <Cell dataLabel="Type">
                                    <BrandDot variant="parsons" />
                                </Cell>
                                <Cell dataLabel="ID">10700P</Cell>
                                <Cell dataLabel="Name"> Tony Stark </Cell>
                                <Cell dataLabel="Cremation Date">Friday, March 18, 2018</Cell>
                                <Cell dataLabel="Confirmed">Yes</Cell>
                                <Cell dataLabel="Outstanding Tasks">19</Cell>
                                <Cell dataLabel="Completion Status">40% Complete</Cell>
                            </Row>
                            <Row pad>
                                <Cell dataLabel="Type">
                                    <BrandDot variant="stc" />
                                </Cell>
                                <Cell dataLabel="ID">10700P</Cell>
                                <Cell dataLabel="Name"> Tony Stark </Cell>
                                <Cell dataLabel="Cremation Date">Friday, March 18, 2018</Cell>
                                <Cell dataLabel="Confirmed">Yes</Cell>
                                <Cell dataLabel="Outstanding Tasks">19</Cell>
                                <Cell dataLabel="Completion Status">40% Complete</Cell>
                            </Row>
                            <Row pad>
                                <Cell dataLabel="Type">
                                    <BrandDot />
                                </Cell>
                                <Cell dataLabel="ID">10700P</Cell>
                                <Cell dataLabel="Name"> Tony Stark </Cell>
                                <Cell dataLabel="Cremation Date">Friday, March 18, 2018</Cell>
                                <Cell dataLabel="Confirmed">Yes</Cell>
                                <Cell dataLabel="Outstanding Tasks">19</Cell>
                                <Cell dataLabel="Completion Status">40% Complete</Cell>
                            </Row>
                            <Row pad>
                                <Cell dataLabel="Type">
                                    <BrandDot variant="parsons" />
                                </Cell>
                                <Cell dataLabel="ID">10700P</Cell>
                                <Cell dataLabel="Name"> Tony Stark </Cell>
                                <Cell dataLabel="Cremation Date">Friday, March 18, 2018</Cell>
                                <Cell dataLabel="Confirmed">Yes</Cell>
                                <Cell dataLabel="Outstanding Tasks">19</Cell>
                                <Cell dataLabel="Completion Status">40% Complete</Cell>
                            </Row>
                            <Row pad>
                                <Cell dataLabel="Type">
                                    <BrandDot variant="stc" />
                                </Cell>
                                <Cell dataLabel="ID">10700P</Cell>
                                <Cell dataLabel="Name"> Tony Stark </Cell>
                                <Cell dataLabel="Cremation Date">Friday, March 18, 2018</Cell>
                                <Cell dataLabel="Confirmed">Yes</Cell>
                                <Cell dataLabel="Outstanding Tasks">19</Cell>
                                <Cell dataLabel="Completion Status">40% Complete</Cell>
                            </Row>
                        </ClearTable>
                    </Card>
                </Grid>
            </Grid>
        );
    }

    loadTasks(user, refresh) {
        this.setState({ loading: true });
        const that = this;
        const filterBy = { type: 'all' };
        getUserTasksFunc(user.ID, 0, 0, '', [], filterBy).then(
            originalTasks => {
                const tasks = cloneDeep(originalTasks);
                const taskStatistics = that.getTaskStatistics(tasks, user);
                const trimmedTasks = { edges: tasks.edges.filter(e => !!e) };
                that.setState({ tasks: trimmedTasks, taskStatistics, originalTasks });
                that.loadObjectTasks(tasks);
                that.setState({ loading: false });
            },
            error => {
                that.onGqlError('Failed to load tasks', error);
                that.setState({ loading: false });
            }
        );
    }

    loadObjectTasks(tasks) {
        const objectTasks =
            groupBy(
                tasks.edges.filter(task => isRelatedObjectDefined(task.node.Cremation)),
                task => task.node.Cremation.ID,
                null,
                true
            ) +
            groupBy(
                tasks.edges.filter(task => isRelatedObjectDefined(task.node.Plaque)),
                task => task.node.Plaque.ID,
                null,
                true
            );
        this.setState({ objectTasks });
    }

    getTaskStatistics(tasks, user) {
        const startOfWeek = moment().startOf('week');
        const todayNow = moment();

        let completedThisWeek = 0;
        let newThisWeek = 0;
        let overdue = 0;
        let outstanding = 0;

        for (let x = 0; x < tasks.edges.length; x++) {
            const task = tasks.edges[x].node;

            //find the assigned details
            const assignedMember = task.AssignedMembers.find(e => Number(e.ID) === Number(user.ID));
            if (!assignedMember) continue;

            if (moment(task.Created) >= startOfWeek) {
                //count newly assigned tasks
                newThisWeek++;
            }

            if (!task.Completed) {
                //count all incomplete tasks
                outstanding++;

                //count all overdue tasks
                if (!!task.Due && moment(task.Due) < todayNow) overdue++;
            } else {
                //count tasks completed this week
                if (moment(task.Completed) >= startOfWeek) completedThisWeek++;
            }
        }

        return {
            completedThisWeek,
            newThisWeek,
            overdue,
            outstanding
        };
    }

    onGqlError(action, error) {
        this.props.setSnackbarMessage(action + (error ? ' - ' + error.message : ''));
    }
}

const staffQuery = gql`
    query($from: String!, $to: String!, $filterStaff: [ID], $filterTypes: [String]) {
        readStaffAllocations(from: $from, to: $to, filterStaff: $filterStaff, filterTypes: $filterTypes) {
            ID
            Title
            Start
            End
            Type
            Allocation
            LocationFlattened
            Recurring
            Appointment {
                ID
                Reason
                Type
                Members {
                    ID
                    FirstName
                    Surname
                }
            }
            Member {
                ID
                FirstName
                Surname
            }
        }
    }
`;

const styles = ({ breakpoints, palette }) => ({
    section: {
        margin: '0 12px 0'
    },
    appointmentContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, 50%)',
        [breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(auto-fit, 33.3%)'
        },
        [breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(auto-fit, 100%)'
        },
        margin: '0 -8px'
    },
    appointmentRow: {
        display: 'flex',
        flexGrow: 1,
        margin: '0.5rem'
    },
    appointmentTime: {
        // backgroundColor: palette.contentForeground[FUNERAL_HOME.H_PARSONS.letterCode],
        color: '#FFFFFF',
        padding: '1rem',
        width: 140,
        minWidth: 140,
        whiteSpace: 'pre',
        textAlign: 'center',
        borderRadius: '4px 0 0 4px',
        lineHeight: '0.75',
        '& > small': {
            fontVariant: 'all-small-caps',
            opacity: 0.75
        }
    },
    appointmentDescription: {
        backgroundColor: '#FFFFFF',
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        padding: '0 1rem',
        borderRadius: '0 4px 4px 0',
        '& > p': {
            margin: 0
        }
    },
    right: {
        float: 'right'
    }
});

export default withErrorBoundary(compose(withSnackbarMessage, withStyles(styles))(Dashboard), { fallback: ErrorFallback });
