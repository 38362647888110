import gql from "graphql-tag";

export default gql`
	fragment DocumentTemplate on CMDocumentTemplate
	{
	    ID
		Type
		Title
		Template {
			... on File {
				ID
				Name
				Size
				AbsoluteLink
			}
		}
	}
`;
