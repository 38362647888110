import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 32 32" {...props}>
        <path
            fill="currentColor"
            d="M25.9,1.5H6.1c-2.5,0-4.6,2.1-4.6,4.6v19.8c0,2.5,2.1,4.6,4.6,4.6h19.8c2.5,0,4.6-2.1,4.6-4.6V6.1
	C30.5,3.6,28.4,1.5,25.9,1.5z M23.8,23.4l-0.4,0.4c-0.5,0.5-1.3,0.5-1.8,0L16,18.2l-5.7,5.7c-0.1,0.2-0.5,0.3-0.8,0.3c0,0,0,0,0,0
	c-0.3,0-0.7-0.1-0.9-0.4l-0.4-0.4c-0.5-0.5-0.5-1.3,0-1.8l5.6-5.6l-5.6-5.6c-0.3-0.3-0.4-0.6-0.4-0.9c0-0.3,0.1-0.7,0.4-0.9l0.4-0.4
	c0.5-0.5,1.3-0.5,1.8,0l5.6,5.6l5.6-5.6c0.3-0.3,0.6-0.4,0.9-0.4c0.3,0,0.7,0.1,0.9,0.4l0.4,0.4c0.5,0.5,0.5,1.3,0,1.8L18.2,16
	l5.6,5.6c0.3,0.3,0.4,0.6,0.4,0.9C24.2,22.9,24,23.2,23.8,23.4z"
        />
    </SvgIcon>
);
