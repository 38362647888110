import gql from 'graphql-tag';
import Customer from "./Customer";
import Member from './Member';

export default gql`
    fragment Invoice on CMInvoice {
        ID
        Created
        Hena
        InvoiceDate
        InvoiceDueDate
        InvoiceSent
        InvoicePaid
        GstAmount
        Total
        XeroID
        XeroStatus
        XeroReference
        IsPurchase
        Notes
        Customer{
            ...Customer
        }
        CreatedBy {
            ...Member
        }
        InvoiceItems{
            ID
            Title
            Price
            Quantity
            Prepaid
            HasGST
        }
    }
	${Customer}
	${Member}
`;
