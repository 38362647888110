import { isNullOrUndefined } from './objects';

/**
 * determines if the contact is defined
 * @param {Object} contact: Any database object
 */
export function isContactDefined(contact) {
    return contact && contact.ID !== '0';
}

/**
 * determines if a foreign key object is defined
 * @param {Object} obj: Any database object
 * @param {String} nestedProperty: Name of field or array key
 */
export function isRelatedObjectDefined(obj, nestedProperty = false) {
    if (!nestedProperty) return obj && obj.ID !== '0' && !isNullOrUndefined(obj.ID);

    return isRelatedObjectDefined(obj) && isRelatedObjectDefined(obj[nestedProperty]);
}

/**
 * sanitizes the foreign key from a object to make it save worthy
 * @param {Object} originalObject
 * @param {String} propertyPath
 * @param {Boolean} clone: to create a clone or modify original
 */
export function sanitizeObjectWithFK(originalObject, propertyPath, clone = true) {
    if (!originalObject || !propertyPath) return originalObject;

    const objectWithFK = clone ? { ...originalObject } : originalObject;

    if (isContactDefined(objectWithFK[propertyPath])) {
        // if we have an ID, save the relationship
        objectWithFK[propertyPath + 'ID'] =
            objectWithFK[propertyPath].ID || (!!objectWithFK[propertyPath].node && objectWithFK[propertyPath].node.ID);
    }
    delete objectWithFK[propertyPath]; // prune the related object

    return objectWithFK;
}

export const GST = 0.1;
