import React from 'react';
import gql from 'graphql-tag';
import GqlAutocomplete from './GqlAutocomplete';
import { joinDefined } from '../../utils/strings';

const EnquiryAutoComplete = ({ category, className, name, readAllQuery, clearOnSelect = true, ...props }) => {
    return (
        <GqlAutocomplete
            className={className}
            readAllQuery={readAllQuery || readAllEnquiries}
            placeholder="Enquiry..."
            labelFieldFunc={e =>
                joinDefined(
                    [
                        e.LegacyKey,
                        '-',
                        e.NameOfDeceased ||
                            (e.Deceased && e.Deceased.FirstName,
                            e.Deceased && e.Deceased.MiddleName,
                            e.Deceased && e.Deceased.Surname)
                    ],
                    ' '
                )
            }
            multiple={false}
            name={name}
            clearOnSelect={clearOnSelect}
            {...props}
        />
    );
};

const readAllEnquiries = gql`
    fragment AllEnquiryFragment on CMEnquiry {
        ID
        LegacyKey
        NameOfDeceased
        Deceased {
            ID
            FirstName
            Surname
        }
    }

    query ReadEnquiries($contains: String) {
        readCMEnquiries(contains: $contains, limit: 5, offset: 0) {
            edges {
                node {
                    ...AllEnquiryFragment
                }
            }
        }
    }
`;

export default EnquiryAutoComplete;
