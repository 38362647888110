import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../../components/modal/Modal';
import AddressBookFragment from '../../../fragments/AddressBook';
import ContactDetail from '../ContactDetail';
import DataFormView from '../../../components/DataFormView';

const contactTabs = [
    {
        id: 'Contact',
        label: 'Contact',
        component: withStyles({})(ContactDetail),
        fragment: AddressBookFragment,
        onLoad: data => {},
        formatSaveData: (saveData, state) => {}
    }
];

class ContactModal extends Component {
    render() {
        const { open, onClose } = this.props;
        return (
            <Modal open={open} variant="primary" canClickOut="true" title="Contacts" subtitle="Add New Contact">
                <DataFormView
                    tabs={contactTabs}
                    createNewFunc={() => ({
                        Name: null,
                        AddressLine1: null,
                        AddressLine2: null,
                        Contact: null,
                        Phone: null,
                        Email: null,
                        Fax: null
                    })}
                    objectType="CremationManagerAddressBook"
                    name="CremationManagerAddressBook"
                    context={this}
                    createNew
                    onCreated={e => this.onCreateNew(e)}
                    additionalActions={[
                        {
                            label: 'Cancel',
                            variant: 'secondary',
                            onClick: onClose,
                            skipSave: true
                        }
                    ]}
                />
            </Modal>
        );
    }

    onCreateNew(enquiry) {
        const { onClose } = this.props;

        onClose(enquiry);
    }
}

export default withStyles({})(ContactModal);
