import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';

export default withStyles({})(({ variant, data, children }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `star-rating-component--${variant}`;

    return (
        <div className={`star-rating-component ${cssVariant || ''}`}>
            <div className="data-container">
                <p className="data">{data}/10</p>
            </div>
            <div className="star-position">
                <div className="star-rating-container">
                    <div className="number">
                        <p>{data}</p>
                    </div>
                    <div className="container">{children}</div>
                </div>
            </div>
        </div>
    );
});
