import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';
import { getBrandHomeByKey } from '../../utils/brands';

export default withStyles({})(({ brand, label, variant, legacyKey }) => {
    let cssVariant = null;
    if (!brand) {
        brand = getBrandHomeByKey(legacyKey);
    }
    variant = (brand && brand.style) || 'tableStyle6';
    label = (brand && brand.label) || 'Unknown';
    const prefix = (brand && brand.letterCode) || 'X';

    if (!stringIsNullOrEmpty(variant)) cssVariant = `table-key--${variant}`;

    return (
        <div className={`table-key ${cssVariant || ''}`}>
            <span>{prefix}</span>
            {label}
        </div>
    );
});
