import React, { Component } from 'react';
import Grid from '../../components/layout/Grid';
import TextField from '../../components/form/TextField';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import Select from '../../components/form/Select';
import { AUSSIE_STATES } from '../../utils/arrays';

class ContactDetail extends Component {
    constructor(props) {
        super(props);
        if (props.createNew === true) {
            this.state.readOnly = false;
        }
    }

    state = {
        readOnly: true
    };

    render() {
        const { form, createNew } = this.props;
        const { readOnly } = this.state;

        return (
            <Grid container spacing={16}>
                <Grid item>
                    <TextField
                        label="Business Name"
                        placeholder="Business name"
                        name="Name"
                        form={form}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Contact Person"
                        placeholder="Contact Person"
                        name="Contact"
                        form={form}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label="Phone" placeholder="Phone" name="Phone" form={form} readOnly={readOnly} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label="Mobile" placeholder="Mobile" name="Mobile" form={form} readOnly={readOnly} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField label="Fax" placeholder="Fax" name="Fax" form={form} readOnly={readOnly} />
                </Grid>
                <Grid item>
                    <TextField label="Email" placeholder="Email" name="Email" form={form} readOnly={readOnly} />
                </Grid>
                <Grid item>
                    <TextField
                        label="Address Line 1"
                        placeholder="Address Line 1"
                        name="AddressLine1"
                        form={form}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        label="Address Line 2"
                        placeholder="Address Line 2"
                        name="AddressLine2"
                        form={form}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="Suburb" placeholder="Suburb" name="Suburb" form={form} readOnly={readOnly} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        label="Postcode"
                        placeholder="Postcode"
                        name="Postcode"
                        form={form}
                        readOnly={readOnly}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Select
                        form={form}
                        label="State"
                        placeholder="State"
                        name="State"
                        readOnly={readOnly}
                        options={AUSSIE_STATES}
                    />
                </Grid>

                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings}>
                        <div />
                        {!createNew && readOnly && (
                            <Button variant="primary" onClick={() => this.setState({ readOnly: !readOnly })}>
                                Edit Contact
                            </Button>
                        )}
                    </Inline>
                </Grid>
            </Grid>
        );
    }
}

export default ContactDetail;
