/**
 * custom indexOf function that takes an array and the comparison function
 * @param {*} array
 * @param {*} func
 */
export const indexOf = (array, func) => {
	for (let x = 0; x < array.length; x++) {
		if (func(array[x])) return x;
	}
	return -1;
};

/**
 * removes an item in an array by id
 * @param {*} array
 * @param {*} id
 */
export const removeById = (array, id) => {
	const index = indexOf(array, x => x.ID === id);

	if (index < 0) {
		return;
	}

	array.splice(index, 1);
};

/*
joins an array of sub arrays into one array
*/
export const joinArray = array => {
    let merged = [];
    for (let x = 0; x < array.length; x++) {
        merged = merged.concat(array[x]);
    }
    return merged;
};

/**
 * flattens a list
 */
export const buildFlatList = items => {
	const itemsList = [];

	items.forEach(item => {
		itemsList.push(item);
		if (item.items) {
			buildFlatList(item.items);
		}
	});

	return itemsList;
};

export const AUSSIE_STATES = [
	{ value: 'NSW', label: 'New South Wales' },
	{ value: 'ACT', label: 'Australian Capital Territory' },
	{ value: 'NT', label: 'Northern Territory' },
	{ value: 'QLD', label: 'Queensland' },
	{ value: 'SA', label: 'South Australia' },
	{ value: 'TAS', label: 'Tasmania' },
	{ value: 'VIC', label: 'Victoria' },
	{ value: 'WA', label: 'Western Australia' }
];
