import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../components/layout/Paper';
import Grid from '../../components/layout/Grid';
import DataFormView from '../../components/DataFormView';
import RecordDetailsTab from './RecordDetailsTab';
import ContactDetailsTab from './ContactDetailsTab';
import CremationActionTab from './CremationActionTab';
import BillingTab from './BillingTab';
import CorrespondenceTab from './CorrespondenceTab';
import Button from '../../components/form/Button';
import ViewHeader from '../../components/header/ViewHeader';
import Inline from '../../components/layout/Inline';
import AlertModal from '../../components/modal/AlertModal';
import TextField from '../../components/form/TextField';
import Query from 'react-apollo/Query';
import { joinDefined, PAGE_ORDER_REQUESTS } from '../../utils/strings';
import CremationOrder from '../../fragments/CremationOrder';
import gql from 'graphql-tag';
import { getClient } from '../../utils/apollo';
import BackIcon from '../../components/icon/BackIcon';
import GoFuneral from '../../components/GoFuneral';
import { getUser } from '../../utils/sessions';
import ErrorFallback from '../../components/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

/**
 * Tab Definition
 */
const CremationTabs = [RecordDetailsTab, ContactDetailsTab, CremationActionTab, BillingTab, CorrespondenceTab];

const query = gql`
    ${CremationOrder}
    query readOneCremationOrder($id: ID!) {
        readOneCremationOrder(ID: $id) {
            ID
            ...CremationOrder
        }
    }
`;

class Cremation extends Component {
    state = {
        showPrimaryAlertModal: false
    };

    render() {
        const { showPrimaryAlertModal } = this.state;
        const { match } = this.props;
        return (
            <Fragment>
                <Grid container>
                    <Grid item>
                        <Query query={query} variables={{ id: match.params.id }}>
                            {results => this.renderHeaderAndAlerts(results)}
                        </Query>
                    </Grid>
                    <Grid item>
                        <Paper elevation={0}>
                            <DataFormView
                                tabs={CremationTabs}
                                objectType="CremationOrder"
                                itemId={match.params.id}
                                name="Cremation"
                                // readOnly
                                context={this}
                            >
                                <AlertModal
                                    variant="save"
                                    open={showPrimaryAlertModal}
                                    title="Prompt"
                                    onClose={() => this.setState({ showPrimaryAlertModal: false })}
                                    primaryAction="Yep, accept order"
                                    onClickPrimaryAction={() => this.approveOrder()}
                                    secondaryAction="Actually, not just yet"
                                    onClickSecondaryAction={() => this.setState({ showPrimaryAlertModal: false })}
                                >
                                    <p>Are you sure you'd like to accept this requested order?</p>
                                    <p>
                                        When you accept the order, you cannot undo it and it will be added to the
                                        cremation records list.
                                    </p>

                                    <p>Please provide any comments or changes required to the record:</p>
                                    <TextField placeholder="Enter comments..." multiline />
                                </AlertModal>
                            </DataFormView>
                        </Paper>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    approveOrder = () => {
        const me = this;
        const { match, history } = this.props;
        const user = getUser();
        const ID = match.params.id;
        const variables = { input: { ID: ID, CremationPending: false, CreatedByID: user.ID } };
        const mutation = gql`
            mutation ApproveCremationOrder($input: UpdateCremationOrderInput!) {
                updateCremationOrder(input: $input) {
                    ID
                    CremationPending
                }
            }
        `;
        getClient()
            .mutate({ mutation, variables })
            .then(
                data => {
                    if (data.updateCremationOrder) {
                        history.push('/cremations/' + ID);
                    }
                    me.setState({ showPrimaryAlertModal: false });
                },
                e => {
                    console.error('gql error', e);
                }
            );
    };

    onCreateNew(cremationOrder) {
        const { history } = this.props;
        history.push(`cremation/${cremationOrder.ID}`);
    }

    renderHeaderAndAlerts = results => {
        const { loading, data } = results;
        if (loading) return null;
        const result = data.readOneCremationOrder;
        return (
            <Fragment>
                {this.renderHeader(result)}
                {this.renderOrderRequest(result)}
                {this.renderAlerts(result)}
            </Fragment>
        );
    };

    renderHeader(cremation) {
        const { history } = this.props;
        const subtitle =
            (cremation &&
                cremation.LegacyKey &&
                cremation.Enquiry &&
                joinDefined(
                    [
                        cremation.LegacyKey,
                        cremation.Deceased.Surname,
                        cremation.Deceased.FirstName,
                        cremation.Deceased.MiddleName
                    ],
                    ' '
                )) ||
            'Unknown';

        return (
            <ViewHeader variant="record" title="Cremation Request" subtitle={subtitle}>
                <Button variant="secondary" onClick={() => history.push(PAGE_ORDER_REQUESTS)}>
                    <BackIcon /> Back
                </Button>
                {!!cremation && !!cremation.Funeral && <GoFuneral funeral={cremation.Funeral} />}
            </ViewHeader>
        );
    }

    renderOrderRequest(cremation) {
        if (!cremation) return null;

        return (
            <Grid container>
                {(cremation.CremationCancelled && (
                    <Grid item>
                        <h2>This order was cancelled.</h2>
                    </Grid>
                )) ||
                    (cremation.CremationPending && (
                        <Grid item>
                            <h2>Would you like to accept this order?</h2>
                            <Inline>
                                <Button
                                    variant="confirmation"
                                    onClick={() => this.setState({ showPrimaryAlertModal: true })}
                                >
                                    Accept Order...
                                </Button>
                                {/*<Button variant="secondary">Make Changes</Button>*/}
                            </Inline>
                        </Grid>
                    )) || (
                        <Grid item>
                            <h2>This order was accepted.</h2>
                        </Grid>
                    )}
            </Grid>
        );
    }

    renderAlerts() {
        return (
            <Fragment>
                {/*
                //Document Alerts...
                <AlertBar variant="warning">
                    Warning, important documents are missing.
                </AlertBar>
                */}
            </Fragment>
        );
    }
}

export default withErrorBoundary(compose(withRouter, withStyles({}))(Cremation), { fallback: ErrorFallback });
