import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';

// The SVG linear gradients require an ID instead of a className.
// We use this function to ensure it's always unique per instance.
// A simple increment works for both server and client rendering and avoids checksum mismatches.
let idInc = 1;
const getUniqueID = () => `spinner_${idInc++}`;

class Spinner extends Component {

	render() {
		const { delayVisibility, size } = this.props;
		return (
			<div
				className={`spinner ${delayVisibility ? 'spinner--delay' : ''} spinner--${size || 'sm'}`}
			>
				<svg className="circle" viewBox="0 0 32 32">
					<defs>
						<linearGradient
							id={`${this.id}-g1`}
							gradientUnits="objectBoundingBox"
							x1="1"
							y1="0"
							x2="0"
							y2="0"
						>
							<stop
								offset="0%"
								stopColor="currentColor"
								stopOpacity="0"
							/>
							<stop
								offset="100%"
								stopColor="currentColor"
								stopOpacity="0.5"
							/>
						</linearGradient>
						<linearGradient
							id={`${this.id}-g2`}
							gradientUnits="objectBoundingBox"
							x1="0"
							y1="0"
							x2="1"
							y2="0"
						>
							<stop
								offset="0%"
								stopColor="currentColor"
								stopOpacity="0.5"
							/>
							<stop
								offset="100%"
								stopColor="currentColor"
								stopOpacity="1"
							/>
						</linearGradient>
					</defs>
					<g fill="none" strokeWidth="3">
						<path
							d="m16,16m-14,0a14,14 0 1,0 28,0"
							stroke={`url(#${this.id}-g1)`}
						/>
						<path
							d="m30,16a14,14 0 1,0 -28,0"
							stroke={`url(#${this.id}-g2)`}
						/>
					</g>
				</svg>
			</div>
		);
	}

	id = getUniqueID();
}

export default withStyles({})(Spinner);
