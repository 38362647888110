import gql from 'graphql-tag';
import CremationManagerAddressBook from './AddressBook';
import Client from './Client';
import Deceased from './Deceased';

export default gql`
    fragment Appointment on Appointment {
        ID
        Date
        DateTo
        TimeFrom
        TimeTo
        Reason
        Type
        AllDay
        Recuring
        Office
        Comment
        Phone
        Cremation {
            ID
            LegacyKey
            Deceased {
                ...Deceased
            }
        }
        Plaque {
            ID
            LegacyKey
            Deceased {
                ...Deceased
            }
        }
        EnquiryCM {
            ID
            LegacyKey
            Enquirer {
                ...Client
            }
            Deceased {
                ...Deceased
            }
        }
        LocationType
        LocationCM {
            ...CremationManagerAddressBook
        }
        LocationResidentialAddress {
            ID
            AddressLine1
            AddressLine2
            Suburb
            State
            Postcode
            Country
        }
        Members {
            ID
            FirstName
            Surname
        }
    }
    ${CremationManagerAddressBook}
    ${Client}
    ${Deceased}
`;
