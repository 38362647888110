import React from 'react';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

export default withStyles({})(({ className, classes, children, ...props }) => {
	return (
		<Paper className={`paper ${className || ''}`} {...props}>
			{children}
		</Paper>
	);
});
