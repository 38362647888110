import gql from "graphql-tag";
import Member from "./Member";

export default gql`
	fragment AshLocationChange on AshLocationChange {
		ID
		Created
		Location
		Ashes
		LocationDetail
		Member {
			...Member
		}
	}
	${Member}
`;
