import React from 'react';
import {withStyles} from '@material-ui/core/styles';

const Label = ({ text, children, className }) => (
	<label className={`form-label ${className || ''}`} >
		{children || text}
	</label>
);

export default withStyles({})(Label);
