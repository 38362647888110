import gql from 'graphql-tag';

export default gql`
    fragment CremationOrderProductOrder on AssociatedProduct {
        ID
        ProductCode
        Description
        Link
    }
`;
