import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M508.48 184.485l-92.48 92c-4.68 4.68-12.28 4.68-16.97 0l-7.07-7.07c-4.68-4.68-4.68-12.28 0-16.971L452.89 192H12c-6.62 0-12-5.37-12-12v-8c0-6.62 5.37-12 12-12h440.905l-60.94-60.444c-4.68-4.68-4.68-12.28 0-16.971l7.07-7.07c4.68-4.68 12.28-4.68 16.97 0l92.48 92c4.68 4.68 4.68 12.28 0 16.97zm-504.97 160l92.48 92c4.68 4.68 12.28 4.68 16.97 0l7.07-7.07c4.68-4.68 4.68-12.28 0-16.971L59.09 352H500c6.62 0 12-5.37 12-12v-8c0-6.62-5.37-12-12-12H59.107l60.93-60.444c4.68-4.68 4.68-12.28 0-16.971l-7.07-7.07c-4.68-4.68-12.28-4.68-16.97 0l-92.48 92c-4.68 4.68-4.68 12.28 0 16.97z"
        />
    </SvgIcon>
);
