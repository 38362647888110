import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Typography from '@material-ui/core/Typography/Typography';
import React, { Component, Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Label from '../../components/form/Label';
import GatedComponent from '../../components/GatedComponent';
import Grid from '../../components/layout/Grid';
import Inline from '../../components/layout/Inline';
import Paper from '../../components/layout/Paper';
import { withSnackbarMessage } from '../../context/SnackbarMessage';
import { joinDefined } from '../../utils/strings';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

class Account extends Component {
    render() {
        const loading = false;
        const { classes } = this.props;
        return (
            <Fragment>
                <Typography variant="headline" gutterBottom className={classes.pageTitle}>
                    <Inline lineHeight="normal">
                        <span>Account Settings</span>
                    </Inline>
                </Typography>
                <br />
                {loading ? <CircularProgress /> : this.renderUser()}
            </Fragment>
        );
    }

    renderUser() {
        const { classes } = this.props;
        return (
            <Paper className={classes.paper} elevation={0}>
                <GatedComponent canViewCode="OBJEDITALL">
                    {(canEdit, data) => {
                        const user = data.readCurrentUser;
                        return (
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <Typography>
                                        <Label text="First Name: " />
                                        {user.FirstName}
                                    </Typography>
                                    <Typography>
                                        <Label text="Surname: " />
                                        {user.Surname}
                                    </Typography>
                                    <Typography>
                                        <Label text="Email: " />
                                        {user.Email}
                                    </Typography>
                                    <Typography>
                                        <Label text="Phone: " />
                                        {user.Phone}
                                    </Typography>
                                    <Typography>
                                        <Label text="Teams: " />
                                        {joinDefined(
                                            user.Groups.map(e => e.Title),
                                            ', '
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                </GatedComponent>
            </Paper>
        );
    }
}

export default withErrorBoundary(compose(withRouter, withSnackbarMessage, withStyles({}))(Account), { fallback: ErrorFallback });
