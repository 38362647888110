import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../form/Button';

export default withStyles({})(({ open, onCancel, onAccept, title, message }) => {
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                {onAccept ? (
                    <Fragment>
                        <Button onClick={onCancel} variant="modal-action modal-action--cancel">
                            Cancel
                        </Button>
                        <Button onClick={onAccept} variant="modal-action modal-action--ok" autoFocus>
                            Ok
                        </Button>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button onClick={onCancel} variant="modal-action modal-action--cancel" autoFocus>
                            Close
                        </Button>
                    </Fragment>
                )}
            </DialogActions>
        </Dialog>
    );
});
