import gql from "graphql-tag";
import Address from "./Address";

export default gql`
	fragment Client on Client {
		ID
		RelationshipToDeceased
		Title
		FirstName
		MiddleName
		Surname
		PrimaryNumber
		SecondaryNumber
		Email
		NoEmailAvailable
		Notes
		PostalAddressSameAsResidentialAddress
		PostalAddress {
			...Address
		}
		ResidentialAddress {
			...Address
		}
	}
	${Address}
`;
