import React from 'react';
import { getProperty, isNullOrUndefined } from './objects';
import { joinDefined, stringIsNullOrEmpty } from './strings';
import TickCircleIcon from '../components/icon/TickCircleIcon';
import ExclamationTriangleIcon from '../components/icon/ExclamationTriangleIcon';
import ExclamationCircleIcon from '../components/icon/ExclamationCircleIcon';

/*
To apply a validation to a dataform, set the following properties on the Tab object:
export const MyTab = {
    label: 'Deceased',
    component: Deceased,
    fragment: DeceasedFragment,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {},

    validation: {
        required: ["FirstName", "Surname"],
        optional: ["MiddleName"],
        suggested: ["KnownAs"],

        //newValue = the value that is about to be set
        //persistedValue = the old value that is saved on to the database OR the new value to be set
        //hasValue = if the value is changing (a new value has been specified)
        //getField = gets an old or new value off the existing database
        onValidate: {
            "PlaceOfViewingItems[0].Date": (newValue, persistedValue, hasValue, getField) => {

                if(!hasValue || isNullOrUndefined(newValue)){
                    return validationHelper.ok();
                }

                const placeOfViewing = getField('PlaceOfViewingItems[0].Location');

                return isRelatedObjectUndefined(placeOfViewing)
                    ? validationHelper.required("Please select a Place of Viewing", ["PlaceOfViewingItems[0].Location"])
                    : validationHelper.ok();
            }
        }
    }
};
*/

export const messages = {
    invalidEmail: 'Please enter a valid email address',
    required: 'This field is required',
    suggested: 'This field should be filled out',
    optional: 'This field could be useful',
    atLeastOne: text => `Add at least one ${text}`,
    missing: text => `${text} is missing`
};

export const requirement = {
    optional: 0,
    suggested: 1,
    required: 2
};

export const validationHelper = {
    optional: (message = messages.optional, dependentFields = []) => ({
        message,
        requirement: requirement.optional,
        valid: true,
        shouldUpdate: false,
        dependentFields
    }),
    suggested: (message = messages.suggested, dependentFields = []) => ({
        message,
        requirement: requirement.suggested,
        valid: true,
        shouldUpdate: false,
        dependentFields
    }),
    required: (message = messages.required, dependentFields = []) => ({
        message,
        requirement: requirement.required,
        valid: false,
        shouldUpdate: false,
        dependentFields
    }),
    ok: () => ({
        message: null,
        requirement: null,
        valid: true,
        shouldUpdate: false,
        dependentFields: []
    })
};

/**
 * joins multiple results together
 */
export const joinValidationResults = validationResults => {
    if (isNullOrUndefined(validationResults)) {
        console.warn('Validation results null. cannot be joined');
        return validationHelper.ok(); //THIS SHOULD NEVER HAPPEN
    }

    if (validationResults.length === 0) return validationHelper.ok();

    let validationResult = validationHelper.ok();

    let messages = [];
    validationResults.forEach(result => {
        messages.push(result.message);
        if (isNullOrUndefined(validationResult.requirement) || result.requirement > validationResult.requirement)
            validationResult.requirement = result.requirement;

        if (!result.valid && validationResult.valid) validationResult.valid = false;

        if (result.shouldUpdate && !validationResult.shouldUpdate) validationResult.shouldUpdate = true;

        if (result.dependentFields.length > 0)
            validationResult.dependentFields = validationResult.dependentFields.concat(result.dependentFields);
    });

    validationResult.message = joinDefined(
        messages.filter((value, index, list) => {
            return list.indexOf(value) === index;
        }),
        ', '
    );

    return validationResult;
};

/**
 * entry point to validate the dataform
 */
export const validationUtility = {
    createState: () => {
        return createValidationState();
    },

    validate: (dataForm, validation) => {
        if (isNullOrUndefined(validation)) return;

        //reset the state
        dataForm.state.validation = createValidationState();
        let invalidCount = 0;
        let validationResults = [];

        Object.keys(validation).forEach(property => {
            const validationSummary = validate(dataForm, validation[property], property);
            invalidCount += validationSummary.invalid;
            hideOrShowBadge(validationSummary.invalid, `[tab-error='${property}']`);
            hideOrShowBadge(validationSummary.suggested, `[tab-suggested='${property}']`);
            validationResults.push(validationSummary);
        });

        return invalidCount === 0;
    },

    validateTabs: (dataForm, tabs) => {
        //reset the state
        dataForm.state.validation = createValidationState();
        let invalidCount = 0;
        let validationResults = [];
        for (const property in tabs) {
            const tab = tabs[property];
            const loaded = dataForm.loaded[tab.id] === true;
            if (isNullOrUndefined(tab.validation) || !loaded) continue;

            const validationSummary = validate(dataForm, tab.validation, tab.label);
            invalidCount += validationSummary.invalid;

            hideOrShowBadge(validationSummary.invalid, `[tab-error='${tab.id}']`);
            hideOrShowBadge(validationSummary.suggested, `[tab-suggested='${tab.id}']`);

            validationResults.push(validationSummary);
        }

        return invalidCount === 0;
    },

    getValidationResult: (fieldName, validation, forceFieldNameAsKey = false, revalidate = false) => {
        if (typeof fieldName === 'string' || forceFieldNameAsKey) {
            return getFieldValidationResult(fieldName, validation, revalidate);
        } else if (typeof fieldName === 'object') {
            return getComponentFieldsValidationResult(fieldName, validation, revalidate);
        }

        console.warn('Unknown validation type', fieldName, validation);
        return validationHelper.ok(); //THIS SHOULD NEVER HAPPEN
    }
};

/**
 * gets an individual field validation result
 * @param {*} fieldName
 * @param {*} validation
 * @param {*} revalidate
 */
const getFieldValidationResult = (fieldName, validation, revalidate = false) => {
    let validationResult = null;
    const dependentField = validation.__dependentLookup[fieldName];

    if (dependentField) {
        //select the dependent field for validation
        validationResult = validation[dependentField];
    } else if (
        validation[fieldName] &&
        (isNullOrUndefined(validation[fieldName].dependentFields) || validation[fieldName].dependentFields.length === 0)
    ) {
        //select the original validation, as long as its not a dependent validation
        validationResult = validation[fieldName];
    }

    if (!validationResult) return validationHelper.ok();

    /*todo: re-enable this later
    don't revalidate right now. the tab badget doesn't update at the same time. disconnected experience
    if(!revalidate || !validationResult.revalidate)
        return validationResult;

    return validationResult.revalidate();
    */
    return validationResult;
};

/**
 * gets component field validation results
 */
const getComponentFieldsValidationResult = (componentFields, validation, revalidate = false) => {
    const validationResults = Object.keys(componentFields).map(key =>
        getFieldValidationResult(componentFields[key], validation, revalidate)
    );
    return joinValidationResults(validationResults);
};

/**
 * hides or shows the badge element
 */
const hideOrShowBadge = (issues, elementKey) => {
    const element = document.querySelector(elementKey);
    if (isNullOrUndefined(element)) return;

    if (issues > 0) {
        element.style.display = 'flex';
        element.innerHTML = issues;
    } else {
        element.style.display = 'none';
        element.innerHTML = '';
    }
};

const createValidationState = () => {
    return { __dependentLookup: {}, tabs: {} };
};

/**
 * combines an old and new validation result
 */
const revalidate = (oldValidationResult, newValidationResult, dataForm, fieldName) => {
    newValidationResult.shouldUpdate = oldValidationResult.valid !== newValidationResult.valid;
    if (isNullOrUndefined(fieldName)) {
        console.error('fieldName is null!', oldValidationResult, newValidationResult, dataForm, fieldName);
        return null;
    }
    dataForm.state.validation[fieldName] = {
        ...oldValidationResult,
        ...newValidationResult
    };
    return newValidationResult;
};

const validate = (dataForm, validation, componentName) => {
    //validate using simple validation
    let validationResults = verifyFields(validation.required, requirement.required, dataForm, componentName)
        .concat(verifyFields(validation.suggested, requirement.suggested, dataForm, componentName))
        .concat(verifyFields(validation.optional, requirement.optional, dataForm, componentName));

    //validate using complex validation
    if (validation.onValidate) {
        const getOtherField = otherFieldName => getOtherDataFormField(dataForm, otherFieldName, componentName);

        Object.keys(validation.onValidate).forEach(fieldName => {
            const createResult = () => {
                const onValidateField = validation.onValidate[fieldName];
                const newValue = getProperty(dataForm.input, fieldName);
                const originalSource = dataForm.original[componentName] || dataForm.original;
                const originalValue = getProperty(originalSource, fieldName);
                const hasValue = fieldHasValue(newValue, originalValue);
                return onValidateField(
                    newValue,
                    newValue !== undefined ? newValue : originalValue,
                    hasValue,
                    getOtherField
                );
            };

            const validationResult = createResult();
            if (isNullOrUndefined(validationResult)) {
                console.error(
                    componentName + '_' + fieldName + ' custom validation returned nothing. It must return something'
                );
                //THIS SHOULD NEVER HAPPEN
            } else {
                validationResults.push({
                    fieldName,
                    validationResult,
                    revalidate: () => revalidate(validationResult, createResult(), dataForm, fieldName)
                });
            }
        });
    }

    //set states
    validationResults.forEach(({ validationResult, revalidate, fieldName }) => {
        if (isNullOrUndefined(fieldName)) {
            console.error('fieldName is null!', validationResult, revalidate, fieldName);
            //THIS SHOULD NEVER HAPPEN
        } else {
            if (validationResult.dependentFields && validationResult.dependentFields.length > 0) {
                //set the dependent field's validation
                validationResult.dependentFields.forEach(dependentField => {
                    dataForm.state.validation.__dependentLookup[dependentField] = fieldName;
                });
            }

            dataForm.state.validation[fieldName] = {
                ...validationResult,
                revalidate
            };
        }
    });

    //calculate the summary
    const summary = {
        invalid: 0,
        suggested: 0,
        optional: 0
    };
    for (let x = 0; x < validationResults.length; x++) {
        if (!validationResults[x].validationResult.valid) {
            summary.invalid++;
        } else if (validationResults[x].validationResult.requirement === requirement.suggested) {
            summary.suggested++;
        } else if (validationResults[x].validationResult.requirement === requirement.optional) {
            summary.optional++;
        }
    }

    //console.log('!!VALIDATION!!:' + componentName + ':', validationResults);
    return summary;
};

/**
 * gets the new value to set the field OR existing field value
 * @param {*} dataForm
 * @param {*} fieldName
 * @param tab
 */
const getOtherDataFormField = (dataForm, fieldName, tab = false) => {
    const newValue = getProperty(dataForm.input, fieldName);
    let originalValue;
    if (tab) {
        originalValue = getProperty(dataForm.original[tab], fieldName);
    } else {
        originalValue = getProperty(dataForm.original, fieldName);
    }

    return newValue !== undefined ? newValue : originalValue;
};

/**
 * iterates over a collection of optional, required or recommend fields and tests if they are set or unset
 * @param {*} fieldNames
 * @param fieldRequirement
 * @param {*} dataForm
 * @param componentName
 */
const verifyFields = (fieldNames, fieldRequirement, dataForm, componentName) => {
    if (!fieldNames) return [];

    let unsetFunction = null;
    if (fieldRequirement === requirement.optional) unsetFunction = () => validationHelper.optional(messages.optional);
    else if (fieldRequirement === requirement.suggested)
        unsetFunction = () => validationHelper.suggested(messages.suggested);
    else if (fieldRequirement === requirement.required)
        unsetFunction = () => validationHelper.required(messages.required);
    else {
        console.error('unknown requirement level ', fieldRequirement);
        return [];
    }

    return fieldNames
        .filter(fieldName => isFieldLoaded(dataForm, fieldName, componentName))
        .map(fieldName => {
            const createResult = () =>
                isIncomingDataFormValueNotEmpty(dataForm, fieldName, componentName)
                    ? validationHelper.ok()
                    : unsetFunction();

            const validationResult = createResult();

            return {
                fieldName,
                validationResult,
                revalidate: () => revalidate(validationResult, createResult(), dataForm, fieldName)
            };
        });
};

/**
 * checks a dataform's value against its new and old value to see if its set or about to be set to null
 */
const isIncomingDataFormValueNotEmpty = (dataForm, fieldName, componentName) => {
    const newValue = getProperty(dataForm.input, fieldName);
    const originalSource = dataForm.original[componentName] || dataForm.original;
    const originalValue = getProperty(originalSource, fieldName);
    return isIncomingValueNotEmpty(newValue, originalValue);
};

/**
 * checksnetworkInterfaces a new and old value to see if the new value will be present when applied
 * @param {*} newValue
 * @param {*} originalValue
 */
const isIncomingValueNotEmpty = (newValue, originalValue) => {
    //new value specified
    if (!isNullOrUndefined(newValue)) return true;

    //the incoming value is not present, but the old value is present
    if (newValue === undefined && !isNullOrUndefined(originalValue)) return true;

    //new value is null and old value is either present or unset
    return false;
};

/**
 * filter values that will be set
 */
const isFieldLoaded = (dataForm, fieldName, componentName) => {
    const originalSource = dataForm.original[componentName] || dataForm.original;
    const originalValue = getProperty(originalSource, fieldName);

    return originalValue !== undefined;
};

const fieldHasValue = (newValue, originalValue) => {
    //new value specified
    if (newValue !== undefined) return true;
    if (newValue === null) return false;

    //new value is undefined, check if the original value is present
    return !isNullOrUndefined(originalValue);
};

/**
 * gets the validation decorations
 */
export const getValidationDecorations = (props, componentFields, prefix) => {
    const { form, name, classes, error } = props;

    let decorations = {
        inError: false,
        errorMsg: '',
        cssClass: '',
        focusedClass: classes.focused,
        validationLabel: classes.validationLabel,
        labelShrinkClass: classes.labelShrink,
        validationIcon: null
    };

    let validationResult;
    if (form) {
        if (!form.getValidation) {
            console.warn(name + ' form does not contain validation function!');
            return decorations;
        }

        if (!isNullOrUndefined(componentFields)) {
            validationResult = form.getValidation(componentFields);
        } else if (!isNullOrUndefined(name)) {
            validationResult = form.getValidation(name);
        } else {
            console.warn('no idea what validation to use on ' + name);
            return decorations;
        }
    }

    if (isNullOrUndefined(form) && isNullOrUndefined(name) && !isNullOrUndefined(props.validationResult)) {
        validationResult = props.validationResult;
    }

    if (validationResult) {
        if (validationResult.requirement === requirement.required || !validationResult.valid) {
            decorations.inError = true;
            decorations.errorMsg = validationResult.message;
            decorations.cssClass = `${prefix || ''}error`;
            decorations.validationResult = validationResult;
            decorations.validationIcon = <ExclamationCircleIcon />;
        } else if (validationResult.requirement === requirement.suggested) {
            decorations.inError = true;
            decorations.errorMsg = validationResult.message;
            decorations.cssClass = `${prefix || ''}suggested`;
            decorations.validationResult = validationResult;
            decorations.validationIcon = <ExclamationTriangleIcon />;
        } else if (validationResult.requirement === requirement.optional) {
            decorations.inError = true;
            decorations.errorMsg = validationResult.message;
            decorations.cssClass = `${prefix || ''}optional`;
            decorations.validationResult = validationResult;
            decorations.validationIcon = <TickCircleIcon />;
        }
    }

    if (!stringIsNullOrEmpty(error)) {
        decorations.inError = true;
        decorations.errorMsg = error;
        decorations.cssClass = 'validation--error';
        decorations.validationIcon = <ExclamationCircleIcon />;
    }

    return decorations;
};

export const enableValidation = true;

export const isEmailValid = email => {
    if (stringIsNullOrEmpty(email)) return false;

    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i.test(email);
};
