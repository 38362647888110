import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import React, { Component, Fragment } from 'react';
import { buildFlatList } from '../../utils/arrays';
import DrawerList from './DrawerList';

class DrawerComponent extends Component {
    componentWillMount() {
        this.rebuildItemsList();
    }

    render() {
        return (
            <Fragment>
                {this.renderDesktopDrawer()}
                {this.renderMobileDrawer()}
            </Fragment>
        );
    }

    rebuildItemsList() {
        const { items } = this.props;
        if (!items) throw new Error('items not set on drawer');

        const itemsList = buildFlatList(items);
        this.setState({ itemsList });
    }

    renderDesktopDrawer() {
        const { minimized } = this.props;
        return (
            <Hidden smDown implementation="css">
                <Drawer variant="permanent" open className={`drawer ${minimized ? 'drawer--minimized' : ''}`}>
                    {this.renderDrawerList(true)}
                </Drawer>
            </Hidden>
        );
    }

    renderMobileDrawer() {
        const { open, onToggleOpen } = this.props;
        return (
            <Hidden mdUp>
                <Drawer
                    variant="temporary"
                    open={open}
                    onClose={onToggleOpen}
                    classes={{
                        paper: 'drawer-paper'
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {this.renderDrawerList()}
                </Drawer>
            </Hidden>
        );
    }

    renderDrawerList(desktop) {
        const { minimized, onToggleMinimized, onToggleOpen, items } = this.props;

        if (!items) throw new Error('Drawer requires items props');

        const { itemsList } = this.state;

        return (
            <DrawerList
                items={items}
                itemsList={itemsList}
                onClickHamburger={desktop ? onToggleMinimized : onToggleOpen}
                minimized={desktop && minimized}
                {...this.props}
            />
        );
    }
}

export default withStyles({})(DrawerComponent);
