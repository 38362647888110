import gql from "graphql-tag";

export default gql`
	fragment QuoteItem on CMQuoteItem {
		ID
        Qty
        Complimentary
        Optional
        Prepaid
        Price
        HasGST
        Title
        TotalIncGST
	}
`;
