import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 18 18" {...props}>
        <path
            fill="currentColor"
            d="M12.106,2.625c-1.99,0-3.583-1.219-6.041-1.219a7.3,7.3,0,0,0-2.706.508A1.687,1.687,0,1,0,1.125,3.279v14.3A.422.422,0,0,0,1.547,18h.281a.422.422,0,0,0,.422-.422V13.3a10.654,10.654,0,0,1,4.769-1.022c1.99,0,3.583,1.219,6.041,1.219a7.874,7.874,0,0,0,4.571-1.51.842.842,0,0,0,.37-.7v-9.2a.844.844,0,0,0-1.2-.765A11.794,11.794,0,0,1,12.106,2.625ZM1.688.984a.7.7,0,1,1-.7.7A.7.7,0,0,1,1.688.984ZM16.876,11.156a6.92,6.92,0,0,1-3.815,1.219c-2.237,0-3.806-1.219-6.041-1.219a12.568,12.568,0,0,0-4.769.914V3.75A6.92,6.92,0,0,1,6.066,2.531c2.236,0,3.806,1.219,6.041,1.219a12.549,12.549,0,0,0,4.769-1.219Z"
        />
    </SvgIcon>
);
