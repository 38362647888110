import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { joinDefined } from '../../utils/strings';

const TypographyWrapper = ({ children, className, weight, subtitle, variant = 'paragraph', ...props }) => {
    return (
        <span className="typography">
            <Typography
                className={`${joinDefined(
                    [className, weight, variant, subtitle ? variant + '--subtitle' : null],
                    ' '
                )}`}
                //variant={variant}
                {...props}
            >
                {children}
            </Typography>
        </span>
    );
};
export default withStyles({})(TypographyWrapper);
