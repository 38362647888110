import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { isNullOrUndefined } from '../../utils/objects';

const LinearProgressIndicator = ({ progress }) => {
    const isIndeterminate = isNullOrUndefined(progress);

    const indicatorStyle = {
        marginLeft: `${progress}%`
    };

    return (
        <div className="linear-progress-indicator">
            {!isIndeterminate && (
                <span className="progress-indicator" style={indicatorStyle}>
                    <div />
                    <span>{Math.round(progress)}%</span>
                </span>
            )}
            <LinearProgress
                className="progress-bar"
                variant={`${isIndeterminate ? 'indeterminate' : 'determinate'}`}
                value={progress}
            />
        </div>
    );
};

export default withStyles({})(LinearProgressIndicator);
