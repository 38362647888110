import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import Modal from '../../modal/Modal';
import LeftLabel from '../../form/LeftLabel';
import TextField from '../../form/TextField';
import Grid from '../../layout/Grid';

function RecurrenceModal(props) {
    const { open, onClose, task } = props;
    return (
        <Modal
            className="workqueue-page"
            open={open}
            variant="primary"
            onClose={onClose}
            canClickOut="true"
            title={task.ID}
            subtitle="Recurrence Details"
        >
            <Grid item>
                <LeftLabel label="Due Date:">
                    <TextField value={moment(task.Due).format('YYYY-MM-DD')} />
                </LeftLabel>

                {!!task.RecurFreq && (
                    <Fragment>
                        <LeftLabel label="Repeats every:">
                            <div className="multi-fields">
                                <div className="small">
                                    <TextField value={task.RecurFreq} />
                                </div>
                                <div className="large">
                                    <TextField value={task.RecurType} />
                                </div>
                            </div>
                        </LeftLabel>
                        <LeftLabel label="Ends:">
                            <TextField value={task.RecurEnds} />
                        </LeftLabel>
                    </Fragment>
                )}
            </Grid>
        </Modal>
    );
}

export default withStyles({})(RecurrenceModal);
