import React, { Fragment } from 'react';
import { compose } from 'react-apollo';
import Hidden from '@material-ui/core/Hidden';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import { InvoiceModalExtendable } from '../../Cremation/Modals/InvoiceModal';
import { withSnackbarMessage } from '../../../context/SnackbarMessage';
import CloseIcon from '../../../components/icon/CloseIcon';
import UsdCircleIcon from '../../../components/icon/UsdCircleIcon';
import Spinner from '../../../components/loading/Spinner';

class SupplierBillingModal extends InvoiceModalExtendable {
    render() {
        const { form, loading } = this.state;
        if (!form) return null;

        let invoice = form.state;
        const { open } = this.props;

        const editingItemsMode =
            !invoice.XeroStatus || ['NOT EXPORTED', 'DRAFT', 'SUBMITTED'].indexOf(invoice.XeroStatus) > -1;

        const supplier = invoice.Customer && invoice.Customer.CustomerName ? invoice.Customer.CustomerName : '?';

        return (
            <Modal
                open={open}
                variant="primary"
                onClose={this.closeModal}
                canClickOut={false}
                title={`Plaque Order: ${supplier ? supplier : '?'}`}
                subtitle={
                    editingItemsMode
                        ? invoice.ID
                            ? 'Edit Bill #' + invoice.ID
                            : 'Add New Bill'
                        : 'View Bill #' + invoice.ID
                }
            >
                <Grid container>
                    <Grid item>
                        <h4>Bill Details:</h4>
                    </Grid>

                    {this.renderLineItemsTable(editingItemsMode)}

                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div>
                                <Button variant="secondary" onClick={this.closeModal}>
                                    <CloseIcon />
                                    <Hidden smDown> Close</Hidden>
                                </Button>
                            </div>

                            {(editingItemsMode && (
                                <Fragment>
                                    {(!invoice.XeroStatus || invoice.XeroStatus === 'NOT EXPORTED') &&
                                        this.renderSaveButton()}

                                    <Button
                                        variant="primary"
                                        disabled={!editingItemsMode}
                                        onClick={() => this.submitToXero()}
                                    >
                                        {loading === 'xero' ? <Spinner size="xs" /> : <UsdCircleIcon />}
                                        {loading === 'xero' ? ' Submitting...' : ' Submit to Xero'}
                                    </Button>
                                </Fragment>
                            )) || (
                                <p style={{ textAlign: 'right' }}>
                                    <strong>{invoice.XeroReference}</strong>
                                    <br />
                                    Status: <strong>{invoice.XeroStatus}</strong>
                                </p>
                            )}
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    isPurchaseInvoice() {
        return true;
    }

    defaultCustomer() {
        const parentForm = this.props.form;
        const { PlaqueSupplier } = parentForm.state;
        if (PlaqueSupplier) {
            return {
                AddressLine1: PlaqueSupplier.AddressLine1,
                AddressLine2: PlaqueSupplier.AddressLine2,
                Suburb: PlaqueSupplier.Suburb,
                State: PlaqueSupplier.State,
                Postcode: PlaqueSupplier.Postcode,
                BillingId: PlaqueSupplier.ID,
                Country: null,
                Mobile: PlaqueSupplier.Mobile,
                Phone: PlaqueSupplier.Phone,
                FirstName: null,
                MiddleName: null,
                Surname: null,
                CustomerName: PlaqueSupplier.Name,
                BillingSource: 'AddressBook',
                RelationToDeceased: null,
                Email: PlaqueSupplier.Email
            };
        }
    }
}

export default compose(withSnackbarMessage)(SupplierBillingModal);
