import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '../../modal/Modal';
import Task from '../../../fragments/Task';
import TaskModalDetail from './TaskModalDetail';
import DataFormView from '../../DataFormView';
import { getMyClient } from '../../../utils/apollo';
import { getUser } from '../../../utils/sessions';
import { validationHelper } from '../../../utils/validation';
import { isNullOrUndefined } from '../../../utils/objects';
import CloseIcon from '../../icon/CloseIcon';
import moment from 'moment';

export const taskTabs = [
    {
        id: 'Task',
        label: 'Task',
        component: withStyles({})(TaskModalDetail),
        fragment: Task,
        onLoad: data => {},

        formatSaveData: (saveData, state) => {
            const localAssignedMembers = [];
            if (!state.ID || (saveData && saveData.AssignedMembers)) {
                state.AssignedMembers.forEach(function(member) {
                    if (!!member.ID) {
                        localAssignedMembers.push({
                            ID: member.ID
                        });
                    }
                });

                saveData.AssignedMembers = localAssignedMembers;
            }

            const localAssignedTeams = [];
            if (saveData && saveData.AssignedTeams) {
                state.AssignedTeams.forEach(function(team) {
                    if (!!team.ID) {
                        localAssignedTeams.push({
                            ID: team.ID
                        });
                    }
                });

                saveData.AssignedTeams = localAssignedTeams;
            }

            if (saveData && saveData.Cremation && saveData.Cremation.ID) {
                saveData.CremationID = saveData.Cremation.ID;
            }
            if (saveData && saveData.Plaque && saveData.Plaque.ID) {
                saveData.PlaqueID = saveData.Plaque.ID;
            }
            if (saveData && saveData.EnquiryCM && saveData.EnquiryCM.ID) {
                saveData.EnquiryCMID = saveData.EnquiryCM.ID;
            }
            if (saveData && saveData.EnquiryFM && saveData.EnquiryFM.ID) {
                saveData.EnquiryFMID = saveData.EnquiryFM.ID;
            }
            if (saveData && saveData.Funeral && saveData.Funeral.ID) {
                saveData.FuneralID = saveData.Funeral.ID;
            }
            delete saveData.Cremation;
            delete saveData.Plaque;
            delete saveData.EnquiryCM;
            delete saveData.EnquiryFM;
            delete saveData.Funeral;
        },
        validation: {
            required: ['Title', 'Description'],
            suggested: ['LinkURL'],
            onValidate: {
                Due: (newValue, persistedValue, hasValue, getField) => {
                    if (!hasValue || isNullOrUndefined(newValue)) {
                        if (getField('Category') === 'Goal') {
                            return validationHelper.required('This field is required for goals');
                        } else {
                            return validationHelper.optional();
                        }
                    } else if (!moment(getField('Due')).isValid()) {
                        return validationHelper.required('Date/time is invalid');
                    }
                    return validationHelper.ok();
                },
                Funeral: (newValue, persistedValue, hasValue, getField) => {
                    const val = newValue || persistedValue || null;
                    if (getField('Category') === 'Funeral' && (!val || isNullOrUndefined(val.ID) || !Number(val.ID))) {
                        return validationHelper.required();
                    }
                    return validationHelper.ok();
                },
                EnquiryFM: (newValue, persistedValue, hasValue, getField) => {
                    const val = newValue || persistedValue || null;
                    if (
                        getField('Category') === 'EnquiryFM' &&
                        (!val || isNullOrUndefined(val.ID) || !Number(val.ID))
                    ) {
                        return validationHelper.required();
                    }
                    return validationHelper.ok();
                },
                Cremation: (newValue, persistedValue, hasValue, getField) => {
                    const val = newValue || persistedValue || null;
                    if (
                        getField('Category') === 'Cremation' &&
                        (!val || isNullOrUndefined(val.ID) || !Number(val.ID))
                    ) {
                        return validationHelper.required();
                    }
                    return validationHelper.ok();
                },
                Plaque: (newValue, persistedValue, hasValue, getField) => {
                    const val = newValue || persistedValue || null;
                    if (getField('Category') === 'Plaque' && (!val || isNullOrUndefined(val.ID) || !Number(val.ID))) {
                        return validationHelper.required();
                    }
                    return validationHelper.ok();
                },
                EnquiryCM: (newValue, persistedValue, hasValue, getField) => {
                    const val = newValue || persistedValue || null;
                    if (
                        getField('Category') === 'EnquiryCM' &&
                        (!val || isNullOrUndefined(val.ID) || !Number(val.ID))
                    ) {
                        return validationHelper.required();
                    }
                    return validationHelper.ok();
                }
            }
        }
    }
];

class TaskModal extends Component {
    state = {
        itemID: null
    };

    static getDerivedStateFromProps({ taskID }, { itemID }) {
        if (taskID && (!itemID || taskID !== itemID)) {
            return { itemID: taskID };
        }

        return null;
    }

    render() {
        const { open, onClose, onSaved } = this.props;
        const { itemID } = this.state;
        return (
            <Modal
                open={open}
                variant="task"
                onClose={onClose}
                taskid={itemID}
                title={itemID ? 'Edit Task' : 'Add New Task'}
            >
                <DataFormView
                    tabs={taskTabs}
                    createNewFunc={() => ({
                        IsRecurring: null,
                        TaskFrequency: null,
                        AssignedMembers: [getUser()],
                        AssignedTeams: [],
                        Due: null,
                        Description: null,
                        Title: null,
                        Funeral: null,
                        EnquiryFM: null
                    })}
                    objectType="Task"
                    name="Task"
                    context={this}
                    createNew={!itemID}
                    itemId={itemID}
                    client={getMyClient()}
                    additionalActions={[
                        {
                            label: (
                                <Fragment>
                                    <CloseIcon />
                                    {'Close'}
                                </Fragment>
                            ),
                            variant: 'secondary',
                            onClick: onClose,
                            skipSave: true
                        }
                    ]}
                    onUpdated={onSaved}
                    onCreated={e => this.onCreateNew(e)}
                />
            </Modal>
        );
    }

    onCreateNew(obj) {
        const { onClose } = this.props;
        this.setState({ itemID: obj.ID });
        if (onClose) onClose();
    }
}

export default withStyles({})(TaskModal);
