import React, { Fragment } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from './Button';

class ScrollingWrapper extends React.Component {
    state = { hasScrolled: false };

    scrollingWrapper = React.createRef();

    componentDidMount() {
        this.scrollingWrapper.current.addEventListener('scroll', this.onScroll);
    }

    onScroll = () => {
        if (this.scrollingWrapper.current.scrollTop > 10 && !this.state.hasScrolled) {
            this.setState({ hasScrolled: true });
        } else if (this.scrollingWrapper.current.scrollTop < 10 && this.state.hasScrolled) {
            this.setState({ hasScrolled: false });
        }
    };

    scrollToTop = () => {
        this.scrollingWrapper.current.scrollTop = 0;
    };

    render() {
        const { children } = this.props;

        return (
            <Fragment>
                {this.state.hasScrolled && (
                    <Button
                        variant="primary icon-button top-button shadow"
                        title="Back to top"
                        onClick={this.scrollToTop}
                    >
                        <KeyboardArrowUpIcon />
                    </Button>
                )}
                <div className="scrolling-container" ref={this.scrollingWrapper}>
                    {children}
                </div>
            </Fragment>
        );
    }
}

export default compose(withRouter)(ScrollingWrapper);
