import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { joinDefined, sanitizeForRegExp } from '../../utils/strings';

const viewAllRecords = {
    key: 'records',
    label: 'View all records',
    path: '/search/records',
    viewAll: true
};

const viewSpecificRecords = {
    key: 'specific',
    label: 'View a specific type of records',
    path: '/search/specific-type',
    viewAll: true
};

const defaultItems = [viewAllRecords, viewSpecificRecords];

class HeaderSearchResults extends Component {
    render() {
        const { term, resultsTerm } = this.props;
        return (
            <Paper className="hover-search-results" square>
                {term && resultsTerm ? this.renderResults() : this.renderDefaultItems()}
            </Paper>
        );
    }

    renderDefaultItems() {
        return defaultItems.map(this.renderItem);
    }

    renderResults() {
        const { results } = this.props;
        if (!results || !results.length) return <div className="item">No Results</div>;
        return results.map(this.renderResult);
    }

    renderResult = (result, index) => {
        const { LegacyKey, ID } = result;
        const option = {
            key: LegacyKey,
            label: this.renderHighlightedLabel(result),
            color: 'grey',
            path: `/record/${LegacyKey}/${ID}`
        };
        return this.renderItem(option, index);
    };

    renderHighlightedLabel({ LegacyKey, FirstName, Surname, DateOfBirth }) {
        const { resultsTerm } = this.props;
        let name = joinDefined([FirstName, Surname], ' ');
        if (isNaN(parseInt(resultsTerm, 10)) && name) {
            name = this.highlight(name);
        } else if (LegacyKey) {
            LegacyKey = `ID ${this.highlight(String(LegacyKey))}`;
        }
        const dob = this.formatDateOfBirth(DateOfBirth);
        // We use dangerouslySetInnerHTML so we can render the
        // bold tags as html elements instead of plain text string
        return (
            <span
                dangerouslySetInnerHTML={{
                    __html: joinDefined([name, LegacyKey, dob], ' - ')
                }}
            />
        );
    }

    renderItem = ({ key, label, color, path, viewAll }, index) => {
        if (!key) key = label;
        const { downshiftData: data, history, onItemSelected } = this.props;
        const itemProps = data.getItemProps({ item: key });

        return (
            <MenuItem
                {...itemProps}
                className={`item ${viewAll ? 'view-all' : ''}`}
                selected={data.highlightedIndex === index}
                key={key}
                onClick={() => {
                    history.push(path);
                    onItemSelected();
                }}
            >
                {color && <span className="dot mr-2" style={{ backgroundColor: color }} />}
                <span className="item-label">{label}</span>
            </MenuItem>
        );
    };

    highlight(str) {
        if (!str) return '';
        const { resultsTerm } = this.props;
        // Clean up the input term to work within a regex
        const term = sanitizeForRegExp(resultsTerm);
        // Split the search term into each word to highlight
        return term.split(' ').reduce((acc, word) => {
            // Wrap all matches for the word with bold tags
            return acc.replace(new RegExp(`(${word})`, 'ig'), '<b>$1</b>');
        }, str);
    }

    formatDateOfBirth(dob) {
        return dob ? dob.split('-').join('/') : '';
    }
}

export default compose(
    withRouter,
    withStyles({})
)(HeaderSearchResults);
