import { withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import gql from 'graphql-tag';
import React, { Component, Fragment } from 'react';
import { Mutation } from 'react-apollo';
import { getMaxUploadSize } from '../../environment';
import { getAssetsClient } from '../../utils/apollo';
import { joinDefined } from '../../utils/strings';
import UploadIcon from '../icon/UploadIcon';
import Button from './Button';

class UploadFile extends Component {
    onChange = mutate => ({
        target: {
            validity,
            files: [file]
        }
    }) => {
        const { parentId, folderPath, publish } = this.props;
        const doPublish = publish !== false;

        if (typeof file !== 'undefined') {
            const maxSize = getMaxUploadSize(),
                size = file.size;
            if (maxSize < size)
                return window.alert('Sorry, file is too big!\nLimit is ' + maxSize / 1024 / 1024 + ' MB');
        }

        if (!folderPath) {
            return window.alert('CMS folderPath was not provided, upload cannot start.');
        }

        return (
            validity.valid &&
            mutate({
                variables: {
                    file: file,
                    parentId: parentId,
                    publish: doPublish,
                    folderPath: folderPath
                }
            })
        );
    };

    onCompleted = data => {
        const { name, form, onComplete } = this.props;
        if (onComplete) {
            onComplete(data);
        }

        if (name && form) {
            const { ID, AbsoluteLink, FileName } = (data && data.uploadFile) || {};
            form.setState({
                [`${name}.ID`]: ID,
                [`${name}.AbsoluteLink`]: AbsoluteLink,
                [`${name}.Name`]: FileName.replace(/(.*?\/)+/, '')
            });
        }
    };

    handleDeleteFile = () => {
        const { name, form, onDelete } = this.props;

        if (!name || !form) return;

        if (onDelete) {
            onDelete(form.getState(name));
        } else {
            form.setState({
                [`${name}.ID`]: 0,
                [`${name}.AbsoluteLink`]: null,
                [`${name}.Name`]: null
            });
        }
    };

    render() {
        const content = (mutation, { loading }) => this.renderButton(mutation, loading);

        return (
            <Mutation
                mutation={uploadFilesQuery}
                fetchPolicy="no-cache"
                onCompleted={this.onCompleted}
                client={getAssetsClient()}
            >
                {content}
            </Mutation>
        );
    }

    renderButton(mutate, loading) {
        const { classes, disabled, label = 'Upload File', accept, useIcon, variant = 'primary' } = this.props;
        const randomID = Math.floor(Math.random() * 10000 + 1);
        const isDisabled = !!loading || !!disabled;

        return (
            <Fragment>
                <label htmlFor={!isDisabled ? 'raised-button-file' + randomID : undefined}>
                    <Button
                        variant={joinDefined(
                            [useIcon && 'icon-button', variant ? variant : 'primary', isDisabled && 'disabled'],
                            ' '
                        )}
                        className={classes.uploadImageButton}
                        disabled={isDisabled}
                        component="div"
                    >
                        {loading ? (
                            <CircularProgress
                                size={18}
                                style={{
                                    color: 'white',
                                    marginRight: useIcon ? 0 : '16px'
                                }}
                            />
                        ) : (
                            <UploadIcon />
                        )}

                        {!useIcon && label}
                    </Button>
                </label>
                <input
                    type="file"
                    accept={accept}
                    required
                    style={{ display: 'none' }}
                    className={classes.input}
                    id={'raised-button-file' + randomID}
                    onChange={this.onChange(mutate)}
                    disabled={isDisabled}
                />
            </Fragment>
        );
    }
}

const uploadFilesQuery = gql`
    mutation($file: UploadType!, $parentId: Int, $publish: Boolean, $folderPath: String) {
        uploadFile(file: $file, parentId: $parentId, publish: $publish, folderPath: $folderPath) {
            ID
            FileName
            Title
            ParentID
            AbsoluteLink
        }
    }
`;

export default withStyles({})(UploadFile);

export const IconUploadFile = withStyles({})(({ ...props }) => <UploadFile useIcon {...props} />);

export const getFileName = fileUploadName => {
    const last = fileUploadName.lastIndexOf('/');
    if (last === -1) return fileUploadName;

    return fileUploadName.substring(last + 1);
};
