import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from './TextField';
import moment from 'moment';

class DatePickerField extends Component {
    state = {
        parentDate: moment(this.props.value).format('YYYY-MM-DD'),
        date: moment(this.props.value).format('YYYY-MM-DD'),
        changeReady: false
    };

    static getDerivedStateFromProps(props, state) {
        const parentDate = moment(props.value).format('YYYY-MM-DD');
        if (parentDate !== state.parentDate) {
            return { parentDate, date: parentDate };
        }
        return null;
    }

    render() {
        const {
            state: { date },
            props
        } = this;
        return (
            <TextField
                {...props}
                type={'date'}
                value={moment(date).format('YYYY-MM-DD')}
                onKeyDown={this.handleDatePressed}
                onChange={this.handleDateChange}
                onBlur={this.handleDateBlur}
                onClick={this.handleDateClick}
            />
        );
    }

    handleDateClick = evt => {
        this.setState({ changeReady: true });
    };

    handleDateChange = evt => {
        if (this.state.changeReady) return this.handleDateBlur(evt);
        let date = evt.target.value;
        if (!date) date = moment();
        this.setState({ date: moment(date).format('YYYY-MM-DD') });
    };

    handleDatePressed = evt => {
        if (evt.key && ['Enter', 'Tab'].includes(evt.key)) return this.handleDateBlur(evt);
        this.setState({ changeReady: false });
    };

    handleDateBlur = evt => {
        let date = evt.target.value;
        if (!date) date = moment().format('YYYY-MM-DD');
        if (!date.startsWith('0')) {
            const event = { ...evt };
            event.target.value = date;
            this.props.onChange(event);
        }
    };
}

DatePickerField.defaultProps = {
    onChange: true,
    value: true
};

const styles = () => ({});
export default withStyles(styles)(DatePickerField);
