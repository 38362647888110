import React, { Component } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { joinDefined, stringIsNullOrEmpty } from '../../utils/strings';
import { getValidationDecorations } from '../../utils/validation';
import { getElementValueWrapper, onChangeWrapper } from '../../utils/objects';
import ReadonlyField from './ReadonlyField';

class FormCheckbox extends Component {
    state = {};

    render() {
        const { label, disabled, checked, rejected, readOnly } = this.props;
        const decorations = getValidationDecorations(this.props);
        const value = getElementValueWrapper(this) || checked;

        if (readOnly) return <ReadonlyField label={label}>{value ? 'Yes' : 'No'}</ReadonlyField>;

        return (
            <div
                className={`checkbox ${joinDefined(
                    [
                        decorations.cssClass,
                        disabled ? 'disabled' : null,
                        rejected ? 'rejected' : null,
                        !!value ? 'checked' : ''
                    ],
                    ' '
                )}`}
            >
                {!stringIsNullOrEmpty(label) ? this.renderLabelWithCheckbox() : this.renderCheckbox()}
                {decorations.inError && (
                    <FormHelperText
                        error={decorations.inError === true}
                        classes={{
                            error: `validation-label ${decorations.cssClass}`
                        }}
                    >
                        {decorations.errorMsg}
                    </FormHelperText>
                )}
            </div>
        );
    }

    renderLabelWithCheckbox() {
        const { label } = this.props;
        return <FormControlLabel className="form-label" control={this.renderCheckbox()} label={label} />;
    }

    renderCheckbox() {
        const {
            className,
            id,
            name,
            form,
            label,
            onChange,
            falseValue,
            validationResult,
            emptyValue,
            ...props
        } = this.props;

        const value = getElementValueWrapper(this);

        return (
            <Checkbox
                id={id || (name ? `check-${name}` : undefined)}
                name={name}
                checked={!!value}
                onChange={e => onChangeWrapper(e, this, true)}
                color="primary"
                className="control"
                {...props}
            />
        );
    }
}

FormCheckbox.defaultProps = {
    falseValue: null,
    emptyValue: false
};

export default withStyles({})(FormCheckbox);
