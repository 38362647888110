import gql from "graphql-tag";
import Address from "./Address";

export default gql`
	fragment Deceased on Deceased {
		ID
		Title
		FirstName
		MiddleName
		Surname
		PrimaryNumber
		SecondaryNumber
		Email
		NoEmailAvailable
		Notes
		PostalAddressSameAsResidentialAddress
		Gender
		MaritalStatus
		DateOfBirth
		Age
		DateOfBirthUnknown
		Situation
		DeathStatus
		DateOfDeath1
		DateOfDeath2
		DateOfDeathTiming
		PlaceOfDeathUseResidentialAddress
		TransferFrom
		PostalAddress {
			...Address
		}
		ResidentialAddress {
			...Address
		}
		PlaceOfDeath {
			...Address
		}
	}
	${Address}
`;
