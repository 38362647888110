import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import Button from '../../components/form/Button';
import ViewHeader from '../../components/header/ViewHeader';
import FilterModal from './Modals/FilterModal';
import DataTable from '../../components/dataTable/DataTable';
import EnquiryFragment from '../../fragments/Enquiry';
import { joinDefined, PAGE_ENQUIRIES, prettyPrice } from '../../utils/strings';
import { niceDateTimeFromString } from '../../utils/date';
import TickIcon from '../../components/icon/TickIcon';
import ErrorFallback from '../../components/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

class EnquiryListing extends Component {
    state = {
        showFilterModal: false
    };

    render() {
        const { showFilterModal } = this.state;

        return (
            <Grid container>
                <Grid item>{this.renderHeader()}</Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <Grid item>{this.renderEnquiryListingsTable()}</Grid>
                    </Paper>
                </Grid>
                <FilterModal
                    open={!!showFilterModal}
                    anchorEl={showFilterModal}
                    onClose={() => this.setState({ showFilterModal: false })}
                />
            </Grid>
        );
    }

    renderHeader() {
        return <ViewHeader title="Enquiries" />;
    }

    renderEnquiryListingsTable() {
        return (
            <Grid container>
                <Grid item>
                    <DataTable
                        queryName="readCMEnquiries"
                        dataObject="CMEnquiry"
                        fragment={EnquiryFragment}
                        filterByFragment={enquiryFilterByFragment}
                        columns={enquiryColumns}
                        variables={{ limit: 10, filterBy: [] }}
                        // brandProperty="LegacyKey"
                        searchVariable="contains"
                        //tableTitle="Enquiries Archive"
                    />
                </Grid>
            </Grid>
        );
    }
}

const enquiryColumns = [
    {
        label: 'Ref',
        propertyPath: 'LegacyKey',
        renderCell: (row, value) => (
            <Button href={PAGE_ENQUIRIES + row.ID} variant="link-orange">
                {value}
            </Button>
        )
    },
    {
        label: 'Deceased Name',
        renderCell: row => (
            <span>{joinDefined([row.Deceased.FirstName, row.Deceased.MiddleName, row.Deceased.Surname], ' ')}</span>
        )
    },
    {
        label: 'Enquiry Date',
        renderCell: row => <span>{niceDateTimeFromString(row.Created)}</span>
    },
    {
        label: 'Enquiry Type',
        renderCell: row => <span>{row.EnquiryType}</span>
    },
    {
        label: 'Applicant',
        renderCell: row => (
            <span>
                {!row.ClientSameAsEnquirer
                    ? joinDefined([row.Client.FirstName, row.Client.MiddleName, row.Client.Surname], ' ')
                    : !row.EnquiryByBusiness
                        ? joinDefined([row.Enquirer.FirstName, row.Enquirer.MiddleName, row.Enquirer.Surname], ' ')
                        : row.Business.Name}
            </span>
        )
    },
    {
        label: 'Quotes',
        renderCell: row => <span>{row.Quotes.length}</span>
    },
    {
        label: 'Quote Status',
        renderCell: row => (
            <span>
                {(row.Quotes.length &&
                        (row.Quotes.find(e => !!e.Accepted)
                            ? 'Accepted'
                            : row.Quotes.find(e => !!e.RespondedDate)
                                ? 'Declined'
                                : row.Quotes.find(e => !!e.Released)
                                    ? 'Released'
                                    : 'Draft')) ||
                    ''}
            </span>
        )
    },
    {
        label: 'Quote Amount',
        renderCell: row => (
            <span>
                {(row.Quotes.length &&
                        prettyPrice(
                            (row.Quotes.find(e => !!e.Accepted) && row.Quotes.find(e => !!e.Accepted).CalcQuoteTotal) ||
                            (row.Quotes.find(e => !!e.RespondedDate) &&
                                row.Quotes.find(e => !!e.RespondedDate).CalcQuoteTotal) ||
                            (row.Quotes.find(e => !!e.Released) && row.Quotes.find(e => !!e.Released).CalcQuoteTotal) ||
                            row.Quotes[0].CalcQuoteTotal
                        )) ||
                    ''}
            </span>
        )
    },
    {
        label: 'Accepted',
        renderCell: row => <span>{row.Quotes.find(e => !!e.Accepted) ? <TickIcon /> : ''}</span>
    }
];

const enquiryFilterByFragment = `
    filterByInfo{
        Status{
            Label
            Options
        }
        EnquiryType{
            Label
            Options
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(EnquiryListing), { fallback: ErrorFallback });

