import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 46.8 48.1" {...props}>
        <path
            fill="currentColor"
            d="M5.1,27.3l-2.2-2.2c-0.9-0.9-0.9-2.4,0-3.3c0,0,0,0,0,0L21.8,3c0.9-0.9,2.4-0.9,3.3,0
	c0,0,0,0,0,0l18.9,18.9c0.9,0.9,0.9,2.4,0,3.3c0,0,0,0,0,0l-2.2,2.2c-0.9,0.9-2.4,0.9-3.3,0c0,0,0,0,0,0L27.3,15.6v27.9
	c0,1.3-1,2.3-2.3,2.3c0,0,0,0,0,0h-3.1c-1.3,0-2.3-1-2.3-2.3c0,0,0,0,0,0V15.6L8.4,27.2C7.5,28.2,6.1,28.2,5.1,27.3
	C5.1,27.3,5.1,27.3,5.1,27.3z"
        />
    </SvgIcon>
);
