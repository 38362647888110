import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import Typography from '../../components/form/Typography';
import Inline from '../../components/layout/Inline';
import Checkbox from '../../components/form/Checkbox';
import RadioGroup from '../../components/form/RadioGroup';
import TextField from '../../components/form/TextField';
import Select from '../../components/form/Select';
import SearchIcon from '../../components/icon/SearchIcon';
import { validationHelper } from '../../utils/validation';
import UploadField from '../../components/form/UploadField';
import SearchWithButton from '../../components/form/SearchWithButton';
import NotesReadOnly from '../../components/form/NotesReadOnly';
import SortByDropdown from '../../components/form/SortByDropdown';
import UploadFile, { IconUploadFile } from '../../components/form/FileUpload';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';

class FormElementStyling extends Component {
    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Separator />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Form Elements</Typography>
                </Grid>
                <Grid item xs={12}>
                    {this.renderFormElements()}
                </Grid>
            </Grid>
        );
    }

    renderFormElements() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography variant="h5">Checkbox</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Inline>
                        <Checkbox label="Sample Checkbox" />
                        <Checkbox label="Checked" checked />
                        <Checkbox label="Disabled" checked disabled />
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">Radio</Typography>
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup
                        options={[
                            { label: 'radio item 1', value: 'item1' },
                            { label: 'radio item 1', value: 'item2' },
                            { label: 'radio item 3', value: 'item3' }
                        ]}
                        value="item2"
                        label="My Radio Group"
                    />
                </Grid>
                <Grid item xs={12}>
                    <RadioGroup
                        options={[
                            { label: 'radio item 1', value: 'item1' },
                            { label: 'radio item 1', value: 'item2' },
                            { label: 'radio item 3', value: 'item3' }
                        ]}
                        value="item2"
                        label="Disabled radio group"
                        disabled
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">Input</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Inline>
                        <TextField label="Type Here" placeholder="placeholder" />

                        <TextField label="Label shrink" placeholder="placeholder" value="some text" />

                        <TextField label="Readonly" value="This text cannot be changed" readOnly />

                        <TextField label="Disabled" value="This text is disabled" disabled />

                        <TextField
                            label="Good validation"
                            value="Turkey"
                            validationResult={validationHelper.optional('This is alright')}
                        />

                        <TextField
                            label="Suggested validation"
                            value="Mince"
                            validationResult={validationHelper.suggested('This is a warning')}
                        />

                        <TextField
                            label="Required validation"
                            value="Batman"
                            validationResult={validationHelper.required('This is an error')}
                        />
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">Input with adornments</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Inline>
                        <TextField label="Search" placeholder="Enter a value" icon={<SearchIcon />} />

                        <TextField label="Search" placeholder="Enter a value" icon={<SearchIcon />} loading />
                        <StaffAutoComplete />
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">Text area</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Inline>
                        <TextField multiline label="Textarea Label" placeholder="placeholder" />
                        <TextField multiline label="Textarea Label" value="Some text" />
                        <TextField multiline label="Readonly" value="This text cannot be changed" readOnly />
                        <TextField multiline label="Disabled" value="This text is disabled" disabled />
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h5">Select</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Inline>
                        <Select
                            label="Select the thing"
                            placeholder="select the first option"
                            options={[
                                { label: 'item 1', value: '15' },
                                { label: 'item 2', value: '30' }
                            ]}
                        />

                        <Select
                            label="Readonly"
                            readOnly
                            placeholder="select the first option"
                            options={[
                                { label: 'item 1', value: '15' },
                                { label: 'item 2', value: '30' }
                            ]}
                        />

                        <Select
                            label="Disabled"
                            disabled
                            placeholder="select the first option"
                            options={[
                                { label: 'item 1', value: '15' },
                                { label: 'item 2', value: '30' }
                            ]}
                        />
                    </Inline>
                </Grid>

                <Grid item>
                    <Separator />
                    <br />
                </Grid>

                <Grid item xs={6}>
                    <Typography variant="h4">Attach / Upload Field</Typography>
                    <UploadField />
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h4">Upload File</Typography>
                    <Inline>
                        <div>
                            <UploadFile />
                            {'<'}UploadFile {'/>'}
                        </div>{' '}
                        <div>
                            <IconUploadFile />
                            {'<'}UploadFile useIcon {'/>'}
                        </div>
                    </Inline>
                </Grid>

                <Grid item>
                    <Typography variant="h4">Search Field with a Button</Typography>
                    <SearchWithButton placeholder="Search with Button..." />
                </Grid>

                <Grid item>
                    <Separator />
                    <br />
                </Grid>
                <Grid item>
                    <Typography variant="h4">Sort By</Typography>
                    <SortByDropdown label="sort by" />
                    <SortByDropdown label="Brand:" variant="brand" />
                </Grid>

                <Grid item>
                    <Separator />
                    <br />
                </Grid>

                <Grid item>
                    <h4>Notes history</h4>
                    <p>Tag = NotesReadOnly. Props = name & form, or value</p>
                    <NotesReadOnly
                        name={false}
                        form={false}
                        value={'I am some notes, hear me roar!\nRoar!\n\nHow good was that?'}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default withStyles({})(FormElementStyling);
