import gql from 'graphql-tag';
import CommunicationNoteTemplate from './CommunicationNoteTemplate';

export default gql`
	fragment CommunicationNote on CMCommunicationNote {
		ID
		Created
		LastEdited
		Title
		Description
		EmailSent
		EmailSentAt
		ScheduledToSendEmail
		SendEmail
		EmailApplicant
		EmailAuthorised
		EmailOther
		OtherEmailAddress
		SendMail
		MailApplicant
		MailAuthorised
		MailOther
		OtherMailAddress
		MailDate
		MailTime

        CremationOrderID
        PlaqueOrderID
		
		CreatedBy {
			ID
			FirstName
			Surname
			Email
		}

		Template {
			...CommunicationNoteTemplate
		}

		Attachments {
			... on File {
				ID
				Name
				Size
				AbsoluteLink
			}
			... on Image {
				ID
				Name
				Size
				AbsoluteLink
			}
		}
	}
	${CommunicationNoteTemplate}
`;
