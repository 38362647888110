import React from 'react';
import { isNullOrUndefined } from './objects';

/**
 * Join only the defined strings
 */
export const joinDefined = (strings, separator = '') => {
    return strings.filter(s => !stringIsNullOrEmpty(s)).join(separator);
};

/**
 * pads a string by x zeros
 */
export const pad = (str, padding, character = '0') => {
    if (typeof myVar !== 'string') str += '';
    while (str.length < padding) str = character + str;
    return str;
};

export function prettyPrice(val, raw = false) {
    if (isNullOrUndefined(val)) return null;
    const bits = (1 * val).toFixed(2).split('.');
    const bobs = bits[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (!!raw) return Number(bobs + '.' + bits[1]);
    return (
        <span style={{ whiteSpace: 'pre' }}>
            $ {bobs}
            <small>.{bits[1]}</small>
        </span>
    );
}

export function prettyNumber(val, raw = false) {
    if (isNullOrUndefined(val)) return null;
    const bobs = Number(Number(val).toFixed(2))
        .toString()
        .replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&,');
    if (!!raw) return bobs;
    return <span>{bobs}</span>;
}

export function prettyEmail(val, hideLink = true, raw = false) {
    if (!val) return null;
    const bits = val.toString().split(/[;,]+/);
    return bits.map((bobs, i) => {
        bobs = bobs.trim();
        if (!!raw) return bobs;
        return (
            <span key={i}>
                {i !== 0 && <br />}
                <a style={!!hideLink ? { color: 'inherit', textDecoration: 'none' } : {}} href={'mailto:' + bobs}>
                    {bobs}
                </a>
            </span>
        );
    });
}

export function prettyPhone(val, hideLink = true, raw = false) {
    if (!val) return null;
    const bits = val.toString().split('/');
    const result = bits.map((bobs, i) => {
        bobs = bobs.trim();
        bobs = bobs.replace(/(\d)-(\d)/g, '$1 $2');
        bobs = bobs.replace(/(\d)\s+?(\d)/g, '$1$2');
        bobs = bobs.replace(/^04(\d{2})(\d{3})(\d{3})/g, '04$1 $2 $3');
        bobs = bobs.replace(/^1800(\d{3})(\d{3})/g, '1800 $1 $2');
        bobs = bobs.replace(/^(0\d)(\d{4})(\d{4})/g, '($1) $2 $3');
        bobs = bobs.replace(/(\d{4})(\d{4})/g, '$1 $2');
        bobs = bobs.replace(/^(\d{4})\s?(\d{4})$/g, '(02) $1 $2');
        if (!!raw) return bobs;
        return (
            <span key={i}>
                {i !== 0 && ' / '}
                <a
                    style={!!hideLink ? { color: 'inherit', textDecoration: 'none' } : {}}
                    href={'tel:' + bobs.replace(/[^\d#*]/g, '')}
                >
                    {bobs}
                </a>
            </span>
        );
    });
    if (!!raw && Array.isArray(result)) return result.join(' / ');
    return result;
}

/**
 * check if a string is null or empty
 * @param {*} str
 */
export const stringIsNullOrEmpty = str => {
    return str === null || str === undefined || str === '';
};

/**
 * sanitizes a string for regex
 */
export const sanitizeForRegExp = (text = '') => {
    // $& means the whole matched string
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * capitalizes the first letter of the word
 */
export const capitalize = word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

const first = [
    '',
    'one ',
    'two ',
    'three ',
    'four ',
    'five ',
    'six ',
    'seven ',
    'eight ',
    'nine ',
    'ten ',
    'eleven ',
    'twelve ',
    'thirteen ',
    'fourteen ',
    'fifteen ',
    'sixteen ',
    'seventeen ',
    'eighteen ',
    'nineteen '
];
const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
const mad = ['', 'thousand', 'million', 'billion', 'trillion'];
/**
 * converts a number to words
 */
export const numberToWords = number => {
    let word = '';

    for (let i = 0; i < mad.length; i++) {
        let tempNumber = number % (100 * Math.pow(1000, i));
        if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
            if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
                word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
            } else {
                word =
                    tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] +
                    '-' +
                    first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] +
                    mad[i] +
                    ' ' +
                    word;
            }
        }

        tempNumber = number % Math.pow(1000, i + 1);
        if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0)
            word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hundred ' + word;
    }
    return word.trim();
};

export const getPats = effort => {
    const GOOD_WORK = [
        'Nice work!',
        'Good job!',
        'Congratulations!',
        'Well done!',
        'Amazing!',
        'Woohoo!',
        'Crikey!',
        'You beauty!',
        'High five!',
        'Excellent!',
        'Wow!',
        'Super effort!',
        'Strewth!',
        'Fantastic!',
        'GJWD!',
        "You're doing great!",
        'Nice work speedy gonzalez!',
        'Thank you!'
    ];

    const MORE_WORK = [
        'Just keep at it!',
        "C'mon!",
        "Don't give up!",
        "Let's get on it!",
        'Keep trying!',
        'Starting now!',
        'Warming up!',
        'Bring it on!',
        "Roll up 'ya sleeves!",
        "Let's do it!",
        'You got this!',
        'Who seeks, shall find.',
        "Don't quit now!",
        'Never, never, never give up.',
        'We believe in you!',
        'You can do it!'
    ];
    if (effort > 3) return GOOD_WORK[Math.floor(Math.random() * GOOD_WORK.length)];
    return MORE_WORK[Math.floor(Math.random() * MORE_WORK.length)];
};

export const PAGE_ENQUIRIES = '/enquiries/'
export const PAGE_ORDER_REQUESTS = PAGE_ENQUIRIES + 'order-requests/'
