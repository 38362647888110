import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';
import Spinner from '../loading/Spinner';

export default withStyles({})(({ variant, topText, data, bottomText, loading }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `data--${variant}`;

    return (
        <div className="data-highlight">
            <p>{topText}</p>
            <p className={`data ${cssVariant || ''}`}>{(!!loading && <Spinner />) || data}</p>
            <p>{bottomText}</p>
        </div>
    );
});
