import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import ExclamationCircleIcon from '../icon/ExclamationCircleIcon';
import TickCircleIcon from '../icon/TickCircleIcon';
import InfoCircleIcon from '../icon/InfoCircleIcon';
import ExclamationTriangleIcon from '../icon/ExclamationTriangleIcon';
import FlagIcon from "../icon/FlagIcon";
import FireIcon from "../icon/FireIcon";

class AlertBar extends Component {
	render() {
		const { variant, children } = this.props;
		return (
			<div className={`alert-bar alert-bar--${variant}`}>
				{this.renderIcon()}
				<div className="content">{children}</div>
			</div>
		);
	}

	renderIcon() {
		const { variant } = this.props;
		if (variant === "info")
			return <InfoCircleIcon />;
		if (variant === "success")
			return <TickCircleIcon />;
		if (variant === "warning")
			return <ExclamationTriangleIcon />;
		if (variant === "error")
			return <ExclamationCircleIcon />;
		if (variant === "attention")
			return <FlagIcon />;
		if (variant === "fire")
			return <FireIcon />;
		
		// eslint-disable-next-line no-console
		console.error('unknown variant ' + variant);
		return <b>Unknown variant {variant}</b>;
	}
}

export default withStyles({})(AlertBar);
