import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TextField from '../../components/form/TextField';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import CremationOrderFragment from '../../fragments/CremationOrder';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import CommunicationNoteModal from '../CommunicationNotes/CommunicationNoteModal';
import StaffAutoComplete from '../../components/form/StaffAutoComplete';
import { joinDefined } from '../../utils/strings';
import FileUpload, { getFileName } from '../../components/form/FileUpload';
import DocumentModal from './Modals/DocumentModal';
import { getUser } from '../../utils/sessions';
import AlertBar from '../../components/form/AlertBar';
import AlertModal from '../../components/modal/AlertModal';
import { getRequiredDocumentTypes } from './CremationConstants';
import { getClient } from '../../utils/apollo';
import RejectNotesModal from './Modals/RejectNotesModal';
import Spinner from '../../components/loading/Spinner';
import Checkbox from '../../components/form/Checkbox';
import {
    CloseIcon,
    CommentsIcon,
    DeleteIcon,
    ExclamationTriangleIcon,
    FlagIcon,
    TickIcon
} from '../../components/IconIndex';

class CorrespondenceTab extends Component {
    state = {
        documentModal: {
            open: false,
            document: null
        },
        showApproveAlertModal: false,
        showRejectAlertModal: false,
        showDeleteStaffMemberAlertModal: false,
        processingRequiredDocument: null,
        showRejectNotesModal: false
    };

    render() {
        const {
            documentModal,
            showApproveAlertModal,
            showRejectAlertModal,
            showDeleteStaffMemberAlertModal,
            processingRequiredDocument,
            showRejectNotesModal
        } = this.state;

        const { form, staffMember, index } = this.props;

        const RequiredDocuments = form.getField('RequiredDocuments');
        let foundIndex = -1;
        if (processingRequiredDocument) {
            foundIndex = RequiredDocuments.findIndex(doc => doc.ID === processingRequiredDocument.ID);
        }

        return (
            <Fragment>
                <ColumnLayout>
                    {this.renderLeftColumn()}
                    {this.renderRightColumn()}
                </ColumnLayout>

                <DocumentModal
                    onClose={() => this.setState({ documentModal: { open: false, document: null } })}
                    open={!!documentModal.open}
                    form={form}
                    document={documentModal.document}
                />

                <AlertModal
                    variant="warning"
                    open={showDeleteStaffMemberAlertModal}
                    title="Prompt"
                    primaryAction="Delete"
                    onClickPrimaryAction={() => this.handleDeleteStaffMember(staffMember, index)}
                    secondaryAction="Cancel"
                    onClickSecondaryAction={() => this.handleCloseDeleteStaffMemberAlertModal()}
                    onClose={() => this.handleCloseDeleteStaffMemberAlertModal()}
                >
                    <p>You're about to delete an assigned staff member.</p>
                    <p>Are you sure?</p>
                </AlertModal>

                <AlertModal
                    variant="save"
                    open={showApproveAlertModal}
                    title="Prompt"
                    onClose={() =>
                        this.setState({
                            showApproveAlertModal: false,
                            processingRequiredDocument: null
                        })
                    }
                    primaryAction="Yep, approve it"
                    onClickPrimaryAction={() => this.changeRequiredDocumentStatus()}
                    secondaryAction="Actually, not just yet"
                    onClickSecondaryAction={() =>
                        this.setState({
                            showApproveAlertModal: false,
                            processingRequiredDocument: null
                        })
                    }
                >
                    <p>Are you sure you want to approve this important document?</p>
                </AlertModal>

                <AlertModal
                    variant="error"
                    open={showRejectAlertModal}
                    title="Prompt"
                    onClose={() =>
                        this.setState({
                            showRejectAlertModal: false,
                            processingRequiredDocument: null
                        })
                    }
                    primaryAction="Submit Rejection"
                    onClickPrimaryAction={() => this.changeRequiredDocumentStatus()}
                    secondaryAction="Actually, not just yet"
                    onClickSecondaryAction={() =>
                        this.setState({
                            showRejectAlertModal: false,
                            processingRequiredDocument: null
                        })
                    }
                >
                    <p>Are you sure you want to reject this important document?</p>
                    <p>If so, please provide comments why document is being rejected:</p>
                    <TextField
                        placeholder="Rejection notes are written here..."
                        multiline
                        name={`RequiredDocuments[${foundIndex}].Notes`}
                        form={form}
                    />
                </AlertModal>

                <RejectNotesModal open={showRejectNotesModal} onClose={() => this.handleCloseRejectNotesModal()} />
            </Fragment>
        );
    }

    changeRequiredDocumentStatus = () => {
        const { form } = this.props;
        const { processingRequiredDocument, showRejectAlertModal, showApproveAlertModal } = this.state;

        if (showApproveAlertModal) {
            this.setState({ showApproveAlertModal: false });
        } else if (showRejectAlertModal) {
            this.setState({ showRejectAlertModal: false });
        }

        const RequiredDocuments = form.getField('RequiredDocuments');
        let foundIndex = -1;
        if (processingRequiredDocument) {
            foundIndex = RequiredDocuments.findIndex(doc => doc.ID === processingRequiredDocument.ID);
        }

        const variables = {
            input: {
                ID: processingRequiredDocument.ID,
                ActionedByID: getUser().ID,
                Actioned: moment().format('YYYY-MM-DD HH:mm:ss'),
                Status: showApproveAlertModal ? 'Approved' : showRejectAlertModal ? 'Rejected' : null,
                Notes: showRejectAlertModal ? form.getField(`RequiredDocuments[${foundIndex}].Notes`) : null
            }
        };
        const mutation = gql`
            mutation UpdateRequiredDocument($input: UpdateRequiredDocumentInput!) {
                updateRequiredDocument(input: $input) {
                    ID
                    Created
                    Name
                    Status
                    Notes
                    Actioned
                    ActionedBy {
                        ID
                        FirstName
                        Surname
                    }
                    File {
                        ... on File {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                        ... on Image {
                            ID
                            Name
                            Size
                            AbsoluteLink
                        }
                    }
                }
            }
        `;
        const me = this;
        getClient()
            .mutate({ mutation, variables })
            .then(
                ({ data }) => {
                    const document = data.updateRequiredDocument;
                    me.setState({ processingRequiredDocument: null });
                    if (document) {
                        const RequiredDocuments = (form.getField('RequiredDocuments') || []).filter(e => !!e);
                        const foundIndex = RequiredDocuments.findIndex(doc => doc.ID === document.ID);
                        if (foundIndex >= -1) {
                            delete document['__typename'];
                            RequiredDocuments[foundIndex] = document;
                        }
                        form.setField({ RequiredDocuments }, true);
                    }
                },
                e => {
                    console.error('gql error', e);
                    me.setState({ processingRequiredDocument: null });
                }
            );
    };

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderAssignStaff()}
                {this.renderCommunicationNotes()}
                {this.renderSignificantDocuments()}
            </Fragment>
        );
    }

    renderAssignStaff() {
        const { form } = this.props;
        const staffMembers = form.getState('StaffMembers') || [];

        let showExistingStaffMembers = false;

        staffMembers.forEach(function(staffMember) {
            if (!staffMember.new) {
                showExistingStaffMembers = true;
            }
        });

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Assign Staff Member</h4>
                </Grid>

                {staffMembers.map((staffMember, index) => this.renderAddStaffMember(staffMember, index))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleAddStaffMember()}>
                        + Assign Staff
                    </Button>
                </Grid>

                {showExistingStaffMembers && (
                    <Grid item>
                        <Table>
                            <HeaderRow pad>
                                <Cell colSpan={1}>Name</Cell>
                                <Cell colSpan={1}>Role</Cell>
                                <Cell colSpan={1}>Allocation</Cell>
                                <Cell colSpan={1}>Remove</Cell>
                            </HeaderRow>

                            {staffMembers.map((staffMember, index) => this.renderStaffMember(staffMember, index))}
                        </Table>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderStaffMember(staffMember, index) {
        if (!!staffMember.new) {
            return;
        }

        let staffMemberName = '';

        if (!!staffMember.Staff) {
            staffMemberName = joinDefined([staffMember.Staff.FirstName, staffMember.Staff.Surname], ' ');
        }

        return (
            <Row pad key={index}>
                <Cell dataLabel="Name">{staffMemberName}</Cell>
                <Cell dataLabel="Role">{staffMember.Role}</Cell>
                <Cell dataLabel="Allocation">{staffMember.Allocation}</Cell>
                <Cell dataLabel="Remove">
                    <IconButton
                        className="icon"
                        title={'Delete'}
                        onClick={() => this.handleDeleteStaffMember(staffMember, index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Cell>
            </Row>
        );
    }

    renderAddStaffMember(staffMember, index) {
        const { form } = this.props;

        if (!staffMember.new) {
            return;
        }

        return (
            <Fragment key={index}>
                <Grid variant="justify" item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                        <h5>Staff Member</h5>

                        <IconButton
                            className="icon"
                            title={'Delete'}
                            onClick={() => this.handleShowDeleteStaffMemberAlertModal()}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Inline>
                </Grid>

                <Grid item>
                    <StaffAutoComplete
                        label="Staff Name"
                        placeholder="Search for staff name"
                        selectProps={{ multiple: false }}
                        onSelect={(_, user) => this.handleSelectStaff(`StaffMembers[${index}].Staff`, user)}
                        form={form}
                        name={`StaffMembers[${index}].Staff`}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Role" form={form} name={`StaffMembers[${index}].Role`} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Allocation" form={form} name={`StaffMembers[${index}].Allocation`} />
                </Grid>
            </Fragment>
        );
    }

    renderCommunicationNotes() {
        const { form } = this.props;
        const user = getUser();
        return <CommunicationNoteModal
            orderID={form.getField('ID')}
            orderKey={form.getField('LegacyKey')}
            orderType="CremationOrder"
            orderClient={form.getField('Client')}
            orderAuthorisedContacts={form.getField('AuthorisedContacts')}
            substitutionFields={{
                '[FirstName]': form.getField('Client.FirstName'),
                '[DeceasedFirstName]': form.getField('Deceased.FirstName'),
                '[DeceasedSurname]': form.getField('Deceased.Surname'),
                '[RelationshipToDeceased]': form.getField('Client.RelationshipToDeceased'),
                //'[OfficeName]': form.getField('Office.BusinessName'),
                //'[OfficePhone]': form.getField('Office.Phone'),
                '[CremationOrderKey]': form.getField('LegacyKey'),
                '[StaffName]': user.FirstName + ' ' + user.Surname
            }}
        />;
    }

    renderSignificantDocuments() {
        const { form } = this.props;
        const significantDocuments = form.getState('SignificantDocuments') || [];

        let showExistingDocuments = significantDocuments.length > 0;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Significant Documents</h4>
                </Grid>

                {showExistingDocuments && (
                    <Grid item>
                        <Table>
                            <HeaderRow pad>
                                <Cell colSpan={1}>ID</Cell>
                                <Cell colSpan={1}>Filename</Cell>
                                <Cell colSpan={1}>Size</Cell>
                            </HeaderRow>

                            {significantDocuments.map((significantDocument, index) =>
                                this.renderSignificantDocument(significantDocument, index)
                            )}
                        </Table>
                    </Grid>
                )}

                <Grid item>
                    <Inline>
                        <FileUpload
                            label={'Upload Documents'}
                            folderPath={`/documents/cremation/${form.getField('ID')}`}
                            onComplete={({ uploadFile }) => this.handleUploadSignificantDocument(uploadFile)}
                        />
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderSignificantDocument(significantDocument, index) {
        return (
            <Row pad key={index}>
                <Cell dataLabel="ID">
                    <Button
                        variant="link-orange"
                        href={significantDocument.AbsoluteLink}
                        onClick={e => {
                            e.preventDefault();
                            this.setState({
                                documentModal: { open: true, document: significantDocument }
                            });
                        }}
                    >
                        #{significantDocument.ID}
                    </Button>
                </Cell>
                <Cell dataLabel="Filename">{significantDocument.Name}</Cell>
                <Cell dataLabel="Size">{significantDocument.Size}</Cell>
            </Row>
        );
    }

    renderRightColumn() {
        return <Fragment>{this.renderCremationCerts()}</Fragment>;
    }

    renderCremationCerts() {
        const { form } = this.props;
        const type = form.getField('UseCoronerCertificates') ? 'Coroner' : 'Doctor';
        const documentTypes = getRequiredDocumentTypes(type);
        const documents = [].concat(form.getField('RequiredDocuments') || []);
        const sortedDocs = documents.filter(e => !!e).sort((a, b) => (a.Created < b.Created ? 1 : -1)); // newest ones first
        const currentDocs = documentTypes
            .map(type => sortedDocs.find(doc => type.fieldName === doc.Name))
            .filter(e => !!e); // get first doc for each type
        const allDocs = !!documentTypes.every(type => currentDocs.find(doc => type.fieldName === doc.Name)); // is one of each type
        const allReviewed = !!currentDocs.every(doc => !!doc.Status); // is every status set
        const badDocs = !!currentDocs.find(doc => doc.Status === 'Rejected'); // is any rejects
        const columns = [
            { label: 'Status' },
            { label: 'Document' },
            { label: 'File' },
            { label: 'Size' },
            { label: 'Actions' }
        ];

        return (
            <Grid container bucket>
                <Grid item>
                    <Inline className="button-alignment" center>
                        <h4>Required Documentation</h4>
                    </Inline>
                </Grid>

                <Grid item>
                    <Checkbox
                        label="Coroner Certificates required"
                        form={form}
                        name="UseCoronerCertificates"
                        readOnly={!!form.getField('Funeral.ID')}
                    />
                </Grid>
                <Grid item>
                    <Table columns={columns}>{documentTypes.map((x, i) => this.renderRequiredDocument(x, i))}</Table>

                    {badDocs && <AlertBar variant="error">Rejected documents need replacing.</AlertBar>}
                    {!allDocs && <AlertBar variant="warning">Important documents are missing.</AlertBar>}
                    {!allReviewed && (
                        <AlertBar variant="attention">New documents have been uploaded for review.</AlertBar>
                    )}
                </Grid>
            </Grid>
        );
    }

    renderRequiredDocument(requiredDocument, index) {
        const { form } = this.props;
        const { processingRequiredDocument } = this.state;
        const canUpload = !form.getField('Funeral.ID');
        const documents = [].concat(form.getField('RequiredDocuments') || []);
        const document = documents
            .sort((a, b) => (a.Created < b.Created ? 1 : -1))
            .find(doc => requiredDocument.fieldName === doc.Name) || {
            FileID: null,
            File: {
                AbsoluteLink: null,
                Name: null,
                Size: 0
            },
            Name: null,
            Status: null
        };

        const folderPath = form.getField('Funeral.ID')
            ? `/documents/cremation/${form.getField('ID')}`
            : `/documents/funeral/${form.getField('Funeral.ID')}`;

        const processing = !!processingRequiredDocument && processingRequiredDocument.ID === document.ID;
        const disableAcceptButton =
            !document.File ||
            !document.File.ID ||
            form.isDirty ||
            (document.File && document.File.ID && document.Status === 'Approved') ||
            processing ||
            !!processingRequiredDocument;
        const disableRejectButton =
            !document.File ||
            !document.File.ID ||
            form.isDirty ||
            (document.File && document.File.ID && document.Status === 'Rejected') ||
            processing ||
            !!processingRequiredDocument;
        const documentFileExists = !!document.File && !!document.File.Name;
        return (
            <Row pad key={index}>
                <Cell dataLabel="Status" className="table-center icon">
                    {(document.Status === 'Approved' && <TickIcon style={{ color: 'green' }} />) ||
                        (document.Status === 'Rejected' && <CloseIcon style={{ color: 'red' }} />) ||
                        (document.File && !!document.File.Name && <FlagIcon className="attention-icon" />) || (
                            <ExclamationTriangleIcon className="warning-icon" />
                        )}
                </Cell>
                <Cell dataLabel="Document Name">{requiredDocument.description}</Cell>

                {!!documentFileExists && (
                    <Cell dataLabel="File Name">
                        <Button
                            variant="link-orange"
                            target="_blank"
                            onClick={() =>
                                this.setState({
                                    documentModal: { open: true, document: document.File }
                                })
                            }
                        >
                            {document.File.Name}
                        </Button>
                    </Cell>
                )}
                {!documentFileExists && <Cell>File has not been uploaded</Cell>}
                <Cell dataLabel="Size">
                    <div style={{ whiteSpace: 'nowrap' }}>
                        {document.File && document.File.Size ? document.File.Size : '-'}
                    </div>
                </Cell>
                <Cell dataLabel="Actions" className="table-center">
                    <Inline nowrap>
                        {document.Status === 'Rejected' && (
                            <Button
                                variant="primary shadow"
                                icon={<CommentsIcon />}
                                title="View Note History"
                                onClick={() => this.handleShowRejectNotesModal(document)}
                            />
                        )}

                        {canUpload && (document.Status === 'Rejected' || !document.File.ID) && (
                            <FileUpload
                                useIcon
                                variant="primary shadow"
                                //disabled={document.File && document.File.ID && document.Status !== 'Rejected'}
                                folderPath={folderPath}
                                onComplete={({ uploadFile }) =>
                                    this.onFileUploaded(uploadFile, requiredDocument.fieldName)
                                }
                            />
                        )}

                        {!document.Status && document.File && document.File.ID && (
                            <Button
                                icon={processing ? <Spinner size="xs" /> : <TickIcon />}
                                variant="confirmation shadow"
                                title="Accept"
                                disabled={!!disableAcceptButton}
                                onClick={() =>
                                    this.setState({
                                        showApproveAlertModal: true,
                                        processingRequiredDocument: document
                                    })
                                }
                            />
                        )}

                        {!document.Status && document.File && document.File.ID && (
                            <Button
                                icon={processing ? <Spinner size="xs" /> : <CloseIcon />}
                                variant="decline shadow"
                                title="Reject"
                                disabled={!!disableRejectButton}
                                onClick={() =>
                                    this.setState({
                                        showRejectAlertModal: true,
                                        processingRequiredDocument: document
                                    })
                                }
                            />
                        )}
                    </Inline>
                </Cell>
            </Row>
        );
    }

    onFileUploaded(uploadFile, fieldName) {
        const { form } = this.props;
        const RequiredDocuments = form.getField('RequiredDocuments') || [];

        const document = {
            FileID: uploadFile.ID,
            File: {
                ID: uploadFile.ID,
                AbsoluteLink: uploadFile.AbsoluteLink,
                Name: getFileName(uploadFile.FileName),
                Size: 'Save to confirm'
            },
            Name: fieldName,
            Status: null,
            Created: moment().format('YYYY-MM-DD HH:mm:ss'),
            ID: null
        };
        RequiredDocuments.push(document);

        form.setField({ RequiredDocuments });
    }

    handleSelectStaff(propertyName, staff) {
        const { form } = this.props;

        if (!!staff) {
            delete staff['__typename'];

            form.setState({ [propertyName]: staff });
        }
    }

    handleAddStaffMember() {
        const { form } = this.props;
        const StaffMembers = form.getState('StaffMembers') || [];

        StaffMembers.push({
            new: true
        });
        form.setState({ StaffMembers });
    }

    handleDeleteStaffMember(staffMember, index) {
        const { form } = this.props;
        const StaffMembers = form.getState('StaffMembers') || [];

        if (index !== -1) {
            StaffMembers.splice(index, 1);
            form.setState({ StaffMembers });
        }
    }

    handleUploadSignificantDocument(uploadFile) {
        const { form } = this.props;

        const SignificantDocuments = form.getField('SignificantDocuments') || [];

        SignificantDocuments.push({
            ID: uploadFile.ID,
            AbsoluteLink: uploadFile.AbsoluteLink,
            Name: getFileName(uploadFile.FileName),
            Size: 'Save to confirm'
        });

        form.setField({ SignificantDocuments });
    }

    handleShowDeleteStaffMemberAlertModal() {
        this.setState({ showDeleteStaffMemberAlertModal: true });
    }

    handleCloseDeleteStaffMemberAlertModal() {
        this.setState({ showDeleteStaffMemberAlertModal: false });
    }

    handleShowRejectNotesModal(document) {
        this.setState({ showRejectNotesModal: document });
    }

    handleCloseRejectNotesModal() {
        this.setState({ showRejectNotesModal: false });
    }
}

export default {
    id: 'Correspondence',
    label: 'Correspondence',
    component: withStyles({})(CorrespondenceTab),
    fragment: CremationOrderFragment,
    onLoad: data => {},
    formatSaveData: (saveData, state) => {
        let localStaffMembers = [];

        if (saveData && saveData.StaffMembers) {
            state.StaffMembers.forEach(function(staffMember, index, object) {
                if (!!staffMember.new) {
                    if (staffMember.Staff && staffMember.Staff.ID) {
                        localStaffMembers.push({
                            StaffID: staffMember.Staff.ID,
                            Role: staffMember.Role,
                            Allocation: staffMember.Allocation
                        });
                    } else if (staffMember.ProductOrder && staffMember.Type === 'productOrder') {
                        localStaffMembers.push({ ProductOrder: staffMember.ProductOrder });
                    }
                } else if (staffMember.ID) {
                    localStaffMembers.push({
                        ID: staffMember.ID
                    });
                }
            });

            saveData.StaffMembers = localStaffMembers;
        }

        const localSignificantDocuments = [];
        if (saveData && saveData.SignificantDocuments) {
            state.SignificantDocuments.forEach(function(significantDocument, index, object) {
                if (!!significantDocument.ID) {
                    localSignificantDocuments.push({
                        ID: significantDocument.ID
                    });
                }
            });

            saveData.SignificantDocuments = localSignificantDocuments;
        }

        const localRequiredDocuments = [];
        if (saveData && saveData.RequiredDocuments) {
            state.RequiredDocuments.forEach(function(requiredDocument) {
                if (!!requiredDocument.ID) {
                    // existing item, just include ID to retain
                    localRequiredDocuments.push({
                        ID: requiredDocument.ID
                    });
                } else {
                    // new item, omit ID to create
                    localRequiredDocuments.push({
                        Name: requiredDocument.Name,
                        FileID: requiredDocument.File.ID
                    });
                }
            });
            saveData.RequiredDocuments = localRequiredDocuments;
        }
    }
};
