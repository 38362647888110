import { compose } from 'react-apollo';
import { withTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import React, { Component } from 'react';
import * as Icons from '../../components/IconIndex';
import Grid from '../../components/layout/Grid';

class IconsPreview extends Component {
    render() {
        return (
            <Grid container>
                {Object.keys(Icons).map(key => {
                    const Icon = Icons[key];
                    return (
                        <Grid item xs={6} md={3}>
                            <Icon value={66} /> &nbsp;&lt;{key}/&gt;
                        </Grid>
                    );
                })}
            </Grid>
        );
    }
}

const styles = () => {
};
export default compose(withTheme(), withStyles(styles))(IconsPreview);
