import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 25.12 25.12" {...props}>
        <path
            fill="currentColor"
            d="M21.24,15.45l-2.39-.69a8.63,8.63,0,1,0-12.37,0l-2.39.69a5.5,5.5,0,0,0-4,5.28v1.36a3.13,3.13,0,0,0,3.14,3.13H22.09a3.13,3.13,0,0,0,3.13-3.13V20.73A5.48,5.48,0,0,0,21.24,15.45ZM12.67,1.68A7.07,7.07,0,1,1,5.6,8.74,7.06,7.06,0,0,1,12.67,1.68Zm11,20.41a1.57,1.57,0,0,1-1.56,1.57H3.25a1.57,1.57,0,0,1-1.57-1.57V20.73A3.92,3.92,0,0,1,4.52,17L8,16a8.67,8.67,0,0,0,9.43,0l3.42,1a3.9,3.9,0,0,1,2.84,3.77Z"
        />
    </SvgIcon>
);
