import { diff, getProperty, setPropertyWrapper } from './objects';
import { validationHelper, validationUtility } from './validation';
import { cloneDeep } from 'apollo-utilities';

/**
 * creates a portable form object
 * @param {*} context = component to attach to (has a state)
 * @param {*} state = list of form fields/values
 */
export default (context, state) => {
    let form = {
        state,
        context,
        original: cloneDeep(state),
        validation: {
            __dependentLookup: {}
        }
    };

    form.getState = property => getProperty(form.state, property);
    form.getField = form.getState;
    form.setState = newState => {
        setPropertyWrapper(form.state, newState);
        !!form.context && form.context.forceUpdate();
    };
    form.setField = form.setState;
    form.setValidation = () => validationHelper.ok();
    form.getValidation = (fieldName, forceFieldNameAsKey = false, revalidate = false) => {
        return validationUtility.getValidationResult(fieldName, form.validation, forceFieldNameAsKey, revalidate);
    };
    form.getOriginal = property => getProperty(form.original, property);
    form.getDirty = () => {
        const amDirty = diff(form.state, form.original, true);
        return Object.keys(amDirty);
    };
    return form;
};
