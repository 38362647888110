import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';

export default withStyles({})(({ variant }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `brandDot--${variant}`;

    return <span className={`brandDot ${cssVariant || ''}`}> </span>;
});
