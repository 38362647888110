export const SampleOptions = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3', value: '3' }
];

export const CollectedByOptions = [
    { label: 'Applicant', value: 'Applicant' },
    { label: 'Contact', value: 'Contact' }
];

export const DispatchMethodOptions = [
    { label: 'Collected By', value: 'Collected by' },
    { label: 'Delivered To', value: 'Delivered to' },
    { label: 'Transferred', value: 'Transferred' },
    { label: 'Posted', value: 'Posted' }
];

export const AssociatedOrderOptions = [
    { label: 'Cremation Order', value: 'cremationOrder' },
    { label: 'Enquiry', value: 'enquiry' },
    { label: 'Product Order', value: 'productOrder' }
];

export const ProductStatus = [
    { label: 'Unknown', value: 'unknown' },
    { label: 'Pending', value: 'pending' }
];

export const MAX_PLAQUE_LINES = 6;
