import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../layout/Grid';
import Inline, { inlineAlignment } from '../layout/Inline';
import Select from '../form/Select';
import EnquiryAutoComplete from '../form/EnquiryAutoComplete';
import PlaqueOrderAutoComplete from '../form/PlaqueOrderAutoComplete';
import TextField from '../form/TextField';
import CremationOrderAutoComplete from '../form/CremationOrderAutoComplete';
import { isRelatedObjectDefined } from '../../utils/bookable';

function AssociatedOrdersModal(props) {
    const { form, context, itemId } = props;
    if (!form) return null;
    const { props: props1 } = context;
    const { parentID, parentType } = props1;

    let i = 0;
    for (i; i < form.state.OrderItems.length; i++) {
        if (form.state.OrderItems[i][parentType] && form.state.OrderItems[i][parentType].ID === parentID) continue;
        break;
    }

    let selected = form.state.Type;
    if (!selected && form.state.OrderItems[i]) {
        if (isRelatedObjectDefined(form.state.OrderItems[i].CremationOrder)) selected = 'CremationOrder';
        else if (isRelatedObjectDefined(form.state.OrderItems[i].PlaqueOrder)) selected = 'PlaqueOrder';
        else if (isRelatedObjectDefined(form.state.OrderItems[i].Enquiry)) selected = 'Enquiry';
        else if (isRelatedObjectDefined(form.state.OrderItems[i].ProductOrder)) selected = 'ProductOrder';
        form.state.Type = selected;
    }

    return (
        <Grid container>
            <Grid variant="justify" item>
                <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                    <h4>{itemId ? 'Edit' : 'Create'} Associated Order</h4>
                </Inline>
            </Grid>

            <Grid item xs={12} sm={6}>
                <Select
                    name="Type"
                    label="Associate with..."
                    options={
                        [
                            { label: 'Cremation Order', value: 'CremationOrder' },
                            { label: 'Plaque Order', value: 'PlaqueOrder' },
                            { label: 'Product Order', value: 'ProductOrder' },
                            { label: 'Enquiry', value: 'Enquiry' }
                        ]
                        /*.filter(e => e.value !== parentType)*/
                    }
                    value={selected}
                    form={form}
                />
            </Grid>

            {selected === 'Enquiry' && (
                <Grid item xs={12} sm={6}>
                    <EnquiryAutoComplete
                        label="Ref ID"
                        placeholder="Search for Reference ID"
                        selectProps={{ multiple: false }}
                        onSelect={(_, object) => form.setField({ [`OrderItems[${i}].Enquiry`]: object })}
                        form={form}
                        name={`OrderItems[${i}].Enquiry`}
                    />
                </Grid>
            )}

            {selected === 'CremationOrder' && (
                <Fragment>
                    <Grid item xs={12} sm={6}>
                        <CremationOrderAutoComplete
                            label="Ref ID"
                            placeholder="Search for Reference ID"
                            selectProps={{ multiple: false }}
                            onSelect={(_, object) => form.setField({ [`OrderItems[${i}].CremationOrder`]: object })}
                            form={form}
                            name={`OrderItems[${i}].CremationOrder`}
                        />
                    </Grid>
                </Fragment>
            )}

            {selected === 'PlaqueOrder' && (
                <Fragment>
                    <Grid item xs={12} sm={6}>
                        <PlaqueOrderAutoComplete
                            label="Ref ID"
                            placeholder="Search for Reference ID"
                            selectProps={{ multiple: false }}
                            onSelect={(_, object) => form.setField({ [`OrderItems[${i}].PlaqueOrder`]: object })}
                            form={form}
                            name={`OrderItems[${i}].PlaqueOrder`}
                        />
                    </Grid>
                </Fragment>
            )}

            {selected === 'ProductOrder' && (
                <Fragment>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Product Code"
                            form={form}
                            name={`OrderItems[${i}].ProductOrder.ProductCode`}
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            label="Product Description"
                            form={form}
                            name={`OrderItems[${i}].ProductOrder.Description`}
                        />
                    </Grid>

                    <Grid item>
                        <TextField label="Product Link" form={form} name={`OrderItems[${i}].ProductOrder.Link`} />
                    </Grid>
                </Fragment>
            )}
        </Grid>
    );
}

export default withStyles({})(AssociatedOrdersModal);
