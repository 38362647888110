import React, { Component } from 'react';
import { compose, Query } from 'react-apollo';
import gql from 'graphql-tag';
import Grid from '../../components/layout/Grid';
import FlexGrid from '../../components/layout/FlexGrid';
import TextField from '../../components/form/TextField';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Checkbox from '../../components/form/Checkbox';
import Button from '../../components/form/Button';
import Select from '../../components/form/Select';
import Spinner from '../../components/loading/Spinner';
import ThumbnailCard from '../../components/cards/ThumbnailCard';
import UploadField, { getFileName } from '../../components/form/UploadField';
import createForm from '../../utils/createForm';
import { getClient } from '../../utils/apollo';
import HtmlEditor from '../../components/form/HtmlEditor';
import { niceDateTimeFromString, shortDateTimeFromString } from '../../utils/date';
import { joinDefined } from '../../utils/strings';
import CommunicationNoteTemplate from '../../fragments/CommunicationNoteTemplate';
import CommunicationNote from '../../fragments/CommunicationNote';
import LinearProgress from '@material-ui/core/LinearProgress';
import { cloneDeep } from 'apollo-utilities';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import IconButton from '@material-ui/core/IconButton';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { CloseIcon, EditIcon, EnvelopeIcon, PayableIcon, SaveIcon, TickIcon } from '../../components/IconIndex';
import moment from 'moment';
import { withSnackbarMessage } from '../../context/SnackbarMessage';

class CommunicationNoteModal extends Component {
    state = {
        communicationNoteTemplates: [],
        savingCommunicationNote: false,
        sendingCommunicationNote: false,
        open: false
    };
    form = createForm(this, {});

    onClose = () => {
        this.form = createForm(this, {});
        this.refetchModal = () => null;
        this.setState({ open: false });
    };

    onOpen = noteID => {
        this.setState({ open: noteID || 0 });
    };

    render() {
        const { orderID: recordID, orderType: recordType } = this.props;
        const variables = { filter: { [recordType + 'ID']: { eq: recordID } } };
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Communication Notes</h4>
                </Grid>
                {!recordID && (
                    <p>
                        Communication notes can be added after the{' '}
                        {recordType ? recordType.toLowerCase() : 'record'} is saved.
                    </p>
                )}
                <Grid item>
                    {!!recordID && (
                        <Query query={queryMyNotes} variables={variables} client={getClient()}>
                            {({ data, loading, error, refetch }) => {
                                if (error) return 'error';
                                this.refetchList = refetch;
                                const result =
                                    !!data &&
                                    !!data.readCMCommunicationNotes &&
                                    data.readCMCommunicationNotes.edges.map(e => e.node);
                                return this.renderNotesTable(loading, result);
                            }}
                        </Query>
                    )}
                </Grid>
                {!!recordID && (
                    <Grid item>
                        <Button variant="primary" onClick={() => this.onOpen(0)}>
                            Add new note...
                        </Button>
                    </Grid>
                )}
                {this.renderNoteModal()}
            </Grid>
        );
    }

    renderNotesTable = (loading, data) => {
        const { classes } = this.props;
        if (!data || !data.length) return <p>There are currently no communication notes.</p>;
        return (
            <>
                <Table>
                    <HeaderRow pad>
                        <Cell>Sent</Cell>
                        <Cell>Subject</Cell>
                        <Cell>Edit</Cell>
                    </HeaderRow>
                    {data.map((communicationNote, index) => {
                            const isSent = !!(communicationNote.EmailSentAt || joinDefined([communicationNote.MailDate, communicationNote.MailTime], ''));
                            const isSentEmail = !!(communicationNote.EmailSentAt);
                            return (
                                <Row pad key={index}>
                                    <Cell dataLabel="Sent" className="icon">
                                        {isSent ? <TickIcon className={classes.tickIcon} /> : ''}
                                    </Cell>
                                    <Cell dataLabel="Subject"><strong>{communicationNote.Title}</strong><br />
                                        <small>{isSent ? (isSentEmail ? 'Emailed ' : 'Posted ') + (shortDateTimeFromString(
                                            communicationNote.EmailSentAt
                                            || joinDefined([communicationNote.MailDate, communicationNote.MailTime], ' ')
                                        )) : 'Draft only'}</small>
                                    </Cell>
                                    <Cell dataLabel="Edit" className="icon">
                                        <IconButton
                                            className="icon"
                                            onClick={() => this.onOpen(communicationNote.ID)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Cell>
                                </Row>
                            );
                        }
                    )}
                </Table>
            </>
        );
    };

    renderNoteModal() {
        const { open } = this.state;
        if (!open) {
            return this.renderModal();
        }
        const variables = { noteId: open };
        return (
            <Query query={queryCommunicationNote} variables={variables} client={getClient()}>
                {({ data, error, loading, refetch }) => {
                    const oldID = this.form.getField('ID');
                    const newID = !!data && !!data.readOneCMCommunicationNote && data.readOneCMCommunicationNote.ID;
                    if (newID) {
                        if (newID !== oldID) {
                            this.form.original = cloneDeep(data.readOneCMCommunicationNote);
                            this.form.state = cloneDeep(data.readOneCMCommunicationNote);
                        } else {
                            const oldStamp = this.form.getField('LastEdited');
                            const newStamp = data.readOneCMCommunicationNote.LastEdited;
                            if (newStamp !== oldStamp) {
                                this.form.original = cloneDeep(data.readOneCMCommunicationNote);
                                this.form.state = cloneDeep(data.readOneCMCommunicationNote);
                            }
                        }
                    }
                    this.refetchModal = refetch;
                    return this.renderModal(loading);
                }}
            </Query>
        );
    }

    renderModal(loading) {
        const { classes } = this.props;
        const { open } = this.state;
        if (open === false) return null;
        return (
            <Dialog
                classes={{ paper: cx(classes.root, 'dialog-modal modal') }}
                open={open !== false}
                onClose={this.onClose}
                disableEnforceFocus
                disableBackdropClick
                disableEscapeKeyDown
            >
                {!!loading && <LinearProgress />}
                {this.renderNoteDetails()}
            </Dialog>
        );
    }

    renderFooter = () => {
        const { savingCommunicationNote, sendingCommunicationNote } = this.state;
        const { state: noteObj, original } = this.form;
        const readonlyCommunicationNote = this.getIsSent(original);

        const disabledSendMailButton =
            !!savingCommunicationNote ||
            !!sendingCommunicationNote ||
            !!noteObj.EmailSent ||
            !!this.form.getDirty().length ||
            !noteObj.ID ||
            !noteObj.Title ||
            !noteObj.Description ||
            !noteObj.SendEmail ||
            !(noteObj.EmailApplicant || noteObj.EmailAuthorised || (noteObj.EmailOther && noteObj.OtherEmailAddress));

        const disabledSaveButton =
            !!savingCommunicationNote || !!sendingCommunicationNote || !this.form.getDirty().length;

        let saveButtonIcon = <SaveIcon />;
        let saveButtonText = !this.form.getDirty().length && this.form.getField('ID') ? 'Saved' : 'Save Changes';
        let sendButtonIcon = <EnvelopeIcon />;
        let sendButtonText = 'Send Email';

        if (!!sendingCommunicationNote) {
            sendButtonIcon = <Spinner size="xs" />;
            sendButtonText = 'Sending';
        } else if (!!savingCommunicationNote) {
            saveButtonIcon = <Spinner size="xs" />;
            saveButtonText = 'Saving';
        }

        return (
            <Inline alignment={inlineAlignment.rightAlignSiblings}>
                <div>
                    <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                        Close
                    </Button>
                </div>

                {!readonlyCommunicationNote && (
                    <>
                        <Button
                            variant="urgent"
                            disabled={disabledSendMailButton}
                            onClick={this.onSendEmail}
                            startIcon={sendButtonIcon}
                        >
                            {sendButtonText}
                        </Button>
                        <Button
                            variant="confirmation"
                            disabled={disabledSaveButton}
                            onClick={this.onSave}
                            startIcon={saveButtonIcon}
                        >
                            {saveButtonText}
                        </Button>
                    </>
                )}
            </Inline>
        );
    };

    renderNoteDetails() {
        const {
            orderClient,
            orderAuthorisedContacts,
            classes,
            orderType: recordType,
            orderKey: recordKey,
            orderID: recordID,
            substitutionFields
        } = this.props;

        const form = this.form;
        const { original, state: noteObj } = form;
        const attachments = form.getField('Attachments') || [];

        let readonlyCommunicationNote = this.getIsSent(original);

        let displayAttachmentsHeading = !readonlyCommunicationNote || (!!attachments && !!attachments.length);

        let noClient = !orderClient;
        let noClientEmail = noClient || !orderClient.Email;

        let noAuthorisedContact = !orderAuthorisedContacts || orderAuthorisedContacts.length === 0;
        let noAuthorisedContactEmail = noAuthorisedContact;

        if (!noAuthorisedContact) {
            noAuthorisedContactEmail = true;

            orderAuthorisedContacts.forEach(function(authorisedContact, index, object) {
                if (!!authorisedContact.Email) {
                    noAuthorisedContactEmail = false;
                }
            });
        }
        const recordTypeTitle = recordType.replace(/([a-z]+)([A-Z])/, '$1 $2');
        return (
            <>
                <DialogTitle className={classes.diagHeader}>
                    <Hidden xsDown>
                        <PayableIcon className={classes.diagHeaderIcon} />
                        <div className={classes.diagHeaderTitle}>
                            {recordTypeTitle} {recordKey || <> #{recordID}</>}
                        </div>
                        <div className={classes.diagHeaderSubtitle}>Communication Note</div>
                    </Hidden>
                    <Hidden smUp>
                        <div className={classes.diagHeaderTitle}>Communication Note</div>
                    </Hidden>
                </DialogTitle>

                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid bucket>
                            {!readonlyCommunicationNote &&
                                <Grid item>
                                    <h4>Prefill from template</h4>
                                </Grid>
                            }
                            <Grid item>
                                {!readonlyCommunicationNote ? (
                                    <Query query={queryCommunicationNoteTemplates}>
                                        {results => this.renderCommunicationNoteTemplateList(results)}
                                    </Query>
                                ) : (!!noteObj.Template && !!noteObj.Template.ID &&
                                    <TextField
                                        label="Communication Template"
                                        readOnly
                                        value={form.getField('Template.ID') > 0 ? `#${form.getField('Template.ID')}: ${form.getField(
                                            'Template.Title'
                                        )}` : 'none'}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid bucket>
                            {!readonlyCommunicationNote &&
                                <Grid item>
                                    <h4>Compose the note</h4>
                                </Grid>
                            }
                            <Grid item>
                                <TextField
                                    required
                                    label="Subject"
                                    placeholder="Enter subject of note..."
                                    form={form}
                                    name="Title"
                                    readOnly={readonlyCommunicationNote}
                                />
                            </Grid>

                            <Grid item>
                                <HtmlEditor
                                    label="Body"
                                    placeholder="Enter body of note, or choose a new template..."
                                    shortcodes={substitutionFields}
                                    required
                                    form={form}
                                    name="Description"
                                    readOnly={readonlyCommunicationNote}
                                />
                            </Grid>

                            {!readonlyCommunicationNote && (
                                <>
                                    {!!displayAttachmentsHeading && (
                                        <Grid item>
                                            <h4>Attach a File</h4>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <UploadField
                                            folderPath={this.getDocumentPath()}
                                            onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, attachments)}
                                        />
                                    </Grid>
                                </>
                            )}

                            {!!attachments &&
                                !!attachments.length &&
                                this.renderAttachmentLinks(attachments, readonlyCommunicationNote)}
                        </Grid>

                        {(!readonlyCommunicationNote || !!noteObj.SendEmail) && (<Grid bucket>
                            <Grid item>
                                <Inline center>
                                    <h4>Send as email</h4>

                                    {!readonlyCommunicationNote && (
                                        <Checkbox
                                            label="Yes, send to email address"
                                            form={form}
                                            name="SendEmail"
                                            readOnly={readonlyCommunicationNote}
                                        />
                                    )}
                                </Inline>

                                {readonlyCommunicationNote && (
                                    <Checkbox
                                        label="Email note"
                                        form={form}
                                        name="SendEmail"
                                        readOnly={readonlyCommunicationNote}
                                    />
                                )}

                                {!!noteObj.SendEmail && (!readonlyCommunicationNote || !!noteObj.EmailApplicant) && (
                                    <Checkbox
                                        label="Email Applicant"
                                        form={form}
                                        name="EmailApplicant"
                                        readOnly={readonlyCommunicationNote}
                                        disabled={noClientEmail}
                                    />
                                )}

                                {!!noteObj.SendEmail && (!readonlyCommunicationNote || !!noteObj.EmailAuthorised) && (
                                    <Checkbox
                                        label="Email Authorised Contact"
                                        form={form}
                                        name="EmailAuthorised"
                                        readOnly={readonlyCommunicationNote}
                                        disabled={noAuthorisedContactEmail}
                                    />
                                )}

                                {!!noteObj.SendEmail && (!readonlyCommunicationNote || !!noteObj.EmailOther) && (
                                    <Checkbox
                                        label="Email Other Recipient"
                                        form={form}
                                        name="EmailOther"
                                        readOnly={readonlyCommunicationNote}
                                    />
                                )}

                                {!!noteObj.SendEmail &&
                                    !!noteObj.EmailOther &&
                                    (!readonlyCommunicationNote || !!noteObj.OtherEmailAddress) && (
                                        <TextField
                                            label="Email Recipient"
                                            form={form}
                                            name="OtherEmailAddress"
                                            readOnly={readonlyCommunicationNote}
                                        />
                                    )}
                            </Grid>
                        </Grid>)}

                        {(!readonlyCommunicationNote || !!noteObj.SendMail) && (<Grid bucket>
                            <Grid item>
                                <Inline center>
                                    <h4>Send as letter</h4>

                                    {!readonlyCommunicationNote && (
                                        <Checkbox
                                            label="Yes, send to postal address"
                                            form={form}
                                            name="SendMail"
                                            readOnly={readonlyCommunicationNote}
                                        />
                                    )}
                                </Inline>

                                {readonlyCommunicationNote && (
                                    <Checkbox
                                        label="Mail to address"
                                        form={form}
                                        name="SendMail"
                                        readOnly={readonlyCommunicationNote}
                                    />
                                )}

                                {!!noteObj.SendMail && (!readonlyCommunicationNote || !!noteObj.MailApplicant) && (
                                    <Checkbox
                                        label="Sent to applicants address"
                                        form={form}
                                        name="MailApplicant"
                                        readOnly={readonlyCommunicationNote}
                                        disabled={noClient}
                                    />
                                )}

                                {!!noteObj.SendMail && (!readonlyCommunicationNote || !!noteObj.MailAuthorised) && (
                                    <Checkbox
                                        label="Sent to authorised contacts address"
                                        form={form}
                                        name="MailAuthorised"
                                        readOnly={readonlyCommunicationNote}
                                        disabled={noAuthorisedContact}
                                    />
                                )}

                                {!!noteObj.SendMail && (!readonlyCommunicationNote || !!noteObj.MailOther) && (
                                    <Checkbox
                                        label="Sent to Other recipient address recipient: "
                                        form={form}
                                        name="MailOther"
                                        readOnly={readonlyCommunicationNote}
                                    />
                                )}

                                {!!noteObj.SendMail &&
                                    !!noteObj.MailOther &&
                                    (!readonlyCommunicationNote || !!noteObj.OtherMailAddress) && (
                                        <TextField
                                            label="Type Address"
                                            form={form}
                                            name="OtherMailAddress"
                                            readOnly={readonlyCommunicationNote}
                                        />
                                    )}
                            </Grid>
                        </Grid>)}

                        {!!noteObj.ID && <Grid container>
                            {!!noteObj.SendMail && (
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Mail Date"
                                        type="date"
                                        form={form}
                                        name="MailDate"
                                        readOnly={readonlyCommunicationNote}
                                    />
                                </Grid>
                            )}

                            {!!noteObj.SendMail && (
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label="Mail Time"
                                        type="time"
                                        form={form}
                                        name="MailTime"
                                        readOnly={readonlyCommunicationNote}
                                    />
                                </Grid>
                            )}

                            {!!noteObj.ID &&
                                <Grid item xs={12} sm={6}>
                                    <h5>Created</h5>
                                    <p>{this.showAuthor(original.CreatedBy, original.Created)}</p>
                                </Grid>
                            }
                        </Grid>}
                    </Grid>
                </DialogContent>

                <DialogActions className={cx(classes.diagActions, 'modal-footer', 'paper')}>
                    {this.renderFooter()}
                </DialogActions>
            </>
        );
    }

    renderCommunicationNoteTemplateList = ({ error, loading, data }) => {
        const { communicationNoteTemplates } = this.state;
        const form = this.form;
        let readonlyCommunicationNote = !!form.state.EmailSent;

        if (error) {
            return 'Error!';
        }

        if (loading) {
            return <span>Loading...</span>;
        }

        if (!data) {
            return '';
        }

        const templates = data.readCMCommunicationNoteTemplates.edges;

        let selectTemplateOptions = [];

        for (let x = 0; x < templates.length; x++) {
            selectTemplateOptions.push({
                label: '#' + templates[x].node.ID + ': ' + templates[x].node.Title,
                value: templates[x].node.ID
            });
            communicationNoteTemplates[templates[x].node.ID] = templates[x].node;
        }

        return (
            <Select
                label="Communication Template"
                placeholder="Choose a template..."
                name="Template.ID"
                options={selectTemplateOptions}
                onChange={this.handleCommunicationNoteTemplateChange}
                form={form}
                readOnly={readonlyCommunicationNote}
            />
        );
    };

    getDocumentPath() {
        return `Uploads/CommunicationNotes/${this.form.getField('ID')}`;
    }

    clearState() {
        this.setState({
            communicationNoteTemplates: [],
            savingCommunicationNote: false,
            sendingCommunicationNote: false
        });
    }

    getIsSent(row) {
        const mailStamp = moment(row.MailDate + ' ' + row.MailTime);
        const isMailed = mailStamp && mailStamp.isValid() && mailStamp.isBefore();
        return !!(row.EmailSent || isMailed);
    }

    showAuthor(author, date) {
        const { FirstName, Surname, Email } = author || {};
        const Name = joinDefined([FirstName, Surname], ' ');
        return joinDefined(['By', Name || Email || 'Unknown', 'on', niceDateTimeFromString(date)], ' ');
    }

    onSave = () => {
        const { onSave, setSnackbarMessage } = this.props;
        const { sendingCommunicationNote } = this.state;

        if (onSave) {
            onSave(this.form.state);
        }

        this.setState({
            savingCommunicationNote: true
        });

        this.saveCommunicationNoteFunction().then(
            ({ data }) => {
                this.setState({
                    savingCommunicationNote: false
                });

                if (!!data && (data.createCMCommunicationNote || data.updateCMCommunicationNote)) {
                    setSnackbarMessage('Success, your changes have been saved.', true);
                    if (!!data.createCMCommunicationNote) {
                        this.setState({ open: data.createCMCommunicationNote.ID });
                    } else {
                        this.refetchModal();
                    }
                    if (sendingCommunicationNote) {
                        this.onSendEmail();
                    }
                } else {
                    this.onGqlError('Failed to return communication note');
                }
                this.refetchList();
                this.clearState();
            },
            error => {
                this.clearState();
                this.onGqlError('Failed to save communication note', error);
            }
        );
    };

    onSendEmail = () => {
        const { onSendEmail, setSnackbarMessage } = this.props;

        if (onSendEmail) {
            onSendEmail(this.form.state);
        }

        this.setState({
            sendingCommunicationNote: true
        });

        this.sendCommunicationNoteFunction().then(
            ({ data }) => {
                this.setState({
                    sendingCommunicationNote: false
                });

                if (!!data) {
                    if (
                        !!this.form.state.ID &&
                        !!data.updateCMCommunicationNote &&
                        !!data.updateCMCommunicationNote.EmailSent
                    ) {
                        setSnackbarMessage('Success, your email has been sent.', true);
                        this.onClose();
                    } else {
                        setSnackbarMessage('Oops, your email was not sent. Do you have a recipient email address?');
                    }
                    this.refetchModal();
                }
                this.refetchList();
                this.clearState();
            },
            error => {
                this.clearState();
                this.onGqlError('Failed to send communication note!', error);
            }
        );
    };

    onGqlError = (action, error) => {
        const { message } = error;
        const { setSnackbarMessage } = this.props;
        setSnackbarMessage(joinDefined([action, message], '\n'));
    };

    saveCommunicationNoteFunction = async () => {
        const { orderID, orderType } = this.props;
        const form = this.form;
        const data = {
            [orderType + 'ID']: orderID,
            Title: form.state.Title,
            Description: form.state.Description,
            SendEmail: form.state.SendEmail,
            EmailApplicant: form.state.EmailApplicant,
            EmailAuthorised: form.state.EmailAuthorised,
            EmailOther: form.state.EmailOther,
            OtherEmailAddress: form.state.OtherEmailAddress,
            SendMail: form.state.SendMail,
            MailApplicant: form.state.MailApplicant,
            MailAuthorised: form.state.MailAuthorised,
            MailOther: form.state.MailOther,
            OtherMailAddress: form.state.OtherMailAddress,
            MailDate: form.state.MailDate,
            MailTime: form.state.MailTime,
            Attachments: [],
            ScheduledToSendEmail: false, // always false, need to save before we can send
            TemplateID: null
        };

        if (!!form.state.Template && !!form.state.Template.ID) {
            data.TemplateID = form.state.Template.ID;
        }

        let mutation = createCommunicationNote;
        if (!!form.state.ID) {
            data.ID = form.state.ID;
            mutation = updateCommunicationNote;
        }
        if (!!form.state.Attachments && form.state.Attachments.length > 0) {
            form.state.Attachments.forEach((attachment) => {
                if (!!attachment.ID) {
                    data.Attachments.push({
                        ID: attachment.ID
                    });
                }
            });
        }
        return await getClient().mutate({
            mutation,
            variables: {
                input: data
            }
        });
    };

    sendCommunicationNoteFunction = async () => {
        // assume it is already saved before we send, so attachments are not lost.
        const { orderID, orderType } = this.props;
        const form = this.form;
        const data = {
            [orderType + 'ID']: orderID,
            ID: form.state.ID,
            ScheduledToSendEmail: true
        };

        return await getClient().mutate({
            mutation: updateCommunicationNote,
            variables: {
                input: data
            }
        });
    };

    handleCommunicationNoteTemplateChange = event => {
        const { communicationNoteTemplates } = this.state;
        const form = this.form;

        let communicationNoteTemplate = communicationNoteTemplates[event.target.value];

        if (!communicationNoteTemplate) {
            return;
        }

        form.state.Title = communicationNoteTemplate.Title;
        form.state.Description = communicationNoteTemplate.Description;
        form.state.Attachments = communicationNoteTemplate.Attachments;
        form.context.forceUpdate();
    };

    renderAttachmentLinks(attachments, readOnly) {
        return (
            <Grid item>
                <h4> Attached Files </h4>
                <FlexGrid>
                    {attachments.map(attachment => (
                        <ThumbnailCard
                            key={'file-' + attachment.ID}
                            fileName={attachment.Name}
                            fileLink={attachment.AbsoluteLink}
                            fileId={attachment.ID}
                            onClickRemoveAction={!readOnly ? attachmentId => this.onAttachmentRemove(attachmentId) : undefined}
                        />
                    ))}
                </FlexGrid>
            </Grid>
        );
    }

    handleUploadAttachment(uploadFile, attachments) {
        const form = this.form;
        attachments = attachments || [];

        attachments.push({
            ID: uploadFile.ID,
            AbsoluteLink: uploadFile.AbsoluteLink,
            Name: getFileName(uploadFile.FileName)
        });

        form.setField({
            Attachments: attachments
        });
    }

    onAttachmentRemove(fileId) {
        const form = this.form;

        let Attachments = form.getField('Attachments');
        Attachments = Attachments.filter(obj => fileId !== obj.ID);

        form.setField({ Attachments });
    }

    refetchModal = () => null;
    refetchList = () => null;
}

const queryCommunicationNoteTemplates = gql`
    ${CommunicationNoteTemplate}
    query($limit: Int) {
        readCMCommunicationNoteTemplates(limit: $limit) {
            edges {
                node {
                    ...CommunicationNoteTemplate
                }
            }
        }
    }
`;

const createCommunicationNote = gql`
    ${CommunicationNote}
    mutation createCommunicationNote($input: CreateCMCommunicationNoteInput!) {
        createCMCommunicationNote(input: $input) {
            ...CommunicationNote
        }
    }
`;

const updateCommunicationNote = gql`
    ${CommunicationNote}
    mutation updateCommunicationNote($input: UpdateCMCommunicationNoteInput!) {
        updateCMCommunicationNote(input: $input) {
            ...CommunicationNote
        }
    }
`;

const queryCommunicationNote = gql`
    ${CommunicationNote}
    query readOneCommunicationNote($noteId: ID!) {
        readOneCMCommunicationNote(ID: $noteId) {
            ...CommunicationNote
        }
    }
`;

const queryMyNotes = gql`
    query readCommunicationNotes($filter: CMCommunicationNoteFilterFields!) {
        readCMCommunicationNotes(filter: $filter) {
            edges {
                node {
                    ID
                    Created
                    Title
                    EmailSentAt
                    MailDate
                    MailTime
                }
            }
        }
    }
`;
const styles = ({ palette, spacing, breakpoints }) => ({
    root: {
        maxWidth: '800px',
        width: '100%',
        maxHeight: 'unset',
        [breakpoints.down('xs')]: {
            margin: spacing.unit
        }
    },
    diagHeader: {
        background: palette.primary.main,
        padding: spacing.unit * 3
    },
    diagHeaderTitle: {
        color: '#FFFFFF',
        fontSize: '16px',
        fontWeight: 'lighter',
        lineHeight: 'normal'
    },
    diagHeaderSubtitle: {
        color: '#FFFFFF',
        fontSize: '36px',
        fontWeight: 'initial',
        lineHeight: 'normal'
    },
    diagHeaderIcon: {
        width: '70px',
        height: '70px',
        float: 'right',
        color: 'rgba(255,255,255,0.5)'
    },
    content: {
        width: '100%',
        padding: spacing.unit * 3,
        maxHeight: 'calc(100vh - 15rem)',
        overflow: 'auto',
        minHeight: 340,
        [breakpoints.down('xs')]: {
            maxHeight: 'calc(100vh - 12rem)',
            padding: spacing.unit
        }
    },
    diagActions: {
        padding: spacing.unit * 3,
        paddingTop: spacing.unit * 2,
        margin: 0,
        display: 'block'
    },
    tickIcon: { color: palette.action.save }
});
export default compose(withSnackbarMessage, withStyles(styles))(CommunicationNoteModal);
