import CalendarIcon from '../icon/CalendarIcon';
import FileIcon from '../icon/FileIcon';
import WorkQueueIcon from '../icon/WorkQueueIcon';
import RecentIcon from '../icon/RecentIcon';

// eslint-disable-next-line import/prefer-default-export
export const items = [
    {
        path: '/recent',
        label: 'Recent',
        icon: RecentIcon,
        permission: 'Tony Stark'
    },
    {
        path: '/work-queue',
        label: 'Task Queue',
        icon: WorkQueueIcon,
        permission: 'FM_ACCESS_Dashboard'
    },
    {
        path: '/calendar',
        label: 'Calendar',
        icon: CalendarIcon
    },
    {
        path: '/runsheet',
        label: 'Run Sheet',
        icon: FileIcon
    }
];
