import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import TableKey from './table/TableKey';
import { getOffices } from '../environment';

const BrandedTableKey = ({ classes }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const allOffices = getOffices();
    return (
        <>
            <Button className={classes.capsuleContent} onClick={evt => setAnchorEl(evt.currentTarget)}>
                Office Colours <Icon className={classes.tipContent}>keyboard_arrow_down</Icon>
            </Button>
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
            >
                <div className={classes.popContent}>
                    {allOffices.map(({ RegistrantSuffix }, idx) => (
                        <TableKey key={idx} legacyKey={RegistrantSuffix} />
                    ))}
                </div>
            </Popover>
        </>
    );
};

const styles = theme => ({
    popContent: {
        padding: '1rem 0 0.5rem 16px',
        [theme.breakpoints.down('md')]: {
            padding: '10px 0 10px 10px'
        }
    },
    tipContent: {
        fontSize: 16
    },
    capsuleContent: {
        fontSize: 12,
        textTransform: 'unset'
    }
});
export default withStyles(styles)(BrandedTableKey);
