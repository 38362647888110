import React, { Component, Fragment } from 'react';
import gql from 'graphql-tag';
import { compose, Query } from 'react-apollo';
import { withRouter } from 'react-router';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Button from '../../components/form/Button';
import SearchWithButton from '../../components/form/SearchWithButton';
import SortByDropdown from '../../components/form/SortByDropdown';
import ContactCard from '../../components/contact/ContactCard';
import FlexGrid from '../../components/layout/FlexGrid';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import AddressBookFragment from '../../fragments/AddressBook';
import { SortBy } from './ContactConstants';
import Spinner from '../../components/loading/Spinner';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

class Contacts extends Component {
    state = {
        searchKeyword: '',
        showFilterModal: false,
        offset: 0,
        limit: 50,
        sortField: 'LastEdited',
        sortDirection: 'DESC',
        activeOnly: true
    };

    render() {
        const { searchKeyword, limit, offset, sortField, sortDirection, activeOnly } = this.state;

        const variables = {
            contains: searchKeyword,
            limit: limit,
            offset: offset,
            sortBy: [{ field: sortField, direction: sortDirection }],
            activeOnly: activeOnly
        };

        return (
            <Grid container>
                <Query query={query} variables={variables} notifyOnNetworkStatusChange>
                    {results => this.renderContent(results)}
                </Query>
                {/*<FilterModal*/}
                {/*	open={!!showFilterModal}*/}
                {/*	anchorEl={showFilterModal}*/}
                {/*	onClose={() => this.setState({ showFilterModal: false })}*/}
                {/*/>*/}
            </Grid>
        );
    }

    renderContent = results => {
        this.queryResults = results;

        return (
            <>
                <Grid item>{this.renderHeader()}</Grid>
                <Grid item>
                    <Grid item>{this.renderContactCards()}</Grid>
                </Grid>
            </>
        );
    };

    onSearchSubmit = value => {
        const { refetch } = this.queryResults;
        this.setState({ searchKeyword: value, offset: 0 }, () => refetch());
    };

    renderHeader() {
        const { sortField, sortDirection } = this.state;
        return (
            <Grid container spacing={8}>
                <Grid item>
                    <Inline alignment={inlineAlignment.rightAlignSiblings} center className="contact-header">
                        <h1 className="title" style={{ marginLeft: 0 }}>
                            Contacts
                        </h1>
                        <SearchWithButton placeholder="Search Contacts" onSearchSubmit={this.onSearchSubmit} />
                        <div style={{ marginTop: 12, marginBottom: 12 }}>
                            <SortByDropdown
                                label="Sort by"
                                options={SortBy}
                                sortField={sortField}
                                sortDirection={sortDirection}
                                setSortField={this.setSortField}
                            />
                        </div>
                        {/*<Button variant="primary" size="lg"
								onClick={(event) => this.setState({ showFilterModal: event.currentTarget })}
						>
							<FilterIcon/>
							Filter
						</Button>*/}
                    </Inline>
                </Grid>

                {this.renderSearchHeader()}
            </Grid>
        );
    }

    renderSearchHeader() {
        const { loading, data } = this.queryResults;
        const { searchKeyword } = this.state;
        if (loading) return null;

        const edges = data && data.readCremationManagerAddressBooks && data.readCremationManagerAddressBooks.edges;
        const total = edges && data.readCremationManagerAddressBooks.pageInfo.totalCount;
        return (
            <Fragment>
                <Grid item>
                    <p style={{ padding: '0 8px' }}>
                        {total} contacts found
                        {searchKeyword && <> for <strong>{searchKeyword}</strong></>}
                    </p>
                </Grid>
                {/*<Grid item>*/}
                {/*<Inline className="button-alignment">*/}
                {/*<Button variant="tag">Clergy</Button>*/}
                {/*<Button variant="tag">Category</Button>*/}
                {/*<Button variant="tag">Clergy</Button>*/}
                {/*<Button variant="tag">Category</Button>*/}
                {/*</Inline>*/}
                {/*</Grid>*/}
            </Fragment>
        );
    }

    renderList() {
        const { loading, error, data, fetchMore, networkStatus } = this.queryResults;
        const { offset } = this.state;

        if (error) return 'Error loading contacts data';
        if (loading && networkStatus === 1)
            return (
                <div>
                    Loading data, please wait a moment...
                    <LinearProgress />
                </div>
            );

        const edges = data && data.readCremationManagerAddressBooks && data.readCremationManagerAddressBooks.edges;
        const total = edges && data.readCremationManagerAddressBooks.pageInfo.totalCount;
        const contacts = edges && edges.length && edges.map(e => e.node);
        const contactsArray = contacts || [];
        const busy = loading || networkStatus !== 7;

        let more = 'End of results.';
        if (edges && edges.length + offset < total) {
            more = (
                <Button
                    disabled={busy}
                    onClick={() =>
                        /*this.setState({ offset: edges.length + this.state.offset })*/
                        fetchMore({
                            variables: { offset: edges.length },
                            updateQuery: (prev, { fetchMoreResult }) => {
                                if (!fetchMoreResult) return prev;

                                return Object.assign(
                                    {},
                                    /*...prev,*/ {
                                        readCremationManagerAddressBooks: {
                                            edges: [
                                                ...prev.readCremationManagerAddressBooks.edges,
                                                ...fetchMoreResult.readCremationManagerAddressBooks.edges
                                            ],
                                            __typename: 'readCremationManagerAddressBooksConnection', // required, to prevent death
                                            pageInfo: fetchMoreResult.readCremationManagerAddressBooks.pageInfo
                                        }
                                    }
                                );
                            }
                        })
                    }
                >
                    {busy ? <><Spinner /> Loading...</> : 'Load more results'}
                </Button>
            );
        }

        return (
            <Fragment>
                {contactsArray.map(contact => {
                    return this.renderItem(contact);
                })}
                <div style={{ textAlign: 'center', padding: 24, flex: '0 0 100%' }}>{total > 0 && more}</div>
            </Fragment>
        );
    }

    renderItem = contact => {
        return <ContactCard key={'contact' + contact.ID} contact={contact} />;
    };

    setSortField = newSortField => {
        const { refetch } = this.queryResults;
        const newState = { sortField: null, sortDirection: null };
        newState.sortField = newSortField.replace(/(ASC|DESC)/, '');
        newState.sortDirection = newSortField.replace(/.*(ASC|DESC)/, '$1');
        newState.offset = 0;

        this.setState(newState, () => refetch());
    };

    renderContactCards() {
        return (
            <Grid container>
                <Grid item>
                    <FlexGrid variant="justify">{this.renderList()}</FlexGrid>
                </Grid>
            </Grid>
        );
    }
}

const query = gql`
    ${AddressBookFragment}

    query(
        $contains: String
        $limit: Int
        $offset: Int
        $sortBy: [SortField]
        $activeOnly: Boolean
    ) {
        readCremationManagerAddressBooks(
            contains: $contains
            limit: $limit
            offset: $offset
            sortBy: $sortBy
            activeOnly: $activeOnly
        ) {
            edges {
                node {
                    ...CremationManagerAddressBook
                }
            }
            pageInfo {
                hasNextPage
                hasPreviousPage
                totalCount
            }
        }
    }
`;

export default withErrorBoundary(compose(withRouter, withStyles({}))(Contacts), { fallback: ErrorFallback });

