import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 512 512" {...props}>
        <path
            fill="currentColor"
            d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm16 336c0 8.82-7.17 16-16 16H48c-8.82 0-16-7.17-16-16V112c0-8.82 7.17-16 16-16h416c8.82 0 16 7.17 16 16v288zm-44-80H332c-6.62 0-12-5.37-12-12v-8c0-6.62 5.37-12 12-12h104c6.62 0 12 5.37 12 12v8c0 6.62-5.37 12-12 12zm0-64H332c-6.62 0-12-5.37-12-12v-8c0-6.62 5.37-12 12-12h104c6.62 0 12 5.37 12 12v8c0 6.62-5.37 12-12 12zm0-64H332c-6.62 0-12-5.37-12-12v-8c0-6.62 5.37-12 12-12h104c6.62 0 12 5.37 12 12v8c0 6.62-5.37 12-12 12zm-197.57 65.948C249.40 244.24 256 226.88 256 208c0-44.11-35.88-80-80-80s-80 35.88-80 80c0 18.88 6.59 36.24 17.57 49.948C75.25 270.54 64 305.70 64 326.363V340c0 24.26 19.73 44 44 44h136c24.26 0 44-19.73 44-44v-13.637c0-20.83-11.43-55.88-49.57-68.415zM176 160c26.51 0 48 21.49 48 48s-21.49 48-48 48-48-21.49-48-48 21.49-48 48-48zm80 180c0 6.62-5.37 12-12 12H108c-6.62 0-12-5.37-12-12v-13.637c0-17.85 11.83-33.55 29.01-38.461l20.64-5.897C155.01 285.85 165.26 288 176 288s20.98-2.14 30.34-5.996l20.64 5.897C244.16 292.80 256 308.50 256 326.363V340z"
        />
    </SvgIcon>
);
