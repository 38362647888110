import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import './index.css';
import App from './App';
import Providers from './components/Providers';
import * as serviceWorker from './serviceWorker';

const { hostname } = window.location;
const env =
    hostname.indexOf('cremationmanager-uat.internetrix.net') > -1
        ? 'uat'
        : hostname.indexOf('cremationmanager.com.au') > -1
            ? 'live'
            : false;
if (1 === 2 && env) {
    Sentry.init({
        dsn: 'https://ef0dc4206c1f4ad3b67bfde62293517c@o52687.ingest.sentry.io/5611586',
        environment: env,
        autoSessionTracking: true,
        release: localStorage.getItem('commit_ref')
    });
}

ReactDOM.render(
    <Providers>
        <App />
    </Providers>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
