import React from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import Label from './Label';
import { stringIsNullOrEmpty } from '../../utils/strings';

function LeftLabel(props) {
    const { className, variant, label, children } = props;

    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `left-label--${variant}`;

    return (
        <div className={`left-label ${cssVariant || ''}`}>
            <div className="label-container">
                <Label className={`label ${className || ''}`}>{label}</Label>
            </div>
            <div className="field-container">{children}</div>
        </div>
    );
}

export default compose(withRouter)(LeftLabel);
