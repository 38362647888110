import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import Hidden from '@material-ui/core/Hidden';
import Add from '@material-ui/icons/Add';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import SearchIcon from '../icon/SearchIcon';
import { clearToken, getToken, getUser } from '../../utils/sessions';
import PayableIcon from '../icon/PayableIcon';
import GatedComponent from '../GatedComponent';
import ContactsIcon from '../icon/ContactsIcon';
import StarIcon from '../icon/StarIcon';
import CalendarIcon from '../icon/CalendarIcon';
import ContactModal from '../../views/Contacts/Modals/ContactModal';
import AppointmentModal from '../modal/AppointmentModal';
import TaskModal from '../workQueue/Modals/TaskModal';
import NotificationPopover from '../notifications/NotificationPopover';
import { isNullOrUndefined } from '../../utils/objects';
import { resetClient } from '../../utils/apollo';
import { setSnackbarMessage } from '../../context/SnackbarMessage';
import { PAGE_ENQUIRIES } from '../../utils/strings';
import { CapsuleLegend } from '../form/Legend';

class HeaderActions extends Component {
    state = {
        userMenuAnchor: undefined,
        addMenuAnchor: undefined,
        showCreateContactModal: false,
        showAppointmentModal: false,
        showNewTaskModal: false
    };

    render() {
        const { showCreateContactModal, showAppointmentModal, showNewTaskModal } = this.state;
        return (
            <Fragment>
                <div className="header-actions">
                    {this.renderNotificationsButton()}
                    {this.renderAddButton()}
                    {this.renderUserButton()}
                    {this.renderUserMenu()}
                    {this.renderAddMenu()}
                </div>
                <ContactModal open={showCreateContactModal} onClose={() => this.handleCloseCreateContactModal()} />
                <AppointmentModal
                    open={showAppointmentModal}
                    onClose={() => this.handleCloseAppointmentModal()}
                    memberList={[getUser()]}
                />
                <TaskModal open={showNewTaskModal} onClose={() => this.handleCloseNewTaskModal()} />
            </Fragment>
        );
    }

    renderAddButton() {
        const { classes } = this.props;
        return (
            <Fragment>
                <Hidden xsDown>
                    <CapsuleLegend
                        onClick={this.onToggleAddMenu}
                        className={classes.capsuleLegend}
                        text={
                            <span>
                                <span style={{ margin: '0 6px' }}>
                                    <Hidden xsDown> Add New </Hidden>
                                </span>
                                <Fab
                                    className="fab"
                                    title="Add New"
                                    color="primary"
                                    aria-label="add new"
                                    onClick={this.onToggleAddMenu}
                                >
                                    <Add />
                                    <KeyboardArrowDown className="user-arrow" />
                                </Fab>
                            </span>
                        }
                        foreground="#48c3d1"
                        background="rgb(231 247 249)"
                    />
                </Hidden>
                <Hidden smUp>
                    <Fab color="primary" aria-label="Add" className="fab" onClick={this.onToggleAddMenu}>
                        <Add />
                        <KeyboardArrowDown className="user-arrow" />
                    </Fab>
                </Hidden>
            </Fragment>
        );
    }

    renderNotificationsButton() {
        return <NotificationPopover />;
    }

    renderUserButton() {
        const token = getToken();
        let profileName = token ? token.name.match(/\b\w/g) : false;
        if (profileName) profileName = ((profileName.shift() || '') + (profileName.pop() || '')).toUpperCase();
        else profileName = 'X';
        return (
            <Fab color="primary" aria-label="user settings" className="user fab" onClick={this.onToggleUserMenu}>
                {profileName}
                <KeyboardArrowDown className="user-arrow" />
            </Fab>
        );
    }

    renderSearchButton() {
        const { onToggleSearch } = this.props;
        return (
            <Hidden smUp>
                <IconButton className="" onClick={onToggleSearch}>
                    <SearchIcon className="search-icon" />
                </IconButton>
            </Hidden>
        );
    }

    renderUserMenu() {
        const { userMenuAnchor } = this.state;

        return (
            <Menu
                id="user-menu"
                anchorEl={userMenuAnchor}
                open={!isNullOrUndefined(userMenuAnchor)}
                onClose={this.onToggleUserMenu}
                transformOrigin={{ vertical: -68, horizontal: 'right' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem style={{ paddingTop: 24, outline: 0 }} button={false}>
                    <GatedComponent canViewCode="OBJEDITALL">
                        {(canEdit, data) => {
                            return (
                                <span>
                                    {data.readCurrentUser.FirstName} {data.readCurrentUser.Surname}
                                    <br />
                                    <small style={{ opacity: 0.5 }}>{data.readCurrentUser.Email}</small>
                                </span>
                            );
                        }}
                    </GatedComponent>
                </MenuItem>
                <MenuItem onClick={this.onClickSettings}>Account Settings</MenuItem>
                <MenuItem onClick={this.onClickLogout}>Logout</MenuItem>
            </Menu>
        );
    }

    renderAddMenu() {
        const { addMenuAnchor } = this.state;
        return (
            <Menu
                id="add-menu"
                anchorEl={addMenuAnchor}
                open={!isNullOrUndefined(addMenuAnchor)}
                onClose={this.onToggleAddMenu}
                transformOrigin={{ vertical: -64, horizontal: 'right' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuItem
                    className="targetNewPage"
                    onClick={() => {
                        this.onToggleAddMenu();
                        const newPath = PAGE_ENQUIRIES + '0';
                        let win = window.open(newPath, '_blank');
                        win.focus();
                    }}
                >
                    <PayableIcon className="icon mr-2" />
                    Enquiry
                </MenuItem>
                <MenuItem onClick={() => this.handleShowCreateContactModal()}>
                    <ContactsIcon className="icon mr-2" />
                    Contact...
                </MenuItem>
                <MenuItem onClick={() => this.handleShowNewTaskModal()}>
                    <StarIcon className="icon mr-2" />
                    Task...
                </MenuItem>
                <MenuItem onClick={() => this.handleShowAppointmentModal()}>
                    <CalendarIcon className="icon mr-2" />
                    Appointment...
                </MenuItem>
            </Menu>
        );
    }

    onToggleUserMenu = e => {
        const { userMenuAnchor } = this.state;
        this.setState({
            userMenuAnchor: userMenuAnchor ? undefined : e.currentTarget
        });
    };

    onToggleAddMenu = e => {
        const { addMenuAnchor } = this.state;
        this.setState({
            addMenuAnchor: addMenuAnchor ? undefined : e.currentTarget
        });
    };

    onClickProfile = () => {
        this.onToggleUserMenu();
        const { history } = this.props;
        history.push('/account');
    };

    onClickSettings = () => {
        this.onToggleUserMenu();
        const { history } = this.props;
        history.push('/account');
    };

    onClickLogout = () => {
        this.onToggleUserMenu();
        clearToken();
        resetClient();
        setSnackbarMessage('You have signed out.', true);
    };

    handleShowCreateContactModal() {
        this.setState({ showCreateContactModal: true });
    }

    handleCloseCreateContactModal() {
        this.setState({ showCreateContactModal: false });
    }

    handleShowAppointmentModal() {
        this.setState({ showAppointmentModal: true });
    }

    handleCloseAppointmentModal() {
        this.setState({ showAppointmentModal: false });
    }

    handleShowNewTaskModal() {
        this.setState({ showNewTaskModal: true });
    }

    handleCloseNewTaskModal() {
        this.setState({ showNewTaskModal: false });
    }
}

const styles = () => ({
    capsuleLegend: {
        display: 'inline-block',
        position: 'relative',
        whiteSpace: 'pre',
        padding: 2,
        width: 'unset',
        cursor: 'pointer'
    }
});

export default withRouter(withStyles(styles)(HeaderActions));
