import gql from 'graphql-tag';
import Member from './Member';
import QuoteItem from './QuoteItem';
import QuoteAction from './QuoteAction';

export default gql`
    fragment Quote on CMQuote {
        ID
        LastEdited
        LegacyKey
        Created
        Title
        QuoteType
        Released
        Accepted
        RespondedDate
        AbsolutePDFLink
        CalcQuoteTotal
        Notes
        AcceptedBy {
            ...Member
        }
        CreatedBy {
            ...Member
        }
        QuoteItems {
            ...QuoteItem
        }
        QuoteActions {
            ...QuoteAction
        }
        QuoteTemplate {
            ID
        }
    }
    ${Member}
    ${QuoteItem}
    ${QuoteAction}
`;
