import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import Typography from '../../../components/form/Typography';
import TextField from '../../../components/form/TextField';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import Stepper from '../../../components/stepper/Stepper';
import { niceDateTimeFromString } from '../../../utils/date';

class AshLocationHistoryModal extends Component {
    state = {
        ID: null,
        Location: '',
        LocationDetail: '',
        Notes: '',
        Changes: []
    };

    clearState() {
        this.setState({
            ID: null,
            Location: '',
            LocationDetail: '',
            Notes: '',
            Changes: []
        });
    }

    componentWillReceiveProps(nextProperties) {
        const ashLocationHistoryProperties = nextProperties.ashLocationHistory;

        if (ashLocationHistoryProperties) {
            this.setState(ashLocationHistoryProperties);
        }
    }

    onCancel() {
        const { onCancel } = this.props;

        if (onCancel) {
            onCancel();
        }

        this.clearState();
    }

    onClose() {
        const { onClose } = this.props;

        if (onClose) {
            onClose();
        }

        this.clearState();
    }

    onSubmit() {
        const { onSubmit } = this.props;

        if (onSubmit) {
            onSubmit(this.state);
        }

        this.clearState();
    }

    onTextFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    render() {
        const { open, onClose } = this.props;

        const disabledSaveButton = !this.state['Location'];

        return (
            <Modal open={open} variant="simple" onClose={onClose} canClickOut={false}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4"> Ash location </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Location"
                            name={`Location`}
                            value={this.state['Location'] || ''}
                            onChange={this.onTextFieldChange}
                            required
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Ashes"
                            name={`Ashes`}
                            value={this.state['Ashes'] || ''}
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            label="Location Notes"
                            name={`LocationDetail`}
                            value={this.state['LocationDetail'] || ''}
                            onChange={this.onTextFieldChange}
                        />
                    </Grid>

                    {this.state['Changes'].length > 1 && this.renderHistory()}

                    <Grid item xs={12}>
                        <TextField
                            label="Notes"
                            name={`Notes`}
                            value={this.state['Notes'] || ''}
                            onChange={this.onTextFieldChange}
                            multiline
                        />
                    </Grid>

                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings}>
                            <div />
                            <Button onClick={() => this.onCancel()} variant="secondary">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => this.onSubmit()}
                                variant="confirmation"
                                disabled={disabledSaveButton}
                            >
                                {this.renderButtonState()}
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    renderButtonState() {
        if (this.state['ID']) {
            return 'Update Ash Location';
        }

        return 'Save Ash Location';
    }

    renderHistory() {
        const changes = (this.state['Changes'] || []).map((item, index) => ({ item, index }));

        if (changes.length === 0) {
            return;
        }

        return (
            (
                <Grid>
                    <Typography variant="h4">Ash location history </Typography>
                </Grid>
            ) && (
                <Grid item xs={12}>
                    <div className="stepper">
                        {changes.map((change, i) => this.renderHistoryItem(change, changes.length))}
                    </div>
                </Grid>
            )
        );
    }

    renderHistoryItem({ item, index }, count) {
        let ashDetails = item.Location;

        if (item.Ashes) {
            ashDetails += ' - ' + item.Ashes;
        }

        if (item.LocationDetail) {
            ashDetails += ' - ' + item.LocationDetail;
        }

        let noteDetails = 'Entered by ';

        if (item.Member) {
            noteDetails += item.Member.FirstName + ' ' + item.Member.Surname;
        } else {
            noteDetails += 'Unknown';
        }

        noteDetails += ', ' + niceDateTimeFromString(item.Created) + '.';

        return (
            <Stepper
                key={index}
                variant={index === 0 ? 'active' : ''}
                stepNumber={count - index}
                ashDetails={ashDetails}
                noteDetails={noteDetails}
            />
        );
    }
}

export default withStyles({})(AshLocationHistoryModal);
