import React, { Component } from 'react';
import { compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core/styles';
import DataFormView from '../../components/DataFormView';
import EnquiryDetail from './EnquiryDetail';
import { createNewEnquiry, validation } from './EnquiryConstants';
import { PAGE_ENQUIRIES } from '../../utils/strings';
import { withErrorBoundary } from '@sentry/react';
import ErrorFallback from '../../components/ErrorFallback';

/**
 * Tab Definition
 */
const EnquiryTabs = [EnquiryDetail];

class EnquiryView extends Component {
    render() {
        const { match } = this.props;

        return (
            <DataFormView
                tabs={EnquiryTabs}
                createNew={parseInt(match.params.id) === 0}
                createNewFunc={createNewEnquiry}
                objectType="CMEnquiry"
                itemId={match.params.id}
                name="Enquiry"
                validation={validation}
                context={this}
                onCreated={e => this.onCreateNew(e)}
            />
        );
    }

    onCreateNew(enquiry) {
        const { history } = this.props;
        history.push(PAGE_ENQUIRIES + enquiry.ID);
    }
}

export default withErrorBoundary(compose(withRouter, withStyles({}))(EnquiryView), { fallback: ErrorFallback });
