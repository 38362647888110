import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { compose } from 'react-apollo';
import TaskWorkspace from './TaskWorkspace';

export default compose(
    withRouter,
    withStyles({})
)(({ tasks, refetch }) => {
    return (
        <div className="star-draw-container" style={{ padding: '0 26px' }}>
            <div className="star-draw-body">
                {(!!tasks && tasks.length > 0 && <TaskWorkspace tasks={tasks} refetch={refetch} />) || (
                    <p>You currently have no starred tasks.</p>
                )}
            </div>
        </div>
    );
});
