import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '../../components/layout/Grid';
import Separator from '../../components/layout/Separator';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Inline from '../../components/layout/Inline';
import Typography from '../../components/form/Typography';
import Button from '../../components/form/Button';
import Pagination from '../../components/form/Pagination';
import TickCircleIcon from '../../components/icon/TickCircleIcon';
import LockIcon from '../../components/icon/LockIcon';
import SaveIcon from '../../components/icon/SaveIcon';

class ButtonStyling extends Component {
    render() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Separator />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h4">Buttons</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ColumnLayout>
                        {this.renderButtons()}
                        {this.renderFormSample()}
                    </ColumnLayout>
                </Grid>
            </Grid>
        );
    }

    renderFormSample() {
        return (
            <Grid container spacing={32}>
                <Grid item xs={12}>
                    <Typography variant="paragraph">Links</Typography>
                    <Inline>
                        <Button variant="link-orange">Text Link - white background</Button>
                        <Button variant="link-denim">Text Link - grey background</Button>
                        <Button variant="link-red">Text Link - remove button</Button>
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="paragraph">Sizes</Typography>
                    <Inline>
                        <Button size="tiny">size=tiny</Button>
                        <Button>regular/no size</Button>
                        <Button size="lg">size=lg</Button>
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Icon buttons</Typography>
                    <pre>variant = icon-button</pre>
                    <Inline>
                        <Button icon={<TickCircleIcon />} />
                        <Button variant="confirmation" icon={<SaveIcon />} />
                        <Button disabled icon={<LockIcon />} />
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Tag</Typography>
                            <pre>variant = tag</pre>

                            <Inline>
                                <Button variant="tag">Tag</Button>
                                <Button variant="tag secondary">Tag Secondary</Button>
                                <Button variant="tag" disabled>
                                    {' '}
                                    Tag Disabled{' '}
                                </Button>
                            </Inline>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6">Pagination</Typography>
                    <Inline>
                        <Pagination />
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderButtons() {
        return (
            <Grid container spacing={24}>
                <Grid item xs={12}>
                    <Typography variant="h6">Primary Buttons</Typography>
                    <pre>variant = primary (default)</pre>

                    <Inline>
                        <Button variant="primary">Primary</Button>
                        <Button variant="primary" disabled>
                            Disabled
                        </Button>
                    </Inline>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Secondary Buttons</Typography>
                            <pre>variant = secondary</pre>

                            <Inline>
                                <Button variant="secondary">Secondary</Button>
                                <Button variant="secondary" disabled>
                                    Disabled
                                </Button>
                            </Inline>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Tertiary Button</Typography>
                            <pre>variant = tertiary</pre>

                            <Inline>
                                <Button variant="tertiary">Tertiary</Button>
                                <Button variant="tertiary" disabled>
                                    Disabled
                                </Button>
                            </Inline>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Quaternary Button</Typography>
                            <pre>variant = quaternary</pre>

                            <Inline>
                                <Button variant="quaternary">Quaternary</Button>
                                <Button variant="quaternary" disabled>
                                    Disabled
                                </Button>
                            </Inline>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Urgent Button</Typography>
                            <pre>variant = urgent</pre>
                            <Inline>
                                <Button variant="urgent">Mark as Urgent</Button>
                            </Inline>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={32}>
                        <Grid item xs={12}>
                            <Typography variant="h6"> Confirmation</Typography>
                            <strong>NOTE: only use this variant when writing data!</strong>
                            <pre>variant = confirmation</pre>
                            <Inline>
                                <Button variant="confirmation">Save</Button>
                                <Button variant="confirmation" disabled>
                                    Disabled
                                </Button>
                            </Inline>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item>
                    <Grid container spacing={32}>
                        <Grid item>
                            <Typography variant="h6">Decline</Typography>
                            <strong>NOTE: only use this variant when writing data!</strong>
                            <pre>variant = decline</pre>
                            <Inline>
                                <Button variant="decline">Decline</Button>
                                <Button variant="decline" disabled>
                                    Decline
                                </Button>
                            </Inline>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles({})(ButtonStyling);
