import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-apollo';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TaskWorkspace from './TaskWorkspace';

const styles = ({ palette }) => ({
    resetPanels: {
        border: 0,
        boxShadow: 'none',
        '& > div, &:before': {
            border: 0
        },
        '& > div:first-child': {
            borderBottom: '1px solid #dedede',
            '& > div:first-child': {
                margin: '20px 0',
                fontSize: '1.2em',
                fontWeight: 200
            }
        },
        '& > div:last-child > div > div > div': {
            padding: '0 26px'
        }
    }
});

export default compose(withStyles(styles))(({ tasksDone, tasksToDo, refetch, classes, recordType }) => {
    return (
        <div className="star-draw-container" style={{ padding: 0 }}>
            <div className="star-draw-body" style={{ padding: 0 }}>
                <ExpansionPanel defaultExpanded className={classes.resetPanels}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        {tasksToDo.length ? tasksToDo.length : ''} Outstanding Task{tasksToDo.length !== 1 && 's'}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {(!!tasksToDo && tasksToDo.length > 0 && (
                            <TaskWorkspace tasks={tasksToDo} refetch={refetch} isRecord={recordType} />
                        )) || <p style={{ margin: '1em 0' }}>There are no outstanding tasks.</p>}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded={!tasksDone.length} className={classes.resetPanels}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        {tasksDone.length ? tasksDone.length : ''} Completed Task{tasksDone.length !== 1 && 's'}
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        {(!!tasksDone && tasksDone.length > 0 && (
                            <TaskWorkspace tasks={tasksDone} refetch={refetch} isRecord={recordType} />
                        )) || <p style={{ margin: '1em 0' }}>There are no completed tasks.</p>}
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        </div>
    );
});
