import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import TrashIcon from '../icon/TrashIcon';
import { joinDefined } from '../../utils/strings';

const UserCircle = ({ person, user, abbreviation, onDelete }) => {
    const personName = person && joinDefined([person.FirstName, person.Surname], ' ');

    const content = (
        <span className="user-circle">
            <span className="abbreviation">{abbreviation || GetInitials(person)}</span>
            {onDelete && (
                <span
                    className="delete"
                    onClick={() => onDelete()}
                    onKeyDown={() => onDelete()}
                    role="button"
                    tabIndex="0"
                >
                    <TrashIcon />
                </span>
            )}
        </span>
    );

    if (onDelete) {
        return <Tooltip title={`Remove ${personName || user}`}>{content}</Tooltip>;
    }

    return <Tooltip title={`${personName || user}`}>{content}</Tooltip>;
};

export const GetInitials = user => {
    return firstLetter(user.FirstName) + firstLetter(user.Surname);
};

const firstLetter = str => {
    if (!str) return '';

    str = str.trim();

    if (str.length === 0) return '';

    return str[0].toUpperCase();
};

export default withStyles({})(UserCircle);
