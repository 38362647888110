import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import cloneDeep from 'lodash.clonedeep';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import TextField from '../../../components/form/TextField';
import Separator from '../../../components/layout/Separator';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import UploadField, { getFileName } from '../../../components/form/UploadField';
import FlexGrid from '../../../components/layout/FlexGrid';
import ThumbnailCard from '../../../components/cards/ThumbnailCard';
import Spinner from '../../../components/loading/Spinner';
import createForm from '../../../utils/createForm';
import { createProofEmailFunc } from './SendProofModal';

class SendProofSupplierModal extends Component {
    state = {
        loading: false,
        form: null
    };

    componentDidUpdate(_, oldState) {
        const { loading, form } = this.state;
        const { open } = this.props;
        if (open && form === null && !loading) {
            this.createProofEmail();
            return;
        }

        if (oldState.form === null && form !== null) form.context = this;
    }

    render() {
        const { open, onClose, plaqueOrderSupplier } = this.props;
        const { form, loading } = this.state;

        if (!form) return null;

        const attachments = form.getField('Attachments') || [];

        return (
            <Modal
                open={open}
                variant="primary"
                onClose={onClose}
                canClickOut={false}
                title={'Supplier Proof: ' + plaqueOrderSupplier.Name || plaqueOrderSupplier.Email}
                subtitle="Send Proof Email"
            >
                <Grid container>
                    <Grid item>
                        <h4> Sending to: </h4>
                        <TextField value={plaqueOrderSupplier.Email} readOnly />
                    </Grid>

                    <Separator />

                    <Grid item>
                        <TextField
                            label="Message"
                            placeholder="Hi %%FirstName%%"
                            multiline
                            name="Message"
                            form={form}
                        />
                    </Grid>

                    <Grid item>
                        <h4> Attachments </h4>
                    </Grid>

                    <Grid item>
                        <UploadField
                            folderPath={`/documents/plaque/${form.getField('ID')}`}
                            onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, attachments)}
                        />
                    </Grid>

                    {!!attachments && !!attachments.length && (
                        <Grid item>
                            <h4> Attached Files </h4>
                            <FlexGrid>
                                {attachments.map(file => (
                                    <ThumbnailCard
                                        key={'file-' + file.ID}
                                        fileName={file.Name}
                                        fileLink={file.AbsoluteLink}
                                        fileId={file.ID}
                                        onClickRemoveAction={fileId => this.onAttachmentRemove(fileId)}
                                    />
                                ))}
                            </FlexGrid>
                        </Grid>
                    )}

                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings}>
                            <div />
                            <Button variant="secondary" onClick={() => onClose()}>
                                Cancel
                            </Button>
                            <Button variant="primary" onClick={() => this.onSendEmail()}>
                                {loading && <Spinner />}
                                Send Proof
                            </Button>
                        </Inline>
                    </Grid>
                </Grid>
            </Modal>
        );
    }

    createProofEmail() {
        const email = {
            Message: null,
            ScheduledToSendEmail: true,
            EmailSupplier: true,
            EmailOther: false,
            OtherEmailAddress: null,
            Approved: false,
            SentToRecipientEmailAddresses: null
        };

        //add form details
        const form = createForm(this, email);
        this.setState({ loading: false, form });
        return null;
    }

    onAttachmentRemove(fileId) {
        const { form } = this.state;

        let Attachments = form.getField('Attachments');
        Attachments = Attachments.filter(obj => fileId !== obj.ID);

        form.setField({ Attachments });
    }

    handleUploadAttachment(uploadFile, attachments) {
        const { form } = this.state;
        attachments = attachments || [];

        attachments.push({
            ID: uploadFile.ID,
            AbsoluteLink: uploadFile.AbsoluteLink,
            Name: getFileName(uploadFile.FileName)
        });

        form.setField({
            Attachments: attachments
        });
    }

    onSendEmail() {
        const { form } = this.state;
        const { parentForm, onClose, plaqueOrderID } = this.props;

        let email = form.state;

        this.setState({ loading: true });
        const that = this;

        return createProofEmailFunc(email, plaqueOrderID).then(
            ({ data }) => {
                const ProofEmails = parentForm.getField('ProofEmails');
                const createdEmail = data.createProofEmail;
                ProofEmails.push(cloneDeep(createdEmail));
                parentForm.setField({ ProofEmails }, true);

                that.setState({
                    loading: false,
                    open: false
                });
                onClose();
                parentForm.save();
            },
            e => that.onGqlError('Failed to create proof email.', e)
        );
    }
}

export default withStyles({})(SendProofSupplierModal);
