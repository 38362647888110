import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Clear from '@material-ui/icons/Clear';
import Popover from '@material-ui/core/Popover';

function FilterWrapper(props) {
	const { classes, open, onClose, className, anchorEl, children } = props;
	return (
		<Popover
			aria-labelledby="modal-title"
			aria-describedby="modal-description"
			open={open}
			anchorEl={anchorEl || null}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			classes={{
				paper: classes.paper
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center'
			}}
		>
			<div className={`popover popover--filter ${className || ''}`}>
				<Fab
					color="primary"
					aria-label="Close"
					className="fab close"
					onClick={() => onClose()}
				>
					<Clear/>
				</Fab>
				<div className="paper">{children}</div>
			</div>
		</Popover>
	);
}

const styles = () => ({
	paper: {
		background: 'transparent',
		boxShadow: 'none',
        padding: '32px 32px 12px 12px',
        margin: '-24px -24px -12px -12px'
	}
});
export default withStyles(styles)(FilterWrapper);
