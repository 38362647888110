import React, { Component, Fragment } from 'react';
import { compose, Mutation } from 'react-apollo';
import { withStyles } from '@material-ui/core/styles';
import { Hidden } from '@material-ui/core';
import cloneDeep from 'lodash.clonedeep';
import Query from 'react-apollo/Query';
import gql from 'graphql-tag';
import Grid from '../../../../components/layout/Grid';
import Modal from '../../../../components/modal/Modal';
import Inline, { inlineAlignment } from '../../../../components/layout/Inline';
import Select from '../../../../components/form/Select';
import TextField from '../../../../components/form/TextField';
import Label from '../../../../components/form/Label';
import Checkbox from '../../../../components/form/Checkbox';
import Button from '../../../../components/form/Button';
import Table, { Cell, HeaderRow, Row } from '../../../../components/table/Table';
import NotesReadOnly from '../../../../components/form/NotesReadOnly';
import { isNullOrUndefined, round } from '../../../../utils/objects';
import createForm from '../../../../utils/createForm';
import Spinner from '../../../../components/loading/Spinner';
import { createQuoteFunc, updateQuoteFunc } from './QuotationConstants';
import { GST } from '../../../../utils/bookable';
import { prettyPrice } from '../../../../utils/strings';
import QuoteTemplate from '../../../../fragments/QuoteTemplate';
import { getClient } from '../../../../utils/apollo';
import QuoteEmailTemplate from '../../../../fragments/QuoteEmailTemplate';
import QuoteAction from '../../../../fragments/QuoteAction';
import Radio from '../../../../components/form/Radio';
import { withSnackbarMessage } from '../../../../context/SnackbarMessage';
import FileUploadCard from '../../../../components/cards/FileUploadCard';
import UploadField, { getFileName } from '../../../../components/form/UploadField';
import {
    BackIcon,
    CloseIcon,
    EditIcon,
    EnvelopeIcon,
    NextIcon,
    SaveIcon,
    TickIcon
} from '../../../../components/IconIndex';
import { niceDateTimeFromString } from '../../../../utils/date';

class QuoteModal extends Component {
    state = {
        tabIndex: 0,
        quoteForm: null,
        emailForm: null,
        quoteIndex: null,
        editingItemIndex: null,
        editingItemsMode: false,
        loading: false,
        gstPrices: [],
        quoteAcceptedUpdating: false,
        emailTemplates: [],
        refetchQuotes: false
    };

    constructor(props) {
        super(props);

        this.templates = [];
    }

    static getDerivedStateFromProps({ open, parentForm, quoteIndex }, state) {
        const quote = parentForm.getField(`Quotes[${quoteIndex}]`);
        const newState = { open };
        if (state.open !== open && open) {
            newState.tabIndex = 0;
            newState.cart = [];

            if (!isNullOrUndefined(quote)) {
                let clonedQuote = cloneDeep(quote);

                const oldContext = state.quoteForm !== null ? state.quoteForm.context : null;
                if (clonedQuote.QuoteTemplate && clonedQuote.QuoteTemplate.ID) {
                    clonedQuote.QuoteTemplateID = clonedQuote.QuoteTemplate.ID;
                }

                if (clonedQuote && clonedQuote.ID > 0) {
                    newState.tabIndex =
                        clonedQuote.Released || clonedQuote.RespondedDate || clonedQuote.Accepted ? 0 : 1;
                }

                newState.quoteForm = createForm(oldContext, clonedQuote);
                newState.quoteIndex = quoteIndex;
                newState.gstPrices = clonedQuote.QuoteItems.map(x => getPriceIncGst(x));
                newState.notes = clonedQuote.Notes;
                delete clonedQuote.Notes;
            } else {
                newState.quoteForm = null;
                newState.quoteIndex = null;
            }
            newState.editingItemsMode = false;
            newState.refetchQuotes = false;
        }

        return newState;
    }

    componentDidMount() {
        const that = this;

        this.setState({ loading: true });

        getEmailTemplates().then(data => {
            const emailTemplates =
                (data &&
                    data.map(e => {
                        return {
                            label: e.Title,
                            value: e.ID,
                            template: e.EmailText,
                            attached: e.EmailAttachments
                        };
                    })) ||
                [];
            that.setState({ emailTemplates, loading: false });
        });
    }

    componentDidUpdate(_, oldState) {
        const { loading, open, quoteIndex, quoteForm, emailForm } = this.state;
        if (open && quoteIndex === null && !loading) {
            //no quote! create one
            this.createQuote();
            return;
        }
        if (open && quoteForm !== null && emailForm === null && !loading) {
            this.createQuoteAction();
            return;
        }

        if (oldState.quoteForm === null && quoteForm !== null) quoteForm.context = this;
        if (oldState.emailForm === null && emailForm !== null) emailForm.context = this;
    }

    render() {
        const { open } = this.props;
        let { tabIndex } = this.state;
        const { quoteForm } = this.state;

        if (!quoteForm) return null;

        const quote = quoteForm.state;

        const {
            templatesTab,
            quoteDetailsTab,
            previewTab,
            historyTab,
            previewAndResendTab,
            notesTab
        } = this.getSteps();
        let list;
        if (quote && quote.ID > 0) {
            if (quote.Released || quote.RespondedDate || quote.Accepted) {
                list = [historyTab, previewAndResendTab, notesTab];
            } else {
                list = [templatesTab, quoteDetailsTab, previewTab, notesTab];
            }
        } else {
            list = [templatesTab, quoteDetailsTab];
        }

        return (
            <Modal
                open={open}
                variant="stepped"
                onClose={this.onClose}
                canClickOut={false}
                activeTab={tabIndex}
                scrollButtons="on"
                steps={list}
            />
        );
    }

    getSteps() {
        const { quoteForm, editingItemsMode } = this.state;
        const templatesTab = {
            abbreviation: 'Template',
            title: 'Step One:',
            subtitle: 'Please select a quote template',
            onChangeTab: this.onChangeTab,
            content: this.renderQuoteTemplatesTab(),
            actions: this.renderQuoteTemplatesActions()
        };
        const quoteDetailsTab = {
            abbreviation: 'Quote Items',
            title: 'Step Two:',
            subtitle: 'Review the quote items',
            onChangeTab: this.onChangeTab,
            button: (
                <Button
                    variant="secondary"
                    disabled={editingItemsMode}
                    onClick={() => this.setState({ editingItemsMode: true })}
                >
                    <EditIcon />
                    <Hidden smDown>&nbsp;{editingItemsMode ? 'Editing ' : 'Edit '}Quote</Hidden>
                </Button>
            ),
            content: this.renderQuoteDetailsTab(),
            actions: this.renderQuoteDetailsActions()
        };
        const previewTab = {
            abbreviation: 'Preview & Send',
            title: 'Step Three:',
            subtitle: 'Preview and seek approval',
            onChangeTab: this.onChangeTab,
            content: this.renderPreview(),
            actions: this.renderPreviewActions()
        };
        const historyTab = {
            abbreviation: 'View Quote',
            title: (quoteForm.getField('Accepted') ? 'Accepted' : '') + ` Quote #${quoteForm.getField('ID')}:`,
            subtitle: 'View the quote and its history',
            onChangeTab: this.onChangeTab,
            content: this.renderHistory(),
            actions: this.renderHistoryActions()
        };
        const previewAndResendTab = {
            abbreviation: 'Review & Resend',
            title: `Review Quote #${quoteForm.getField('ID')}:`,
            subtitle: 'Send the quote again or change its status',
            onChangeTab: this.onChangeTab,
            content: this.renderPreview(),
            actions: this.renderPreviewActions()
        };
        const notesTab = {
            abbreviation: 'Notes',
            title: `Notes on Quote #${quoteForm.getField('ID')}:`,
            subtitle: `Read or add staff notes about this quote`,
            onChangeTab: this.onChangeTab,
            content: this.renderNoteHistory(),
            actions: this.renderNoteHistoryActions()
        };
        return { templatesTab, quoteDetailsTab, previewTab, historyTab, previewAndResendTab, notesTab };
    }

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    onSubmit = () => {
        this.updateQuote();
    };

    createQuote() {
        const { parentForm } = this.props;
        if (parentForm.loading !== false) return;

        this.setState({ loading: true });
        const that = this;

        const enquiryType = parentForm.getField('EnquiryType');

        //add form details
        const quoteForm = createForm(that, {
            Enquiry: { ID: parentForm.getField('ID') },
            AcceptedBy: {},
            CreatedBy: {},
            QuoteItems: []
        });

        if (enquiryType === 'Plaque') {
            quoteForm.QuoteType = 'PLAQUE';
        }

        const Quotes = parentForm.getField('Quotes');
        const quoteIndex = Quotes.length;
        that.setState({ loading: false, quoteIndex, quoteForm });
        return null;
    }

    createQuoteAction() {
        const { parentForm } = this.props;
        if (parentForm.loading !== false) return;

        const { quoteForm } = this.state;
        if (!quoteForm) return null;

        const quote = quoteForm.state;
        const emailForm = createForm(this, {
            EmailTo: null,
            EmailMe: false,
            EmailSubject: this.getSubject(quote.ID),
            EmailBody: null,
            QuoteID: quote.ID,
            Attachments: []
        });

        this.setState({ emailForm });
        return null;
    }

    getSubject(quoteID) {
        const { quoteForm } = this.state;
        if (!quoteForm) return null;
        const quote = quoteForm.state;

        return 'Quote #' + (quoteID || quote.ID);
    }

    updateQuote() {
        const { quoteIndex, quoteForm, emailForm, tabIndex, quoteAcceptedUpdating } = this.state;
        let quote = quoteForm.state;
        const { parentForm } = this.props;

        this.setState({ loading: true });
        const that = this;
        const original = parentForm.getField(`Quotes[${quoteIndex}]`);

        if (quoteAcceptedUpdating && quote.Accepted) {
            quote.Accepted = false;
        } else if (quoteAcceptedUpdating && !quote.Accepted) {
            quote.Accepted = true;
        }
        if (!quote.ID || quote.ID === '0') {
            return createQuoteFunc(quote).then(
                ({ data }) => {
                    const Quotes = parentForm.getField('Quotes');
                    const createdQuote = cloneDeep(data.createCMQuote);
                    Quotes.push(createdQuote);
                    parentForm.setField({ Quotes }, true);

                    const quoteIndex = Quotes.length - 1;

                    if (createdQuote.QuoteTemplate && createdQuote.QuoteTemplate.ID) {
                        createdQuote.QuoteTemplateID = createdQuote.QuoteTemplate.ID;
                    }

                    const notes = createdQuote.Notes;
                    delete createdQuote.Notes;
                    const quoteForm = createForm(that, createdQuote);
                    that.setState({
                        loading: false,
                        quoteIndex,
                        quoteForm,
                        editingItemIndex: null,
                        editingItemsMode: false,
                        quoteUpdated: false,
                        quoteAcceptedUpdating: false,
                        tabIndex: tabIndex === 1 ? 2 : tabIndex,
                        notes: notes,
                        refetchQuotes: true
                    });
                    emailForm.setField({ QuoteID: createdQuote.ID, EmailSubject: that.getSubject(createdQuote.ID) });
                },
                e => that.onGqlError('Failed to create quote.', e)
            );
        } else {
            return updateQuoteFunc(quote, original).then(
                result => {
                    //todo: check this. it wont be result
                    const Quotes = parentForm.getField('Quotes');
                    let updatedQuote = cloneDeep(result.data.updateCMQuote);

                    const notes = updatedQuote.Notes;
                    delete updatedQuote.Notes;

                    if (updatedQuote.QuoteTemplate && updatedQuote.QuoteTemplate.ID) {
                        updatedQuote.QuoteTemplateID = updatedQuote.QuoteTemplate.ID;
                    }

                    Object.assign(Quotes[quoteIndex], updatedQuote);
                    parentForm.setField({ Quotes }, true);
                    const quoteForm = createForm(that, updatedQuote);
                    quoteForm.setField({ Notes: null });
                    that.setState({
                        loading: false,
                        quoteForm,
                        editingItemIndex: null,
                        editingItemsMode: false,
                        quoteUpdated: false,
                        quoteAcceptedUpdating: false,
                        tabIndex: tabIndex === 1 ? 2 : tabIndex,
                        notes: notes,
                        refetchQuotes: true
                    });
                },
                e => that.onGqlError('Failed to update quote.', e)
            );
        }
    }

    renderQuoteTemplatesTab() {
        return (
            <Query
                query={queryQuoteTemplates}
                variables={{
                    sortBy: [{ field: 'Created', direction: 'DESC' }]
                }}
            >
                {results => this.renderTemplateList(results)}
            </Query>
        );
    }

    renderTemplateList = ({ error, loading, data }) => {
        if (error) return 'Error!';
        if (loading)
            return (
                <span>
                    <Spinner /> Loading...
                </span>
            );
        const { parentForm } = this.props;

        this.templates = data && cloneDeep(data.readCMQuoteTemplates);
        const enqType = parentForm.getField('EnquiryType');
        const filteredTemplates = this.templates.filter(e => !enqType || e.QuoteType === enqType.toUpperCase());
        return (
            <Grid container>
                <Grid item>
                    <Table>
                        <HeaderRow pad>
                            <Cell>Template</Cell>
                            <Cell>Description</Cell>
                            <Cell>Amount</Cell>
                            <Cell>Selected</Cell>
                        </HeaderRow>
                        {filteredTemplates.length > 0 ? (
                            filteredTemplates.map((template, index) => this.renderTemplateRow(template, index))
                        ) : (
                            <Row pad>
                                <Cell colSpan={4}>
                                    <p>There are currently no templates for {enqType} enquiries.</p>
                                </Cell>
                            </Row>
                        )}
                    </Table>
                </Grid>
            </Grid>
        );
    };

    renderQuoteTemplatesActions = () => {
        const { quoteForm } = this.state;
        const quote = quoteForm.state;
        return <Inline alignment={inlineAlignment.rightAlignSiblings}>
            <div>
                <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                    Close
                </Button>
            </div>
            {!!quote.QuoteItems.length && (
                <Button onClick={() => this.setState({ tabIndex: 1 })} endIcon={<NextIcon />}>
                    Next
                </Button>
            )}
        </Inline>;
    };

    renderTemplateRow(template, index) {
        const { quoteForm } = this.state;
        const amount =
            template.QuoteTemplateItems.nodes.reduce(
                (sum, val) => sum + (val.Complimentary || val.Optional ? 0 : Number(val.Price)),
                0
            ) || 0;
        return (
            <Row pad key={index}>
                <Cell dataLabel="Key">{template.LegacyKey}</Cell>
                <Cell dataLabel="Description">{template.Description}</Cell>
                <Cell dataLabel="Amount">{prettyPrice(amount)}</Cell>
                <Cell dataLabel="Selected">
                    <Radio
                        value={'' + template.ID}
                        onChange={e => {
                            this.setTemplate(e);
                        }}
                        checked={parseInt(quoteForm.getField('QuoteTemplateID')) === parseInt(template.ID)}
                    />
                </Cell>
            </Row>
        );
    }

    setTemplate(event) {
        const { quoteForm } = this.state;
        const quote = this.templates.find(obj => {
            return obj.ID === event.target.value;
        });

        if (quote) {
            // clone the object, don't alter the original
            const newQuote = cloneDeep(quote);
            newQuote.Title = quote.Description;
            newQuote.QuoteTemplateID = quote.ID;
            delete newQuote.ID;
            delete newQuote.QuoteTemplateItems;
            delete newQuote.Description;
            delete newQuote.Created;

            newQuote.QuoteItems = quote.QuoteTemplateItems.nodes.map(e => {
                return {
                    ID: undefined,
                    Title: e.Title,
                    Content: e.Content,
                    Qty: e.Optional ? 0 : e.Qty,
                    Complimentary: e.Complimentary,
                    Optional: e.Optional,
                    HasGST: e.HasGST,
                    Price: e.Price
                };
            });
            quoteForm.setState(newQuote);
            this.setState({
                gstPrices: newQuote.QuoteItems.map(x => getPriceIncGst(x)),
                quoteUpdated: true
            });
        }
    }

    renderQuoteDetailsTab() {
        const { quoteForm, editingItemsMode } = this.state;

        if (!quoteForm) return null;

        const quote = quoteForm.state;
        const quoteItems = quote.QuoteItems;
        const totals = calculateTotals(quote);

        return (
            <Grid container>
                {!!quote.QuoteTemplateID || (!!quote.QuoteTemplate && !!quote.QuoteTemplate.ID) ? (
                    <Fragment>
                        <Grid item>
                            {!!quoteItems.length ? (
                                <Table>
                                    <HeaderRow pad>
                                        <Cell collSpan={1}>Description</Cell>
                                        <Cell collSpan={1}>Qty</Cell>
                                        <Cell collSpan={1}>Price</Cell>
                                        <Cell collSpan={1}>Has GST</Cell>
                                        <Cell collSpan={1}>Sub total</Cell>
                                        <Cell collSpan={1}>GST</Cell>
                                        <Cell collSpan={1}>Total</Cell>
                                        <Cell collSpan={1}>Complimentary</Cell>
                                        <Cell collSpan={1}>Optional</Cell>
                                    </HeaderRow>

                                    {quoteItems.map((item, index) => this.renderQuoteItem(item, index))}

                                    <Row pad className="total-row-desktop">
                                        <Cell colSpan={4} className="empty-cell" />
                                        <Cell className="total-cell">Total</Cell>
                                        <Cell>{prettyPrice(totals.gst)}</Cell>
                                        <Cell>{prettyPrice(totals.totalWithGst)}</Cell>
                                        <Cell colSpan={2} className="empty-cell" />
                                    </Row>
                                    {/*Mobile version of total*/}
                                    <Row pad className="total-row-mobile">
                                        <Cell colSpan={1} dataLabel="Total">
                                            {prettyPrice(totals.gst)}
                                        </Cell>
                                        <Cell colSpan={1} dataLabel="Total">
                                            {prettyPrice(totals.totalWithGst)}
                                        </Cell>
                                    </Row>
                                    {/*End Mobile version of total*/}
                                </Table>
                            ) : (
                                <p className="no-actions">There are no quote items added yet.</p>
                            )}
                            {editingItemsMode && (
                                <Button onClick={() => this.onCreateNewQuoteItem()}>Add New Item</Button>
                            )}
                        </Grid>
                    </Fragment>
                ) : (
                    <Grid item>
                        <p className="no-actions">Please select a quote first.</p>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderQuoteDetailsActions = () => {
        const { quoteForm, loading, quoteUpdated, tabIndex } = this.state;
        if (!quoteForm) return null;
        const quote = quoteForm.state;
        return <Inline alignment={inlineAlignment.rightAlignSiblings} center>
            <Inline>
                <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                    Close
                </Button>
                <Button onClick={() => this.setState({ tabIndex: 0 })} startIcon={<BackIcon />}>
                    Back
                </Button>
            </Inline>

            {!!quote.QuoteItems.length && (
                <Button
                    variant="confirmation"
                    disabled={!quoteUpdated || loading}
                    onClick={this.onSubmit}
                    startIcon={loading ? <Spinner /> : <SaveIcon />}
                >
                    {loading ? 'Generating PDF' : 'Save & Preview'}
                </Button>
            )}

            {!!quote.QuoteItems.length && !quoteUpdated && (
                <Button onClick={() => this.setState({ tabIndex: tabIndex + 1 })} disabled={loading}
                        endIcon={<NextIcon />}>
                    Next
                </Button>
            )}
        </Inline>;
    };

    onCreateNewQuoteItem() {
        const { quoteForm } = this.state;

        const quote = quoteForm.state;
        const QuoteItems = quote.QuoteItems;
        QuoteItems.push({
            Price: '0.00',
            Qty: 0,
            Title: '',
            Content: '',
            HasGST: true
        });
        quoteForm.setField({ QuoteItems });
        this.setState({
            gstPrices: QuoteItems.map(x => getPriceIncGst(x)),
            editingItemIndex: QuoteItems.length - 1,
            editingItemsMode: true,
            quoteUpdated: true
        });
    }

    renderQuoteItem(item, index) {
        const { quoteForm } = this.state;
        const { gstPrices, editingItemsMode, editingItemIndex } = this.state;

        let qty = parseInt(item.Qty);
        qty = !isNaN(qty) ? qty : 0;

        const priceInclGST = item.Complimentary ? '0.00' : round(gstPrices[index], 2);

        const totalWithGST = round(priceInclGST * qty);
        const gstValue = item.HasGST && totalWithGST > 0 ? totalWithGST / ((GST + 1) * 10) : 0;
        const subtotal = item.HasGST ? round(totalWithGST - gstValue) : totalWithGST;
        const canEdit = editingItemIndex === index || editingItemsMode;

        return (
            <Row pad key={index}>
                <Cell dataLabel="Description">
                    {canEdit ? (
                        <TextField
                            type="text"
                            required
                            name={`QuoteItems[${index}].Title`}
                            form={quoteForm}
                            onChange={e => this.onChangeQuoteItemTitle(index, e.target.value)}
                        />
                    ) : (
                        quoteForm.getField(`QuoteItems[${index}].Title`)
                    )}
                </Cell>
                <Cell dataLabel="Qty">
                    {canEdit ? (
                        <TextField
                            type="number"
                            required
                            onChange={e => this.onChangeQuantity(index, e.target.value)}
                            name={`QuoteItems[${index}].Qty`}
                            form={quoteForm}
                        />
                    ) : (
                        quoteForm.getField(`QuoteItems[${index}].Qty`)
                    )}
                </Cell>
                <Cell dataLabel="Price">
                    {canEdit ? (
                        <TextField
                            type="number"
                            required
                            value={priceInclGST || '0'}
                            onChange={e => this.onChangeTotalPrice(index, e.target.value)}
                        />
                    ) : (
                        priceInclGST || '0'
                    )}
                </Cell>
                <Cell dataLabel="Has GST">
                    {canEdit ? (
                        <Checkbox
                            name={`QuoteItems[${index}].HasGST`}
                            form={quoteForm}
                            onChange={e => this.onChangeHasGST(index, e.target.checked)}
                        />
                    ) : quoteForm.getField(`QuoteItems[${index}].HasGST`) ? (
                        <TickIcon />
                    ) : (
                        'N/A'
                    )}
                </Cell>
                <Cell dataLabel="Sub total">{prettyPrice(subtotal)}</Cell>
                <Cell dataLabel="GST">{prettyPrice(gstValue)}</Cell>
                <Cell dataLabel="Total">{prettyPrice(totalWithGST)}</Cell>
                <Cell dataLabel="Complimentary">
                    {canEdit ? (
                        <Checkbox
                            name={`QuoteItems[${index}].Complimentary`}
                            form={quoteForm}
                            onChange={e => this.onChangeQuoteItemComplimentary(index, e.target.checked)}
                        />
                    ) : quoteForm.getField(`QuoteItems[${index}].Complimentary`) ? (
                        <TickIcon />
                    ) : (
                        'N/A'
                    )}
                </Cell>
                <Cell dataLabel="Optional">
                    {canEdit ? (
                        <Checkbox
                            name={`QuoteItems[${index}].Optional`}
                            form={quoteForm}
                            onChange={e => this.onChangeQuoteItemOptional(index, e.target.checked)}
                        />
                    ) : quoteForm.getField(`QuoteItems[${index}].Optional`) ? (
                        <TickIcon />
                    ) : (
                        'N/A'
                    )}
                </Cell>
            </Row>
        );
    }

    onClose = () => {
        const { refetchQuotes } = this.state;
        const { onClose } = this.props;
        this.setState({ emailForm: null });
        return onClose(refetchQuotes);
    };

    onChangeHasGST(lineItemIndex, checked) {
        const { quoteForm } = this.state;
        const quote = quoteForm.state;

        const lineItem = quote.QuoteItems[lineItemIndex];
        const price = lineItem.Price;
        recalculatePrice(lineItem, checked ? price : price * (GST + 1));
        this.setState({
            quoteUpdated: true
        });
    }

    onChangeTotalPrice(lineItemIndex, stringValue) {
        const { quoteForm } = this.state;
        const quote = quoteForm.state;

        const value = typeof stringValue === 'number' ? stringValue : parseFloat(stringValue);
        if (isNaN(value)) return 0;

        const { gstPrices } = this.state;
        const lineItem = quote.QuoteItems[lineItemIndex];

        gstPrices[lineItemIndex] = value;
        recalculatePrice(lineItem, value);

        this.setState({
            gstPrices,
            quoteUpdated: true
        });
    }

    onChangeQuantity(index, stringValue) {
        const { quoteForm } = this.state;
        const quote = quoteForm.state;
        const value = typeof stringValue === 'number' ? stringValue : parseInt(stringValue, 10);
        if (isNaN(value) || value < 0) return 0;

        const lineItem = quote.QuoteItems[index];
        lineItem.Qty = value;

        this.forceUpdate();
        this.setState({
            gstPrices: quote.QuoteItems.map(x => getPriceIncGst(x)),
            quoteUpdated: true
        });
    }

    onChangeQuoteItemTitle(index, newTitle) {
        const { quoteForm } = this.state;
        const quote = quoteForm.state;

        quote.QuoteItems[index].Title = newTitle;
        quoteForm.setState(quote);

        // this.forceUpdate();
        this.setState({
            quoteForm: quoteForm,
            quoteUpdated: true
        });
    }

    onChangeQuoteItemComplimentary(index, checked) {
        const { quoteForm } = this.state;
        const quote = quoteForm.state;

        quote.QuoteItems[index].Complimentary = checked;

        quoteForm.setState(quote);

        this.setState({
            quoteForm: quoteForm,
            quoteUpdated: true
        });
    }

    onChangeQuoteItemOptional(index, checked) {
        const { quoteForm } = this.state;
        const quote = quoteForm.state;

        quote.QuoteItems[index].Optional = checked;
        quote.QuoteItems[index].Qty = checked ? 0 : 1;

        quoteForm.setState(quote);

        this.setState({
            quoteForm: quoteForm,
            quoteUpdated: true
        });
    }

    renderPreview() {
        const { quoteAcceptedUpdating, quoteForm, emailForm, emailTemplates } = this.state;
        const quote = quoteForm.state;

        if (!emailForm) return null;

        const templateID = emailForm.getField('QuoteEmailTemplate.ID');
        const template = emailTemplates.find(x => Number(x.value) === Number(templateID));
        const templateAttachments = !!template && template.attached;
        const myAttachments = emailForm.getField('Attachments') || [];

        return (
            <Grid container>
                <Grid item xs={12} md={7}>
                    {this.renderPdfView()}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid bucket>
                        <Grid item>
                            <Checkbox
                                label={quote.Accepted ? 'Accepted Quote has been cancelled?' : 'I approve this quote'}
                                onChange={e => this.setState({ quoteAcceptedUpdating: e.target.checked })}
                                checked={quoteAcceptedUpdating}
                            />
                        </Grid>
                        <Grid item>
                            <h4>Sending:</h4>
                        </Grid>
                        <Grid item>
                            <Label>Email to</Label>
                            <TextField placeholder="Enter email address..." name="EmailTo" form={emailForm} />
                            <Checkbox label="Send me a copy" name="EmailMe" form={emailForm} />
                        </Grid>
                        <Grid item>
                            <Label>Subject</Label>
                            <TextField placeholder="Subject" name="EmailSubject" form={emailForm} />
                        </Grid>
                        <Grid item>
                            <Label>Template</Label>
                            <Select
                                options={emailTemplates}
                                name="QuoteEmailTemplate.ID"
                                form={emailForm}
                                onChange={e => {
                                    const template = emailTemplates.find(
                                        x => Number(x.value) === Number(e.target.value)
                                    );
                                    emailForm.setField({ EmailBody: !!template ? template.template : null });
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Label>Message</Label>
                            <TextField multiline name="EmailBody" form={emailForm} />
                        </Grid>

                        <Grid item>
                            <Label>Attachments</Label>
                            <UploadField
                                buttonOnly
                                label="Choose File"
                                folderPath={`/documents/cm-quotes/${quote.ID}`}
                                onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, myAttachments)}
                            />
                            {!!myAttachments &&
                                myAttachments.length > 0 &&
                                myAttachments.map(e => (
                                    <FileUploadCard
                                        key={'attachment' + e.ID}
                                        fileLink={e.AbsoluteLink}
                                        variant="mini"
                                        fileName={e.Name}
                                    />
                                ))}
                            {!!templateAttachments &&
                                templateAttachments.length > 0 &&
                                templateAttachments.map(e => (
                                    <FileUploadCard
                                        key={'attachment' + e.ID}
                                        fileLink={e.AbsoluteLink}
                                        variant="mini"
                                        fileName={e.Name}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderPreviewActions = () => {
        const { tabIndex, quoteAcceptedUpdating, loading, emailForm } = this.state;
        return <Inline alignment={inlineAlignment.rightAlignSiblings} center>
            <Inline>
                <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                    Close
                </Button>
                {tabIndex !== 0 && <>
                    <Button startIcon={<BackIcon />}
                            onClick={() => this.setState({ tabIndex: tabIndex - 1 })}>
                        Back
                    </Button>
                </>}
            </Inline>

            <Button variant="confirmation" disabled={loading || !quoteAcceptedUpdating} onClick={this.onSubmit}
                    startIcon={loading ? <Spinner /> : <SaveIcon />}>
                {loading ? 'Saving' : 'Save'}
            </Button>

            <Mutation
                mutation={createQuoteAction}
                onCompleted={this.onClose}
                onError={e => this.handleMutateError(e)}
            >
                {(mutate, { loading }) => {
                    const disabled =
                        loading ||
                        !emailForm.getField('EmailTo') ||
                        !emailForm.getField('QuoteEmailTemplate.ID');
                    return (
                        <Fragment>
                            <Button
                                variant="urgent"
                                disabled={disabled}
                                startIcon={loading ? <Spinner /> : <EnvelopeIcon />}
                                onClick={e =>
                                    this.onQuoteEmailSendButtonClicked(
                                        mutate,
                                        !disabled && { input: emailForm.state },
                                        e
                                    )
                                }
                            >
                                {loading ? 'Sending' : 'Send Email'}
                            </Button>
                        </Fragment>
                    );
                }}
            </Mutation>

            {tabIndex !== 0 &&
                <Button endIcon={<NextIcon />}
                        onClick={() => this.setState({ tabIndex: tabIndex + 1 })}>
                    Next
                </Button>
            }
        </Inline>;
    };

    renderHistory() {
        const { emailForm, quoteForm } = this.state;
        const quote = quoteForm.state;
        const { classes } = this.props;
        if (!emailForm) return null;

        return (
            <Grid container>
                <Grid item xs={12} md={7}>
                    {this.renderPdfView()}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Grid bucket>
                        <Grid item>
                            <p>
                                <strong>{niceDateTimeFromString(quote.Created)}</strong>
                                <br />
                                <SaveIcon color="primary" className={classes.icons} /> Created by:{' '}
                                <strong>
                                    {quote.CreatedBy ? quote.CreatedBy.FirstName + ' ' + quote.CreatedBy.Surname : '(unknown)'}
                                </strong>
                            </p>
                            {quote.QuoteActions &&
                                quote.QuoteActions.map((quoteAction, index) => {
                                    return (
                                        <Fragment key={quoteAction.ID || 'X' + index}>
                                            <hr />
                                            <p>
                                                <strong>{niceDateTimeFromString(quoteAction.Created)}</strong>
                                                <br />
                                                <EnvelopeIcon color="primary" className={classes.icons} /> Sent by:{' '}
                                                <strong>
                                                    {quoteAction.ActionedBy.FirstName} {quoteAction.ActionedBy.Surname}
                                                </strong>
                                                <br />
                                                To: <strong>{quoteAction.EmailTo}</strong>
                                            </p>
                                        </Fragment>
                                    );
                                })}
                            {quote.Accepted && quote.AcceptedBy && Number(quote.AcceptedBy.ID) > 0 && (
                                <Fragment>
                                    <hr />
                                    <p>
                                        <strong>{niceDateTimeFromString(quote.RespondedDate)}</strong>
                                        <br />
                                        <TickIcon color="primary" className={classes.icons} /> Approved By:{' '}
                                        <strong>{quote.AcceptedBy.FirstName + ' ' + quote.AcceptedBy.Surname}</strong>
                                    </p>
                                </Fragment>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderHistoryActions = () => {
        return <Inline alignment={inlineAlignment.rightAlignSiblings}>
            <div>
                <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                    Close
                </Button>
            </div>
            <Button onClick={() => this.setState({ tabIndex: 1 })} endIcon={<NextIcon />}>
                Next
            </Button>
        </Inline>;
    };

    handleMutateError(e) {
        this.onGqlError('Oops, unable to write - ', e);
    }

    handleUploadAttachment(uploadFile, attachments) {
        const { emailForm } = this.state;
        attachments = attachments || [];

        attachments.push({
            ID: uploadFile.ID,
            AbsoluteLink: uploadFile.AbsoluteLink,
            Name: getFileName(uploadFile.FileName)
        });

        emailForm.setField({
            Attachments: attachments
        });
    }

    onQuoteEmailSendButtonClicked = (mutate, sendData, e) => {
        e.preventDefault();

        const { setSnackbarMessage } = this.props;

        if (sendData) {
            const variables = cloneDeep(sendData);
            variables.input.QuoteEmailTemplateID = variables.input.QuoteEmailTemplate.ID;
            variables.input.Attachments = sendData.input.Attachments.map(e => {
                return { ID: e.ID };
            }).filter(e => !!e && Number(e.ID));
            delete variables.input.QuoteEmailTemplate;

            mutate({ variables }).then(
                response => {
                    if (response && response.data && response.data.createCMQuoteAction) {
                        setSnackbarMessage('Success, sent email to: ' + response.data.createCMQuoteAction.EmailTo, true);
                        this.setState({ refetchQuotes: true }, () => this.onClose());
                    } else {
                        if (response) {
                            if (response.errors && response.errors[0]) {
                                setSnackbarMessage('Oops, there was an error - ' + response.errors[0].message);
                            } else {
                                setSnackbarMessage('Something went wrong');
                            }
                        }
                    }
                },
                e => this.onGqlError('Oops, there was an error - ', e)
            );
        }
    };

    renderPdfView() {
        const { quoteForm } = this.state;
        const PDFLink = (quoteForm && quoteForm.getField('AbsolutePDFLink')) || false;

        return (
            <iframe
                src={PDFLink ? PDFLink : 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'}
                style={{ width: '100%', height: 660 }}
                title="quote-pdf"
            />
        );
    }

    renderNoteHistory() {
        return (
            <Grid container>
                {this.renderNoteLayout()}
            </Grid>
        );
    }

    renderNoteHistoryActions() {
        const { tabIndex } = this.state;
        return <Inline center>
            <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                Close
            </Button>
            <Button onClick={() => this.setState({ tabIndex: tabIndex - 1 })}
                    startIcon={<BackIcon />}>
                Back
            </Button>
        </Inline>;
    }

    renderNoteLayout() {
        return (
            <Fragment>
                <Grid item xs={12} md={7}>
                    {this.renderLeftNotes()}
                </Grid>
                <Grid item xs={12} md={5}>
                    {this.renderRightNotes()}
                </Grid>
            </Fragment>
        );
    }

    renderLeftNotes() {
        const { quoteForm, loading } = this.state;
        if (!quoteForm) return null;

        const buttonDisable = loading || !quoteForm.getField('Notes');
        return (
            <Grid bucket>
                <Grid item>
                    <Label>Add a note</Label>
                    <TextField
                        value={quoteForm.getField('Notes')}
                        onChange={e => {
                            quoteForm.setField({ Notes: e.target.value });
                            this.setState(quoteForm);
                        }}
                        placeholder="Type a note here..."
                        multiline
                        rows={10}
                    />
                </Grid>
                <Grid item>
                    <Button variant="confirmation" disabled={buttonDisable} onClick={this.onSubmit}
                            startIcon={!!loading ? <Spinner size="xs" /> : <SaveIcon />}>
                        Add Note
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderRightNotes() {
        const { notes } = this.state;

        return (
            <Grid bucket>
                <Grid item>
                    <h4>Notes History</h4>
                </Grid>
                <Grid item>
                    <NotesReadOnly name="NotesValue" value={notes} />
                </Grid>
            </Grid>
        );
    }

    onGqlError(action, error) {
        const { setSnackbarMessage } = this.props;
        setSnackbarMessage(action + (error ? ' - ' + error.message : ''));
        this.setState({ loading: false });
    }
}

export const getPriceIncGst = quoteLineItem => {
    const price = parseFloat(quoteLineItem.Price);
    return quoteLineItem.HasGST ? price * (GST + 1) : price;
};

export const recalculatePrice = (quoteLineItem, newGstPrice) => {
    quoteLineItem.Price = quoteLineItem.HasGST ? newGstPrice / (GST + 1) : newGstPrice;
};

export const calculateTotals = quote => {
    let total = 0,
        gst = 0,
        totalWithGst = 0;

    for (let x = 0; x < quote.QuoteItems.length; x++) {
        const item = quote.QuoteItems[x];
        if (item.Qty > 0 && !item.Complimentary) {
            const itemTotal = item.Price * item.Qty;

            total += itemTotal;

            const itemTotalWithGst = item.HasGST ? round(itemTotal * (GST + 1), 2) : itemTotal;
            totalWithGst += itemTotalWithGst;

            gst += item.HasGST ? itemTotalWithGst / ((GST + 1) * 10) : 0;
        }
    }

    return { total, gst, totalWithGst };
};

const queryQuoteTemplates = gql`
    query ReadQuoteTemplates {
        readCMQuoteTemplates {
            ...QuoteTemplate
        }
    }
    ${QuoteTemplate}
`;

const getEmailTemplates = async () => {
    const asyncQuery = await getClient().query({ query: readEmailTemplates() });
    return asyncQuery && asyncQuery.data ? asyncQuery.data.readCMQuoteEmailTemplates : {};
};

const readEmailTemplates = () => gql`
    ${QuoteEmailTemplate}
    query {
        readCMQuoteEmailTemplates {
            ...QuoteEmailTemplate
        }
    }
`;

const createQuoteAction = gql`
    ${QuoteAction}
    mutation CreateQuoteAction($input: CreateCMQuoteActionInput!) {
        createCMQuoteAction(input: $input) {
            ...QuoteAction
        }
    }
`;
const styles = () => ({
    icons: {
        margin: '0 0 -6px'
    }
});
export default compose(withSnackbarMessage, withStyles(styles))(QuoteModal);
