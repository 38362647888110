import gql from 'graphql-tag';
import AshLocation from './AshLocation';
import Client from './Client';
import CremationManagerAddressBook from './AddressBook';
import CremationOrderStaffMember from './CremationOrderStaffMember';
import PlaqueProductOrder from './PlaqueProductOrder';
import Deceased from './Deceased';
import Enquiry from './Enquiry';
import PlaqueOrderAsh from './PlaqueOrderAsh';
import Invoice from './Invoice';
import PlaquePhoto from './PlaquePhoto';
import ProofEmail from './ProofEmail';
import PlaqueCombination from './PlaqueCombination';
import PlaqueCombinationLocation from './PlaqueCombinationLocation';

export default gql`
    fragment PlaqueOrder on PlaqueOrder {
        ID
        LegacyKey
        Status
        InstallationDate
        InstallationTime
        FamilyAttendance
        FamilyContact
        FamilyAttendanceDate
        FamilyAttendanceTime
        PlacementByCouncil
        PlaquePosition
        PlaqueMaterial
        AttachmentMethod
        WallPosition
        QuoteSignedDate
        AshesApplicationFormSignedDate
        PlaqueWordingSentDate
        ProofArrivedDate
        ApprovalSentToSupplierDate
        InvoiceCreatedDate
        PlaqueArrivedDate
        AccountPaidDate
        PlaqueOrAshesPlacedOrDeliveredDate
        NicheConfirmedDate
        AdditionalNotes
        FlowerBorder
        RaisedBorder
        BorderDetails
        PlaqueWordingAdditionalLines
        PlaqueWordingLines
        MatchPlaqueToPhoto
        Urgent
        Complete
        Cancelled
        CancelledDate
        CancelledReason
        Enquiry {
            ...Enquiry
        }
        Deceased {
            ...Deceased
        }
        Client {
            ...Client
        }
        PlaqueLocation {
            ...PlaqueCombinationLocation
        }
        PlaqueCombination {
            ...PlaqueCombination
        }
        PlaqueSupplier {
            ...CremationManagerAddressBook
        }
        ProductOrder {
            ...PlaqueProductOrder
        }
        Ashes {
            ...PlaqueOrderAsh
        }
        AshLocations {
            ...AshLocation
        }
        StaffMembers {
            ...CremationOrderStaffMember
        }
        Invoices {
            ...Invoice
        }
        PlaquePhotos {
            ...PlaquePhoto
        }
        ProofEmails {
            ...ProofEmail
        }
        SignificantDocuments {
            ... on File {
                ID
                Name
                Size
                AbsoluteLink
            }
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
    ${Client}
    ${Deceased}
    ${Enquiry}
    ${CremationManagerAddressBook}
    ${CremationOrderStaffMember}
    ${PlaqueOrderAsh}
    ${AshLocation}
    ${Invoice}
    ${PlaqueProductOrder}
    ${PlaquePhoto}
    ${ProofEmail}
    ${PlaqueCombination}
    ${PlaqueCombinationLocation}
`;
