export const SampleOptions = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3', value: '3' }
];

export const GenderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'Indeterminate', value: 'Indeterminate' },
    { label: 'Intersex', value: 'Intersex' },
    { label: 'Unknown', value: 'Unknown' }
];

export const DispatchMethodOptions = [
    { label: 'Collection', value: 'Collected by' },
    { label: 'Delivery', value: 'Delivered to' },
    { label: 'Transfer', value: 'Transferred' },
    { label: 'Post', value: 'Posted' }
];

export const AshesCollectedByOptions = [
    { label: 'Applicant', value: 'Applicant' },
    { label: 'Contact', value: 'Contact' }
];

export const MaritalStatusOptions = [
    { label: 'Married', value: 'Married' },
    { label: 'De facto', value: 'Defacto' },
    { label: 'Divorced', value: 'Divorced' },
    { label: 'Widowed', value: 'Widowed' },
    { label: 'Never married', value: 'Never married' },
    { label: 'Unknown', value: 'Unknown' }
];
