import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme } from '@material-ui/core/styles';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import { SearchTermProvider } from '../context/SearchTerm';
import { SnackbarMessageProvider } from '../context/SnackbarMessage';
import { getClient } from '../utils/apollo';
import history from '../utils/history';
import { EditAppointmentProvider } from '../context/EditAppointment';

const theme = createMuiTheme({
    palette: {
        action: {
            active: '#413BBE',
            hover: '#EFF8FD',
            selected: '#E1F0FB',
            save: '#14A554',
            destroy: '#bb5a5a'
        },
        primary: {
            main: '#7d9cc4'
        },
        secondary: {
            main: '#e64040'
        }
    },
    sizes: {
        headerHeight: 72,
        footerHeight: 80,
        drawerWidth: 250,
        tasksDrawerWidth: 350,
        drawerWidthMinimized: 50,
        tabHeight: 56
    }
});

const client = getClient();

export default ({ children }) => (
    <SnackbarMessageProvider>
        <SearchTermProvider>
            <EditAppointmentProvider>
                <ApolloProvider client={client}>
                    <Router history={history}>
                        <MuiThemeProvider theme={theme}>
                            <CssBaseline />
                            {children}
                        </MuiThemeProvider>
                    </Router>
                </ApolloProvider>
            </EditAppointmentProvider>
        </SearchTermProvider>
    </SnackbarMessageProvider>
);
