import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Tab, Typography } from '@material-ui/core';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import Tabs from '../../components/layout/Tabs';
import ColourPalette from './ColourPalette';
import TypographyStyling from './TypographyStyling';
import ButtonStyling from './ButtonStyling';
import FormElementStyling from './FormElementStyling';
import AlertStyling from './AlertStyling';
import TableStyling from './TableStyling';
import ModalStyling from './ModalStyling';
import TaskCardStyling from './TaskCardStyling';
import MetricDashboardStyling from './MetricDashboardStyling';
import IconsPreview from './IconsPreview';
import ErrorFallback from '../../components/ErrorFallback';
import { withErrorBoundary } from '@sentry/react';

class Styles extends Component {
    state = {
        selectedIndex: 0
    };

    render() {
        const { selectedIndex } = this.state;

        return (
            <Paper>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4">Styles</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <br />
                    </Grid>
                    <Grid item xs={12}>
                        <Tabs value={selectedIndex} onChange={(e, i) => this.setState({ selectedIndex: i })}>
                            <Tab label="Typography" />
                            <Tab label="Colour Palette" />
                            <Tab label="Buttons" />
                            <Tab label="Form elements" />
                            <Tab label="Status" />
                            <Tab label="Table" />
                            <Tab label="Modals" />
                            <Tab label="Cards" />
                            <Tab label="Metric Dashboard" />
                            <Tab label="Icons" />
                        </Tabs>
                    </Grid>
                    {this.renderTab()}
                </Grid>
            </Paper>
        );
    }

    renderTab() {
        const { selectedIndex } = this.state;

        if (selectedIndex === 0) return <TypographyStyling />;
        if (selectedIndex === 1) return <ColourPalette />;
        if (selectedIndex === 2) return <ButtonStyling />;
        if (selectedIndex === 3) return <FormElementStyling />;
        if (selectedIndex === 4) return <AlertStyling />;
        if (selectedIndex === 5) return <TableStyling />;
        if (selectedIndex === 6) return <ModalStyling />;
        if (selectedIndex === 7) return <TaskCardStyling />;
        if (selectedIndex === 8) return <MetricDashboardStyling />;
        if (selectedIndex === 9) return <IconsPreview />;

        return <div>todo</div>;
    }
}

export default withErrorBoundary(withStyles({})(Styles), { fallback: ErrorFallback });
