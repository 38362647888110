import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';

export default withStyles({})(({ noteDetails, ashDetails, stepNumber, variant }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `step--${variant}`;

    return (
        <div className={`step ${cssVariant || ''}`}>
            <div>
                <div className="circle">{stepNumber}</div>
            </div>
            <div>
                <div className="ash-details">{ashDetails}</div>
                <div className="caption">{noteDetails}</div>
            </div>
        </div>
    );
});
