import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import Query from 'react-apollo/Query';
import Grid from '../../../components/layout/Grid';
import Graph from '../../../components/metrics/Graph';
import MetricCard from '../../../components/metrics/MetricCard';
import { getMyClient } from '../../../utils/apollo';
import PieChart from '../../../components/metrics/PieChart';
import Stats from '../../../components/metrics/Stats';
import LineChart from '../../../components/metrics/LineChart';

const styles = () => ({
    gridThis: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: '7em 10em 10em',
        justifyItems: 'center',
        '& > div.stats-content': {
            width: '100%'
        },
        '& h3': {
            justifySelf: 'left',
            alignSelf: 'center',
            gridArea: '1 / 1 / 1 / 3'
        }
    }
});

export default withStyles(styles)(props => {
    const [reloading, setReloading] = React.useState(false);
    const [prevType, setPrevType] = React.useState(null);

    const { period, variant } = props;
    const { type } = period || null;

    if (type !== prevType) {
        // fake a reload, so CSS animations can restart
        setReloading(true);
        setPrevType(type);
    }

    const variables = {
        metricList: [
            { name: 'myArrangements', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myArrangementsConverted', variables: JSON.stringify({ ...period, variant }) },
            { name: 'myEnquiries', variables: JSON.stringify({ ...period, variant: variant }) },
            { name: 'myConversions', variables: JSON.stringify({ ...period, variant }) }
        ]
    };

    const query = gql`
        query ScoreArrangerPerformanceIndividual($metricList: [MetricsRequest]!) {
            loadMetrics(metrics: $metricList) {
                name
                result
            }
        }
    `;

    const { classes } = props;

    const renderResult = ({ data, loading, error }) => {
        if (loading) setReloading(true);
        else setReloading(false);

        const { loadMetrics } = (!error && data) || null;

        // enquiry type numbers - variables
        const enqMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myEnquiries')) || null;
        const enqResultRaw = (enqMetric && JSON.parse(enqMetric.result)) || [];
        const enqResult = (enqResultRaw && enqResultRaw.Results) || [];

        const enqPhoneType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PHONE');
        const enqAtneedType = enqResult.length && enqResult.find(e => e.EnquiryType === 'ATNEED');
        const enqPreneedType = enqResult.length && enqResult.find(e => e.EnquiryType === 'PRENEED');
        const enqPhoneCount = (enqPhoneType && enqPhoneType.Count) || 0;
        const enqAtneedCount = (enqAtneedType && enqAtneedType.Count) || 0;
        const enqPreneedCount = (enqPreneedType && enqPreneedType.Count) || 0;

        // conversion rate by enquiry type - variables
        const conMetric = (loadMetrics && loadMetrics.find(e => e.name === 'myConversions')) || null;
        const conResultRaw = (conMetric && JSON.parse(conMetric.result)) || [];
        const conResult = (conResultRaw && conResultRaw.Results) || [];

        const conPhoneType = conResult.length && conResult.find(e => e.EnquiryType === 'PHONE');
        const conPhoneCount = (conPhoneType && conPhoneType.Count) || 0;

        return (
            <Fragment>
                <Grid item xs={12} sm={6} lg={4}>
                    <MetricCard>
                        <div className={classes.gridThis}>
                            <h3 className="metrics">Ashes Collected by Correspondence</h3>
                            <Stats heading="Ashes Heldover (1+ month)" stats={conPhoneCount} />
                            <Stats heading="Letter One (L1) Sent" stats={conPhoneCount} />
                            <Stats heading="L1 Not Sent" stats={conPhoneCount} />
                            <Stats heading="Not Collected After L1" stats={conPhoneCount} />
                        </div>
                    </MetricCard>
                </Grid>

                <Grid item xs={12} sm={6} lg={4} xl={3}>
                    <MetricCard>
                        <Graph variant="three" title="Letter One (L1) Success Rates:">
                            <PieChart
                                loading={reloading}
                                data={[
                                    { label: 'Not sent', value: enqPhoneCount },
                                    { label: 'Sent and collected', value: enqAtneedCount },
                                    { label: 'Sent but not collected', value: enqPreneedCount }
                                ]}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>

                <Grid item xs={12} lg={4} xl={5}>
                    <MetricCard>
                        <Graph variant="three" title="Ashes Collected Over Time:">
                            <LineChart
                                label="Collected"
                                loading={reloading}
                                data={[
                                    { label: 'Not sent', value: enqPhoneCount },
                                    { label: 'Sent and collected', value: enqAtneedCount },
                                    { label: 'Sent but not collected', value: enqPreneedCount }
                                ]}
                            />
                        </Graph>
                    </MetricCard>
                </Grid>
            </Fragment>
        );
    };

    return (
        <div className="AdminPerformance">
            <Grid container spacing={24}>
                <Grid item pc>
                    <h2>All Correspondence</h2>
                </Grid>
                <Query query={query} variables={variables} client={getMyClient()} fetchPolicy="cache-and-network">
                    {result => renderResult(result)}
                </Query>
            </Grid>
        </div>
    );
});
