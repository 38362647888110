import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 22.44 25.33" {...props}>
        <path
            fill="currentColor"
            d="M21.48,11.13h-.8V8.05A8,8,0,1,0,4.65,8v3.15h-.8a2.38,2.38,0,0,0-2.4,2.37h0V23a2.38,2.38,0,0,0,2.4,2.36H21.48A2.39,2.39,0,0,0,23.89,23h0V13.5a2.39,2.39,0,0,0-2.41-2.37ZM6.25,8A6.42,6.42,0,0,1,19.08,8v3.15H6.25ZM21.48,23.76H3.85a.79.79,0,0,1-.8-.79V13.5a.79.79,0,0,1,.8-.79H21.48a.79.79,0,0,1,.8.79V23A.79.79,0,0,1,21.48,23.76Z"
        />
    </SvgIcon>
);
