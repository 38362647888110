import React from 'react';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import fmLogoSvg from '../assets/logo.svg';

const Anonymous = ({ children }) => (
	<div className="anonymous">
		<div className="side side--left">
			<img src={fmLogoSvg} alt="logo" className="logo" />
			<Typography variant="display1" align="center" className="title">
				Cremation
				<br />
				Manager
			</Typography>
		</div>
		<div className="side side--right">{children}</div>
	</div>
);

export default withStyles({})(Anonymous);
