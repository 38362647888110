import React, { Component } from 'react';
import gql from 'graphql-tag';
import AuthorisedContact from '../../fragments/AuthorisedContact';
import Select from './Select';
import { joinDefined } from '../../utils/strings';
import { getClient } from '../../utils/apollo';

class AuthorisedContactSelector extends Component {
    state = {
        options: [],
        cremationId: undefined
    };

    componentWillMount() {
        this.loadAuthorisedContacts();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cremationId !== this.state.cremationId && nextProps.cremationId !== null)
            this.loadAuthorisedContacts(nextProps);
    }

    render() {
        const { options } = this.state;
        const { onChange, value } = this.props;

        const newValue = !!value && !!options.find(val => val.value.toUpperCase() === value.toUpperCase()) ? value : '';
        return (
            <Select
                value={newValue}
                label={!!newValue ? 'Authorised Contact' : 'Select an Authorised Contact'}
                placeholder={options.length > 0 ? 'Please choose one...' : 'Please enter details.'}
                options={options}
                {...this.props}
                onChange={e => {
                    const item = options.find(val => e.target.value === val.value);
                    onChange(item);
                }}
            />
        );
    }

    loadAuthorisedContacts(nextProps) {
        const { cremationId } = nextProps || this.props;
        if (cremationId !== this.state.cremationId && cremationId !== null) {
            var that = this;
            getAuthorisedContactsFunc(cremationId).then(options => {
                that.setState({ options, cremationId });
            });
        }
    }
}

//reads the family fragment
const readOneAuthorisedContact = gql`
    ${AuthorisedContact}
    query loadFamilyData($id: ID!) {
        readOneCremationOrder(ID: $id) {
            ID
            LegacyKey
            AuthorisedContacts {
                ...AuthorisedContact
            }
        }
    }
`;

//gets the family list from the database and joins it into an options list
const getAuthorisedContactsFunc = async cremationId => {
    if (!cremationId) return [];

    const asyncQuery = await getClient().query({ query: readOneAuthorisedContact, variables: { id: cremationId } });
    if (!asyncQuery || !asyncQuery.data) return [];

    const cremation = asyncQuery.data.readOneCremationOrder;
    const options = []; //{ label: 'None', value: '', metadata: null }];
    if (!cremation) return options;
    for (let x = 0; x < cremation.AuthorisedContacts.length; x++) {
        const contact = cremation.AuthorisedContacts[x];
        const relationship = contact.RelationshipToDeceased;
        const label =
            joinDefined([contact.FirstName, contact.MiddleName, contact.Surname], ' ') + ' (' + relationship + ')';
        options.push({
            label: label,
            metadata: {
                relationship: relationship,
                source: contact
            },
            value: label
        });
    }

    return options.sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });
};

export default AuthorisedContactSelector;
