import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import cloneDeep from 'lodash.clonedeep';
import Grid from '../../../components/layout/Grid';
import Modal from '../../../components/modal/Modal';
import TextField from '../../../components/form/TextField';
import Checkbox from '../../../components/form/Checkbox';
import Inline, { inlineAlignment } from '../../../components/layout/Inline';
import Button from '../../../components/form/Button';
import UploadField, { getFileName } from '../../../components/form/UploadField';
import SaveIcon from '../../../components/icon/SaveIcon';
import FlexGrid from '../../../components/layout/FlexGrid';
import ThumbnailCard from '../../../components/cards/ThumbnailCard';
import { isNullOrUndefined } from '../../../utils/objects';
import createForm from '../../../utils/createForm';
import { dateToString, niceTimeFromString } from '../../../utils/date';
import { getClient } from '../../../utils/apollo';
import ProofEmail from '../../../fragments/ProofEmail';
import { trimProofEmail } from './SendProofModal';
import { BackIcon, CloseIcon, EnvelopeIcon } from '../../../components/IconIndex';
import NextIcon from '../../../components/icon/NextIcon';
import HtmlEditor from '../../../components/form/HtmlEditor';

class SendProofModal extends Component {
    state = {
        tabIndex: 0,
        emailId: null,
        loading: false,
        form: null,
        open: false
    };

    static getDerivedStateFromProps({ emailId, open, parentForm }, state) {
        const newState = { open };
        const proofEmails = parentForm.getField('ProofEmails');
        const proofEmail = proofEmails.find(e => e.ID === emailId);
        if (!state.form || !state.form.state || (!!proofEmail && state.form.state.ID !== proofEmail.ID)) {
            if (!isNullOrUndefined(proofEmail)) {
                let clonedEmail = cloneDeep(proofEmail);
                const oldContext = state.form !== null ? state.form.context : null;
                newState.form = createForm(oldContext, clonedEmail);
                newState.emailId = emailId;
            } else {
                newState.form = null;
            }
            newState.tabIndex = 0;
        }

        return newState;
    }

    componentDidUpdate(_, oldState) {
        const { form } = this.state;
        if (oldState.form === null && form !== null) form.context = this;
    }

    render() {
        const { open } = this.props;
        const { tabIndex, form } = this.state;
        if (!form) return null;

        return (
            <Modal
                open={open}
                variant="stepped"
                onClose={this.onClose}
                canClickOut={false}
                className="email-modal"
                activeTab={tabIndex}
                steps={[
                    {
                        abbreviation: 'Send Proof',
                        title: 'Send proof email of plaque details to the clients',
                        onChangeTab: this.onChangeTab,
                        content: <div>{this.renderEmailDetails()}</div>,
                        actions: <div>{this.renderActions(0)}</div>
                    },
                    {
                        abbreviation: 'Approval',
                        title: 'Provide evidence of approval',
                        onChangeTab: this.onChangeTab,
                        content: <div>{this.renderEvidence()}</div>,
                        actions: <div>{this.renderActions(1)}</div>
                    }
                ]}
            />
        );
    }

    renderEmailDetails() {
        const { plaqueOrderClient } = this.props;
        const { form } = this.state;
        const readOnly = !!form.getField('EmailSent');
        const attachments = form.getField('Attachments') || [];

        return (
            <Grid container spacing={16}>
                <Grid item>
                    <h4> The following details were {form.getField('EmailSent') ? 'sent' : 'saved'} </h4>
                </Grid>

                {!!form.getField('EmailSent') && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Date Sent"
                                readOnly
                                type="text"
                                value={dateToString(form.getField('EmailSent'))}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Time Sent"
                                readOnly
                                type="text"
                                value={niceTimeFromString(form.getField('EmailSent'))}
                            />
                        </Grid>
                    </Fragment>
                )}

                <Grid item>
                    <h4> Email to </h4>
                    <Checkbox readOnly={readOnly} label="Email Applicant" disabled={!plaqueOrderClient}
                              name="EmailApplicant" form={form} />
                    <Checkbox readOnly={readOnly} label="Email Other Recipient" name="EmailOther" form={form} />
                </Grid>
                {form.getField('EmailOther') && (
                    <Grid item>
                        <TextField readOnly={readOnly} label="Email Recipients" name="OtherEmailAddress" form={form} />
                    </Grid>
                )}

                <Grid item>
                    <h4> Message </h4>
                </Grid>

                <Grid item>
                    <HtmlEditor readOnly={readOnly} placeholder="Enter message here..." name="Message" form={form} />
                </Grid>

                {!readOnly && <>
                    <Grid item>
                        <Grid item>
                            <h4> Attachments </h4>
                        </Grid>
                        <UploadField
                            folderPath={`/documents/plaque/${form.getField('ID')}`}
                            onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, 'Attachments')}
                        />
                    </Grid></>
                }

                {!!attachments && !!attachments.length && (
                    <Grid item>
                        <h4> Attached Files </h4>
                        <FlexGrid>
                            {attachments.map(file => (
                                <ThumbnailCard
                                    key={'file-' + file.ID}
                                    fileName={file.Name}
                                    fileLink={file.AbsoluteLink}
                                    fileId={file.ID}
                                    onClickRemoveAction={!readOnly ? fileId => this.onAttachmentRemove(fileId, 'Attachments') : null}
                                />
                            ))}
                        </FlexGrid>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderEvidence() {
        const { form } = this.state;

        const evidenceFiles = form.getField('EvidenceFiles');

        return (
            <Grid container>

                <Grid item>
                    <Checkbox label="Client has approved proof" name="Approved" form={form} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Date Approved" type="date" name="ApprovedDate" form={form} />
                </Grid>

                {!!evidenceFiles && !!evidenceFiles.length && (
                    <Grid item>
                        <h4> Evidence Files </h4>
                        <FlexGrid>
                            {evidenceFiles.map(file => (
                                <ThumbnailCard
                                    key={'file-' + file.ID}
                                    fileName={file.Name}
                                    fileLink={file.AbsoluteLink}
                                    fileId={file.ID}
                                    onClickRemoveAction={fileId => this.onAttachmentRemove(fileId, 'EvidenceFiles')}
                                />
                            ))}
                        </FlexGrid>
                    </Grid>
                )}


                <Grid item>
                    <h4>{!!evidenceFiles && !!evidenceFiles.length ? 'Add more ' : 'Please provide '}
                        evidence of approval:</h4>
                </Grid>

                <Grid item>
                    <UploadField
                        folderPath={`/documents/plaque/${form.getField('ID')}`}
                        onComplete={({ uploadFile }) => this.handleUploadAttachment(uploadFile, 'EvidenceFiles')}
                    />
                </Grid>

            </Grid>
        );
    }

    renderActions = tabIdx => {
        const { form } = this.state;
        const readOnly = !!form.getField('EmailSent');
        const isDirty = !!form.getDirty().length;

        return (
            <Inline className="button-alignment" alignment={inlineAlignment.rightAlignSiblings} center>
                <Inline>
                    <Button variant="secondary" onClick={this.onClose} startIcon={<CloseIcon />}>
                        Cancel
                    </Button>
                    {1 === tabIdx &&
                        <Button variant="primary" onClick={() => this.onChangeTab(tabIdx - 1)} startIcon={<BackIcon />}>
                            Back
                        </Button>
                    }
                </Inline>

                {1 === tabIdx
                    ? <Button disabled={!isDirty} variant="confirmation" onClick={this.onSave}
                              startIcon={<SaveIcon />}>
                        Save
                    </Button> : <>
                        <Button disabled={readOnly || !isDirty} variant="confirmation" onClick={this.onSave}
                                startIcon={<SaveIcon />}>
                            {(readOnly || !isDirty) ? 'Saved' : 'Save Draft'}
                        </Button>
                        <Button disabled={readOnly} variant="urgent" onClick={this.onSendEmail}
                                startIcon={<EnvelopeIcon />}>
                            {readOnly ? 'Sent' : 'Send Email'}
                        </Button>
                    </>
                }

                {1 !== tabIdx &&
                    <Button variant="primary" onClick={() => this.onChangeTab(tabIdx + 1)} endIcon={<NextIcon />}>
                        Next
                    </Button>
                }
            </Inline>
        );
    };

    onChangeTab = tabIndex => {
        this.setState({ tabIndex });
    };

    onSendEmail = () => {
        const { form } = this.state;

        this.setState({
            loading: true
        });

        form.state.ScheduledToSendEmail = true;

        this.onSave();
    };

    onSave = () => {
        const { form, emailId } = this.state;
        const { parentForm, onClose, plaqueOrderID, proofEmail } = this.props;

        let email = form.state;

        this.setState({ loading: true });
        const that = this;

        return updateProofEmailFunc(email, proofEmail, plaqueOrderID).then(
            ({ data }) => {
                const ProofEmails = parentForm.getField('ProofEmails');
                const updatedEmail = data.updateProofEmail;
                const foundIndex = ProofEmails.findIndex(e => e.ID === emailId);

                Object.assign(ProofEmails[foundIndex], cloneDeep(updatedEmail));
                parentForm.setField({ ProofEmails }, true);

                that.setState({
                    loading: false,
                    open: false
                });
                onClose();
                parentForm.save();
            },
            e => that.onGqlError('Failed to update proof email.', e)
        );
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
        this.setState({
            tabIndex: 0,
            loading: false,
            form: null,
            open: false
        });
    };

    onAttachmentRemove(fileId, property) {
        const { form } = this.state;

        let attachments = form.getField('Attachments');
        attachments = attachments.filter(obj => fileId !== obj.ID);

        form.setField({ [property]: attachments });
    }

    handleUploadAttachment(uploadFile, property) {
        const { form } = this.state;
        const email = form.state;
        const attachments = email[property];

        attachments.push({
            ID: uploadFile.ID,
            AbsoluteLink: uploadFile.AbsoluteLink,
            Name: getFileName(uploadFile.FileName)
        });

        form.setField({ [property]: attachments });
    }
}

const updateProofEmailMutation = gql`
    ${ProofEmail}
    mutation UpdateProofEmail($input: UpdateProofEmailInput!) {
        updateProofEmail(input: $input) {
            ...ProofEmail
        }
    }
`;

const updateProofEmailFunc = async (proofEmail, original, plaqueOrderID) => {
    return await getClient().mutate({
        mutation: updateProofEmailMutation,
        variables: {
            input: trimProofEmail(plaqueOrderID, proofEmail, original)
        }
    });
};

export default withStyles({})(SendProofModal);
