import React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import IconButton from '@material-ui/core/IconButton';
import Grid from '../layout/Grid';
import DeleteIcon from '../icon/DeleteIcon';
import MapMarkerIcon from '../icon/MapMarkerIcon';
import PhoneSquareIcon from '../icon/PhoneSquareIcon';
import EnvelopeSquareIcon from '../icon/EnvelopeSquareIcon';
import FaxIcon from '../icon/FaxIcon';
import MobileIcon from '../icon/MobileIcon';
import Inline, { inlineAlignment } from '../layout/Inline';
import FlexGrid from '../layout/FlexGrid';
import { joinDefined, stringIsNullOrEmpty } from '../../utils/strings';
import ViewContactModal from '../../views/Contacts/Modals/ViewContactModal';
import GatedComponent from '../GatedComponent';

class ContactCard extends React.Component {
    state = {
        item: null
    };

    static getDerivedStateFromProps({ contact }, { item }) {
        if (contact && (!item || contact.ID !== item.ID)) {
            return { item: { ...contact } };
        }

        return null;
    }

    render() {
        const { variant, contact, readOnly = false } = this.props;
        if (!contact) return null;
        let cssVariant = null;
        if (!stringIsNullOrEmpty(variant)) cssVariant = `contactcard--${variant}`;

        const { item } = this.state;
        const address =
            item &&
            joinDefined(
                [
                    item.AddressLine1,
                    item.AddressLine2,
                    joinDefined(
                        [item.Suburb, item.State, item.Postcode, item.Country !== 'Australia' && item.Country],
                        ' '
                    )
                ],
                ', '
            );

        const { mutation, variables } = this.getRetirementMutation();

        return (
            <div className={`contactcard ${cssVariant || ''}`}>
                <Grid container spacing={16}>
                    <Grid item>
                        <FlexGrid variant="justify">
                            <h3 className="business-name">{item.Name}</h3>
                            <GatedComponent>
                                {() => {
                                    return (
                                        <Mutation
                                            mutation={mutation}
                                            onCompleted={this.onSaveCompleted}
                                            onError={e => this.handleMutateError(e)}
                                        >
                                            {(mutate, { loading }) => {
                                                const disabled = loading || readOnly;
                                                return (
                                                    <IconButton
                                                        aria-label="Delete"
                                                        className="icon"
                                                        title={item.NotCurrent ? 'Restore' : 'Retire'}
                                                        disabled={disabled}
                                                        onClick={e =>
                                                            this.onDeleteClicked(mutate, !disabled && variables, e)
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                );
                                            }}
                                        </Mutation>
                                    );
                                }}
                            </GatedComponent>
                        </FlexGrid>
                        <Inline center>
                            <p className="contact-name">{item.Contact}</p>
                            <p className="business-category">{item.AddressBookCategory}</p>
                        </Inline>
                    </Grid>

                    {address && (
                        <Grid item>
                            <Inline center>
                                <MapMarkerIcon className="contact-icon" />
                                <p className="location">{address}</p>
                            </Inline>
                        </Grid>
                    )}
                    {item.Phone && (
                        <FlexGrid variant="justify">
                            <Inline center className="contact-info">
                                <PhoneSquareIcon className="contact-icon" />
                                <p className="contact-details">{item.Phone}</p>
                            </Inline>
                        </FlexGrid>
                    )}
                    {item.Mobile && (
                        <FlexGrid variant="justify">
                            <Inline center className="contact-info">
                                <MobileIcon className="contact-icon" />
                                <p className="contact-details">{item.Mobile}</p>
                            </Inline>
                        </FlexGrid>
                    )}
                    {item.Fax && (
                        <FlexGrid variant="justify">
                            <Inline center className="contact-info">
                                <FaxIcon className="contact-icon" />
                                <p className="contact-details">{item.Fax}</p>
                            </Inline>
                        </FlexGrid>
                    )}
                    {item.Email && (
                        <FlexGrid variant="justify">
                            <Inline center className="contact-info">
                                <EnvelopeSquareIcon className="contact-icon" />
                                <p className="contact-details">{item.Email}</p>
                            </Inline>
                        </FlexGrid>
                    )}

                    <Grid item>
                        <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                            <div />
                            <ViewContactModal onClose={this.updateContact} contact={item} readOnly={readOnly} />
                        </Inline>
                    </Grid>
                </Grid>
            </div>
        );
    }

    onDeleteClicked = (mutate, variables, e) => {
        e.preventDefault();
        let msg = "You're about to retire this contact.\nAre you sure?";
        if (!variables.input.NotCurrent) msg = "You're about to restore this contact.\nAre you sure?";
        const axe = window.confirm(msg);
        if (axe) {
            if (variables) mutate({ variables });
        }
    };

    updateContact = data => {
        const { item } = this.state;
        this.setState({ item: { ...item, ...data } });
    };

    getRetirementMutation() {
        const { item } = this.state;
        return {
            mutation: gql`
                mutation RetireContact($input: UpdateCremationManagerAddressBookInput!) {
                    updateCremationManagerAddressBook(input: $input) {
                        ID
                        NotCurrent
                    }
                }
            `,
            variables: {
                input: { NotCurrent: !item.NotCurrent, ID: item.ID }
            }
        };
    }
}

export default ContactCard;
