import React, { Component, Fragment } from 'react';
import Query from 'react-apollo/Query';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import * as moment from 'moment';
import TextField from '../../components/form/TextField';
import Checkbox from '../../components/form/Checkbox';
import ColumnLayout from '../../components/layout/ColumnLayout';
import Grid from '../../components/layout/Grid';
import Paper from '../../components/layout/Paper';
import Inline, { inlineAlignment } from '../../components/layout/Inline';
import Button from '../../components/form/Button';
import Select from '../../components/form/Select';
import EnquiryFragment from '../../fragments/Enquiry';
import Table, { Cell, HeaderRow, Row } from '../../components/table/Table';
import Label from '../../components/form/Label';
import TickIcon from '../../components/icon/TickIcon';
import Spinner from '../../components/loading/Spinner';
import {
    copyFuneral,
    createCremationAsync,
    createPlaqueAsync,
    CurrentSituation,
    DeathTimingOption,
    DeathTimingOptions,
    EnquiryType,
    GenderOptions,
    readEnquiryQuotes,
    RelationshipToDeceasedOptions,
    TitleOptions,
    validation
} from './EnquiryConstants';
import AddressAutocomplete, { createAddressComponentFields } from '../../components/form/AddressAutocomplete';
import ViewHeader from '../../components/header/ViewHeader';
import Separator from '../../components/layout/Separator';
import NotesReadOnly from '../../components/form/NotesReadOnly';
import QuoteModal from './Modals/Quotation/QuoteModal';
import AppointmentModal from '../../components/modal/AppointmentModal';
import AshLocationHistoryModal from '../Cremation/Modals/AshLocationHistoryModal';
import { joinDefined, prettyEmail, prettyPhone, prettyPrice } from '../../utils/strings';
import ContactLookup from '../../components/contact/ContactLookup';
import ContactCard from '../../components/contact/ContactCard';
import { isContactDefined } from '../../utils/bookable';
import DeleteIcon from '../../components/icon/DeleteIcon';
import SearchIcon from '../../components/icon/SearchIcon';
import EditIcon from '../../components/icon/EditIcon';
import { deleteTypeName, isNullOrUndefined } from '../../utils/objects';
import { getUser } from '../../utils/sessions';
import { assembleReasonFromEnquiry } from '../Calendar/AppointmentPopover';
import AlertBar from '../../components/form/AlertBar';
import AssociatedOrders from '../../components/AssociatedOrders';
import FuneralSearchModal from './Modals/FuneralSearchModal';
import RecordDrawer from '../../components/workQueue/RecordDrawer';
import { cloneDeep } from 'apollo-utilities';

class Enquiry extends Component {
    state = {
        openSearchModal: false,
        searchModalSuggestion: null,
        showAppointmentModal: false,
        appointmentId: null,
        quoteModal: {
            open: false,
            quoteIndex: null
        },
        editAshLocationHistoryIndex: null,
        showAshLocationHistoryModal: false,
        generatingRelatedRecord: false,
        generatedRelatedRecord: null
    };

    render() {
        const {
            showAppointmentModal,
            appointmentId,
            quoteModal,
            showAshLocationHistoryModal,
            editAshLocationHistoryIndex,
            openSearchModal,
            searchModalSuggestion
        } = this.state;
        const { form } = this.props;

        let editAshLocationHistory = {
            ID: null,
            Location: '',
            LocationDetail: '',
            Notes: '',
            Changes: []
        };

        if (editAshLocationHistoryIndex !== null && editAshLocationHistoryIndex !== undefined) {
            editAshLocationHistory = form.getState('AshLocations')[editAshLocationHistoryIndex];
        }

        return (
            <Grid container>
                <Grid item>{this.renderHeader()}</Grid>
                <Grid item>
                    <Paper elevation={0}>
                        <Grid item>{this.renderEnquiryDetails()}</Grid>

                        <Grid item>
                            <Separator />
                        </Grid>

                        <ColumnLayout>
                            {this.renderLeftColumn()}
                            {this.renderRightColumn()}
                        </ColumnLayout>

                        <AppointmentModal
                            open={!!showAppointmentModal}
                            parentForm={form}
                            appointmentId={appointmentId || null}
                            appointmentDefaults={
                                appointmentId
                                    ? null
                                    : {
                                          EnquiryCM: {
                                              ID: form.getField('ID'),
                                              LegacyKey: form.getField('LegacyKey')
                                          },
                                          ...assembleReasonFromEnquiry(form.state, { Type: 'Appointment' })
                                      }
                            }
                            memberList={[getUser()]}
                            onClose={() => this.handleCloseAppointmentModal()}
                            onMutate={appointment => this.onAfterAppointmentAddOrUpdate(appointment)}
                        />
                    </Paper>
                </Grid>

                <FuneralSearchModal
                    open={openSearchModal}
                    initialSearchTerm={searchModalSuggestion}
                    onAccept={e => this.copyDetailsFromFuneral(e, form)}
                    onClose={e => this.setState({ openSearchModal: false, searchModalSuggestion: null })}
                />

                <QuoteModal
                    open={quoteModal.open}
                    quoteIndex={quoteModal.quoteIndex}
                    onClose={refetch => this.handleCloseQuoteModal(refetch)}
                    parentForm={form}
                />

                <AshLocationHistoryModal
                    open={showAshLocationHistoryModal}
                    onClose={() => this.handleCloseAshLocationHistoryModal()}
                    onCancel={() => this.handleCloseAshLocationHistoryModal()}
                    onSubmit={ashLocationHistory => this.handleSaveAshLocationHistoryModal(ashLocationHistory)}
                    ashLocationHistory={editAshLocationHistory}
                    name={'editAshLocationHistory'}
                />
            </Grid>
        );
    }

    onAfterAppointmentAddOrUpdate(appointment) {
        const { form } = this.props;
        const Appointments = form.getField('Appointments');
        const foundIndex = Appointments.findIndex(e => e.ID === appointment.ID);
        if (foundIndex >= 0) {
            Object.assign(Appointments[foundIndex], appointment);
        } else {
            Appointments.push(appointment);
        }
        form.setField({ Appointments }, true);
    }

    renderHeader() {
        const { itemId, form } = this.props;
        const enquiryID = form.getField('LegacyKey');
        const enquiryType = form.getField('EnquiryType');

        const title = (itemId && itemId > 0 ? '' : 'New ') + (enquiryType ? enquiryType : '') + ' Enquiry';
        let subtitle = '';
        if (enquiryID) {
            subtitle =
                itemId && itemId > 0
                    ? joinDefined(
                          [
                              enquiryID,
                              form.getField('DeceasedFirstName'),
                              form.getField('DeceasedMiddleName'),
                              form.getField('DeceasedSurname')
                          ],
                          ' '
                      )
                    : '';
        }

        return (
            <ViewHeader title={title} subtitle={subtitle}>
                <RecordDrawer enquiryCMId={itemId} legacyKey={enquiryID} />
            </ViewHeader>
        );
    }

    renderEnquiryDetails() {
        const { form } = this.props;
        let userName = '';
        if (form.getField('CreatedBy')) {
            userName += (form.getField('CreatedBy').FirstName || '') + ' ' + (form.getField('CreatedBy').Surname || '');
            userName = userName.trim();
        }
        if (!userName) userName = form.getField('CreatedBy') && form.getField('CreatedBy').Email;
        if (!userName) userName = '(unknown)';

        return (
            <Inline className="enquiry-header" alignment={inlineAlignment.rightAlignSiblings} center>
                <div>
                    <div className="sort-by-container">
                        <Label className="label brand">Type</Label>
                        <Select
                            required
                            name="EnquiryType"
                            placeholder="Select a type of Enquiry"
                            options={EnquiryType}
                            form={form}
                        />
                    </div>
                </div>
                <Inline center>
                    <h3>
                        Date Created:
                        <span className="regular">{' ' + moment(form.getField('Created')).format('Do MMMM Y')}</span>
                    </h3>
                    <h3>
                        By: <span className="regular">{userName}</span>
                    </h3>
                </Inline>
            </Inline>
        );
    }

    renderLeftColumn() {
        return (
            <Fragment>
                {this.renderEnquiryMadeBy()}
                {this.renderAuthorisedContact()}
                {this.renderRequestedOnBehalfOf()}
            </Fragment>
        );
    }

    handleSelectBusinessContact(propertyName, addressBook) {
        const { form } = this.props;
        delete addressBook['__typename'];

        if (!!addressBook) {
            form.setState({ [propertyName]: addressBook });
        }
    }

    renderEnquiryMadeBy() {
        const { form } = this.props;
        const businessContact = form.getField('Business');

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Enquiry Made By</h4>
                </Grid>

                <Grid item>
                    <Checkbox
                        label="The enquiry made by an organisation or business?"
                        name="EnquiryByBusiness"
                        form={form}
                    />
                </Grid>
                {(!!form.getField('EnquiryByBusiness') && (
                    <Fragment>
                        {(!businessContact && (
                            <Grid item>
                                <ContactLookup
                                    name="Business.ID"
                                    form={form}
                                    onSelect={(_, addressBook) =>
                                        this.handleSelectBusinessContact('Business', addressBook)
                                    }
                                    onAddNewContact={addressBook =>
                                        this.handleSelectBusinessContact('Business', addressBook)
                                    }
                                />
                            </Grid>
                        )) || (
                            <Grid item>
                                <div style={{ float: 'right', marginTop: -24 }}>
                                    <Button variant="link-orange" onClick={() => form.setField({ Business: null })}>
                                        Change Contact
                                    </Button>
                                </div>
                                <div className="container">
                                    <ContactCard
                                        variant="fullwidth" //Do no remove
                                        contact={businessContact}
                                    />
                                </div>
                            </Grid>
                        )}
                    </Fragment>
                )) || (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <Select
                                required
                                label="Relationship to the client - their"
                                options={RelationshipToDeceasedOptions}
                                form={form}
                                name="Enquirer.RelationshipToDeceased"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Select required label="Title" options={TitleOptions} form={form} name="Enquirer.Title" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField required label="First name" form={form} name="Enquirer.FirstName" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Middle names (optional)" form={form} name="Enquirer.MiddleName" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Surname" form={form} name="Enquirer.Surname" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField required label="Primary number" form={form} name="Enquirer.PrimaryNumber" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Secondary number (optional)"
                                form={form}
                                name="Enquirer.SecondaryNumber"
                            />
                        </Grid>

                        <Grid item>
                            <TextField
                                label="Email"
                                form={form}
                                name="Enquirer.Email"
                                disabled={!!form.getField('Enquirer.NoEmailAvailable')}
                                required={!form.getField('Enquirer.NoEmailAvailable')}
                            />
                            <Checkbox label="No Email Available" form={form} name="Enquirer.NoEmailAvailable" />
                        </Grid>

                        <Grid item>
                            <Label>Enquirer's Residential Address</Label>
                            <AddressAutocomplete
                                placeholder="Search for residential address..."
                                form={form}
                                allowCustomAddress
                                name="Enquirer.ResidentialAddress"
                                componentFields={createAddressComponentFields('Enquirer.ResidentialAddress')}
                            />
                        </Grid>

                        <Grid item>
                            <Inline center>
                                <Label>Postal Address</Label>
                                <Checkbox
                                    label="Same as Enquirer's Residential Address"
                                    form={form}
                                    name="Enquirer.PostalAddressSameAsResidentialAddress"
                                />
                            </Inline>
                        </Grid>

                        <Grid item>
                            <Inline center>
                                {!form.getField('Enquirer.PostalAddressSameAsResidentialAddress') && (
                                    <AddressAutocomplete
                                        placeholder="Search for postal address..."
                                        form={form}
                                        allowCustomAddress
                                        name="Enquirer.PostalAddress"
                                        componentFields={createAddressComponentFields('Enquirer.PostalAddress')}
                                    />
                                )}
                            </Inline>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderAuthorisedContact() {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <Inline center>
                        <div>
                            <h4>Authorised Contact</h4>
                        </div>
                        <Checkbox label="Same as applicant" form={form} name="ClientSameAsEnquirer" />
                    </Inline>
                </Grid>

                {!form.getField('ClientSameAsEnquirer') && (
                    <Fragment>
                        <Grid item xs={12} sm={6}>
                            <Select label="Title" options={TitleOptions} form={form} name="Client.Title" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="First name" form={form} name="Client.FirstName" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Middle names (optional)" form={form} name="Client.MiddleName" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Surname" form={form} name="Client.Surname" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Primary number" form={form} name="Client.PrimaryNumber" />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <TextField label="Secondary number (optional)" form={form} name="Client.SecondaryNumber" />
                        </Grid>

                        <Grid item>
                            <TextField label="Email" form={form} name="Client.Email" />
                            <Checkbox label="No Email Available" form={form} name="Client.NoEmailAvailable" />
                        </Grid>

                        <Grid item>
                            <Label>Authorised Contact's Residential Address</Label>
                            <AddressAutocomplete
                                placeholder="Search for residential address..."
                                form={form}
                                allowCustomAddress
                                name="Client.ResidentialAddress"
                                componentFields={createAddressComponentFields('Client.ResidentialAddress')}
                            />
                        </Grid>

                        <Grid item>
                            <Inline center>
                                <Label>Postal Address</Label>
                                <Checkbox
                                    label="Same as Authorised Contact's Residential Address"
                                    form={form}
                                    name="Client.PostalAddressSameAsResidentialAddress"
                                />
                            </Inline>
                        </Grid>

                        <Grid item>
                            <Inline center>
                                {!form.getField('Client.PostalAddressSameAsResidentialAddress') && (
                                    <AddressAutocomplete
                                        placeholder="Search for postal address..."
                                        form={form}
                                        allowCustomAddress
                                        name="Client.PostalAddress"
                                        componentFields={createAddressComponentFields('Client.PostalAddress')}
                                    />
                                )}
                            </Inline>
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderRequestedOnBehalfOf() {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Quote Requested on behalf of</h4>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="First name" name="Deceased.FirstName" form={form} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Middle names (optional)" name="Deceased.MiddleName" form={form} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField label="Surname" name="Deceased.Surname" form={form} />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <Select label="Gender" options={GenderOptions} name="Deceased.Gender" form={form} />
                </Grid>

                <Grid item>
                    <Label>Residential Address</Label>
                    <AddressAutocomplete
                        placeholder="Search for residential address..."
                        form={form}
                        allowCustomAddress
                        name="Deceased.ResidentialAddress"
                        componentFields={createAddressComponentFields('Deceased.ResidentialAddress')}
                    />
                </Grid>

                <Grid item>
                    <Label>Date of Birth</Label>
                </Grid>

                {!form.getField('Deceased.DateOfBirthUnknown') && (
                    <Grid item xs={12} sm={6}>
                        <TextField type="date" notFuture name="Deceased.DateOfBirth" form={form} />
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                    <Checkbox label="DOB Unknown" name="Deceased.DateOfBirthUnknown" form={form} />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Button variant="primary" onClick={e => this.openSearchModal(form)}>
                        <SearchIcon />
                        <span>Prefill from existing funeral...</span>
                    </Button>
                </Grid>

                <Grid item>
                    <Label>Current Situation</Label>
                    <Select name="Deceased.Situation" options={CurrentSituation} form={form} />
                </Grid>

                {form.getField('Deceased.Situation') === 'Deceased' && (
                    <Fragment>
                        <Grid item>
                            <Label>Date of Death</Label>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Select
                                options={DeathTimingOptions}
                                name="Deceased.DateOfDeathTiming"
                                form={form}
                                required={form.getField('Deceased.Situation') === 'Deceased'}
                            />
                        </Grid>

                        {form.getField('Deceased.DateOfDeathTiming') !== DeathTimingOption.UNKNOWN.value &&
                            (form.getField('Deceased.DateOfDeathTiming') === DeathTimingOption.BETWEEN.value ? (
                                <Fragment>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            name="Deceased.DateOfDeath1"
                                            type="date"
                                            notFuture
                                            form={form}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <TextField
                                            name="Deceased.DateOfDeath2"
                                            type="date"
                                            notFuture
                                            form={form}
                                            required
                                        />
                                    </Grid>
                                </Fragment>
                            ) : (
                                <Grid item xs={6} sm={5} lg={5}>
                                    <TextField
                                        name="Deceased.DateOfDeath1"
                                        type="date"
                                        notFuture
                                        form={form}
                                        required
                                    />
                                </Grid>
                            ))}

                        <Grid item>
                            <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                                <Inline center>
                                    <Label>Place of Death</Label>
                                    <Checkbox
                                        label="Same as residential address"
                                        name="Deceased.PlaceOfDeathUseResidentialAddress"
                                        form={form}
                                    />
                                </Inline>
                                {/*<a variant="link-orange">Add new</a>*/}
                            </Inline>
                            {!form.getField('Deceased.PlaceOfDeathUseResidentialAddress') && (
                                <AddressAutocomplete
                                    placeholder="Search for place of death..."
                                    form={form}
                                    allowCustomAddress
                                    name="Deceased.PlaceOfDeath"
                                    componentFields={createAddressComponentFields('Deceased.PlaceOfDeath')}
                                />
                            )}
                        </Grid>
                    </Fragment>
                )}
            </Grid>
        );
    }

    renderRightColumn() {
        const { form } = this.props;

        return (
            <Fragment>
                {form.getField('EnquiryType') === 'Plaque' && this.renderPlaqueLocation()}

                {this.renderQuotes()}
                {this.renderArrangementAppointment()}

                {this.renderAssociatedOrders()}
                {form.getField('EnquiryType') === 'Cremation' && this.renderCremationDetails()}
                {form.getField('EnquiryType') === 'Plaque' && this.renderAshLocation()}
                {form.getField('ID') &&
                    (form.getField('EnquiryType') === 'Cremation' || form.getField('EnquiryType') === 'Plaque') &&
                    this.renderRelatedRecords()}
                {this.renderEnquiryNotes()}
            </Fragment>
        );
    }

    renderPlaqueLocation() {
        const { form } = this.props;
        const location = form.getField('PlaqueLocation');

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Plaque Location</h4>
                </Grid>
                {(!location && (
                    <Grid item>
                        <ContactLookup
                            name="PlaqueLocation.ID"
                            form={form}
                            onSelect={(_, addressBook) =>
                                this.handleSelectBusinessContact('PlaqueLocation', addressBook)
                            }
                            onAddNewContact={addressBook =>
                                this.handleSelectBusinessContact('PlaqueLocation', addressBook)
                            }
                        />
                    </Grid>
                )) || (
                    <Grid item>
                        <div style={{ float: 'right', marginTop: -24 }}>
                            <Button variant="link-orange" onClick={() => form.setField({ PlaqueLocation: null })}>
                                Change Location
                            </Button>
                        </div>
                        <div className="container">
                            <ContactCard
                                variant="fullwidth" //Do no remove
                                contact={location}
                            />
                        </div>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderQuotes() {
        const { form } = this.props;
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Quotes</h4>
                </Grid>

                {!Number(form.getField('ID')) && (
                    <Grid item>
                        <p>Quotes can be added after the enquiry is saved.</p>
                    </Grid>
                )}

                {!!Number(form.getField('ID')) ? (
                    <Query
                        query={readEnquiryQuotes}
                        variables={{ id: form.getField('ID') }}
                        fetchPolicy="cache-and-network"
                        notifyOnNetworkStatusChange
                    >
                        {({ loading, data, error, refetch, networkStatus }) => {
                            this.refetchQuotes = refetch;

                            if (!!loading && (!data || networkStatus === 1))
                                return (
                                    <Grid item>
                                        <Spinner /> Loading quotes...
                                    </Grid>
                                );
                            if (!!error)
                                return (
                                    <Grid item>
                                        <AlertBar variant="error">Error loading quotes.</AlertBar>
                                    </Grid>
                                );
                            const items = (data && data.readCMQuotes && data.readCMQuotes.edges.map(e => e.node)) || [];
                            return (
                                <Fragment>
                                    {!!items && items.length > 0 && (
                                        <Grid item>
                                            <div style={{ position: 'relative' }}>
                                                {networkStatus !== 7 && (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            bottom: 0,
                                                            left: 0,
                                                            right: 0,
                                                            opacity: 0.5,
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <Spinner />
                                                    </div>
                                                )}
                                                <Table>
                                                    <HeaderRow pad>
                                                        {quoteColumns.map(column => (
                                                            <Cell key={column.label}>{column.label}</Cell>
                                                        ))}
                                                    </HeaderRow>
                                                    {items.map((row, index) => {
                                                        return (
                                                            <Row pad key={row.ID}>
                                                                {quoteColumns.map(column => {
                                                                    return (
                                                                        <Cell
                                                                            key={column.label}
                                                                            dataLabel={column.label}
                                                                        >
                                                                            {column.renderCell(row, this, index)}
                                                                        </Cell>
                                                                    );
                                                                })}
                                                            </Row>
                                                        );
                                                    })}
                                                </Table>
                                            </div>
                                        </Grid>
                                    )}

                                    <Grid item>
                                        <Inline>
                                            {!!items && !items.find(e => e.Accepted) && (
                                                <Button variant="primary" onClick={() => this.onClickAddQuote(refetch)}>
                                                    Add {items.length ? 'another' : 'a'} quote...
                                                </Button>
                                            )}
                                        </Inline>
                                    </Grid>
                                </Fragment>
                            );
                        }}
                    </Query>
                ) : (
                    <Grid item>
                        <Inline>
                            <Button variant="primary" disabled>
                                + Add a quote
                            </Button>
                        </Inline>
                    </Grid>
                )}
            </Grid>
        );
    }

    renderArrangementAppointment() {
        const { form } = this.props;
        const items = form.getField('Appointments');
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Appointments</h4>
                </Grid>
                {!Number(form.getField('ID')) && (
                    <Grid item>
                        <p>Appointments can be added after the enquiry is saved.</p>
                    </Grid>
                )}

                {!!items && items.length > 0 && (
                    <Grid item>
                        <Table>
                            <HeaderRow pad>
                                {appointmentColumns.map(column => (
                                    <Cell key={column.label}>{column.label}</Cell>
                                ))}
                            </HeaderRow>
                            {items.map((row, index) => {
                                return (
                                    <Row pad key={row.ID}>
                                        {appointmentColumns.map(column => {
                                            return (
                                                <Cell key={column.label} dataLabel={column.label}>
                                                    {column.renderCell(row, this, index)}
                                                </Cell>
                                            );
                                        })}
                                    </Row>
                                );
                            })}
                        </Table>
                    </Grid>
                )}

                <Grid item>
                    <Inline>
                        <Button
                            disabled={!Number(form.getField('ID'))}
                            variant="primary"
                            onClick={() => this.handleShowAppointmentModal()}
                        >
                            Add Appointment...
                        </Button>
                        <Button variant="secondary" href="/calendar/" target="_blank">
                            View Calendar
                        </Button>
                    </Inline>
                </Grid>
            </Grid>
        );
    }

    renderAssociatedOrders() {
        const { form } = this.props;
        return <AssociatedOrders parentType="CMEnquiry" parentID={form.getField('ID')} />;
    }

    renderCremationDetails() {
        const { form } = this.props;
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Requested Cremation Date and Time</h4>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label="Cremation Date" type="date" form={form} name={'CremationDate'} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField label="Cremation Time" type="time" form={form} name={'CremationTime'} />
                </Grid>
            </Grid>
        );
    }

    renderAshLocation() {
        const { form } = this.props;
        const ashLocations = form.getState('AshLocations') || [];
        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Transfer of Ashes</h4>
                </Grid>

                {ashLocations.length > 0 &&
                    ashLocations.map((ashLocation, index) => (
                        <Grid item key={ashLocation.ID}>
                            <Inline alignment={inlineAlignment.rightAlignSiblings} center>
                                <p>{ashLocation.Location}</p>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowAshLocationHistoryModal(index)}
                                >
                                    <EditIcon />
                                </IconButton>
                                <IconButton
                                    className="icon"
                                    onClick={() => this.handleShowDeleteAshLocationAlertModal()}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                {/*<IconButton className="icon" onClick={() => this.handleDeleteAshLocationHistory(index)}><DeleteIcon /></IconButton>*/}
                            </Inline>
                        </Grid>
                    ))}

                <Grid item>
                    <Button variant="primary" onClick={() => this.handleShowAshLocationHistoryModal()}>
                        Add Transfer of Ashes...
                    </Button>
                </Grid>
            </Grid>
        );
    }

    renderRelatedRecords() {
        const { form, context } = this.props;
        const { generatingRelatedRecord, generatedRelatedRecord } = this.state;

        let canHaveCremationOrder = false;
        let canHavePlaqueOrder = false;
        let relatedRecord = null;
        let canHaveRelatedRecord;
        let relatedRecordType = null;
        let relatedRecordListLink = null;

        if (
            form.getField('EnquiryType') === 'Cremation' &&
            form.getField('Deceased.Situation') ===
                'Deceased' /* &&
            !!form.getField('Quotes') &&
            !!form.getField('Quotes').find(e => e.Accepted)*/
        ) {
            canHaveCremationOrder = true;
            relatedRecordType = 'Cremation';
            relatedRecordListLink = '/cremations/';
            relatedRecord = form.getField('CremationOrder') || generatedRelatedRecord;
        }

        if (
            form.getField('EnquiryType') === 'Plaque' &&
            form.getField('Deceased.Situation') ===
                'Deceased' /* &&
            !!form.getField('Quotes') &&
            !!form.getField('Quotes').find(e => e.Accepted)*/
        ) {
            canHavePlaqueOrder = true;
            relatedRecordType = 'Plaque';
            relatedRecordListLink = '/plaques/';
            relatedRecord = form.getField('PlaqueOrder') || generatedRelatedRecord;
        }

        canHaveRelatedRecord = canHaveCremationOrder || canHavePlaqueOrder;

        if (canHaveRelatedRecord) {
            return (
                <Grid container bucket>
                    <Grid item>
                        <h4>Related Records</h4>
                    </Grid>

                    {(relatedRecord && Number(relatedRecord.ID) > 0 && (
                        <Grid item>
                            <Button
                                variant="secondary"
                                onClick={() => context.props.history.push(relatedRecordListLink + relatedRecord.ID)}
                            >
                                View {relatedRecordType} {relatedRecord.LegacyKey}
                            </Button>
                        </Grid>
                    )) || (
                        <Grid item>
                            <Button
                                variant="confirmation"
                                disabled={generatingRelatedRecord || form.isDirty}
                                onClick={() => this.generateRelatedRecord(relatedRecordType)}
                            >
                                {!generatingRelatedRecord ? (
                                    'Generate ' + relatedRecordType + ' Record'
                                ) : (
                                    <Fragment>
                                        <Spinner /> Generating {relatedRecordType}...
                                    </Fragment>
                                )}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            );
        }

        return null;
    }

    generateRelatedRecord(relatedRecordType) {
        const { form } = this.props;

        const recordFieldName = relatedRecordType + 'Order';
        const canCreateRelatedRecord =
            form.getField('Deceased.Situation') === 'Deceased' &&
            // form.getField('Quotes') &&
            // form.getField('Quotes').find(e => !!e.Accepted) &&
            !(form.getField(recordFieldName) && form.getField(recordFieldName).ID !== '0');

        if (!canCreateRelatedRecord) {
            return Promise.resolve(false);
        }

        const me = this;
        me.setState({ generatingRelatedRecord: true });

        if (relatedRecordType === 'Cremation') {
            return createCremationAsync(form).then(
                ({ data }) => {
                    if (data.createCremationOrder) {
                        me.setState({
                            generatingRelatedRecord: false,
                            generatedRelatedRecord: data.createCremationOrder
                        });
                    }
                },
                e => this.handleMutateError(e)
            );
        } else if (relatedRecordType === 'Plaque') {
            return createPlaqueAsync(form).then(
                ({ data }) => {
                    if (data.createPlaqueOrder) {
                        me.setState({
                            generatingRelatedRecord: false,
                            generatedRelatedRecord: data.createPlaqueOrder
                        });
                    }
                },
                e => this.handleMutateError(e)
            );
        }
    }

    renderEnquiryNotes() {
        const { form } = this.props;

        return (
            <Grid container bucket>
                <Grid item>
                    <h4>Enquiry Notes</h4>
                </Grid>

                <Grid item>
                    <TextField multiline placeholder="Notes are written here..." name="Notes" form={form} />
                </Grid>

                {form.getField('OldNotes') && (
                    <Grid item>
                        <h5>Note History</h5>
                        <NotesReadOnly name="OldNotes" form={form} />
                    </Grid>
                )}
            </Grid>
        );
    }

    onClickViewQuote(index) {
        this.setState({
            quoteModal: {
                open: true,
                quoteIndex: index
            }
        });
    }

    onClickViewAppointment(id) {
        this.setState({ showAppointmentModal: true, appointmentId: id });
    }

    onClickAddQuote() {
        this.setState({
            quoteModal: {
                open: true,
                quoteIndex: null
            }
        });
    }

    handleCloseQuoteModal(refetch = false) {
        this.setState({
            quoteModal: {
                open: false,
                quoteIndex: null
            }
        });
        if (!!this.refetchQuotes && !!refetch)
            this.refetchQuotes().then(({ data }) => {
                const { form } = this.props;
                const Quotes = deleteTypeName(cloneDeep(data.readCMQuotes.edges.map(e => e.node)));
                form.setState({ Quotes });
            });
    }

    handleShowAppointmentModal() {
        this.setState({ showAppointmentModal: true, appointmentId: null });
    }

    handleCloseAppointmentModal() {
        this.setState({ showAppointmentModal: false, appointmentId: null });
    }

    handleShowAshLocationHistoryModal(index) {
        this.setState({ editAshLocationHistoryIndex: index });
        this.setState({ showAshLocationHistoryModal: true });
    }

    handleCloseAshLocationHistoryModal() {
        this.setState({ showAshLocationHistoryModal: false });
    }

    handleSaveAshLocationHistoryModal(ashLocation) {
        const { form } = this.props;
        const { editAshLocationHistoryIndex } = this.state;
        const AshLocations = form.getState('AshLocations') || [];

        if (
            editAshLocationHistoryIndex !== null &&
            editAshLocationHistoryIndex !== undefined &&
            AshLocations[editAshLocationHistoryIndex]
        ) {
            const existingAshLocationHistory = AshLocations[editAshLocationHistoryIndex];
            Object.assign(existingAshLocationHistory, ashLocation);
        } else {
            AshLocations.push(ashLocation);
        }
        this.setState({ showAshLocationHistoryModal: false });
        this.setState({ editAshLocationHistoryIndex: null });

        form.setState({ AshLocations });
        form.save();
    }

    handleDeleteAshLocationHistory(index) {
        const { form } = this.props;
        const AshLocations = form.getState('AshLocations') || [];

        if (index !== -1) {
            AshLocations.splice(index, 1);
            form.setState({ AshLocations });
        }
    }

    openSearchModal(form) {
        let searchModalSuggestion = joinDefined(
            [
                form.getField('Deceased.FirstName'),
                form.getField('Deceased.MiddleName'),
                form.getField('Deceased.Surname')
            ],
            ' '
        );
        this.setState({ openSearchModal: true, searchModalSuggestion });
    }

    copyDetailsFromFuneral(e, form) {
        if (!isNullOrUndefined(e)) {
            const state = copyFuneral(e);
            form.setField(state);
        }
        this.setState({ openSearchModal: false, searchModalSuggestion: null });
    }
    refetchQuotes = null;
}

export const quoteColumns = [
    {
        label: 'Quote',
        renderCell: (row, context, index) => {
            return (
                <Button variant="link-orange" onClick={() => context.onClickViewQuote(index)}>
                    #{row.ID}
                </Button>
            );
        }
    },
    {
        label: 'Type',
        renderCell: row => {
            return <span>{row.QuoteType}</span>;
        }
    },
    {
        label: 'Status',
        renderCell: row => (
            <span>
                {row.Accepted ? 'Accepted' : row.RespondedDate ? 'Declined' : row.Released ? 'Released' : 'Draft'}
            </span>
        )
    },
    {
        label: 'Created',
        renderCell: row => <span>{moment(row.Created).format('D/MM/YYYY h:mma')}</span>
    },
    {
        label: 'Amount',
        renderCell: row => <span>{prettyPrice(row.CalcQuoteTotal)}</span>
    },
    {
        label: 'Accepted',
        renderCell: row => {
            return row.Accepted ? <TickIcon /> : '';
        }
    }
];

export const appointmentColumns = [
    {
        label: 'Reason',
        renderCell: row => {
            return <span>{row.Reason}</span>;
        }
    },
    {
        label: 'Date/Time',
        renderCell: row => <span>{moment(row.Date + 'T' + row.TimeFrom).format('ddd Do MMM Y [from] LT')}</span>
    },
    {
        label: 'Staff',
        renderCell: row => (
            <span>
                {row.Members.map((user, i, a) => (
                    <p key={i}>
                        {joinDefined([user.FirstName, user.Surname], ' ')}{' '}
                        {!!user.Phone && prettyPhone(user.Phone, false)}
                        <br />
                        {!!user.Email && prettyEmail(user.Email, false)}
                    </p>
                ))}
            </span>
        )
    },
    {
        label: 'Location',
        renderCell: x => (
            <span>
                {x.LocationType === 'AddressBook'
                    ? !!x.LocationCM &&
                      x.LocationCM.Name +
                          ' - ' +
                          joinDefined([x.LocationCM.AddressLine1, x.LocationCM.AddressLine2, x.LocationCM.Suburb], ', ')
                    : (!!x.LocationResidentialAddress &&
                          joinDefined(
                              [
                                  x.LocationResidentialAddress.AddressLine1,
                                  x.LocationResidentialAddress.AddressLine2,
                                  x.LocationResidentialAddress.Suburb,
                                  x.LocationResidentialAddress.Country === 'Australia'
                                      ? x.LocationResidentialAddress.State
                                      : x.LocationResidentialAddress.Country
                              ],
                              ', '
                          )) ||
                      (x.Phone && <span>Phone: {prettyPhone(x.Phone)}</span>)}
            </span>
        )
    },
    {
        label: '',
        renderCell: (row, context) => {
            return (
                <IconButton className="icon" onClick={() => context.onClickViewAppointment(row.ID)}>
                    <EditIcon />
                </IconButton>
            );
        }
    }
];

export default {
    id: 'Enquiry',
    label: 'Enquiry',
    component: withStyles({})(Enquiry),
    validation: validation,
    fragment: EnquiryFragment,
    onLoad: data => {
        // create a stub to detect new notes on save!
        if (!!data.Notes) {
            data.OldNotes = '' + (data.Notes || '');
        }
        delete data.Notes;
    },
    formatSaveData: (saveData, state) => {
        if (isContactDefined(saveData.Business)) {
            saveData.BusinessID = state.Business.ID;
            delete saveData.Business;
        } else if (saveData.Business === null) {
            saveData.BusinessID = null;
            delete saveData.Business;
        }

        if (isContactDefined(saveData.PlaqueLocation)) {
            saveData.PlaqueLocationID = state.PlaqueLocation.ID;
            delete saveData.PlaqueLocation;
        } else if (saveData.PlaqueLocation === null) {
            saveData.PlaqueLocationID = null;
            delete saveData.PlaqueLocation;
        }
        delete saveData.Quotes;
        delete saveData.Appointments;
        delete saveData.AssociatedOrders;
    }
};
