import gql from 'graphql-tag';

export default gql`
    fragment PlaqueProduct on PlaqueProduct {
        ID
        Title
        UnitPrice
        Image {
            ... on Image {
                ID
                Name
                Size
                AbsoluteLink
            }
        }
    }
`;
