import React from 'react';

export default ({ dateTime, description }) => {
    return (
        <div className="appointment">
            <div className="time">{dateTime}</div>
            <div className="description">{description}</div>
        </div>
    );
};
