import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';
import FlexGrid from '../layout/FlexGrid';

class Graph extends Component {
    render() {
        const { variant = 'two' } = this.props;

        if (variant === 'one') return this.renderOne();

        if (variant === 'three') return this.renderThree();

        if (stringIsNullOrEmpty(variant) || variant === 'two') return this.renderTwo();

        if (variant === 'four') return this.renderFour();

        return <span>unknown modal variant {variant}</span>;
    }

    renderOne() {
        const { children, title } = this.props;
        return (
            <div className="one">
                {!!title && (
                    <div className="graph-title">
                        <p>{title}</p>
                    </div>
                )}
                <FlexGrid variant="no-wrap">{children}</FlexGrid>
            </div>
        );
    }

    renderTwo() {
        const { children, title } = this.props;
        return (
            <div className="two">
                {!!title && (
                    <div className="graph-title">
                        <p>{title}</p>
                    </div>
                )}
                <FlexGrid variant="no-wrap">{children}</FlexGrid>
            </div>
        );
    }

    renderThree() {
        const { children, title } = this.props;
        return (
            <div className="three">
                {!!title && (
                    <div className="graph-title">
                        <p>{title}</p>
                    </div>
                )}
                <FlexGrid variant="no-wrap">{children}</FlexGrid>
            </div>
        );
    }

    renderFour() {
        const { children, title } = this.props;
        return (
            <div className="four">
                {!!title && (
                    <div className="graph-title">
                        <p>{title}</p>
                    </div>
                )}
                <FlexGrid className="full-height">{children}</FlexGrid>
            </div>
        );
    }
}
export default withStyles({})(Graph);
