import gql from 'graphql-tag';
import { capitalize } from '../../utils/strings';

export const createTableQuery = (queryName, fragment, dataObject, filterByFragment, isSortable) => {
    const capitalizedQueryName = capitalize(queryName);
    const filterByInput = filterByFragment ? `$filterBy: [Filters]` : '';
    const sortByInput = isSortable ? `$sortBy: [SortField]` : '';
    const filterByInputVar = filterByFragment ? `filterBy: $filterBy` : '';
    const sortByInputVar = isSortable ? `sortBy: $sortBy` : '';
    //const filterByInfo = filterByFragment || '';

    return gql`
		${fragment}
		query ${capitalizedQueryName}(
            $limit: Int
            $offset: Int
            $contains: String
			$filters: [Filters] 
			${filterByInput}
			${sortByInput}
		){
			${queryName}(
			    limit: $limit
			    offset: $offset 
			    contains: $contains
				filters: $filters
				${filterByInputVar}
				${sortByInputVar}
			){
				edges {
					node {
						ID
						...${fragment.definitions[0].name.value}
					}
				}
				pageInfo {
                    hasNextPage
                    hasPreviousPage
                    totalCount
                }
                
			}
			${getTypeQuery(`SortField`)}
		}`;
};

export const getTypeQuery = typeName => {
    return `
		__type(name:"${typeName}"){
			name
			kind
			inputFields {
				name
				type {
					name
					kind
					ofType {
						kind
						name
						description
						enumValues {
							name
							description
						}
					}
				}
			}
		}`;
};
