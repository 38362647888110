import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/es/IconButton/IconButton';
import StarIcon from '../icon/StarIcon';

class TaskStar extends Component {
    render() {
        const { disabled = null } = this.props;
        const checked = this.getValue();
        return (
            <IconButton disabled={disabled} className="task-star" onClick={() => this.onCheckChanged(!checked)}>
                <StarIcon filled={checked ? 'filled' : null} className="star-icon" />
            </IconButton>
        );
    }

    onCheckChanged(value) {
        const { onChange } = this.props;

        if (onChange) onChange(value);
    }

    getValue() {
        return this.props.value || false;
    }
}

export default withStyles({})(TaskStar);
