import React from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = () => ({
    root: {
        display: 'inline-block',
        whiteSpace: 'nowrap'
    },

    capsuleLegend: {
        borderRadius: '25px',
        background: '#FFFFFF',
        foreground: '#000000',
        border: '1px solid #000000',
        borderColor: '#000000',
        padding: '2px 1rem',
        fontSize: '12px',
        width: 120,
        textAlign: 'center',
        lineHeight: '1rem !important'
    },

    dotLegend: {
        fontSize: '12px',
        display: 'flex',
        alignItems: 'center'
    },

    dotLegendDot: {
        height: '18px',
        width: '18px',
        borderRadius: '25px',
        display: 'inline-block'
    },

    dotLegendLabel: {
        margin: '0px 0px 0px 8px',
        whiteSpace: 'pre'
    }
});

export const CapsuleLegend = withStyles(styles)(
    ({ key, classes, text, background, foreground, className, ...props }) => {
        return (
            <span
                key={key}
                className={cx(classes.capsuleLegend, className)}
                style={{
                    background: background,
                    color: foreground,
                    borderColor: foreground
                }}
                {...props}
            >
                {text}
            </span>
        );
    }
);

export const DotLegend = withStyles(styles)(({ key, classes, text, dotColor, children, className, ...props }) => {
    return (
        <span className={cx(classes.dotLegend, className)} {...props} key={key}>
            <span
                className={classes.dotLegendDot}
                style={{
                    background: dotColor
                }}
            >
                {children}
            </span>
            <span className={classes.dotLegendLabel}>{text}</span>
        </span>
    );
});
