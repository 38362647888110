import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { stringIsNullOrEmpty } from '../../utils/strings';

export default withStyles({})(({ label, variant }) => {
    let cssVariant = null;
    if (!stringIsNullOrEmpty(variant)) cssVariant = `attention-required--${variant}`;

    return (
        <div className={`attention-required ${cssVariant || ''}`}>
            <div className="attention" />
            <p>{label}</p>
        </div>
    );
});
