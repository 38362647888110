import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default props => (
    <SvgIcon viewBox="0 0 511.98 512.02" {...props}>
        <path
            fill="currentColor"
            d="M464,4.3,16,262.7C-7,276-4.7,309.9,19.8,320L160,378V480c0,30.2,37.8,43.3,56.7,20.3l60.7-73.8,126.4,52.2A32,32,0,0,0,447.6,454l64-417.1C515.7,10.2,487-9,464,4.3ZM192,480V391.2l54.5,22.5Zm224-30.9L209.8,363.9,409.3,128.1c4.8-5.6-2.9-13.2-8.5-8.4L145.5,337.3,32,290.5,480,32Z"
        />
    </SvgIcon>
);