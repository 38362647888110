import React from 'react';

const ColourBlock = ({ colours, label }) => {
	return (
		<div className="colour-block">
			<div className="label">{label}</div>
			<div className="block-container">
				{colours.map(colour => (
					<div
						key={colour}
						className="block"
						style={{ backgroundColor: colour }}
					>
						{colour}
					</div>
				))}
			</div>
		</div>
	);
};

export default ColourBlock;
