import React, { Component, Fragment } from 'react';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TickIcon from '../icon/TickIcon';
import { getProperty, setPropertyWrapper } from '../../utils/objects';
import Spinner from '../loading/Spinner';

class TickableCircle extends Component {
    render() {
        const { classes, className, disabled, loading } = this.props;
        const checked = this.getValue();

        const title = checked ? 'Task is completed' : 'Mark as completed';

        return (
            <Fragment>
                <IconButton
                    disabled={disabled || loading}
                    title={title}
                    className={cx(classes.button, className, checked ? classes.checked : '')}
                    onClick={e => this.onCheckChanged(!checked, e)}
                >
                    {!!loading ? <Spinner className={classes.spinner} /> : <TickIcon className={classes.icon} />}
                </IconButton>
            </Fragment>
        );
    }

    getValue() {
        const { name, form, convertFrom } = this.props;
        if (form && name) {
            const formValue = getProperty(form, name);
            return convertFrom ? convertFrom(formValue) : formValue;
        }
        return this.props.value || false;
    }

    onCheckChanged(value, e) {
        e.stopPropagation();
        const { onChange, name, form, convertTo } = this.props;
        const formValue = convertTo ? convertTo(value) : value;
        if (form && name) setPropertyWrapper(form, { [name]: formValue });
        if (onChange) onChange(value, formValue);
    }
}

const styles = ({ palette }) => ({
    spinner: {
        marginTop: -3,
        flexShrink: 0
    },
    icon: {
        color: '#dedede'
    },
    button: {
        height: '32px',
        width: '32px',
        borderRadius: '50px',
        border: '1px solid ' + palette.action.save,
        backgroundColor: '#FFF',
        padding: '4px',
        '&:hover': {
            backgroundColor: palette.action.save + '!important'
        }
    },
    checked: {
        backgroundColor: palette.action.save + '!important',
        '&:hover': {
            backgroundColor: palette.action.save + '66!important'
        },
        '& svg': { color: '#fff' }
    }
});

export default withStyles(styles)(TickableCircle);
