import React, { Component, Fragment } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '../form/Button';
import TextField from '../form/TextField';
import Modal from './Modal';

export default class CustomAddressModal extends Component {
    static defaultProps = {
        title: 'Enter specific address details',
        onClose: () => {}
    };

    state = {};

    render() {
        const { open, label = 'Address' } = this.props;
        return (
            <Modal
                open={open}
                variant="primary"
                canClickOut="true"
                title={label}
                subtitle="Add Specific Address"
                aria-labelledby={this.getId()}
            >
                {this.renderForm()}
            </Modal>
        );
    }

    renderForm = () => {
        const { customAddressFields } = this.props;
        if (!customAddressFields) return <div />;

        return (
            <Fragment>
                {customAddressFields.line1 && this.renderField('line1', 'Address line 1')}
                {customAddressFields.line2 && this.renderField('line2', 'Address line 2')}
                {customAddressFields.city && this.renderField('city', 'Suburb/City')}
                {customAddressFields.state && this.renderField('state', 'State')}
                {customAddressFields.code && this.renderField('code', 'Postcode')}
                {customAddressFields.country && this.renderField('country', 'Country')}
                <DialogActions>
                    <Button onClick={() => this.onClose()} variant="modal-action modal-action--cancel">
                        Cancel
                    </Button>
                    <Button onClick={this.onApply} variant="modal-action modal-action--ok">
                        Ok
                    </Button>
                </DialogActions>
            </Fragment>
        );
    };

    renderField(name, label, autoFocus, required) {
        const state = this.state;
        return (
            <TextField
                label={label}
                name={name}
                autoFocus={autoFocus}
                margin="dense"
                required={required}
                value={state[name] || ''}
                onChange={this.onChange}
            />
        );
    }

    getId() {
        const { title } = this.props;
        return `CustomAddressModal-${title}`;
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();

        //clear the state
        this.setState({
            line1: null,
            line2: null,
            city: null,
            state: null,
            code: null,
            country: null
        });
    };

    onApply = () => {
        const { onApply, customAddressFields, form } = this.props;
        const state = this.state;

        if (customAddressFields && form) {
            let address = {};
            Object.keys(customAddressFields)
                .filter(fieldKey => customAddressFields[fieldKey])
                .forEach(fieldKey => (address[customAddressFields[fieldKey]] = state[fieldKey]));

            form.setState({ ...address });
        }

        if (onApply) onApply();

        this.onClose();
    };
}
